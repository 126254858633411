import _ from 'lodash/fp';

export const needUpdateKeys = (keys) => (prev, next) =>
  _.flow(
    _.map((k) => _.isEqual(_.get(k, prev), _.get(k, next))),
    _.some(_.isEqual(false)),
    (x) => !x,
  )(keys);

export const dispatchBannerMessage = ({ type = 'error', content = null, error = {}, setDispatch, DISPATCH_FNS }) => {
  setDispatch(DISPATCH_FNS.bannerMsgState.setTypeContent, {
    content: null,
  });
  setTimeout(() => {
    if (type === 'error') {
      setDispatch(DISPATCH_FNS.bannerMsgState.setTypeContent, {
        content: _.getOr('Unknown Error', 'message', error),
        type: 'error',
      });
    } else {
      setDispatch(DISPATCH_FNS.bannerMsgState.setTypeContent, {
        content,
        type,
      });
    }
  }, 0);
};

// export const useProxyData = (proxyData, initDataVal = []) => {
//   const [data, setData] = React.useState(initDataVal);
//   React.useEffect(() => {

//     }
//   }, [proxyData]);
//   return data;
// };
