import React from 'react';
import { CREATE_WALLET_ASSOCIATION_FIELDS, generateWalletTasFields, generateWalletIpacFields } from '../forms/consts';

const CREATE_WALLET_TAS_FIELDS = generateWalletTasFields();
const CREATE_WALLET_IPAC_FIELDS = generateWalletIpacFields();
const apiValidationSectionsMapping = {
  tasSection: CREATE_WALLET_TAS_FIELDS,
  ipacSection: CREATE_WALLET_IPAC_FIELDS,
  associationSection: CREATE_WALLET_ASSOCIATION_FIELDS,
};

export const applyApiValidation = (apiValidationErrors, setError) => {
  const validationErrors = [];
  // eslint-disable-next-line array-callback-return
  apiValidationErrors.map((validationError) => {
    validationError?.fields.map((field) => {
      if (validationErrors.find((error) => error.name === field)) {
        validationErrors[
          validationErrors.findIndex((el) => el.name === field)
        ].message += `/n ${validationError.message}`;
      } else {
        validationErrors.push({
          name: field,
          message: validationError.message,
          section: validationError.section,
        });
      }
      return validationErrors;
    });
  });
  validationErrors.map((error) =>
    setError(apiValidationSectionsMapping[error.section][error.name].name, {
      type: 'custom',
      message: (
        <div className="text-bold">
          {error.message.split('/n').map((errorMessage, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${error.name}-${index}`}>{errorMessage}</span>
          ))}
        </div>
      ),
    }),
  );
};

export const isLeasingType = (salesGroupId) => salesGroupId === 3 || salesGroupId === 4;

export const extractMonthYear = (statePlateExp) => {
  if (!statePlateExp || statePlateExp.length === 0) {
    return null;
  }
  const month = statePlateExp.substring(0, 2);
  const year = statePlateExp.substring(3, 7);
  return parseInt([year, month].join(''), 10);
};
