import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { AddressConfirmationModal } from '@gsa/afp-component-library';
import { VALIDATE_ADDRESS } from 'services/data-layer/usps-validation';

export const AddressValidator = ({ addressToValidate, setAddress }) => {
  const [validatedAddress, setValidatedAddress] = useState(null);

  const [uspsValidation, { error, loading }] = useLazyQuery(VALIDATE_ADDRESS, {
    onCompleted: (validatedAddressData) => {
      if (validatedAddressData?.validateAddress) {
        const { address, address2, city, state, zip } = validatedAddressData.validateAddress;
        setValidatedAddress({
          address,
          address2,
          city,
          state,
          zip,
          countryCode: 'US',
        });
      }
    },
  });

  useEffect(() => {
    if (addressToValidate && Object.keys(addressToValidate).length) {
      uspsValidation({
        variables: { ...addressToValidate },
      });
    }
  }, [addressToValidate]);

  return (
    <AddressConfirmationModal
      validating={loading}
      hasValidationError={!!error}
      addressToValidate={addressToValidate}
      validatedAddress={validatedAddress}
      saveButtonText="Save and continue"
      onSave={setAddress}
    />
  );
};

AddressValidator.propTypes = {
  addressToValidate: PropTypes.shape({}).isRequired,
  setAddress: PropTypes.func.isRequired,
};
