import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

export const ResolveModal = ({
                                id,
                                variant,
                                title,
                                onClose,
                                onUpdate,
                                updating,
                                updateButtonText,
                                updateButtonVariant,
                                cancelButtonText,
                                children,
                            }) => (
                              <div className="afp-modal-wrapper" id={id}>
                                <div className="afp-modal-overlay">
                                  <Modal
                                    variant={variant}
                                    title={
                                      <h1 className="margin-bottom-1" data-testid="resolve-modal-title">
                                        {title}
                                      </h1>
                }
                                    onClose={onClose}
                                    actions={
                                      <>
                                        <Button 
                                          variant="unstyled" 
                                          onClick={onClose} 
                                          id="cancel-btn" 
                                          label={cancelButtonText} 
                                        />
                                        {updateButtonText && (
                                        <Button 
                                          variant={updateButtonVariant} 
                                          onClick={onUpdate} 
                                          disabled={updating} 
                                          label={updateButtonText} 
                                        />
                        )}
                                      </>
                }
                                  >
                                    {children}
                                  </Modal>
                                </div>
                              </div>
);

ResolveModal.defaultProps = {
    variant: "large",
    updating: false,
    updateButtonText: PropTypes.string,
    updateButtonVariant: PropTypes.string,
    cancelButtonText: "Cancel",
};

ResolveModal.propTypes = {
    id: PropTypes.string.isRequired,
    variant: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    updateButtonText: PropTypes.string,
    updateButtonVariant: PropTypes.string,
    cancelButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
};
