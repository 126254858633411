import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/* eslint-disable react/prop-types */
const WelcomeMessage = ({ message, className }) => {
  return (
    <div
      data-testid="message"
      className={classnames('usa-prose margin-bottom-4', className)}
    >
      <p>{message}</p>
    </div>
  );
};

WelcomeMessage.prototype = {
  message: PropTypes.string,
};

export default WelcomeMessage;
