import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Label, DatePicker, ErrorMessage, Textbox, Alert, Icon, Spinner } from '@gsa/afp-component-library';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { UTCDateStrToUS, getTomorrowDate, getTomorrowStr, USDateStrToUTC } from 'components/helpers/afp-bm-date';
import { classNameErrBox, classNameErrInput, classNameErrDatePicker } from 'components/helpers/afp-bm-helpers';
import useCharCounter from 'components/widgets/char-counter';
import { STATUS } from 'components/helpers/constants';
import {
  GET_DISTINCT_LEASE_RATE_CODE,
  GET_LEASE_RATE_BY_LRC_START_DATE,
  UPDATE_LEASE_RATE,
  CREATE_LEASE_RATE,
} from './lease-rate-helper';
import './style/lease-rate-update-modal.scss';

const tomorrowDate = getTomorrowDate();
const tomorrowStr = getTomorrowStr();
const MAX_LEN_DESC = 200;

const schema = yup.object().shape({
  leaseRateCode: yup
    .string()
    .required('Code is a required field')
    .matches('^[a-zA-Z0-9]{4,6}$', 'Code must be 4-6 characters of letters or numbers')
    .when('isCreate', {
      is: true,
      then: yup
        .string()
        .test(
          'check duplicate',
          'Code already exists.',
          (val, ctx) => !ctx.parent.distinctCodes?.includes(val?.toUpperCase()),
        ),
    }),
  leaseRateDescription: yup
    .string()
    .nullable()
    .required('Description is a required field')
    .min(1, 'Description must not be empty')
    .max(200, 'Description cannot exceed 200 characters'),
  monthlyRate: yup
    .string()
    .required('Monthly rate is a required field')
    // eslint-disable-next-line
    .matches(/^\d{1,9}[\.]\d{1,2}$/, 'Please enter up to 2 digits after the decimal'), // prettier-ignore
  mileageRate: yup
    .string()
    .required('Mileage rate is a required field')
    // eslint-disable-next-line
    .matches(/^\d*[\.]\d{1,3}$/, 'Please enter up to 3 digits after the decimal'), // prettier-ignore
  startDate: yup
    .date()
    .transform((value) => (moment(value).isValid() ? value : null))
    .when(['isCreate', 'hasFieldChanged'], {
      is: (isCreate, hasFieldChanged) => isCreate || hasFieldChanged,
      then: yup
        .date()
        .min(tomorrowDate, 'New start date must be a future date')
        .required('Start date must be updated as any rate changes')
        .typeError('Please designate a start date for this Lease Rate Code'),
    }),
  avgAgeAtSale: yup
    .string()
    .nullable()
    .required('Average age at sale is a required field')
    .matches(/^-?\d\d*$/, 'Please use the correct format')
    .matches(/^\d*[1-9]+\d*$/, 'Please enter a value greater than zero'),
  highCostThresholdMarkup: yup
    .mixed()
    .nullable()
    .required('High cost threshold markup is a required field')
    .test({
      name: 'isWholeNumber',
      exclusive: false,
      message: 'Markup must be greater than zero',
      test: (value) => value > 0,
    })
    .test({
      name: 'hasTwoDecimalPlaces',
      exclusive: false,
      message: 'Please enter up to 2 digits after the decimal',
      test: (value) => /^\d{0,9}[\.]?\d{1,2}$/.test(value), // eslint-disable-line
    }),
  salvagePercent: yup
    .string()
    .nullable()
    .required('Salvage percent is a required field')
    .matches(/^-?\d\d*$/, 'Please use the correct format')
    .matches(/^[1-9]\d?$|^99$/, 'Please enter a value between 1% and 99%'),
  usefulLifeInMonth: yup
    .string()
    .nullable()
    .required('Useful life is a required field')
    .matches(/^-?\d\d*$/, 'Please use the correct format')
    .matches(/^\d*[1-9]+\d*$/, 'Please enter a value greater than zero'),
  optionalEquipMarkup: yup
    .mixed()
    .nullable()
    .required('Optional equipment markup is a required field')
    .test({
      name: 'isWholeNumber',
      exclusive: false,
      message: 'Markup must be greater than zero',
      test: (value) => value > 0,
    })
    .test({
      name: 'hasTwoDecimalPlaces',
      exclusive: false,
      message: 'Please enter up to 2 digits after the decimal',
      test: (value) => /^\d{0,9}[\.]?\d{1,2}$/.test(value), // eslint-disable-line
    }),
});

const LeaseRateUpdateModal = ({ onClose, setBannerMsg, data, isCreate }) => {
  const {
    leaseRateCode,
    rateStatus,
    leaseRateDescription,
    monthlyRate,
    mileageRate,
    startDate,
    avgAgeAtSale,
    highCostThresholdMarkup,
    salvagePercent,
    usefulLifeInMonth,
    optionalEquipMarkup,
  } = data;

  const [saveError, setSaveError] = useState(null);
  const [descCounter, setDescCount] = useCharCounter(MAX_LEN_DESC);

  // useForm declaration
  const { register, handleSubmit, errors, setError: setFormError, clearErrors, control, getValues, setValue } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
        leaseRateCode,
        leaseRateDescription,
        monthlyRate: monthlyRate && parseFloat(monthlyRate).toFixed(2),
        mileageRate: mileageRate && parseFloat(mileageRate).toFixed(3),
        startDate: startDate || tomorrowStr,
        avgAgeAtSale: avgAgeAtSale && Number(avgAgeAtSale),
        highCostThresholdMarkup: highCostThresholdMarkup && parseFloat(highCostThresholdMarkup).toFixed(2),
        salvagePercent: salvagePercent && parseInt(salvagePercent), // eslint-disable-line
        usefulLifeInMonth: usefulLifeInMonth && Number(usefulLifeInMonth),
        optionalEquipMarkup: optionalEquipMarkup && parseFloat(optionalEquipMarkup).toFixed(2),
      },
    },
  );

  // gql calls
  const [checkStartDate, { loading: loadingCheckStartDate }] = useLazyQuery(GET_LEASE_RATE_BY_LRC_START_DATE, {
    onCompleted: (resData) => {
      if (resData?.getLeaseRateByRateCodeStartDate) {
        setFormError('startDate', { message: 'A rate already exists for the start date' }, { shouldFocus: true });
      } else {
        // eslint-disable-next-line
        createOrUpdateLRC();
      }
    },
    onError: (err) =>
      setSaveError(
        <>
          Network error. Please try again later.
          <br />
          {err.message}
        </>,
      ),
  });

  const [updateLeaseRate, { loading: loadingUpdate }] = useMutation(UPDATE_LEASE_RATE, {
    onCompleted: (resData) => {
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully updated Lease Rate Code <b>{resData.updateLeaseRate.leaseRateCode}</b> which will take
            effect on {UTCDateStrToUS(resData.updateLeaseRate.startDate)}.
          </>
        ),
      });
      onClose(true);
    },
    onError: (err) => {
      if (
        err.message === 'Unable to create pending lease rate with past Start Date' ||
        err.message.substr(0, 19) === 'Start date overlaps'
      )
        setFormError(
          'startDate',
          { message: err.message },
          {
            shouldFocus: true,
          },
        );
      else
        setSaveError(
          <>
            Error occured when updating the Lease Rate Code. Please try again later.
            <br />
            {err.message}
          </>,
        );
    },
  });

  const [createLeaseRate, { loading: loadingCreate }] = useMutation(CREATE_LEASE_RATE, {
    onCompleted: (resData) => {
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully created Lease Rate Code <b>{resData.createLeaseRate.leaseRateCode}</b> which will take
            effect on {UTCDateStrToUS(resData.createLeaseRate.startDate)}.
          </>
        ),
      });
      onClose(true, resData.createLeaseRate);
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occured when creating the Lease Rate Code.
          <br />
          {err.message}
        </>,
      );
    },
  });

  const [fetchDistincCodes, { loading: loadingDistinctCodes, data: distinctCodesData }] = useLazyQuery(
    GET_DISTINCT_LEASE_RATE_CODE,
    {
      onError: (err) => {
        setSaveError(
          <>
            Error occured when retrieving existing Lease Rate Codes.
            <br />
            {err.message}
          </>,
        );
      },
    },
  );

  useEffect(() => {
    register({ name: 'isCreate' });
    register({ name: 'distinctCodes' });
    register({ name: 'hasFieldChanged' });
    setValue('isCreate', isCreate);
    setValue('distinctCodes', []);
    setValue('hasFieldChanged', false);

    if (isCreate) fetchDistincCodes();
    if (data) setDescCount(leaseRateDescription ? leaseRateDescription.length : 0);
  }, []);

  useEffect(() => {
    if (distinctCodesData) {
      setValue(
        'distinctCodes',
        distinctCodesData.getDistinctLeaseRateCode.map((d) => d.leaseRateCode),
      );
    }
  }, [distinctCodesData]);

  const createOrUpdateLRC = () => {
    const {
      leaseRateCode: leaseRateCodeSubmit,
      monthlyRate: monthlyRateSubmit,
      mileageRate: mileageRateSubmit,
      leaseRateDescription: leaseRateDescriptionSubmit,
      startDate: startDateSubmit,
      avgAgeAtSale: avgAgeAtSaleSubmit,
      highCostThresholdMarkup: highCostThresholdMarkupSubmit,
      salvagePercent: salvagePercentSubmit,
      usefulLifeInMonth: usefulLifeInMonthSubmit,
      optionalEquipMarkup: optionalEquipMarkupSubmit,
    } = getValues();
    const updateLeaseRateData = {
      variables: {
        leaseRateCode: leaseRateCodeSubmit?.toUpperCase(),
        leaseRateInput: {
          monthlyRate: monthlyRateSubmit,
          mileageRate: mileageRateSubmit,
          leaseRateDescription: leaseRateDescriptionSubmit,
          startDate: startDateSubmit,
          endDate: null,
          avgAgeAtSale: Number(avgAgeAtSaleSubmit),
          highCostThresholdMarkup: highCostThresholdMarkupSubmit,
          salvagePercent: Number(salvagePercentSubmit),
          usefulLifeInMonth: Number(usefulLifeInMonthSubmit),
          optionalEquipMarkup: optionalEquipMarkupSubmit,
        },
      },
    };

    if (isCreate) {
      updateLeaseRateData.variables.sinFuelTypeAssoc = [];
      createLeaseRate({ ...updateLeaseRateData });
    } else {
      updateLeaseRate(updateLeaseRateData);
    }
  };

  const onSubmit = () => {
    const newStartDate = getValues('startDate');
    if (isCreate || rateStatus === STATUS.NEW || newStartDate === startDate) {
      createOrUpdateLRC();
    } else {
      const query = {
        leaseRateCode,
        startDate: newStartDate,
      };
      checkStartDate({ variables: query });
    }
  };

  const onFieldBlur = (e, field) => {
    const {
      target: { value },
    } = e;

    if (value.indexOf('.') === -1 && value !== '') {
      if (field === 'monthlyRate') setValue('monthlyRate', Number(value).toFixed(2));

      if (field === 'mileageRate') setValue('mileageRate', Number(value).toFixed(3));
    }
  };

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const showErrorMessage = () => {
    if (saveError)
      return (
        <Alert slim type="error" style={{ marginBottom: 18 }}>
          {saveError}
        </Alert>
      );

    if (Object.keys(errors).length)
      return (
        <Alert slim focused={false} type="error" style={{ marginBottom: 18 }}>
          You have {Object.keys(errors).length} error on this form. Please correct before saving
        </Alert>
      );

    return null;
  };

  const checkDuplicateCode = (e, name) => {
    const { value } = e.target;
    const isDuplicate = getValues('distinctCodes').includes(value.trim().toUpperCase());
    if (isDuplicate) setFormError(name, { message: 'Code already exists' });
    else clearErrors(name);
  };

  const onFieldChange = (e) => {
    const { value } = e.target;
    setDescCount(value.length);
  };

  return (
    <EditModal
      id="lease-rate-update"
      title={`${isCreate ? 'Create a New' : 'Update'} Lease Rate Code ${isCreate ? '' : leaseRateCode}`}
      onClose={() => onClose()}
      formId="lease-rate-update-form"
      modalType="editModal"
    >
      {loadingDistinctCodes || loadingCheckStartDate || loadingCreate || loadingUpdate ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.15)',
          }}
        >
          <div style={{ margin: '300px auto' }}>
            <Spinner />
          </div>
        </div>
      ) : null}
      {showErrorMessage()}
      <p>
        {isCreate
          ? `Use this window to create a new Lease Rate Code.`
          : `Use this window to update the rates, rate factors, and description for this existing Lease Rate Code.  Saving an update with a start date in the future will generate a pending rate record.`}
      </p>

      <form onSubmit={handleSubmit(onSubmit)} id="lease-rate-update-form">
        <div className="form-ra">
          <div className="grid-container">
            <div style={isCreate ? {} : { display: 'none' }}>
              <div className="grid-col-4 margin-bottom-4">
                <Controller
                  control={control}
                  name="leaseRateCode"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="lease-rate-code" required>
                        Enter a new Lease Rate Code
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Textbox
                        id="lease-rate-code"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter Lease Rate Code"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          checkDuplicateCode(e, name);
                        }}
                        onBlur={onBlur}
                      />
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="grid-row">
              <h2>{isCreate ? 'Add a ' : 'Update'} description, monthly, and mileage rate</h2>
              <div className="grid-col-12">
                <Controller
                  control={control}
                  name="leaseRateDescription"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="description" required>
                        Description
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Textbox
                        id="description"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter description"
                        name={name}
                        type="textarea"
                        rows={2}
                        maxLength={MAX_LEN_DESC}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          onFieldChange(e);
                        }}
                        onBlur={onBlur}
                      />
                      {descCounter}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="grid-row grid-gap bm-form-row">
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="monthlyRate"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="monthly-rate" required>
                        Monthly rate
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Icon
                        iconName="attach_money"
                        className={`bm-prefix-icon-dollar ${errors[name] && 'bm-prefix-icon-dollar-error'}`}
                      />
                      <Textbox
                        id="monthly-rate"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter monthly rate"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={(e) => {
                          onFieldBlur(e, 'monthlyRate');
                          onBlur(e);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="mileageRate"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="mileage-rate" required>
                        Mileage rate
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Icon
                        iconName="attach_money"
                        className={`bm-prefix-icon-dollar ${errors[name] && 'bm-prefix-icon-dollar-error'}`}
                      />
                      <Textbox
                        id="mileage-rate"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter mileage rate"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={(e) => {
                          onFieldBlur(e, 'mileageRate');
                          onBlur(e);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </div>

            <h2 className="margin-top-8">{isCreate ? 'Add' : 'Update'} factors for Lease Rate Code</h2>
            <div className="grid-row grid-gap bm-form-row">
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="salvagePercent"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="salvage-percent" required>
                        Salvage percent
                      </Label>
                      {createErrorMessage(errors[name])}
                      <span className="prefix-icon-percent">%</span>
                      <Textbox
                        id="salvage-percent"
                        // label="Salvage percent"
                        // errorMessage={createErrorMessage(errors[name])}
                        // showLabelError={false}
                        // required
                        aria-label="enter salvage percent"
                        className={`usa-input ${classNameErrInput(errors[name])}`}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                        data-testid="salvage-percent"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="usefulLifeInMonth"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="useful-life-in-month" required>
                        Useful life
                      </Label>
                      {createErrorMessage(errors[name])}
                      <span className="prefix-icon-months">months</span>
                      <Textbox
                        id="useful-life-in-month"
                        aria-label="enter useful life in month"
                        className={`usa-input ${classNameErrInput(errors[name])}`}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                        data-testid="useful-life-in-month"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="avgAgeAtSale"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="avg-age-at-sale" required>
                        Average age at sale
                      </Label>
                      {createErrorMessage(errors[name])}
                      <span className="prefix-icon-months">months</span>
                      <Textbox
                        id="avg-age-at-sale"
                        aria-label="enter average age at sale in month"
                        className={`usa-input ${classNameErrInput(errors[name])}`}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                      />
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="grid-row grid-gap bm-form-row">
              <div className="grid-col-4">
                <Controller
                  control={control}
                  name="optionalEquipMarkup"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="opt-equip-markup" required>
                        Optional equipment markup
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Textbox
                        id="opt-equip-markup"
                        aria-label="enter optional equipment markup"
                        className={`usa-input ${classNameErrInput(errors[name])}`}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                        data-testid="opt-equip-markup"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="grid-col-4 factors">
                <Controller
                  control={control}
                  name="highCostThresholdMarkup"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="high-cost-threshold-markup" required>
                        High cost threshold markup
                      </Label>
                      {createErrorMessage(errors[name])}
                      <input
                        id="high-cost-threshold-markup"
                        aria-label="enter high cost threshold markup"
                        className={`usa-input ${classNameErrInput(errors[name])}`}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                        data-testid="high-cost-threshold-markup"
                      />
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="grid-row grid-gap margin-top-4">
              <div className="grid-col-4" style={{ minWidth: 300 }}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ name, value, onBlur }) => (
                    <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="start-date" required>
                        Start date
                      </Label>
                      <div className="usa-hint" id="start-date-hint">
                        mm/dd/yyyy
                      </div>
                      {createErrorMessage(errors[name])}
                      <DatePicker
                        id="start-date"
                        name={name}
                        className={classNameErrDatePicker(errors[name])}
                        defaultValue={value < tomorrowStr ? null : value}
                        placeholder={UTCDateStrToUS(value)}
                        disabled={rateStatus === STATUS.PENDING}
                        minDate={tomorrowStr}
                        onChange={(val) => {
                          setValue('startDate', val ? USDateStrToUTC(val) : null);
                          setValue('hasFieldChanged', true);
                        }}
                        onBlur={onBlur}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </EditModal>
  );
};

LeaseRateUpdateModal.defaultProps = {
  isCreate: false,
  row: { original: { id: '' } },
};

LeaseRateUpdateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
  data: PropTypes.shape({
    leaseRateCode: PropTypes.string.isRequired,
    rateStatus: PropTypes.string.isRequired,
    leaseRateAssociation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    leaseRateDescription: PropTypes.string.isRequired,
    monthlyRate: PropTypes.string.isRequired,
    mileageRate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    avgAgeAtSale: PropTypes.string.isRequired,
    highCostThresholdMarkup: PropTypes.string.isRequired,
    salvagePercent: PropTypes.string.isRequired,
    usefulLifeInMonth: PropTypes.string.isRequired,
    optionalEquipMarkup: PropTypes.string.isRequired,
  }).isRequired,
  isCreate: PropTypes.bool,
  row: PropTypes.shape({
    original: PropTypes.shape({}),
    values: PropTypes.shape({
      standardItemNumber: PropTypes.string,
      fuelType: PropTypes.string,
    }),
  }),
};

export default LeaseRateUpdateModal;
