import * as yup from 'yup';

export const validationSchema = {
  category_toggleActiveForm: yup.object().shape({
    reason: yup.string().required('Reason is required'),
  }),
  category_updateOptInCategoryForm: yup.object().shape({
    serviceCategory: yup.string().required('Service Category is required'),
    description: yup.string().required('Description is required'),
  }),
};
