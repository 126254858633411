import { gql } from '@apollo/client';

export const OptInServiceResponse = gql`
  fragment OptInServiceResponseFields on OptInService {
    optInSvcId
    optInSvcName
    optInSvcDesc
    optInSvcCategory
    rateStatus
    optInSvcSalesId
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
  }
`;

export const GET_DIST_SERVICE_NAME_AND_START_DATE = gql`
  query getDistinctOptInServiceNameAndStartDate($optInSvcName: String!, $startDate: String!) {
    getDistinctOptInServiceNameAndStartDate(optInSvcName: $optInSvcName, startDate: $startDate) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;
