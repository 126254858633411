import { gql } from '@apollo/client';

export const OptInServiceResponse = gql`
  fragment OptInServiceResponseFields on OptInService {
    optInSvcId
    optInSvcName
    optInSvcDesc
    optInSvcCategory
    rateStatus
    optInSvcSalesId
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
  }
`;

export const DELETE_OPT_IN_SERVICE = gql`
  mutation deleteOptInSvc($optInSvcId: String!, $rateStatus: String!, $optInSvcInput: OptInSvcInput!) {
    deleteOptInSvc(optInSvcId: $optInSvcId, rateStatus: $rateStatus, optInSvcInput: $optInSvcInput) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;
