import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@gsa/afp-component-library';

import './styles/button-actions.scss';

const ButtonActions = ({ actions, onSelectAction, label, className }) => {
  const [open, setOpen] = useState(false);
  const dropContentRef = useRef(null);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const actionButton = useMemo(() => (
    <button
      type="button"
      title={label || 'document actions'}
      aria-haspopup={actions?.length > 0}
      aria-expanded={open}
      className={className}
      onClick={() => setOpen(!open)}
    >
      <span aria-hidden="true">{label}</span>
      <Icon iconName={open ? 'expand_less' : 'expand_more'} className="margin-left-1s" />
    </button>
  ));

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown">
      {actionButton}
      <div ref={dropContentRef} className={`dropdown-content ${open ? 'show' : ''}`}>
        {actions &&
          actions.map((action, i) => {
            return (
              <div className="dropdown-menu-item" key={`${action.label}`}>
                <Button
                  variant="unstyled"
                  className="text-no-underline text-ink margin-y-1 padding-1 margin-right-2"
                  onClick={() => {
                    onSelectAction(action);
                    setOpen(false);
                  }}
                  title={action.label}
                  leftIcon={{ name: action.icon }}
                  label={action.label}
                />
                {/* Divider */}
                {i < actions.length - 1 ? <div className="border-bottom border-base-lighter" /> : null}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const actionShape = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  canShowIndicator: PropTypes.string,
};

ButtonActions.defaultProps = {
  className: 'usa-button dropdown-button top-action-button text-no-underline create-new-req',
};
ButtonActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(actionShape)).isRequired,
  onSelectAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ButtonActions;
