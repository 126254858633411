import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toFixedFn } from 'utilities/formatUtils';
import { stateValue } from 'utilities/stateUtils';
import RateInputComponent from 'components/widgets/RateInput';
import { validationSchema } from '../model/schemas/formSchema';
import { parseFunds } from '../helper';

/* eslint-disable react/prop-types */
const InitialFundingForm = ({ pageState, setDispatch, updateFunds, DISPATCH_FNS }) => {
  const { handleSubmit, trigger, ...fieldProps } = useForm({
    resolver: yupResolver(validationSchema.InitialFundingForm),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });

  const validatedSubmit = (data) => {
    const parsedFundsData = parseFunds(data);
    updateFunds({
      variables: {
        acquisitionFundingInput: {
          fiscalYear: stateValue(pageState.fiscalYear),
          consolidationFunding: toFixedFn(parsedFundsData.consolidation, 4),
          replacementFunding: toFixedFn(parsedFundsData.replacement, 4),
          additionalFunding: toFixedFn(parsedFundsData.additional, 4),
        },
      },
    });
  };

  const onSubmit = async (data) => {
    const result = await trigger(['replacement']);
    if (result) {
      validatedSubmit(data);
    }
  };
  const labelNameFn = (name) => `${name} funding`;

  const responsiveGridClass = `tablet:grid-col-2`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="acquisition-funding-initial-form">
      <div className="grid-row grid-gap bm-form-row">
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.initialFunding_replacement)}
            path="initialFunding_replacement"
            afterOnchangeFn={() => setDispatch(DISPATCH_FNS.initialFunding.setTotal)}
            required
            readOnly={stateValue(pageState.fundingInitialed)}
            name="replacement"
            labelNameFn={labelNameFn}
            allowNegative={false}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.initialFunding_consolidation)}
            afterOnchangeFn={() => setDispatch(DISPATCH_FNS.initialFunding.setTotal)}
            readOnly={stateValue(pageState.fundingInitialed)}
            name="consolidation"
            path="initialFunding_consolidation"
            labelNameFn={labelNameFn}
            allowNegative={false}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.initialFunding_additional)}
            afterOnchangeFn={() => setDispatch(DISPATCH_FNS.initialFunding.setTotal)}
            readOnly={stateValue(pageState.fundingInitialed)}
            name="additional"
            path="initialFunding_additional"
            labelNameFn={labelNameFn}
            allowNegative={false}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            value={stateValue(pageState.initialFunding_total)}
            errors={fieldProps.errors}
            allowNegative={false}
            readOnly
            name="total"
            path="initialFunding_total"
            labelNameFn={labelNameFn}
          />
        </div>

        <div className={responsiveGridClass}>
          {stateValue(pageState.fundingInitialed) || (
            <div className="bm-form-row-button">
              <Button
                size="sm"
                data-testid="initial-save"
                variant="primary"
                id="fund-save-btn"
                aria-label="Submit form"
                hidden="hidden"
                type="submit"
                label="Save"
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default InitialFundingForm;
