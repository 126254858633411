import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResolveModal } from 'components/modals/resolve-modal/resolve-modal';
import { useMutation } from '@apollo/client';
import { ADD_OR_UPDATE_WS_ERR_RESOLVED, ADD_OR_UPDATE_FF_ERR_RESOLVED } from '../../services/data-layer/shared-billing';
import {WEB_SERVICES} from './pegasys-common-util';

const PegasysErrorResolveModal = ({ onClose, data: { controlLogId, documentNumber }, setBannerMsg, serviceType }) => {

    const [saveError, setSaveError] = useState(null);

    const modalTitle = 'Update Error Resolution Status';
    const query = serviceType === WEB_SERVICES ? ADD_OR_UPDATE_WS_ERR_RESOLVED : ADD_OR_UPDATE_FF_ERR_RESOLVED;
    const [updateErrorStatus, { loading: updating }] = useMutation(query, {
        onCompleted: () => {
            const message = (
              <>
                You have successfully updated status for <b>{documentNumber}</b>.
              </>
            );
            setBannerMsg({ type: 'success', message });
            onClose();

        },
        onError: (err) => {
            setSaveError(
              <>
                Error occurred when updating the error status for {documentNumber}. Please try again later.
                <br />
                {err.message}
              </>,
            );
            setBannerMsg({ type: 'error', saveError });
            onClose();

        }
        });
        
    const onSubmit = () => {
      const updatedVariables = serviceType === WEB_SERVICES ? {pegasysSoapControlLogId: controlLogId} : {batchCorrelationMapId: controlLogId}
        const variables = {
            ...updatedVariables,
            isResolved: true
        }
        updateErrorStatus({variables});
        onClose();
    };



    return (
      <ResolveModal
        id='pegasys-error-resolve-modal'
        title={modalTitle}
        onClose={onClose}
        onUpdate={onSubmit}
        updating={updating}
        updateButtonText='resolve'
      >
        <div>
          <p className='margin-top-0 margin-bottom-5' data-testid='resolve-modal-body'>
            Are you sure you would like to mark this error as resolved?
          </p>
        </div>
      </ResolveModal>
    );
};

PegasysErrorResolveModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.shape({
        controlLogId: PropTypes.number.isRequired,
        documentNumber: PropTypes.string.isRequired,
    }).isRequired,
    setBannerMsg: PropTypes.func.isRequired,
    serviceType: PropTypes.string.isRequired
};

export default PegasysErrorResolveModal;
