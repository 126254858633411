import { gql } from '@apollo/client';

export const PurchaseRatesResponse = gql`
  fragment PurchaseRatesResponseFields on PurchaseRate {
    purchaseType
    purchaseRateType
    purchaseTypeDesc
    rateStatus
    purchaseTypeCode
    fixedPercent
    flatDollarFee
    effectiveStartDate
    effectiveEndDate
    deletedAt
    comment
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    isDeleted
    dataSource
    dmlFlag
  }
`;

export const PaginatedPurchaseRateResponse = gql`
  fragment PaginatedPurchaseRateFields on PaginatedPurchaseRateResponse {
    rows {
      purchaseType
      purchaseRateType
      purchaseTypeDesc
      rateStatus
      purchaseTypeCode
      fixedPercent
      flatDollarFee
      effectiveStartDate
      effectiveEndDate
      lastUpdateUserInfo {
        fullName
        email
      }
      deletedAt
      comment
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      isDeleted
      dataSource
      dmlFlag
    }
    count
    hasMore
  }
`;

export const GET_PURCHASE_RATE_BY_TYPE_ESD = gql`
  query getPurchaseRateByTypeEffectiveStartDate($purchasetype: String!, $effectivestartdate: String!) {
    getPurchaseRateByTypeEffectiveStartDate(purchasetype: $purchasetype, effectivestartdate: $effectivestartdate) {
      ...PurchaseRatesResponseFields
    }
  }
  ${PurchaseRatesResponse}
`;

export const UPDATE_OR_CREATE_PURCHASE_RATE = gql`
  mutation updateOrCreatePurchaseRate(
    $purchasetype: String!
    $effectivestartdate: String!
    $PurchaseRateInput: PurchaseRateInput!
  ) {
    updateOrCreatePurchaseRate(
      purchasetype: $purchasetype
      effectivestartdate: $effectivestartdate
      PurchaseRateInput: $PurchaseRateInput
    ) {
      ...PurchaseRatesResponseFields
    }
  }
  ${PurchaseRatesResponse}
`;

export const GET_PURCHASE_RATE_BY_STATUS = gql`
  query getPurchaseRates($offset: Int, $limit: Int, $filters: FilterType, $virtualFilter: FilterType, $order: OrderBy) {
    getPurchaseRates(offset: $offset, limit: $limit, filters: $filters, virtualFilter: $virtualFilter, order: $order) {
      ...PaginatedPurchaseRateFields
    }
  }
  ${PaginatedPurchaseRateResponse}
`;

export const DELETE_PURCHASE_RATE_TYPE = gql`
  mutation deletePurchaseRateType($purchasetype: String!, $ratestatus: String!, $effectivestartdate: String!) {
    deletePurchaseRateType(
      purchasetype: $purchasetype
      ratestatus: $ratestatus
      effectivestartdate: $effectivestartdate
    )
  }
`;

export const CREATE_NEW_PURCHASE_TYPE = gql`
  mutation createNewPurchaseRate(
    $purchasetype: String!
    $effectivestartdate: String!
    $PurchaseRateInput: PurchaseRateInput!
  ) {
    createNewPurchaseRate(
      purchasetype: $purchasetype
      effectivestartdate: $effectivestartdate
      PurchaseRateInput: $PurchaseRateInput
    ) {
      ...PurchaseRatesResponseFields
    }
  }
  ${PurchaseRatesResponse}
`;

export const DEACTIVATE_PURCHASE_TYPE = gql`
  mutation deactivateActivePurchaseType(
    $purchasetype: String!
    $ratestatus: String!
    $comment: String!
    $effectivestartdate: String!
  ) {
    deactivateActivePurchaseType(
      purchasetype: $purchasetype
      ratestatus: $ratestatus
      comment: $comment
      effectivestartdate: $effectivestartdate
    )
  }
`;

export const REACTIVATE_PURCHASE_TYPE = gql`
  mutation reactivatePurchaseType(
    $purchasetype: String!
    $ratestatus: String!
    $comment: String!
    $effectivestartdate: String!
  ) {
    reactivatePurchaseType(
      purchasetype: $purchasetype
      ratestatus: $ratestatus
      comment: $comment
      effectivestartdate: $effectivestartdate
    )
  }
`;

export const GET_DISTINCT_PURCHASE_TYPES = gql`
  query getDistinctPurchaseTypes {
    getDistinctPurchaseTypes {
      purchaseType
    }
  }
`;

export const GET_PR_START_DATE_RANGE = gql`
  query getPrStartDateRange {
    getPrStartDateRange {
      effectiveStartDate
    }
  }
`;
