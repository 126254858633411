import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ResponsiveFilterPanel, Spinner } from '@gsa/afp-component-library';
import { ENTITY, ROLE_OP, hasAbilityTo } from 'components/role-permission/role-permission';
import { getFYInt, getFYUTCDateRange } from 'components/helpers/afp-bm-date';
import { STATUS, DEFAULT_FILTER_STATE } from 'components/helpers/constants';
import './style/lease-rate-filter.scss';
import { LEASE_RATE_ASSOCIATE_TYPEAHEAD_OPTIONS_SEARCH, GET_DISTINCT_LEASE_RATE_CODE } from './lease-rate-helper';

const ROLE_ENTITY = ENTITY.LEASE_RATE;
const FilterItems = {
  status: {
    id: 'rateStatus',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: [STATUS.ACTIVE, STATUS.PENDING, STATUS.NEW, STATUS.EXPIRED, STATUS.INACTIVE],
    className: 'StatusFilter',
    noChip: true,
  },
  leaseRateCode: {
    id: 'leaseRateCode',
    title: 'Lease Rate Code',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
  standardItemNumber: {
    id: 'standardItemNumber',
    title: 'Standard Item Number',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
  fuelType: {
    id: 'fuelType',
    title: 'Fuel type',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
  fiscalYear: {
    id: 'fiscalYear',
    title: 'Fiscal year',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
};

const LeaseRateFilter = ({ onFilter, timestamp }) => {
  const [filterOptions, setFilterOptions] = useState({});
  const hasPermission = hasAbilityTo([ROLE_OP.CREATE], ROLE_ENTITY);

  const genSelectOptions = (arr, field = null) =>
    arr.map((a) => {
      const val = field ? a[field] : a;
      return { value: val, label: val };
    });

  const onSINFuelComplete = ({ getAssociationsTypeAheadOptions }) => {
    if (getAssociationsTypeAheadOptions) {
      const { options, key } = getAssociationsTypeAheadOptions;
      setFilterOptions((prev) => ({
        ...prev,
        [key]: genSelectOptions(options),
      }));
    }
  };

  const onFilterAPIError = (key) =>
    setFilterOptions((prev) => ({
      ...prev,
      [key]: [],
    }));

  const [fetchLeaseRateCodes, { loading: loadingLRC }] = useLazyQuery(GET_DISTINCT_LEASE_RATE_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getDistinctLeaseRateCode }) => {
      setFilterOptions((prev) => {
        return {
          ...prev,
          leaseRateCode: genSelectOptions(getDistinctLeaseRateCode, 'leaseRateCode'),
        };
      });
    },
    onError: () => onFilterAPIError('leaseRateCode'),
  });

  const [fetchSINumbers, { loading: loadingSIN }] = useLazyQuery(LEASE_RATE_ASSOCIATE_TYPEAHEAD_OPTIONS_SEARCH, {
    fetchPolicy: 'network-only',
    variables: { key: 'standardItemNumber', search: '' },
    onCompleted: onSINFuelComplete,
    onError: () => onFilterAPIError('standardItemNumber'),
  });

  const [fetchFuelTypes, { loading: loadingFT }] = useLazyQuery(LEASE_RATE_ASSOCIATE_TYPEAHEAD_OPTIONS_SEARCH, {
    fetchPolicy: 'network-only',
    variables: { key: 'fuelType', search: '' },
    onCompleted: onSINFuelComplete,
    onError: () => onFilterAPIError('fuelType'),
  });

  const setFYOption = () => {
    const curYear = getFYInt();
    const endYear = curYear + 1;
    const startYear = curYear - 2;
    const yearList = [];
    for (let i = startYear; i <= endYear; i += 1) {
      const year = i.toString();
      yearList.push({ value: year, label: year });
    }
    setFilterOptions((prev) => {
      return { ...prev, fiscalYear: yearList };
    });
  };

  const getFilterItems = (hasPermission2) => {
    if(!hasPermission2){
      delete FilterItems.leaseRateCode;
    }
    return FilterItems;
  }

  useEffect(() => {
    fetchLeaseRateCodes();
    fetchSINumbers();
    fetchFuelTypes();
    setFYOption();
  }, [timestamp]);

  const buildFilters = useCallback((state) => {
    const filters = {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'OR', value: [] },
      associationFilter: { operator: 'AND', value: [] },
    };

    Object.keys(state).forEach((key) => {
      const value = state[key];
      switch (key) {
        case 'rateStatus':
          value?.forEach((val) => {
            filters.virtualFilter.value.push({
              key,
              operator: 'EQ',
              value: val,
            });
          });
          break;
        case 'fuelType':
        case 'standardItemNumber':
          filters.associationFilter.value.push({ key, operator: 'EQ', value });
          break;
        case 'leaseRateCode':
          filters.filters.value.push({ key, operator: 'EQ', value });
          break;
        case 'fiscalYear':
          {
            const { startDate, endDate } = getFYUTCDateRange(value);
            filters.filters.value.push(
              { key: 'startDate', operator: 'LTE', value: endDate },
              {
                operator: 'OR',
                value: [
                  { key: 'endDate', operator: 'EQ', value: null },
                  { key: 'endDate', operator: 'GTE', value: startDate },
                ],
              },
            );
          }
          break;
        default:
      }
    });

    Object.keys(filters).forEach((key) => {
      if (!filters[key].value.length) delete filters[key];
    });

    return { filters, state };
  }, []);

  return (
    <div>
      {loadingLRC || loadingSIN || loadingFT ? (
        <div style={{ position: 'absolute', top: '-40px', width: '100%' }}>
          <Spinner />
        </div>
      ) : null}
      <ResponsiveFilterPanel
        defaultState={DEFAULT_FILTER_STATE}
        items={getFilterItems(hasPermission)}
        filterOptions={filterOptions}
        onChange={(state) => onFilter(buildFilters(state))}
      />
    </div>
  );
};

LeaseRateFilter.defaultProps = {
  timestamp: Date.now(),
};
LeaseRateFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  timestamp: PropTypes.number,
};

export default LeaseRateFilter;
