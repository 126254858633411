import { gql } from '@apollo/client';

export const GET_WALLETS_LIST = gql`
  query getWallets($order: OrderBy, $limit: Float, $offset: Float, $filters: FilterType) {
    getWallets(order: $order, limit: $limit, offset: $offset, filters: $filters) {
      count
      hasMore
      rows {
        id
        fiscalYear
        tas
        boacId
        bcnObanAsn
        limit
        accountInformation
        fiscalStation
        allocationTransferAgency
        standardDocumentNumber
        agencyIdentifier
        walletSalesGroupModel {
          name
          programCodeModel {
            offering
          }
        }
        boacModel {
          boac
        }
        isDoD
        updatedAt
        updatedByUser {
          fullName
        }
        createdByUser {
          fullName
        }
      }
    }
  }
`;

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission($operation: String!, $subject: String!, $order: OrderBy, $feature: String) {
    getAgenciesByPermission(operation: $operation, subject: $subject, order: $order, feature: $feature) {
      id
      name
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
    }
  }
`;

export const GET_BOACS = gql`
  query GetBoacs($getBoacsArgs: GetBoacsInput!) {
    getBoacs(getBoacsArgs: $getBoacsArgs) {
      boacId
      boac
    }
  }
`;
