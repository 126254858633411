import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel, Typeahead } from '@gsa/afp-component-library';
import { useWalletFilter } from './filter-provider';
import { GET_BOACS } from '../wallet.gql';

const { FilterPanelFilterShape, useFilterPanel } = FilterPanel;

const BoacFilterItem = ({ filter }) => {
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();
  const [tpState, setTpState] = useState([]);
  const { setFilters, filters } = useWalletFilter();
  const [tpFilterValue, setTpFilterValue] = useState(false);

  const { getFilterFromState, mergedFilters, clearOneFilter, setFilters: setAllFilters } = useFilterPanel();

  const [getBoacs, { data: boacOptions, loading: boacOptionsLoading }] = useLazyQuery(GET_BOACS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const agencyFilter =
      getFilterFromState('$boacModel.agency_code$') || getFilterFromState('boacModel.agency_code') || {};
    setAgencyCode(agencyFilter.value);
    const bureauFilter =
      getFilterFromState('$boacModel.bureau_code$') || getFilterFromState('boacModel.bureau_code') || {};
    setBureauCode(bureauFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setTpState([]);
    setTpFilterValue('');
    if (agencyCode && bureauCode.length === 1) {
      getBoacs({
        variables: {
          getBoacsArgs: {
            agencyCode,
            bureauCode: bureauCode[0],
          },
        },
      });
    }
  }, [bureauCode]);

  if (boacOptionsLoading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return <p>Cannot filter by BOAC when multiple bureaus are selected</p>;
  }

  const fetchValues = (value) => {
    if(agencyCode && bureauCode) {
      const formattedBoacsOptions = boacOptions?.getBoacs.map(({ boac }) => boac);
      if (formattedBoacsOptions.find((val) => val.toLocaleLowerCase().includes(value.toLocaleLowerCase()))) {
        setTpState(formattedBoacsOptions.filter((el) => el.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
      } else {
        setTpState([]);
      }
    }
  };

  const onClear = () => {
    setFilters([...filters?.value?.filter((item) => item.key !== filter.key)]);
    setAllFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: { ...filter, value: '' },
    });
  };

  return (
    <div className="margin-top-3">
      <Typeahead
        {...filter}
        filterValue={tpFilterValue}
        onOptionEnter={(value) => {
          setTpFilterValue(value);
          setFilters([
            ...filters?.value?.filter((item) => item.key !== filter.key),
            {
              operator: filter.operator,
              key: filter.key,
              value,
            },
          ]);
          setAllFilters({
            type: 'setOne',
            fetchNewData: true,
            filter: { ...filter, value },
          });
        }}
        typeaheadValues={tpState}
        fetchTypeaheadValues={(accessor, value) => {
          fetchValues(value);
        }}
        onClear={onClear}
        disabled={!agencyCode || bureauCode?.length === 0}
      />
    </div>
  );
};

BoacFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BoacFilterItem;
