import React from 'react';
import { RequiredFieldIndicator } from '@gsa/afp-component-library';

const RequiredNotation = () => (
  <span>
    Required fields are marked with an asterisk (<RequiredFieldIndicator />
    ).
  </span>
);

export default RequiredNotation;
