import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from '@gsa/afp-component-library';

const BannerMessage = ({ type, focused, showClose, className, onClose, slim, heading, children }) => (
  <Alert
    type={type}
    focused={focused}
    className={className}
    slim={slim}
    heading={heading}
    actions={
      showClose ? (
        <div className="clearfix">
          <Button
            className="usa-button--unstyled"
            style={{ position: 'absolute', top: 0, right: 8 }}
            id="banner-message-close-btn"
            data-testid="banner-message-close-btn"
            onClick={onClose}
            aria-label="Close alert"
            leftIcon={{ name: 'close', className: 'bm-banner-message-close' }}
          />
        </div>
      ) : null
    }
  >
    {children}
  </Alert>
);

BannerMessage.defaultProps = {
  type: 'success',
  focused: false,
  className: '',
  onClose: undefined,
  showClose: true,
  heading: '',
  slim: true,
  children: '',
};

BannerMessage.propTypes = {
  type: PropTypes.string,
  focused: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  heading: PropTypes.string,
  slim: PropTypes.bool,
  children: PropTypes.node,
};

export default BannerMessage;
