import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Can } from '@gsa/afp-shared-ui-utils';
import { Pagination, Button, useModal } from '@gsa/afp-component-library';
import RateStatusBlock from 'components/widgets/rate-status-block';
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import { STATUS } from 'components/helpers/constants';
import UpdateInformation from 'components/subRowComponent/UpdateInformation';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { DEFAULT_NO_ITEMS_PER_PAGE, DEFAULT_PAGINATION_OPTIONS, DoReset } from 'components/helpers/pagination-helpers';
import AfpTable from 'widgets/afp-table-wrapper';
import { GET_UNIQUE_RATES, UR_TYPE } from './unique-rate-helper';
import UniqueRateUpdateModal from './unique-rate-update-modal';
import UniqueRateDeleteModal from './unique-rate-delete-modal';
import './style/unique-rate-table.scss';
import UniqueRateFilter from './unique-rate-filter';

const ROLE_ENTITY = ENTITY.UNIQUE_RATE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.UPDATE, ACTIONS.DEACTIVATE],
  [STATUS.PENDING]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.NEW]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.EXPIRED]: [],
  [STATUS.INACTIVE]: [ACTIONS.ACTIVATE],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

const DEFAULT_ORDER = [
  ['uniqueRateType', 'ASC'],
  ['startDate', 'ASC'],
];

const UniqueRatePage = ({ setBannerMsg }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCreate, setIsCreate] = useState(false);

  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [curPage, setcurPage] = useState(1);
  const [isReset, setIsReset] = useState(false);

  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [filterTS, setFilterTS] = useState(Date.now());
  const [filters, setFilters] = useState({
    virtualFilter: {
      operator: 'OR',
      value: [{ key: 'rateStatus', operator: 'EQ', value: 'Active' }],
    },
    limit: DEFAULT_NO_ITEMS_PER_PAGE,
    offset: 0,
    order: DEFAULT_ORDER,
  });

  const [responseData, setResponseData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });
  const [showError, setShowError] = useState(false);

  // useModal hooks
  const { isOpen: isEditModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const [getData, { loading, data, refetch }] = useLazyQuery(GET_UNIQUE_RATES, {
    fetchPolicy: 'network-only',
    variables: { ...filters, limit, offset, order },
    onError: (err) => {
      setShowError(true);
      setBannerMsg({
        type: 'error',
        message: (
          <>
            Error occured when loading unique rate data. <br />
            {err.message}
          </>
        ),
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.getUniqueRates) {
      const trimFloat = (f) => (f ? parseFloat(f).toString() : f);
      const rows = data.getUniqueRates.rows.map((row) => ({
        ...row,
        monthlyRateFactor: trimFloat(row.monthlyRateFactor),
        mileageRateFactor: trimFloat(row.mileageRateFactor),
        thresholdAmount: trimFloat(row.thresholdAmount),
      }));
      setResponseData({ ...data.getUniqueRates, rows });
    }
  }, [data]);

  const handleSelectedAction = (label, original) => {
    const codeData = { ...original };
    switch (label) {
      case ACTIONS.CREATE.label:
        codeData.uniqueRateType = '';
        codeData.startDate = null;
        setIsCreate(true);
        openEditModal();
        break;
      case ACTIONS.UPDATE.label:
        setIsCreate(false);
        openEditModal();
        break;
      case ACTIONS.DELETE.label:
      case ACTIONS.DEACTIVATE.label:
      case ACTIONS.ACTIVATE.label:
        openDeleteModal();
        break;
      default:
    }
    setSelectedItem(codeData);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    if (itemsPerPage !== limit) {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setLimit(itemsPerPage);
      setcurPage(1);
      setOffset(0);
      DoReset(setIsReset);
    } else {
      setcurPage(currentPage);
      setOffset((currentPage - 1) * itemsPerPage);
    }
  };

  const handleClose = (modalType, doRefetch = false) => {
    switch (modalType) {
      case 'editModal':
        closeEditModal();
        break;
      case 'deleteModal':
        closeDeleteModal();
        break;
      default:
    }

    if (doRefetch === true) {
      refetch({
        variables: { limit, offset, order },
      });
      setFilterTS(Date.now());
    }
  };

  const handleDelete = (type, message) => {
    setBannerMsg({
      type,
      message,
    });
  };

  const columns = [
    {
      Header: 'Unique rate type',
      accessor: 'uniqueRateType',
      Cell: ({ value }) => `${value}`,
    },
    {
      Header: 'Status',
      accessor: 'rateStatus',
      className: 'statusStyle',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={value}>{value}</RateStatusBlock>,
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: 'Monthly rate factor',
      accessor: 'monthlyRateFactor',
      Cell: ({ value }) => (value !== null ? value : '-'),
      sortable: true,
    },
    {
      Header: 'Mileage rate factor',
      accessor: 'mileageRateFactor',
      Cell: ({ value }) => (value !== null ? value : '-'),
      sortable: true,
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      Cell: ({ value }) => UTCDateStrToUS(value),
    },
  ];
  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    columns.push({
      Header: 'Actions',
      sortable: false,
      Cell: (rowData) =>
        genRowActions(ROW_ACTIONS[rowData.row.original?.rateStatus] || [], rowData, handleSelectedAction),
    });

  const onSort = (val) => {
    const res = val.split(' ');
    setOrder([[...res[0].split('`')[1].split('.'), res[1]]]);
  };

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-header">Description</div>
            <div className="grid-row">
              {original?.uniqueRateDesc && (original.uniqueRateDesc ? original.uniqueRateDesc : '–-')}
            </div>
            {original?.comment && (
              <div>
                <div className="grid-row bm-subrow-header">
                  Reason for {original?.rateStatus === STATUS.ACTIVE ? 'reactivation' : 'deactivation'}
                </div>
                <div className="margin-bottom-2">{original.comment}</div>
              </div>
            )}
          </div>
          <div className="tablet:grid-col">
            {original?.uniqueRateType === UR_TYPE.HIGH_COST ? (
              <div className="grid-row bm-subrow-grid-row">
                <div className="tablet:grid-col bm-subrow-title">High cost threshold</div>
                <div className="tablet:grid-col text-right">{`$${original.thresholdAmount}`}</div>
              </div>
            ) : null}
            {original?.endDate ? (
              <div className="grid-row bm-subrow-grid-row">
                <div className="tablet:grid-col bm-subrow-title">End date</div>
                <div className="tablet:grid-col text-right">{UTCDateStrToUS(original?.endDate)}</div>
              </div>
            ) : null}
            <UpdateInformation original={original} />
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div>
      <Can I={ROLE_OP.CREATE} a={ROLE_ENTITY}>
        <div className="bm_create_rate_button">
          <Button
            onClick={() => handleSelectedAction('Create', null)}
            leftIcon={{ name: 'add' }}
            label="Create unique rate"
          />
        </div>
      </Can>
      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3 standard-table-filters-wrapper margin-top-4">
          <UniqueRateFilter
            onFilter={(filterValue) => {
              setOffset(0);
              setcurPage(1);
              DoReset(setIsReset);
              setFilters(filterValue.filters);
            }}
            timestamp={filterTS}
          />
        </div>
        <div className="desktop:grid-col-10 unique-rate-table-container tablet-lg:grid-col-9">
          <AfpTable
            onError={showError}
            isLoading={loading}
            expandable
            columns={columns}
            data={responseData?.rows || []}
            NoDataMessages={{
              title: 'No Rates Available',
              text: 'There are no matches for the filtered values at left.',
            }}
            renderRowSubComponent={renderRowSubComponent}
            onSort={onSort}
            defaultSort={order}
            fullWidth
          />

          <div className="padding-y-4">
            <Pagination
              itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
              onPageChange={handlePaginationChange}
              variant="advanced"
              currentPage={curPage}
              itemsCount={responseData?.count}
              itemsPerPage={limit}
              isReset={isReset}
            />
          </div>

          {isEditModalOpen && (
            <UniqueRateUpdateModal
              onClose={(doRefetch) => handleClose('editModal', doRefetch)}
              data={selectedItem}
              setBannerMsg={setBannerMsg}
              isCreate={isCreate}
            />
          )}

          {isDeleteModalOpen && (
            <UniqueRateDeleteModal
              onClose={(doRefetch) => handleClose('deleteModal', doRefetch)}
              data={selectedItem}
              handleDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UniqueRatePage.defaultProps = {
  row: { original: {} },
};

UniqueRatePage.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }),
  setBannerMsg: PropTypes.func.isRequired,
};

export default UniqueRatePage;
