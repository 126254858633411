import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';

const AlertMessage = ({ focused, type, className, slim, heading, onClose, content, showClose, id, buttonStyle }) => {
  if (isEmpty(content)) return null;
  return (
    <Alert
      type={type}
      className={className}
      heading={heading}
      slim={slim}
      focused={focused}
      actions={
        showClose ? (
          <div className="clearfix">
            <Button
              className="usa-button--unstyled"
              style={buttonStyle}
              id={id}
              data-testid={id}
              onClick={onClose}
              leftIcon={{ name: 'close', className: 'bm-banner-message-close' }}
            />
          </div>
        ) : null
      }
    >
      {content}
    </Alert>
  );
};

AlertMessage.defaultProps = {
  type: 'success',
  className: '',
  focused: true,
  onClose: () => null,
  heading: '',
  slim: true,
  content: null,
  showClose: true,
  id: 'banner-message-close-btn',
  buttonStyle: { position: 'absolute', top: 0, right: 8 },
};

AlertMessage.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  heading: PropTypes.string,
  slim: PropTypes.bool,
  showClose: PropTypes.bool,
  focused: PropTypes.bool,
  id: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default AlertMessage;
