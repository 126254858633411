import React from 'react';
import _ from 'lodash';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { ToggleActiveModal } from '../ToggleActiveModal';
import { UpdateOptInServiceModal } from './UpdateOptInServiceModal';

/* eslint-disable react/prop-types */
const ServiceModals = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  closeModal,
  loading,
  mutation,
  getServiceNameAndStartDateThenUpdate,
}) => {
  const { reactivateActive, deactivateActive, createNewOptInService, updateOptInService, deleteService } = mutation;
  const { rowData } = pageState.service_table;
  return (
    <>
      {pageState.service_toggleActiveModal.show && (
        <ToggleActiveModal
          onClose={() => {
            setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal, isCategory: false });
          }}
          setDispatch={setDispatch}
          DISPATCH_FNS={DISPATCH_FNS}
          pageState={pageState}
          deactivateActive={deactivateActive}
          reactivateActive={reactivateActive}
          formId="service-toggleActiveForm"
          isDeactivate={pageState.service_toggleActiveModal.isDeactivate}
          loading={loading}
          isCategoryPage={false}
        />
      )}
      {pageState.service_updateOptInServiceModal.show && (
        <UpdateOptInServiceModal
          onClose={() => {
            setDispatch(DISPATCH_FNS.service_updateOptInServiceModal, { show: false, closeModal });
          }}
          setDispatch={setDispatch}
          DISPATCH_FNS={DISPATCH_FNS}
          pageState={pageState}
          createNewOptInService={createNewOptInService}
          updateOptInService={updateOptInService}
          getServiceNameAndStartDateThenUpdate={getServiceNameAndStartDateThenUpdate}
          formId="category-service_updateOptInServiceModal"
          isCreate={pageState.service_updateOptInServiceModal.isCreate}
          loading={loading}
        />
      )}
      {/* optInSvcId: $optInSvcId rateStatus: $rateStatus optInSvcInput: $optInSvcInput */}
      {pageState.service_deleteModal.show && (
        <DeleteModal
          id="afv-delete-surcharge-modal"
          title="Delete a Service"
          onClose={() => setDispatch(DISPATCH_FNS.service_updateDeleteModal, { show: false, closeModal })}
          onDelete={() => {
            deleteService({
              variables: {
                optInSvcId: rowData.optInSvcId,
                rateStatus: rowData.rateStatus,
                optInSvcInput: {
                  optInSvcCategory: rowData.optInSvcCategory,
                  optInSvcSalesId: _.toString(rowData.optInSvcSalesId),
                  optInSvcDesc: rowData.optInSvcDesc,
                  optInSvcName: rowData.optInSvcName,
                  startDate: rowData.startDate,
                },
              },
            });
          }}
          deleting={false}
          deleteButtonText="Delete"
        >
          <div className="margin-top-4 margin-bottom-4">{`You are about to delete ${pageState.service_table.rowData.optInSvcCategory}-${pageState.service_table.rowData.optInSvcName}. Are you sure you want to delete this service?`}</div>
        </DeleteModal>
      )}
    </>
  );
};

export default ServiceModals;
