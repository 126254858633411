import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import { ENTITY, ROLE_OP, hasAbilityTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions } from 'components/role-permission/row-action';
import AfpTable from 'widgets/afp-table-wrapper';
import exportCSVFile from 'components/file-export/client-side-csv-export';
import { formatDollar } from 'components/helpers/afp-bm-helpers';
import AfvSurchargeFilter from './afv-funding-filter';
import { GET_AGENCIES, GET_BUREAUS } from '../unique-rate-app/unique-rate-app-helper';
import {
  CURRENT_FY,
  GET_FINALIZED_AFV_SURCHARGES,
  GET_ALL_AMOUNT_FUNDED,
  GET_MOCK_DATA_FOR_BILLING,
  GET_MOCK_DATA_FOR_STORE,
  GET_MOCK_DATA_FOR_VMS,
  MergeAgencyData,
} from './afv-funding-helper';
import './style/afv-funding-table.scss';

const ROLE_ENTITY = ENTITY.AFV_SURCHARGE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  current: [ACTIONS.MANAGE],
  past: [ACTIONS.VIEW],
};

const DEFAULT_FILTER_STATE = { fiscalYear: CURRENT_FY };
const DEFAULT_ORDER = [['name', 'ASC']];
const DEFAULT_INDICATOR = 'N/A';
const DEFAULT_CSV_ORDER_BY = 'name';

const AFVFundingPage = ({ setBannerMsg }) => {
  const [agencyList, setAgencyList] = useState([]);
  const [fiscalYear, setFiscalYear] = useState(CURRENT_FY);
  const [filters, setFilters] = useState(DEFAULT_FILTER_STATE);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [showError, setShowError] = useState(false);

  const viewOnly = !hasAbilityTo(ROLE_OP.UPDATE, ENTITY.AFV_SURCHARGE);
  const history = useHistory();

  const onSort = (val) => {
    const res = val.split(' ');
    setOrder([[...res[0].split('`')[1].split('.'), res[1]]]);
  };

  const [fetchAgencies, { data: dataAgencies, loading: loadingAgencies, error: errorAgencies }] = useLazyQuery(
    GET_AGENCIES,
  );
  const [fetchBureaus, { data: dataBureaus, loading: loadingBureaus, error: errorBureaus }] = useLazyQuery(GET_BUREAUS);

  const [fetchSurcharges, { data: dataSurcharges, loading: loadingSurcharges, error: errorSurcharges }] = useLazyQuery(
    GET_FINALIZED_AFV_SURCHARGES,
  );
  const [
    fetchAmountFunded,
    { data: dataAmountFunded, loading: loadingAmountFunded, error: errorAmountFunded },
  ] = useLazyQuery(GET_ALL_AMOUNT_FUNDED);
  const [fetchVMSData, { data: dataVMS, loading: loadingVMS, error: errorVMS }] = useLazyQuery(GET_MOCK_DATA_FOR_VMS);
  const [fetchStoreData, { data: dataStore, loading: loadingStore, error: errorStore }] = useLazyQuery(
    GET_MOCK_DATA_FOR_STORE,
  );
  const [fetchBillingData, { data: dataBilling, loading: loadingBilling, error: errorBilling }] = useLazyQuery(
    GET_MOCK_DATA_FOR_BILLING,
  );

  useEffect(() => {
    fetchAgencies();
    fetchBureaus({ variables: { agencyCode: '' } });
  }, []);

  const fetchAllData = (fy) => {
    if (!fy) return;
    const query = { variables: { fiscalYear: fy } };
    fetchSurcharges(query);
    if (!viewOnly) {
      fetchAmountFunded(query);
      fetchBillingData(query);
      fetchVMSData();
      fetchStoreData(query);
    }
  };

  useEffect(() => {
    fetchAllData(fiscalYear);
  }, [fiscalYear]);

  useEffect(() => {
    if (!dataAgencies || !dataBureaus) return;
    const allBureaus = dataBureaus.getBureaus;
    const agencies = dataAgencies.getAgencies.map((item) => {
      const bureaus = allBureaus.filter((b) => b.agencyCode === item.id);
      const indicators = new Set();
      bureaus.forEach((b) => {
        if (!b.agencyIndicatorCode || b.agencyIndicatorCode === '0') indicators.add(DEFAULT_INDICATOR);
        else indicators.add(b.agencyIndicatorCode.trim());
      });
      if (indicators.size === 0) indicators.add(DEFAULT_INDICATOR);
      return {
        agencyCode: item.id,
        name: item.name,
        shortName: item.shortName,
        indicators: [...indicators],
      };
    });
    setAgencyList(agencies.sort((a, b) => (a.agencyCode < b.agencyCode ? -1 : 1)));
    fetchAllData(fiscalYear);
  }, [dataAgencies, dataBureaus]);

  useEffect(() => {
    if (!agencyList || !dataSurcharges) return;
    if (!viewOnly && !(dataAmountFunded && dataVMS && dataStore && dataBilling)) return;

    const rowCal = agencyList
      .map((agencyInfo) =>
        MergeAgencyData(
          viewOnly,
          fiscalYear,
          agencyInfo,
          {
            dataSurcharges: dataSurcharges.getFinalizedAFVSurcharges,
            dataAmountFunded: dataAmountFunded?.getAFVFYFunds,
            dataVMS: dataVMS?.getMockDataNumVehicles,
            dataStore: dataStore?.getMockDataByFY,
            dataBilling: dataBilling?.getMockDataByFY,
          },
          fiscalYear === CURRENT_FY,
        ),
      )
      .filter((row) => row);

    const detailsOptions = rowCal.map((r) => ({
      agencyCode: r.agencyCode,
      name: r.name,
      shortName: r.shortName,
      indicators: r.indicators,
    }));

    setData(
      rowCal.map((row) => ({
        ...row,
        detailsOptions,
      })),
    );
  }, [dataSurcharges, dataAmountFunded, dataVMS, dataStore, dataBilling]);

  // error handling
  const errorList = [
    errorAgencies,
    errorBureaus,
    errorSurcharges,
    errorAmountFunded,
    errorVMS,
    errorStore,
    errorBilling,
  ];
  const errorNames = ['agencies', 'bureaus', 'surcharges', 'amount funded', 'vehicles', 'spending', 'collection'];
  useEffect(() => {
    const errors = errorList.map((e, i) => (e ? errorNames[i] : '')).filter((e) => e);
    if (errors.length) {
      setShowError(true);
      setBannerMsg({
        type: 'error',
        message: `Error occured when loading the following data: ${errors.join(', ')}`,
      });
    }
  }, errorList);

  useEffect(() => {
    setFiscalYear(filters.fiscalYear || CURRENT_FY);
  }, [filters.fiscalYear]);

  useEffect(() => {
    let resRows = [];
    if (filters?.agencyCode) resRows = data.filter((r) => r.agencyCode === filters.agencyCode);
    else if (order?.length)
      resRows = [...data].sort((a, b) => {
        const [field, type] = order[0];
        const sortMethod = type === 'ASC' ? 1 : -1;
        return a[field] < b[field] ? -sortMethod : sortMethod;
      });
    else resRows = [...data];
    setRowData(resRows);
  }, [data, filters.agencyCode, order]);

  useEffect(() => {
    if (!rowData?.length) return;

    if (viewOnly) {
      setTableRows([...rowData]);
      return;
    }

    /** add Total row to table */
    const totalRowInit = {
      name: 'Total',
      totalSurcharges: undefined,
      totalAmountFunded: 0.0,
      totalAmountCollected: 0.0,
      totalAmountSpent: 0.0,
      totalBalance: 0.0,
      totalProjectToCollect: 0.0,
      totalProjectToSpend: 0.0,
      totalProjectedBalance: 0.0,
    };
    const totalRow = rowData.reduce(
      (sum, r) => ({
        name: sum.name,
        totalSurcharges: sum.totalSurcharges,
        totalAmountFunded: sum.totalAmountFunded + r.totalAmountFunded,
        totalAmountCollected: sum.totalAmountCollected + r.totalAmountCollected,
        totalAmountSpent: sum.totalAmountSpent + r.totalAmountSpent,
        totalBalance: sum.totalBalance + r.totalBalance,
        totalProjectToCollect: sum.totalProjectToCollect + r.totalProjectToCollect,
        totalProjectToSpend: sum.totalProjectToSpend + r.totalProjectToSpend,
        totalProjectedBalance: sum.totalProjectedBalance + r.totalProjectedBalance,
      }),
      totalRowInit,
    );
    setTableRows([...rowData, totalRow]);
  }, [rowData]);

  const handleSelectedAction = (label, original) => {
    if (label === 'Manage' || label === 'View')
      history.push({
        pathname: `/bm/afv-funding/surcharge`,
        state: {
          detailsOptions: original.detailsOptions,
          fiscalYear: original.fiscalYear,
          agencyCode: original.agencyCode,
        },
      });
  };

  const createRowActions = (cellData, actions) => {
    if (cellData?.row?.original?.name === 'Total') return '';
    return genRowActions(actions || [], cellData, handleSelectedAction);
  };

  const dollarCell = ({ value, column }) => {
    if (!value) {
      if (column.Header === 'Surcharge') return '';
      if (value !== 0) return '--';
    }
    return <span className={value < 0 ? 'minusBalance' : ''}>{formatDollar(value)}</span>;
  };

  const columnsViewOnly = useMemo(
    () => [
      {
        Header: 'Agency',
        accessor: 'name',
        // eslint-disable-next-line
        Cell: ({ value }) => value,
      },
      {
        Header: 'Surcharge',
        accessor: 'totalSurcharges',
        sortable: false,
        disableSortBy: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
    ],
    [],
  );
  const columnsCommon = [...columnsViewOnly];
  columnsCommon.push({
    Header: 'Amount funded',
    accessor: 'totalAmountFunded',
    sortable: true,
    Cell: dollarCell,
    cellClassName: 'cell-right',
    headerClassName: 'cell-right',
  });
  const columns = useMemo(() => {
    const cols = [...columnsCommon];
    cols.splice(
      3,
      0,
      {
        Header: 'Projected to collect',
        accessor: 'totalProjectToCollect',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Projected to spend',
        accessor: 'totalProjectToSpend',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Projected balance',
        accessor: 'totalProjectedBalance',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line
        Cell: (cellData) => createRowActions(cellData, ROW_ACTIONS.current),
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
      },
    );
    return cols;
  }, []);

  const columnsPastFY = useMemo(() => {
    const cols = [...columnsCommon];
    cols.splice(
      3,
      0,
      {
        Header: 'Funds collected',
        accessor: 'totalAmountCollected',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Funds spent',
        accessor: 'totalAmountSpent',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Balance',
        accessor: 'totalBalance',
        sortable: true,
        Cell: dollarCell,
        cellClassName: 'cell-right',
        headerClassName: 'cell-right',
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line
        Cell: (cellData) => createRowActions(cellData, ROW_ACTIONS.past),
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
      },
    );
    return cols;
  }, []);

  const onExportCSV = () => {
    if (!data?.length) return;
    let cols;
    if (viewOnly) cols = columnsViewOnly;
    else cols = (fiscalYear >= CURRENT_FY ? columns : columnsPastFY).slice(0, 6);
    const headers = cols.map((col) => col.Header);
    const accessors = cols.map((col) => col.accessor);
    data.sort((a, b) => (a[DEFAULT_CSV_ORDER_BY] < b[DEFAULT_CSV_ORDER_BY] ? -1 : 1))
    const items = data
      .map((row) =>
        accessors.map((acc) => {
          const val = row[acc];
          if (!val) return '';
          return typeof val === 'number' ? val.toFixed(2) : val;
        }),
      );
    exportCSVFile(headers, items, `AFV Funding Summary ${fiscalYear}`);
  };

  const isLoading =
    loadingAgencies ||
    loadingBureaus ||
    loadingSurcharges ||
    loadingAmountFunded ||
    loadingVMS ||
    loadingStore ||
    loadingBilling;
  return (
    <div className="margin-bottom-6">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '-64px 0 16px 0',
        }}
      >
        <Button
          id="export-csv-btn"
          data-testid="export-csv-btn"
          onClick={onExportCSV}
          leftIcon={{ name: 'file_download' }}
          label="Export to CSV"
        />
      </div>
      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3 afv-funding-filter-container standard-table-filters-wrapper margin-top-2">
          <AfvSurchargeFilter
            onFilter={(filterValue) => {
              const { fiscalYear: fy, agencyCode } = filterValue.filters;
              setFilters((prev) => ({ ...prev, fiscalYear: fy, agencyCode }));
            }}
            agencyList={agencyList}
          />
        </div>
        {viewOnly ? (
          <div className="desktop:grid-col-10 afv-funding-table-viewonly-container tablet-lg:grid-col-9">
            <AfpTable
              id="table-view-only"
              columns={columnsViewOnly}
              isLoading={isLoading}
              onError={showError}
              NoDataMessages={{
                title: 'No Data Available',
                text: 'There are no matches for the filtered values at left.',
              }}
              data={tableRows}
              onSort={onSort}
              fullWidth
            />
          </div>
        ) : (
          <div className="desktop:grid-col-10 afv-funding-table-container tablet-lg:grid-col-9">
            {fiscalYear >= CURRENT_FY && (
              <AfpTable
                id="table-current-fy"
                NoDataMessages={{
                  title: 'No Data Available',
                  text: 'There are no matches for the filtered values at left.',
                }}
                isLoading={isLoading}
                onError={showError}
                columns={columns}
                data={tableRows}
                onSort={onSort}
                fullWidth
              />
            )}
            {fiscalYear < CURRENT_FY && (
              <AfpTable
                id="table-past-fy"
                columns={columnsPastFY}
                isLoading={isLoading}
                onError={showError}
                NoDataMessages={{
                  title: 'No Data Available',
                  text: 'There are no matches for the filtered values at left.',
                }}
                data={tableRows}
                onSort={onSort}
                fullWidth
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AFVFundingPage.propTypes = {
  setBannerMsg: PropTypes.func.isRequired,
};

export default AFVFundingPage;
