import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { Can } from '@gsa/afp-shared-ui-utils';
import { Pagination, Button, useModal, Spinner } from '@gsa/afp-component-library';
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo, hasAbilityTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import AfpTable from 'widgets/afp-table-wrapper';
import { parseDollar } from 'components/helpers/afp-bm-helpers';
import { STATUS } from 'components/helpers/constants';
import UpdateInformation from 'components/subRowComponent/UpdateInformation';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { DEFAULT_NO_ITEMS_PER_PAGE, DEFAULT_PAGINATION_OPTIONS, DoReset } from 'components/helpers/pagination-helpers';
import RateStatusBlock from 'components/widgets/rate-status-block';
import exportCSVFile from 'components/file-export/client-side-csv-export';
import { GET_LEASE_RATES, CSVColumns, parseData2CSV } from './lease-rate-helper';
import LeaseRateUpdateModal from './lease-rate-update-modal';
import LeaseRateMoveModal from './lease-rate-move-modal';
import LeaseRateDeleteModal from './lease-rate-delete-modal';
import LeaseRateBulkUpdateModal from './lease-rate-bulk-update-modal';
import LeaseRateFilter from './lease-rate-filter';
import './style/lease-rate-table.scss';

const ROLE_ENTITY = ENTITY.LEASE_RATE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.UPDATE, ACTIONS.DUPLICATE, ACTIONS.MODIFY_COMBINATION, ACTIONS.DEACTIVATE],
  [STATUS.INACTIVE]: [ACTIONS.ACTIVATE],
  [STATUS.PENDING]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.NEW]: [ACTIONS.UPDATE, ACTIONS.MODIFY_COMBINATION, ACTIONS.DELETE],
  [STATUS.EXPIRED]: [],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

const DEFAULT_ORDER = [
  ['leaseRateCode', 'ASC'],
  ['startDate', 'ASC'],
];

const LeaseRatePage = ({ setBannerMsg }) => {
  // useState hooks
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [numberofItems, setnumberofItems] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [curPage, setCurPage] = useState(1);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [filterTS, setFilterTS] = useState(Date.now());
  const [filters, setFilters] = useState({
    virtualFilter: {
      operator: 'OR',
      value: [{ key: 'rateStatus', operator: 'EQ', value: 'Active' }],
    },
    limit: DEFAULT_NO_ITEMS_PER_PAGE,
    offset: 0,
    order: DEFAULT_ORDER,
  });
  const [isReset, setResetPagination] = useState(false);

  const [responseData, setResponseData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });
  const [showError, setShowError] = useState(false);

  // useModal hooks
  const { isOpen: isEditModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { isOpen: isMoveModalOpen, openModal: openMoveModal, closeModal: closeMoveModal } = useModal();
  const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const {
    isOpen: isBulkUpdateModalOpen,
    openModal: openBulkUpdateModal,
    closeModal: closeBulkUpdateModal,
  } = useModal();

  const onErrorFn = (err) => {
    setShowError(true);
    setBannerMsg({
      type: 'error',
      message: (
        <>
          Error occured when loading Lease Rate data. <br />
          {err.message}
        </>
      ),
    });
  };
  const [getData, { loading, data, refetch }] = useLazyQuery(GET_LEASE_RATES, {
    fetchPolicy: 'network-only',
    variables: { ...filters, limit, offset, order },
    onError: onErrorFn,
  });
  const [getExportData, { loading: loadingExport }] = useLazyQuery(GET_LEASE_RATES, {
    fetchPolicy: 'network-only',
    onError: onErrorFn,
    onCompleted: ({ getLeaseRates }) => {
      if (!getLeaseRates?.rows?.length) return;
      const { headers, items } = parseData2CSV(getLeaseRates.rows, CSVColumns);
      exportCSVFile(headers, items, `Fleet Lease Rates ${moment().format('YYYY-MM-DD-HHmmss')}`);
    },
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.getLeaseRates) {
      const rowData = data.getLeaseRates?.rows?.map((r) => ({
        ...r,
        leaseRateAssocTable: r.leaseRateAssociation.map((l) => ({
          sinFuelType: `${l.standardItemNumber} - ${l.fuelType}`,
        })),
      }));

      const tableData = {
        getLeaseRates: {
          ...data.getLeaseRates,
          rows: rowData,
        },
      };

      setResponseData(tableData.getLeaseRates);
    }
  }, [data]);

  const handleSelectedAction = (label, original) => {
    const codeData = { ...original };

    switch (label) {
      case ACTIONS.DUPLICATE.label:
      case ACTIONS.CREATE.label:
        codeData.leaseRateCode = '';
        codeData.leaseRateAssociation = [];
        codeData.startDate = null;
        setIsCreate(true);
        openEditModal();
        break;
      case ACTIONS.UPDATE.label:
        setIsCreate(false);
        openEditModal();
        break;
      case ACTIONS.MODIFY_COMBINATION.label:
        setIsCreate(false);
        openMoveModal();
        break;
      case ACTIONS.ACTIVATE.label:
      case ACTIONS.DEACTIVATE.label:
      case ACTIONS.DELETE.label:
        openDeleteModal();
        break;
      default:
    }

    setSelectedItem(codeData);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    let off = 0;
    if (itemsPerPage !== numberofItems) {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setCurPage(1);
      setnumberofItems(itemsPerPage);
      DoReset(setResetPagination);
    } else {
      off = (currentPage - 1) * itemsPerPage;
      setCurPage(currentPage);
    }

    setLimit(itemsPerPage);
    setOffset(off);
  };

  const onCloseModal = (modalType, doRefetch = false, newLRCData = null) => {
    switch (modalType) {
      case 'editModal':
        closeEditModal();
        if (isCreate && newLRCData) {
          setSelectedItem(newLRCData);
          openMoveModal();
        }
        break;
      case 'moveModal':
        closeMoveModal();
        break;
      case 'deleteModal':
        closeDeleteModal();
        if (newLRCData) {
          setSelectedItem(newLRCData);
          openMoveModal();
        }
        break;
      case 'bulkUpdateModal':
        closeBulkUpdateModal();
        break;
      default:
    }

    if (doRefetch === true) {
      refetch({
        variables: { ...filters, limit, offset, order },
      });
      setFilterTS(Date.now());
    }
  };

  const handleDelete = (type, message) => {
    setBannerMsg({
      type,
      message,
    });
  };

  let columns = [
    {
      Header: 'Lease Rate Code',
      accessor: 'leaseRateCode',
    },
    {
      Header: 'Standard Item Number - Fuel type',
      Cell: ({ row: { original = {} } }) =>
        (original.leaseRateAssocTable &&
          original.leaseRateAssocTable.map(({ sinFuelType }, i) => (
            // eslint-disable-next-line
            <div key={`${i}-${sinFuelType}`} className="lineData">
              {sinFuelType}
            </div>
          ))) ||
        null,
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: 'rateStatus',
      className: 'statusStyle',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={value} />,
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: 'Monthly rate',
      accessor: 'monthlyRate',
      Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
      cellClassName: 'cell-right',
      headerClassName: 'cell-right',
    },
    {
      Header: 'Mileage rate',
      accessor: 'mileageRate',
      Cell: ({ value }) => `$${parseFloat(value).toFixed(3)}`,
      cellClassName: 'cell-right',
      headerClassName: 'cell-right',
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      Cell: ({ value }) => UTCDateStrToUS(value),
    },
  ];
  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    columns.push({
      Header: 'Actions',
      sortable: false,
      Cell: (rowData) =>
        genRowActions(ROW_ACTIONS[rowData.row.original?.rateStatus] || [], rowData, handleSelectedAction),
    });

  if (!hasAbilityTo([ROLE_OP.CREATE], ROLE_ENTITY)) {
    columns = columns.filter((x) => x.Header !== 'Lease Rate Code');
  }

  const onSort = (val) => {
    const res = val.split(' ');
    setOrder([[...res[0].split('`')[1].split('.'), res[1]]]);
  };

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-header">Description</div>
            <div className="margin-bottom-2">{original?.leaseRateDescription || '–-'}</div>
          </div>
          {original?.comment && (
            <div className="tablet:grid-col">
              <div className="grid-row bm-subrow-header">
                Reason for {original?.rateStatus === STATUS.ACTIVE ? 'reactivation' : 'deactivation'}
              </div>
              <div className="margin-bottom-2">{original.comment}</div>
            </div>
          )}
        </div>
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Average daily rate</div>
              <div className="tablet:grid-col text-right">
                ${original.monthlyRate ? parseFloat((original.monthlyRate * 12) / 365).toFixed(2) : '--'}
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">End date</div>
              <div className="tablet:grid-col text-right">
                {original.endDate ? UTCDateStrToUS(original.endDate) : '–'}
              </div>
            </div>
            <UpdateInformation original={original} />
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Salvage percent</div>
              <div className="tablet:grid-col text-right">
                {original.salvagePercent
                  ? `${parseInt(original.salvagePercent)}%` // eslint-disable-line
                  : '--'}
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Useful life</div>
              <div className="tablet:grid-col text-right">
                {original.usefulLifeInMonth ? `${original.usefulLifeInMonth} months` : '--'}
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">High cost threshold markup</div>
              <div className="tablet:grid-col text-right">
                {original.highCostThresholdMarkup ? parseDollar(original.highCostThresholdMarkup) : '--'}
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Average age at sale</div>
              <div className="tablet:grid-col text-right">
                {original.avgAgeAtSale ? `${original.avgAgeAtSale} months` : '--'}
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Optional equipment markup</div>
              <div className="tablet:grid-col text-right">
                {original.optionalEquipMarkup ? parseDollar(original.optionalEquipMarkup) : '--'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const onExportCSV = () => {
    if (responseData?.count) getExportData({ variables: { ...filters, limit: responseData?.count, offset: 0, order } });
  };

  return (
    <div>
      <div className="bm_create_rate_button">
        {loadingExport && <Spinner className="margin-right-1" />}
        <Button
          id="export-csv-btn"
          data-testid="export-csv-btn"
          onClick={onExportCSV}
          leftIcon={{ name: 'file_download' }}
          label="Export to CSV"
          disabled={loading || loadingExport}
        />

        <Can I={ROLE_OP.CREATE} a={ROLE_ENTITY}>
          <Button
            onClick={openBulkUpdateModal}
            leftIcon={{ name: 'edit' }}
            label="Bulk update lease rates"
            data-testid="bulk-update-btn"
          />
          <Button
            onClick={() => handleSelectedAction('Create', null)}
            leftIcon={{ name: 'add' }}
            label="Create Lease Rate Code"
          />
        </Can>
      </div>
      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3 standard-table-filters-wrapper margin-top-4">
          <LeaseRateFilter
            onFilter={(filterValue) => {
              setOffset(0);
              setCurPage(1);
              DoReset(setResetPagination);
              setFilters(filterValue.filters);
            }}
            timestamp={filterTS}
          />
        </div>
        <div className="desktop:grid-col-10 lease-rate-table-container tablet-lg:grid-col-9">
          <AfpTable
            onError={showError}
            isLoading={loading}
            expandable
            columns={columns}
            data={responseData.rows || []}
            NoDataMessages={{
              title: 'No Rates Available',
              text: 'There are no matches for the filtered values at left.',
            }}
            renderRowSubComponent={renderRowSubComponent}
            onSort={onSort}
            defaultSort={order}
            fullWidth
          />

          <div className="padding-y-4">
            <Pagination
              itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
              onPageChange={handlePaginationChange}
              variant="advanced"
              currentPage={curPage}
              itemsCount={responseData?.count}
              itemsPerPage={numberofItems}
              isReset={isReset}
            />
          </div>

          {isEditModalOpen && hasSomeAbilitiesTo([ROLE_OP.CREATE, ROLE_OP.UPDATE], ROLE_ENTITY) && (
            <LeaseRateUpdateModal
              onClose={(doRefetch, newLRCData) => onCloseModal('editModal', doRefetch, newLRCData)}
              setBannerMsg={setBannerMsg}
              data={selectedItem}
              isCreate={isCreate}
            />
          )}

          {isMoveModalOpen && selectedItem && (
            <Can I={ROLE_OP.UPDATE} a={ROLE_ENTITY}>
              <LeaseRateMoveModal
                onClose={(doRefetch, newLRCData) => onCloseModal('moveModal', doRefetch, newLRCData)}
                data={selectedItem}
                isCreate={isCreate}
              />
            </Can>
          )}

          {isDeleteModalOpen && selectedItem && (
            <Can I={ROLE_OP.DELETE} a={ROLE_ENTITY}>
              <LeaseRateDeleteModal
                onClose={(doRefetch, newLRCData) => onCloseModal('deleteModal', doRefetch, newLRCData)}
                setBannerMsg={setBannerMsg}
                data={selectedItem}
                handleDelete={handleDelete}
              />
            </Can>
          )}

          {isBulkUpdateModalOpen && hasSomeAbilitiesTo([ROLE_OP.UPDATE], ROLE_ENTITY) && (
            <LeaseRateBulkUpdateModal
              onClose={(doRefetch, newLRCData) => onCloseModal('bulkUpdateModal', doRefetch, newLRCData)}
              setBannerMsg={setBannerMsg}
            />
          )}
        </div>
      </div>
    </div>
  );
};

LeaseRatePage.defaultProps = {
  row: { original: {} },
};

LeaseRatePage.propTypes = {
  setBannerMsg: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }),
};

export default LeaseRatePage;
