import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useWalletFilter } from './filters/filter-provider';
import { generateWalletFilterStructure } from './filters/wallet-filter-structure';

const WalletSidebar = () => {
  const { filterStructure, setFilters, setStructure } = useWalletFilter();

  const setWalletFilters = (conditions) => {
    setFilters(conditions);
  };

  useEffect(() => {
    setStructure(generateWalletFilterStructure());
  }, []);

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setWalletFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default WalletSidebar;
