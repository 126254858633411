import React from 'react';
import { classNameErrBox } from 'components/helpers/afp-bm-helpers';
import { Label, Checkbox } from '@gsa/afp-component-library';
import { createErrorMessage, validateCheckBoxError } from '../../helper';
import RateCalculator from './RateCalculator';

/* eslint-disable react/prop-types */

const RateStructureGroup = ({ setDispatch, pageState, DISPATCH_FNS, register, errors, setError, clearErrors }) => {
  return (
    <>
      <div className={`usa-form-group ${classNameErrBox(errors?.rateSection)}`}>
        <Label required>Rate and frequency</Label>
        {createErrorMessage(errors?.rateSection)}
        <Checkbox
          className="margin-top-2"
          name="oneTimeCheckbox"
          data-testid="oneTimeCheckbox"
          label="This rate requires one-time fee"
          inputRef={register}
          onChange={(ev) => {
            validateCheckBoxError(setError, clearErrors, ev, [pageState.service_table.rowData.recurringRate.checkBox]);
            setDispatch(DISPATCH_FNS.updateStateByPath, {
              path: 'service_table.rowData.oneTimeRate.checkBox',
              data: ev.target.checked,
            });
          }}
          checked={pageState.service_table.rowData.oneTimeRate.checkBox}
        />
        {pageState.service_table.rowData.oneTimeRate.checkBox && (
          <RateCalculator
            setDispatch={setDispatch}
            pageState={pageState}
            DISPATCH_FNS={DISPATCH_FNS}
            register={register}
            errors={errors}
          />
        )}
        <Checkbox
          className="margin-top-2"
          name="recurringCheckbox"
          data-testid="recurringCheckbox"
          label="This rate requires a recurring fee"
          inputRef={register}
          onChange={(ev) => {
            validateCheckBoxError(setError, clearErrors, ev, [pageState.service_table.rowData.oneTimeRate.checkBox]);
            setDispatch(DISPATCH_FNS.updateStateByPath, {
              path: 'service_table.rowData.recurringRate.checkBox',
              data: ev.target.checked,
            });
          }}
          checked={pageState.service_table.rowData.recurringRate.checkBox}
        />
        {pageState.service_table.rowData.recurringRate.checkBox && (
          <RateCalculator
            setDispatch={setDispatch}
            pageState={pageState}
            DISPATCH_FNS={DISPATCH_FNS}
            register={register}
            errors={errors}
            rateStructure="recurringRate"
          />
        )}
      </div>
    </>
  );
};

export default RateStructureGroup;
