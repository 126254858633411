import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const PegasysFilterContext = createContext({});
const usePegasysFilter = () => useContext(PegasysFilterContext);

const initialState = {
  error: {},
  ffFilters: {
    filters: { operator: 'AND', value: []},
    virtualFilter: { operator: 'AND', value: [] },
    order: [['id', 'DESC']],
    offset: 0,
    limit: 10,
  },
  wsFilters: {
    filters: { operator: 'AND', value: []},
    virtualFilter: { operator: 'AND', value: [] },
    order: [['id', 'DESC']],
    offset: 0,
    limit: 10,
  },
  ffFilterStructure: [],
  wsFilterStructure: [],
};

const actions = {
  setFlatFileFilters: 'SET_FF_FILTERS',
  setError: 'SET_ERROR',
  setFlatFilesStructure: 'SET_FF_STRUCTURE',
  setWsStructure: 'SET_WS_STRUCTURE',
  setWsFilters: 'SET_WS_FILTERS',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const PegasysFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setFlatFileFilters: {
      return mergeState(payload, 'ffFilters');
    }
    case actions.setWsFilters: {
      return mergeState(payload,
        'wsFilters',
      );
    }
    case actions.setFlatFilesStructure: {
      return mergeState(payload, 'ffFilterStructure');
    }
    case actions.setWsStructure: {
      return mergeState(payload, 'wsFilterStructure');
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const PegasysFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(PegasysFilterReducer, initialState, () => initialState);

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchFilters = (conditions) => dispatch(actions.setFlatFileFilters, conditions);

  const dispatchWsFilters = (conditions) => dispatch(actions.setWsFilters, conditions);

  const dispatchFlatFileStructure = (structure) => dispatch(actions.setFlatFilesStructure, structure);

  const dispatchWSFilterStructure = (structure) => dispatch(actions.setWsStructure, structure);

  return (
    <PegasysFilterContext.Provider
      value={{
        ...state,
        dispatch,
        setFlatFileFilters: dispatchFilters,
        setFlatFilesStructure: dispatchFlatFileStructure,
        setWsStructure: dispatchWSFilterStructure,
        setWsFilters: dispatchWsFilters,
      }}
    >
      {children}
    </PegasysFilterContext.Provider>
  );
};

PegasysFilterProvider.defaultProps = {
  children: undefined,
};

PegasysFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { PegasysFilterProvider as default, usePegasysFilter };