import React, { useEffect, useMemo, useState } from 'react';
import { AFPTable, Pagination, FilterTableFrame, useModal, Spinner, EmptyState } from '@gsa/afp-component-library';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { 
  rowSubComponent, 
  WEB_SERVICES, 
  returnSelectedActionsData,
  ROW_ACTIONS,
  ACTIONS,
} from './pegasys-common-util';
import './style/pegasys-file-error-handling-table.scss';
import { genRowActions } from '../../components/role-permission/row-action';
import PegasysErrorCommentModal from './pegasys-error-comment-modal';
import PegasysErrorResolveModal from './pegasys-error-resolve-modal';
import PegasysWsSidebar from './pegasys-ws-filter-sidebar';
import { usePegasysContext } from './pegasys-provider';
import { usePegasysFilter } from './filters/pegasys-filter-provider';


const PegasysWsErrorHandlingTable = ({ setBannerMsg, setSelectedTab }) => {
  const { wsFilters } = usePegasysFilter();
  const { fetchWsData, receivedWsData, setWsErrResponse, wsErrResponse, wsDataLoading, fetchAllWsVINSAndDocs } = usePegasysContext();

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen: isCommentModalOpen, openModal: openCommentModal, closeModal: closeCommentModal } = useModal();
  const { isOpen: isResolveModalOpen, openModal: openResolveModal, closeModal: closeResolveModal } = useModal();
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    if (receivedWsData?.getWsError?.count > 0) {
      setWsErrResponse(receivedWsData?.getWsError?.rows);
    } else {
      setWsErrResponse([]);
    }
  }, [receivedWsData]);

  useEffect(() => {
    const filtersTemp = {
      ...wsFilters,
      offset: paginationState.offset,
      limit: paginationState.limit,
    };

    fetchWsData({
      variables: { ...filtersTemp },
    });
    setSuccessMsg(null)
  }, [paginationState, wsFilters, successMsg]);

  useEffect(() => {
    fetchAllWsVINSAndDocs();
  }, []);

  const handleSelectedAction = (label, original) => {
    const returnedData = returnSelectedActionsData(original);
    setSelectedItem(returnedData);
    switch (label) {
      case ACTIONS.MARK_RESOLVED.label:
        openResolveModal();
        break;
      case ACTIONS.COMMENT.label:
        openCommentModal();
        break;
      default:
    }
  };
  
  const handleClose = (modalType) => {
    switch (modalType) {
      case 'commentModal':
        closeCommentModal();
        break;
      case 'resolveModal':
        closeResolveModal();
        break;
      default:
    }
  };

  const wsColumns = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY') : null),
    },
    {
      Header: 'Document type',
      accessor: 'documentType',
    },
    {
      Header: 'Document number',
      accessor: 'documentNumber',
    },
    {
      Header: 'VIN',
      accessor: 'vin',
    },
    {
      Header: 'Actions',
      id: 'table-row-action',
      sortable: false,
      Cell: (rowData) =>
          genRowActions(ROW_ACTIONS[rowData.row.original.errorDataStatus] || [], rowData, handleSelectedAction, false),
    },
  ];

  const onSetMsg = (message) => {
    setSelectedTab(WEB_SERVICES);
    if (message?.type === 'success') {
      setSuccessMsg(message.message.props.children[0]);
    }
    setBannerMsg(message);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const TableWrapper = ({
    wsErrResponseData,
    pegasysWsErrorsData,
    isCommentModal,
    isResolveModal,
    selectedItemObj,
    paginationStateObj,
    isLoading
  }) => {
    
    return (
      <>
        {isLoading ? (
          <div style={{ margin: '300px auto' }}>
            <Spinner />
          </div>
        ) : (
          <>
            <AFPTable
              columns={wsColumns}
              data={wsErrResponseData}
              expandable
              renderRowSubComponent={rowSubComponent}
            />
            {!wsErrResponseData.length && (
              <>
                <div data-testid='empty-state' className='bg-gray-3 padding-y-5'>
                  <div className='text-center padding-y-4'>
                    <EmptyState alt='Image not available' hasBackground />
                  </div>
                  <div className='text-center text-bold'>No Records Available</div>
                </div>
              </>
            )}
            <Pagination
              variant='advanced'
              itemsCount={pegasysWsErrorsData?.getWsError?.count}
              itemsPerPage={paginationStateObj.limit}
              currentPage={paginationStateObj.currentPage}
              isReset={paginationStateObj.isReset}
              onPageChange={handlePaginationChange}
            />
          </>
        )}

        {isCommentModal && (
          <PegasysErrorCommentModal
            data={selectedItemObj}
            onClose={() => handleClose('commentModal')}
            setBannerMsg={onSetMsg}
            serviceType={WEB_SERVICES}
          />
        )}
        {isResolveModal && (
          <PegasysErrorResolveModal
            data={selectedItemObj}
            onClose={() => handleClose('resolveModal')}
            setBannerMsg={onSetMsg}
            serviceType={WEB_SERVICES}
          />
        )}
      </>
    );
      }

  TableWrapper.defaultProps = {
    wsErrResponseData: {},
    pegasysWsErrorsData: {},
    isCommentModal: false,
    isResolveModal: false,
    selectedItemObj: {},
    paginationStateObj: {},
    isLoading: false,
  };
  
  TableWrapper.propTypes = {
    wsErrResponseData: PropTypes.objectOf(PropTypes.object),
    pegasysWsErrorsData: PropTypes.objectOf(PropTypes.object),
    isCommentModal: PropTypes.bool,
    isResolveModal: PropTypes.bool,
    selectedItemObj: PropTypes.objectOf(PropTypes.object),
    paginationStateObj: PropTypes.objectOf(PropTypes.object),
    isLoading: PropTypes.bool,
  };

  const FTF = useMemo(() => FilterTableFrame(null, null, PegasysWsSidebar, TableWrapper), []);

  return (
    <>
      <FTF
        lowRightProps={{
          wsErrResponseData: wsErrResponse,
          isCommentModal: isCommentModalOpen,
          isResolveModal: isResolveModalOpen,
          pegasysWsErrorsData: receivedWsData,
          selectedItemObj: selectedItem,
          paginationStateObj: paginationState,
          isLoading: wsDataLoading
        }}
        filterToggle
      />
    </>
  );
};
PegasysWsErrorHandlingTable.defaultProps = {
  setBannerMsg: undefined,
  setSelectedTab: undefined
};

PegasysWsErrorHandlingTable.propTypes = {
  setBannerMsg: PropTypes.func,
  setSelectedTab: PropTypes.func,
};
export default PegasysWsErrorHandlingTable;