import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Spinner, RequiredFieldIndicator } from '@gsa/afp-component-library';
import WalletIpacVehiclesForm from './forms/wallet-ipac-vehicles-form';
import { useWalletDetails } from './wallet-details-provider';

const ModalActions = ({ closeModal, mode, asset }) => {
  const { createIpacVehicleLoading, deleteIpacVehicle, deleteIpacVehicleLoading } = useWalletDetails();
  return (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={closeModal}
        data-testid="ipac-vehicles-modal-cancel-button"
        label="Cancel"
      />
      {mode === 'edit' ? (
        <Button
          variant="primary"
          type="submit"
          form="ipac-vehicles-form"
          data-testid="ipac-vehicles-modal-submit-button"
          disabled={createIpacVehicleLoading || deleteIpacVehicleLoading}
          label="Submit"
        />
      ) : (
        <Button
          variant="secondary"
          onClick={() => {
            deleteIpacVehicle({
              variables: {
                // eslint-disable-next-line react/prop-types
                id: asset?.walletAssetId,
              },
            });
          }}
          data-testid="ipac-vehicles-modal-delete-button"
          disabled={createIpacVehicleLoading || deleteIpacVehicleLoading}
          label="Delete"
        />
      )}
      {createIpacVehicleLoading && <Spinner size="small" className="display-inline-block margin-left-2" />}
    </>
  );
};

ModalActions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    tagNumber: PropTypes.string.isRequired,
    modelYear: PropTypes.string,
    makeName: PropTypes.string,
    modelCode: PropTypes.string,
    makeColorName: PropTypes.string,
    makeColorCode: PropTypes.string,
  }).isRequired,
};

const WalletIpacVehiclesModal = ({ isOpen, closeModal, asset, mode }) => {
  const { alertMessage, updatedIpacVehicle, deletedIpacVehicle } = useWalletDetails();
  const { header, message, type, context } = alertMessage;

  useEffect(() => {
    if (updatedIpacVehicle?.id || deletedIpacVehicle) {
      closeModal();
    }
  }, [updatedIpacVehicle, deletedIpacVehicle]);

  const title = mode === 'edit' ? 'IPAC for vehicle' : 'Delete confirmation';

  return isOpen ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>{title}</h2>}
          actions={<ModalActions closeModal={closeModal} mode={mode} asset={asset} />}
          onClose={closeModal}
          variant="large"
        >
          {context === 'walletIpacVehiclesModal' && message && (
            <div className="margin-bottom-2" data-testid="ipac-vehicles-modal-alert">
              <Alert type={type} heading={header || null}>
                {message}
              </Alert>
            </div>
          )}
          {mode === 'edit' ? (
            <>
              <p>Enter information below</p>
              <p>
                Required fields are marked with an asterisk (
                <RequiredFieldIndicator />
                ).
              </p>
              <p className="title-m">
                Information entered here will ONLY apply to one Vehicle: <strong>{asset?.tagNumber}</strong>
              </p>
              <p>
                <strong>
                  {asset?.modelYear} {asset?.makeName} {asset?.modelCode}{' '}
                  {asset?.makeColorName ? asset?.makeColorName : asset?.makeColorCode || ''}
                </strong>
              </p>
              <WalletIpacVehiclesForm asset={asset} />
            </>
          ) : (
            <p className="font-body-xs margin-bottom-10">
              Are you sure you want to delete the IPAC information? <br />
              IPAC data provided for the BOAC will now be used for this vehicle.
            </p>
          )}
        </Modal>
      </div>
    </div>
  ) : null;
};

export default WalletIpacVehiclesModal;

WalletIpacVehiclesModal.defaultProps = {
  mode: 'edit',
};

WalletIpacVehiclesModal.propTypes = {
  mode: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    tagNumber: PropTypes.string.isRequired,
    modelYear: PropTypes.string,
    makeName: PropTypes.string,
    modelCode: PropTypes.string,
    makeColorName: PropTypes.string,
    makeColorCode: PropTypes.string,
  }).isRequired,
};
