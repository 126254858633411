import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Can } from '@gsa/afp-shared-ui-utils';
import { Button, Pagination, useModal } from '@gsa/afp-component-library';
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import { fracToPercent } from 'components/helpers/afp-bm-helpers';
import UpdateInformation from 'components/subRowComponent/UpdateInformation';
import { STATUS } from 'components/helpers/constants';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import RateStatusBlock from 'components/widgets/rate-status-block';
import AfpTable from 'widgets/afp-table-wrapper';
import { DEFAULT_NO_ITEMS_PER_PAGE, DEFAULT_PAGINATION_OPTIONS, DoReset } from 'components/helpers/pagination-helpers';
import './style/purchase-rate-table.scss';
import PurchaseRateUpdateModal from './purchase-rate-update-modal';
import PurchaseRateDeleteModal from './purchase-rate-delete-modal';
import PurchaseRateFilter from './purchase-rate-filter';
import { GET_PURCHASE_RATE_BY_STATUS } from './components/graphql-queries';

const ROLE_ENTITY = ENTITY.PURCHASE_RATE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.UPDATE, ACTIONS.DEACTIVATE],
  [STATUS.INACTIVE]: [ACTIONS.ACTIVATE],
  [STATUS.PENDING]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.NEW]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.EXPIRED]: [],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

const DEFAULT_FILTER_STATE = { rateStatus: [STATUS.ACTIVE] };
const DEFAULT_FILTERS = {
  virtualFilter: {
    operator: 'OR',
    value: [{ key: 'rateStatus', operator: 'EQ', value: 'Active' }],
  },
};
const PRIMARY_ORDER = ['purchaseType', 'ASC'];
const SECONDARY_ORDER = ['effectiveStartDate', 'ASC'];
const DEFAULT_ORDER = [PRIMARY_ORDER, SECONDARY_ORDER];

const PurchaseRateTable = ({ setBannerMsg }) => {
  // useState hooks
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCreate, setIsCreate] = useState(false);

  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [isReset, setIsReset] = useState(false);

  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filterState, setFilterState] = useState({ ...DEFAULT_FILTER_STATE });
  const [currentFilterState, setCurrentFilterState] = useState({
    ...DEFAULT_FILTER_STATE,
  });
  const [filterTS, setFilterTS] = useState(Date.now());
  const [showError, setShowError] = useState(false);

  // useModal hooks
  const { isOpen: isEditModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const { loading, refetch, data: purchaseRate } = useQuery(GET_PURCHASE_RATE_BY_STATUS, {
    variables: {
      ...filters,
      order,
      limit,
      offset,
    },
    onError: (err) => {
      setShowError(true);
      setBannerMsg({
        type: 'error',
        message: `Loading Error: ${err.message}`,
      });
    },
  });

  // pagination handler
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    if (limit !== itemsPerPage) {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setLimit(itemsPerPage);
      setCurPage(1);
      setOffset(0);
      DoReset(setIsReset);
    } else {
      setCurPage(currentPage);
      setOffset((currentPage - 1) * itemsPerPage);
    }
  };

  // useEffect hooks
  useEffect(() => {
    setCurPage(1);
    setOffset(0);
    DoReset(setIsReset);
  }, [filters, order]);

  const onSetMsg = (message) => {
    if (message?.type === 'success') {
      refetch({
        variables: { limit, offset, order, ...filters },
      });
    }
    setBannerMsg(message);
  };

  const onSort = (val) => {
    const res = val?.split(' ');
    if (!res?.length) return;
    const primaryOrder = [...res[0].split('`')[1].split('.'), res[1]];
    if (primaryOrder[0] === PRIMARY_ORDER[0]) setOrder([primaryOrder, SECONDARY_ORDER]);
    else setOrder([primaryOrder, PRIMARY_ORDER]);
  };

  const onAddPendingFilter = () => {
    if (currentFilterState.rateStatus && !currentFilterState.rateStatus.includes(STATUS.PENDING)) {
      const newFilterState = { ...currentFilterState };
      newFilterState.rateStatus.push(STATUS.PENDING);
      setFilterState(newFilterState);
      setFilterTS(Date.now());
    }
  };

  const handleSelectedAction = (label, original) => {
    const rowData = { ...original };
    setSelectedItem(rowData);
    switch (label) {
      case ACTIONS.CREATE.label:
        rowData.purchaseType = '';
        rowData.purchaseRateType = '';
        rowData.purchaseTypeCode = '';
        rowData.effectiveStartDate = null;
        rowData.rateStatus = STATUS.NEW;
        setIsCreate(true);
        openEditModal();
        break;
      case ACTIONS.UPDATE.label:
        setIsCreate(false);
        openEditModal();
        break;
      case ACTIONS.ACTIVATE.label:
      case ACTIONS.DEACTIVATE.label:
      case ACTIONS.DELETE.label:
        openDeleteModal();
        break;
      default:
    }
  };

  const handleClose = (modalType) => {
    switch (modalType) {
      case 'editModal':
        closeEditModal();
        break;
      case 'deleteModal':
        closeDeleteModal();
        break;
      default:
    }
  };

  const columns = [
    {
      Header: 'Purchase type',
      accessor: 'purchaseType',
      sortable: true,
      collapsedRowClassName: 'purchaseTypeStyle',
    },
    {
      Header: 'Status',
      accessor: 'rateStatus',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={value} />,
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: 'Rate',
      sortable: false,
      Cell: ({ row: { original = {} } }) => {
        let value = '--';
        if (original.flatDollarFee) value = `$${parseFloat(original.flatDollarFee).toFixed(2)}`;
        else if (original.fixedPercent) {
          value = `${fracToPercent(original.fixedPercent)}%`;
        }
        return value;
      },
      cellClassName: 'cell-right',
      headerClassName: 'cell-right',
    },
    {
      Header: 'Start date',
      accessor: 'effectiveStartDate',
      sortable: true,
      Cell: ({ value }) => UTCDateStrToUS(value),
    },
    {
      Header: 'End date',
      accessor: 'effectiveEndDate',
      sortable: true,
      Cell: ({ value }) => (value == null ? '–' : UTCDateStrToUS(value)),
    },
  ];
  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    columns.push({
      Header: 'Actions',
      accessor: 'action',
      sortable: false,
      disableSortBy: true,
      Cell: (rowData) =>
        genRowActions(ROW_ACTIONS[rowData.row.original?.rateStatus] || [], rowData, handleSelectedAction),
    });

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-header">Rate application</div>
            <div className="grid-row">{original.purchaseTypeDesc || '–-'}</div>
            {original.comment && [STATUS.ACTIVE, STATUS.INACTIVE].includes(original?.rateStatus) && (
              <div>
                <div className="grid-row bm-subrow-header">
                  Reason for {original?.rateStatus === STATUS.ACTIVE ? 'reactivation' : 'deactivation'}
                </div>
                <div className="grid-row">{original.comment}</div>
              </div>
            )}
          </div>
          <div className="tablet:grid-col">
            <UpdateInformation original={original} />
          </div>
        </div>
      </div>
    );
  }, []);

  // Purchase Rate Page
  return (
    <div>
      <Can I={ROLE_OP.CREATE} a={ROLE_ENTITY}>
        <div className="bm_create_rate_button">
          <Button
            id="create-purchase-rate-btn"
            data-testid="create-purchase-rate-btn"
            onClick={() => handleSelectedAction('Create', null)}
            leftIcon={{ name: 'add' }}
            label="Create purchase type"
          />
        </div>
      </Can>
      <div className="grid-row grid-gap margin-bottom-4">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3 standard-table-filters-wrapper margin-top-4">
          <PurchaseRateFilter
            defaultState={filterState}
            onFilter={(filterValue) => {
              setFilters(filterValue.filters);
              setCurrentFilterState(filterValue.state);
            }}
            timestamp={filterTS}
          />
        </div>

        <div className="desktop:grid-col-10 tablet-lg:grid-col-9 rates-table standard-table-wrapper tbl">
          <div id="rates-table">
            <AfpTable
              onError={showError}
              isLoading={loading}
              expandable
              columns={columns}
              data={purchaseRate?.getPurchaseRates?.rows}
              NoDataMessages={{
                title: 'No Rates Available',
                text: 'There are no matches for the filtered values at left.',
              }}
              renderRowSubComponent={renderRowSubComponent}
              onSort={onSort}
              defaultSort={order}
              fullWidth
              className="RatesTableHeader"
            />

            <div className="padding-y-4">
              <Pagination
                itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
                onPageChange={handlePaginationChange}
                variant="advanced"
                currentPage={curPage}
                itemsCount={purchaseRate?.getPurchaseRates?.count}
                itemsPerPage={limit}
                isReset={isReset}
              />
            </div>

            {isEditModalOpen && hasSomeAbilitiesTo([ROLE_OP.CREATE, ROLE_OP.UPDATE], ROLE_ENTITY) && (
              <PurchaseRateUpdateModal
                isCreate={isCreate}
                data={selectedItem}
                onClose={() => handleClose('editModal')}
                setBannerMsg={onSetMsg}
                addPendingFilter={onAddPendingFilter}
              />
            )}

            {isDeleteModalOpen && (
              <Can I={ROLE_OP.DELETE} a={ROLE_ENTITY}>
                <PurchaseRateDeleteModal
                  data={selectedItem}
                  onClose={() => handleClose('deleteModal')}
                  setBannerMsg={onSetMsg}
                />
              </Can>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PurchaseRateTable.defaultProps = {
  setBannerMsg: undefined,
};

PurchaseRateTable.propTypes = {
  setBannerMsg: PropTypes.func,
};

export default PurchaseRateTable;
