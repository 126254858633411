import React from 'react';
import { Button } from '@gsa/afp-component-library';
import _ from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { stateValue } from 'utilities/stateUtils';
import { toFixedFn } from 'utilities/formatUtils';
import RateInputComponent from 'components/widgets/RateInput';

/* eslint-disable react/prop-types */

const NewFundingForm = ({ pageState, setDispatch, DISPATCH_FNS, updateFunds }) => {
  const { handleSubmit } = useForm({
    shouldFocusError: true,
    defaultValues: {
      replacement: stateValue(pageState.newFunding.replacement),
      consolidation: stateValue(pageState.newFunding.consolidation),
      additional: stateValue(pageState.newFunding.additional),
      total: stateValue(pageState.newFunding.total),
    },
  });

  const validatedSubmit = () => {
    updateFunds({
      variables: {
        acquisitionFundingInput: {
          fiscalYear: stateValue(pageState.fiscalYear),
          consolidationFunding: toFixedFn(pageState.newFunding.consolidation, 4),
          replacementFunding: toFixedFn(pageState.newFunding.replacement, 4),
          additionalFunding: toFixedFn(pageState.newFunding.additional, 4),
        },
      },
    });
    setDispatch(DISPATCH_FNS.reset);
  };

  const isFundAdjusted = !_.isEmpty(stateValue(pageState.newFunding.total));

  const onSubmit = async () => {
    isFundAdjusted && validatedSubmit();
  };
  const labelNameFn = (name) => `new ${name}`;

  const responsiveGridClass = `tablet:grid-col-2`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="acquisition-funding-new-form">
      <div className="grid-row grid-gap bm-form-row">
        <div className={responsiveGridClass}>
          <RateInputComponent
            name="replacement"
            labelNameFn={labelNameFn}
            readOnly
            path="newFunding.replacement"
            value={stateValue(pageState.newFunding.replacement)}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            readOnly
            name="consolidation"
            labelNameFn={labelNameFn}
            path="newFunding.consolidation"
            value={stateValue(pageState.newFunding.consolidation)}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            path="newFunding.additional"
            value={stateValue(pageState.newFunding.additional)}
            readOnly
            name="additional"
            labelNameFn={labelNameFn}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            path="newFunding.total"
            value={stateValue(pageState.newFunding.total)}
            readOnly
            name="total"
            labelNameFn={labelNameFn}
          />
        </div>
        <div className={responsiveGridClass}>
          <div className="bm-form-row-button">
            <Button
              data-testid="new-save"
              size="sm"
              variant="primary"
              id="fund-save-btn"
              aria-label="Submit form"
              hidden="hidden"
              type="submit"
              label="Save"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewFundingForm;
