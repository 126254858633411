import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@gsa/afp-component-library';

const SortIcon = ({ id, order, label, onClick }) => {
  let iconProps;
  if (order === 'ASC')
    iconProps = {
      dataTestId: 'sort-asc',
      iconName: 'icon-sort-down',
      onClickOrder: 'DESC',
    };
  else if (order === 'DESC')
    iconProps = {
      dataTestId: 'sort-desc',
      iconName: 'icon-sort-up',
      onClickOrder: 'ASC',
    };
  else
    iconProps = {
      dataTestId: 'sort-none',
      iconName: 'icon-sort-toggle',
      onClickOrder: 'ASC',
    };

  return (
    <div className="bm-sort-icon">
      <button
        type="button"
        variant="unstyled"
        onClick={() => onClick(id, iconProps.onClickOrder)}
        data-testid={iconProps.dataTestId}
        aria-label={`sort by ${label}`}
      >
        <Icon type="custom" iconName={iconProps.iconName} className="table__sort-icon usa-icon--size-3" />
        <span>{label}</span>
      </button>
    </div>
  );
};

SortIcon.defaultProps = {
  order: undefined,
  label: '',
};

SortIcon.propTypes = {
  id: PropTypes.string.isRequired,
  order: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default SortIcon;
