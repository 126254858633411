import { gql } from '@apollo/client';

export const CREATE_NEW_OPT_IN_CATEGORY = gql`
  mutation createNewOptInCategory($optInCategoryInput: OptInCategoryInput!) {
    createNewOptInCategory(optInCategoryInput: $optInCategoryInput) {
      rateStatus
      optInCategoryName
      optInCategoryDesc
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      dataSource
      dmlFlag
    }
  }
`;
