import { useState } from 'react';
import { createContainer } from 'react-tracked';
import { setProxyState, useStateBuilder } from 'utilities/stateUtils';
import { getFYInt } from 'components/helpers/afp-bm-date';
import _ from 'lodash/fp';
import { getValuesStrTotal } from '../helper';

/* eslint-disable */

const commonPageStateConfig = {
  currentPage: 'Requisition Funding',
  bannerMsgState: { type: null, content: null },
};

export const currentFY = _.toString(getFYInt());

const pageStateConfig = {
  fiscalYear: currentFY,
  fundingInitialed: false,
  amountSpent: {
    replacement: '0',
    consolidation: '0',
    additional: '0',
  },
  initialFunding_replacement: '',
  initialFunding_consolidation: '',
  initialFunding_additional: '',
  initialFunding_total: '',

  fundingAdjustment: {
    replacement: '',
    consolidation: '',
    additional: '',
    total: '',
  },
  newFunding: {
    replacement: '',
    consolidation: '',
    additional: '',
    total: '',
  },

  selectedTabId: 'balance-tab',
  historyTab: { currentPage: 1, itemsCount: 0, limit: 10, historyRows: [], order: [['updatedAt', 'DESC']] },

  balanceRows: [],
};
export const initialState = _.merge(commonPageStateConfig, pageStateConfig);

export const DISPATCH_FNS = {
  reset(draft) {
    Object.assign(draft, { ...initialState, fiscalYear: draft.fiscalYear, bannerMsgState: draft.bannerMsgState });
  },

  updateStateByPath(draft, { path, data }) {
    setProxyState(draft, path, data);
  },
  initialFunding: {
    setTotal(draft) {
      draft.initialFunding_total = getValuesStrTotal([
        draft.initialFunding_replacement,
        draft.initialFunding_consolidation,
        draft.initialFunding_additional,
      ]);
    },
  },
  fundingAdjustment: {
    // eslint-disable-next-line no-unused-vars
    setCalculate(draft, payload, dispatchFns) {
      draft.newFunding.replacement = getValuesStrTotal([
        draft.fundingAdjustment.replacement,
        draft.initialFunding_replacement,
      ]);
      draft.newFunding.consolidation = getValuesStrTotal([
        draft.fundingAdjustment.consolidation,
        draft.initialFunding_consolidation,
      ]);
      draft.newFunding.additional = getValuesStrTotal([
        draft.fundingAdjustment.additional,
        draft.initialFunding_additional,
      ]);
      dispatchFns.newFunding.setTotal(draft);
    },
  },
  newFunding: {
    setTotal: (draft) => {
      draft.newFunding.total = getValuesStrTotal([
        draft.newFunding.replacement,
        draft.newFunding.consolidation,
        draft.newFunding.additional,
      ]);
    },
    reset: (draft) => {
      draft.newFunding.replacement = '';
      draft.newFunding.consolidation = '';
      draft.newFunding.additional = '';
      draft.newFunding.total = '';
    },
  },
  bannerMsgState: {
    setTypeContent(draft, { type, content }) {
      draft.bannerMsgState.type = type;
      draft.bannerMsgState.content = content;
    },
  },
  updateAmountSpent(draft, { data }) {
    draft.amountSpent = data;
  },

  setItemPerPage(draft, { limit, resetPaginationComponent, tableName }, dispatchFns) {
    draft[`${tableName}`].limit = limit;
    dispatchFns.resetPagination(draft, { resetPaginationComponent, tableName });
  },
  resetPagination(draft, { resetPaginationComponent, tableName }) {
    draft[`${tableName}`].currentPage = 1;
    draft[`${tableName}`].offset = 0;
    resetPaginationComponent();
  },
};

const useValue = () => useState(initialState);

const { Provider, useTrackedState, useUpdate: useSetState } = createContainer(useValue);

const useDispatch = () => useStateBuilder(useSetState, DISPATCH_FNS);

export { Provider, useTrackedState, useDispatch };

//  hookstate store
// export const DISPATCH_FNS = {
//   reset(pageState) {
//     pageState.set((draft) => ({ ...initialState, fiscalYear: draft.fiscalYear, bannerMsgState: draft.bannerMsgState }));
//   },

//   updateStateByPath(draft, { path, data }) {
//     setProxyState(draft, path, data);
//   },
//   initialFunding: {
//     setTotal: (pageState) => {
//       pageState.initialFunding_total.set(() =>
//         getValuesStrTotal([
//           pageState.initialFunding_replacement.value,
//           pageState.initialFunding_consolidation.value,
//           pageState.initialFunding_additional.value,
//         ]),
//       );
//     },
//   },

//   fundingAdjustment: {
//     // eslint-disable-next-line no-unused-vars
//     setCalculate(pageState, payload, dispatchFns) {
//       pageState.newFunding.replacement.set(
//         getValuesStrTotal([pageState.fundingAdjustment.replacement.value, pageState.initialFunding_replacement.value]),
//       );
//       pageState.newFunding.consolidation.set(
//         getValuesStrTotal([
//           pageState.fundingAdjustment.consolidation.value,
//           pageState.initialFunding_consolidation.value,
//         ]),
//       );
//       pageState.newFunding.additional.set(
//         getValuesStrTotal([pageState.fundingAdjustment.additional.value, pageState.initialFunding_additional.value]),
//       );
//       dispatchFns.newFunding.setTotal(pageState);
//     },
//   },
//   newFunding: {
//     setTotal: (pageState) => {
//       pageState.newFunding.total.set(
//         getValuesStrTotal([
//           pageState.newFunding.replacement.value,
//           pageState.newFunding.consolidation.value,
//           pageState.newFunding.additional.value,
//         ]),
//       );
//     },
//     reset: (pageState) => {
//       pageState.newFunding.replacement.set('');
//       pageState.newFunding.consolidation.set('');
//       pageState.newFunding.additional.set('');
//       pageState.newFunding.total.set('');
//     },
//   },
//   bannerMsgState: {
//     setTypeContent(state, { type, content }) {
//       state.bannerMsgState.type.set(type);
//       state.bannerMsgState.content.set(content);
//     },
//   },
//   updateAmountSpent(state, { data }) {
//     state.amountSpent.set(data);
//   },
// };
