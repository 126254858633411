// Default items-per-page number
export const DEFAULT_NO_ITEMS_PER_PAGE = 10;

// Default items-per-page options, e.g. [5,10,25], or 'undefined' to use component library default
export const DEFAULT_PAGINATION_OPTIONS = undefined;

// Common operation to reset pagination
const RESET_DELAY = 500;
export const DoReset = (doReset) => {
  doReset(true);
  setTimeout(() => {
    doReset(false);
  }, RESET_DELAY);
};
