import React from 'react';
import { Tooltip, Icon } from '@gsa/afp-component-library';

import { getCurrentFiscalYear } from '../../../utilities/dateUtils';

const getToolTip = (toolTipLabel) => (
  <Tooltip className="usa-button--unstyled" label={toolTipLabel} position="right">
    <Icon iconName="info" className="text-primary margin-left-1 text-ink" />
  </Tooltip>
);

export const FormTypes = {
  input: 'text',
  textArea: 'textArea',
  select: 'select',
};

const DEFAULT_OPTION = {
  value: '',
  label: 'Select',
  defaultValue: true,
};

export const FLEET_OFFERING_OPTIONS = [
  { value: '1', label: 'Purchasing' },
  { value: '3', label: 'Leasing-Accidents' },
  { value: '4', label: 'Leasing-Non-Accidents' },
  { value: '2', label: 'Short Term Rental' },
];

export const DOD_AGENCIES = [
  {
    label: 'Department of the Navy',
    value: '017',
  },
  {
    label: 'Department of the Army',
    value: '021',
  },
  {
    label: 'Department of the Air Force',
    value: '057',
  },
  {
    label: 'Corps of Engineers, Civil',
    value: '096',
  },
  {
    label: 'Department of Defense (Office of the Secretary of Defense)',
    value: '097',
  },
  {
    label: 'Executive Office of the President',
    value: '011',
  },
  ... window.AFP_CONFIG.execution_env !== 'prod' ? [{
    label: 'Test DOD agency',
    value: 'T5G',
  }] : [],
];

export const AID_AGENCIES = [
  {
    label: '467',
    value: '467',
  },
  {
    label: '056',
    value: '056',
  },
];

export const CREATE_WALLET_BASIC_FIELDS = {
  fiscalYear: {
    label: 'Fiscal Year',
    name: 'fiscalYear',
    id: 'fiscalYear',
    dataId: 'fiscalYear',
    required: true,
    toolTip: getToolTip(`This reflects the fiscal year that the line \n of accounting is valid`),
  },
  agency: {
    label: 'Agency',
    name: 'agency',
    id: 'agency',
    dataId: 'agency',
    required: true,
  },
  bureau: {
    label: 'Bureau',
    name: 'bureau',
    id: 'bureau',
    dataId: 'bureau',
    required: true,
  },
};

export const CREATE_WALLET_ASSOCIATION_FIELDS = {
  boac: {
    label: 'BOAC',
    name: 'boac',
    id: 'boac',
    dataId: 'boac',
    required: true,
    maxLength: 7,
    hint: 'Please make sure to select an Agency and Bureau first.',
    toolTip: getToolTip('Billing Office Address Code.'),
  },
  fleetOffering: {
    label: 'Fleet offering',
    name: 'fleetOffering',
    id: 'fleetOffering',
    dataId: 'fleetOffering',
    required: true,
    options: [DEFAULT_OPTION, ...FLEET_OFFERING_OPTIONS],
    toolTip: getToolTip(
      'The Fleet segments in which a unique line of accounting may be \n applied. \n\n Leasing Non-Accidents is comprised of all leasing charges related to \n monthly, mileage, telematics, AFV surcharge, non-accident AIEs, and \n other Fleet leasing costs. \n\n Leasing-Accidents is comprised of all leasing charges related to your \n accident AIEs. \n\n Rental is comprised of all charges associated with short term rentals. \n\n Purchasing is comprised of all costs associated with purchasing \n vehicles owned by your agency.',
    ),
  },
};

const fiscalYear = getCurrentFiscalYear();

export const getFiscalYearOptions = (selectedFiscalYear, name) => {
  const fiscalYearValue = parseInt(selectedFiscalYear, 10);
  return name === 'beginningPeriodOfAvailability'
    ? [ 
        { value: 0, label: 'N/A' },
        { value: fiscalYearValue, label: fiscalYearValue },
        { value: fiscalYearValue - 1, label: fiscalYearValue - 1 },
        { value: fiscalYearValue - 2, label: fiscalYearValue - 2 },
        { value: fiscalYearValue - 3, label: fiscalYearValue - 3 }
      ]
    : [
      { value: 0, label: 'N/A' },
      { value: fiscalYearValue, label: fiscalYearValue },
      { value: fiscalYearValue + 1, label: fiscalYearValue + 1 },
      { value: fiscalYearValue + 2, label: fiscalYearValue + 2 },
      { value: fiscalYearValue + 3, label: fiscalYearValue + 3 },
    ];
};

const bpoaFiscalYearOptions = getFiscalYearOptions(fiscalYear, 'beginningPeriodOfAvailability');
const epoaFiscalYearOptions = getFiscalYearOptions(fiscalYear, 'endingPeriodOfAvailability');;

const availabilityTypeOptions = [
  {
    value: '',
    label: 'N/A',
  },
  {
    value: 'X',
    label: 'X',
  },
  {
    value: 'F',
    label: 'F',
  },
  {
    value: 'A',
    label: 'A',
  },
];

export const isDod = (agency) => DOD_AGENCIES.map((dodAgency) => dodAgency.value).includes(agency);
export const isAid = (agency) => AID_AGENCIES.map((aidAgency) => aidAgency.value).includes(agency);

export const generateWalletTasFields = (values, createdWallet = false) => {
  const agency = values?.agencyIdentifier;
  const haveBpoaValue = !!(values?.beginningPeriodOfAvailability
    ? parseInt(values?.beginningPeriodOfAvailability, 10)
    : 0);
  const haveEpoaValue = !!(values?.endingPeriodOfAvailability ? parseInt(values?.endingPeriodOfAvailability, 10) : 0);
  const haveAvailabilityTypeValue = !!values?.availabilityType;

  const isDodAgency = isDod(agency);
  const isAidAgency = isAid(agency);
  return {
    subLevelPrefix: {
      label: 'Sublevel Prefix (SP)',
      name: 'subLevelPrefix',
      id: 'subLevelPrefix',
      dataId: 'subLevelPrefix',
      required: false,
      type: FormTypes.input,
      maxLength: 3,
      toolTip: getToolTip(
        'The Sub-level Prefix is a programmatic breakdown \n of the account for Treasury publication purposes. \n Not usually populated.',
      ),
      readOnly: createdWallet,
    },
    allocationTransferAgency: {
      label: 'Allocation Transfer Agency (ATA)',
      name: 'allocationTransferAgency',
      id: 'allocationTransferAgency',
      dataId: 'allocationTransferAgency',
      required: false,
      type: FormTypes.input,
      maxLength: 4,
      toolTip: getToolTip(
        'The Agency Identifier of the agency receiving \n funds through an allocation transfer.\n Not usually populated.',
      ),
      readOnly: createdWallet,
    },
    agencyIdentifier: {
      label: 'Agency Identifier (AID)',
      name: 'agencyIdentifier',
      id: 'agencyIdentifier',
      dataId: 'agencyIdentifier',
      required: true,
      type: isDodAgency || isAidAgency ? FormTypes.select : FormTypes.input,
      disabled: !isDodAgency && !isAidAgency,
      options: isDodAgency ? [...DOD_AGENCIES] : [...AID_AGENCIES],
      toolTip: getToolTip(
        'Agency Identifier represents the department, \n agency or establishment of the U.S. Government \n that is responsible for the TAS. Used in \n conjunction with the main account code.',
      ),
      readOnly: createdWallet,
    },
    availabilityType: {
      label: 'Availability Type Code (A)',
      name: 'availabilityType',
      id: 'availabilityType',
      dataId: 'availabilityType',
      required: !haveBpoaValue && !haveEpoaValue,
      disabled: haveBpoaValue || haveEpoaValue,
      type: FormTypes.select,
      options: availabilityTypeOptions,
      toolTip: getToolTip(
        'Identifies no-year accounts "X", clearing/suspense \n accounts "F", and Treasury\'s central summary \n general ledger accounts "A". If BPOA/EPOA \n exists, field is not applicable.',
      ),
      readOnly: createdWallet,
    },
    beginningPeriodOfAvailability: {
      label: 'Beginning Period of Availability (BPOA)',
      name: 'beginningPeriodOfAvailability',
      id: 'beginningPeriodOfAvailability',
      dataId: 'beginningPeriodOfAvailability',
      required: !haveAvailabilityTypeValue,
      disabled: haveAvailabilityTypeValue,
      type: FormTypes.select,
      options: bpoaFiscalYearOptions,
      toolTip: getToolTip(
        'In annual and multi-year funds, identifies the first \n year of availability under law that an appropriation \n account may incur new obligations. This value is not \n applicable when there is an availability type code.',
      ),
      readOnly: createdWallet,
    },
    endingPeriodOfAvailability: {
      label: 'Ending Period of Availability (EPOA)',
      name: 'endingPeriodOfAvailability',
      id: 'endingPeriodOfAvailability',
      dataId: 'endingPeriodOfAvailability',
      required: !haveAvailabilityTypeValue,
      disabled: haveAvailabilityTypeValue,
      type: FormTypes.select,
      toolTip: getToolTip(
        'In annual and multi-year funds, identifies the last \n year of funds availability under law that an appropriation \n account may incur new obligations.  This value is not \n applicable when there is an availability type code.',
      ),
      options: epoaFiscalYearOptions,
      readOnly: createdWallet,
    },
    mainAccount: {
      label: 'Main Account (MAIN)',
      name: 'mainAccount',
      id: 'mainAccount',
      dataId: 'mainAccount',
      required: true,
      maxLength: 5,
      type: FormTypes.input,
      toolTip: getToolTip('Identifies the type and purpose of the fund.'),
      readOnly: createdWallet,
    },
    subAccount: {
      label: 'Sub Account (SUB)',
      name: 'subAccount',
      id: 'subAccount',
      dataId: 'subAccount',
      required: true,
      maxLength: 4,
      type: FormTypes.input,
      toolTip: getToolTip(
        'Identifies an available receipt or other \n Treasury-defined subdivision of the main account.',
      ),
      readOnly: createdWallet,
    },
  };
};

export const generateWalletIpacFields = (values, createdWallet = false) => {
  const agency = values?.agencyIdentifier;
  return {
    fiscalStation: {
      label: 'Fiscal Station Number (FSN)',
      name: 'fiscalStation',
      id: 'fiscalStation',
      dataId: 'fiscalStation',
      required: false,
      visible: isDod(agency),
      maxLength: 7,
      type: FormTypes.input,
      readOnly: createdWallet,
      toolTip: getToolTip(
        'A six-digit number identifying the activity responsible for performing the official \n accounting and reporting for the funds. Examples: 021001, 503000.',
      ),
    },
    standardDocumentNumber: {
      label: 'Standard Document Number (SDN)',
      name: 'standardDocumentNumber',
      id: 'standardDocumentNumber',
      dataId: 'standardDocumentNumber',
      required: !!values?.fiscalStation,
      visible: true,
      type: FormTypes.input,
      maxLength: 18,
      readOnly: createdWallet,
      toolTip: getToolTip(
        'A customer driven alphanumeric code utilized by accounting to track each obligation record \n through all accounting phases. Aligns with MIPR, FMZ, Purchase Order, \n or other obligating reference number.',
      ),
    },
    bcnObanAsn: {
      label: 'BCN OBAN ASN',
      name: 'bcnObanAsn',
      id: 'bcnObanAsn',
      dataId: 'bcnObanAsn',
      required: !!values?.fiscalStation,
      visible: isDod(agency),
      type: FormTypes.input,
      maxLength: 7,
      readOnly: createdWallet,
      toolTip: getToolTip(
        'BCN (Bureau Control Number, Navy): A five-digit number that identifies the activity \n or ship to which an allotment was issued.  For allotment appropriations, \n the OB is a five-digit code comprised of a two-digit budget project number \n and a three-digit allotment number. \n \n OBAN (Operating Budget Account Number, Air Force): A two-digit number assigned \n by OAC to identify a base or specific organization receiving funds. \n \n ASN (Allotment Serial Number, Army/DOD): A three-five digit number assigned by \n the OA to identify specific allotments or allowances of funds.',
      ),
    },
    limit: {
      label: 'Limit',
      name: 'limit',
      id: 'limit',
      dataId: 'limit',
      required: !!values?.fiscalStation,
      visible: isDod(agency),
      type: FormTypes.input,
      maxLength: 5,
      readOnly: createdWallet,
      toolTip: getToolTip(
        'A four-character suffix to the basic symbol used to identify a subdivision of funds that \n restricts the amount or use for a certain purpose.',
      ),
    },
    accountInformation: {
      label: 'Supplemental accounting information',
      name: 'accountInformation',
      id: 'accountInformation',
      dataId: 'accountInformation',
      required: false,
      visible: true,
      type: FormTypes.textArea,
      gridColumns: '12',
      hint: '54 characters allowed',
      maxLength: 55,
      readOnly: createdWallet,
      toolTip: getToolTip(
        'Any additional accounting information to identify the funding associated with the obligation.',
      ),
    },
  };
};

export const IPAC_VEHICLES_FIELDS = {
  standardDocumentNumber: {
    label: 'Standard Document Number (SDN)',
    name: 'standardDocumentNumber',
    id: 'standardDocumentNumber',
    dataId: 'standardDocumentNumber',
    required: true,
    visible: true,
    type: FormTypes.input,
    maxLength: 21,
  },
  bcnObanAsn: {
    label: 'BCN/OBAN',
    name: 'bcnObanAsn',
    id: 'bcnObanAsn',
    dataId: 'bcnObanAsn',
    required: true,
    type: FormTypes.input,
    maxLength: 7,
  },
  limit: {
    label: 'Limit',
    name: 'limit',
    id: 'limit',
    dataId: 'limit',
    required: true,
    type: FormTypes.input,
    maxLength: 5,
  },
  accountInformation: {
    label: 'Supplemental accounting information',
    name: 'accountInformation',
    id: 'accountInformation',
    dataId: 'accountInformation',
    required: false,
    type: FormTypes.textArea,
    hint: '54 characters allowed',
    maxLength: 55,
  },
};

export const ipacConfigHeaders = [
  ['Fiscal Year', 'fiscalYear', 'string'],
  ['License Plate', 'tagNumber', 'string'],
  ['Standard Document Number (SDN)', 'standardDocumentNumber', 'string'],
  ['BCN OBAN ASN', 'bcnObanAsn', 'string'],
  ['Limit', 'limit', 'string'],
  ['Supplemental Accounting Information', 'accountInformation', 'string'],
  ['assetId', 'assetId', 'string'],
  ['walletAssetId', 'walletAssetId', 'string'],
  ['walletBoacSalesGroupId', 'walletBoacSalesGroupId', 'string'],
];

export const ipacReportConfig = {
  mapping: ipacConfigHeaders,
  errorFileName: 'IPAC_Upload_Errors',
  errorSheetColumnWidths: [{ wch: 25 }, { wch: 9 }, { wch: 125 }],
  errorSheetHeaders: ['License Plate', 'Status', 'Errors'],
  errorSheetName: 'IPAC Upload Errors',
};

export const batchSize = 500;
export const AGENCY_467 = '467';
export const AID_FOR_AGENCY_467 = '056';

