import _ from 'lodash/fp';

import {
  transformWithKey,
  arrayToObject,
  safeParseFloat,
  toFixedFn,
  mapValuesWithKey,
  omitDeep,
  firstValue,
} from 'utilities/formatUtils';
import numeral from 'numeral';

/* eslint-disable no-param-reassign */

export const toNumValue = (num) => numeral(num).value();

// string -> ({key:val}->[value])
export const pickValue = (field) => _.flow(_.pick([`${field}`]), _.values);

// need improvement
export const parseFloatAdd = (a, b) => safeParseFloat(a) + safeParseFloat(b);
// {key:val}->key
export const firstKey = _.flow(_.keys, _.head);

export const firstKeyInList = _.curry((List, obj) => _.includes(firstKey(obj))(List));

const orderRowsWithKeyByList = _.curry((list, rows) => _.map((x) => _.get(x, rows), list));

export const isUserId = (x) => _.isString(x) && _.split('-', x).length > 2;
// [ dirtyVals] -> [number]
export const dirtyValuesToNums = _.flow(
  _.reject(_.isDate),
  _.reject(_.isObject),
  _.reject(_.isBoolean),
  _.reject(isUserId),
  _.map(toNumValue),
  _.compact,
);
// [ dirtyVals] -> number
export const getValuesStrTotal = _.flow(dirtyValuesToNums, _.sum, toFixedFn);

// ({...x})->number|null
export const getObjectValuesTotal = _.flow(_.values, getValuesStrTotal);

export const aggregateTotalNum = (list, row) =>
  _.flow(_.filter(firstKeyInList(list)), _.flatMapDeep(_.values), getValuesStrTotal)(row);
// need separate
// name refactor needed
export const calFinal = ({
  dataArray,
  categoryFieldName = 'category',
  rowOrderList = [],
  subtractList = [],
  sumList = [],
}) => {
  const addTotalField = (obj) => ({ ..._.omit(['__typename'], obj), totalFunding: getObjectValuesTotal(obj) });
  const objWithTotal = _.mapValues(addTotalField)(dataArray);

  const addCategory = (result, value, rowKey) => {
    result.push(
      transformWithKey((_result, _value, category) => {
        _result.push({ [`${rowKey}`]: _value, [`${categoryFieldName}`]: category });
      }, [])(value),
    );
  };

  const arrayWithCategory = _.flow(transformWithKey(addCategory, []), _.flattenDeep)(objWithTotal);

  const groupByObj = _.groupBy(`${categoryFieldName}`)(arrayWithCategory);

  const arrayFormatRowData = transformWithKey((result, value, key) => {
    const row = _.map(_.omit('category'))(value);
    const balance = aggregateTotalNum(sumList, row) - aggregateTotalNum(subtractList, row);
    result.push(_.concat(row, [{ balance }, { category: _.replace('Funding', '')(key) }]));
  }, [])(groupByObj);

  const addProjectedBalance = _.map((x) => ({
    ...x,
    projectedBalance: safeParseFloat(x.balance) - safeParseFloat(x.amountPending),
  }));
  return _.flow(
    _.map(arrayToObject(firstKey, firstValue)),
    _.keyBy(categoryFieldName),
    orderRowsWithKeyByList(_.reverse(rowOrderList)),
    addProjectedBalance,
  )(arrayFormatRowData);
};

// {replacement:'',total}->{replacement:'0.00'}
export const parseFunds = _.flow(
  _.omit(['total']),
  mapValuesWithKey((value) => toFixedFn(safeParseFloat(value, 0))),
);

export const addTotalColumn = (totalColumn) =>
  _.flow(_.map((x) => ({ ...x, [`${totalColumn}`]: _.flow(_.values, getValuesStrTotal)(x) })));

// string->object->object
export const getOmitDeepValue = (omitProp) => _.flow(omitDeep(omitProp), firstValue);
// [string]->object->object
export const getValueAndOmit = (omitProps) => _.flow(firstValue, _.omit(omitProps));
