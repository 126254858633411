import { gql } from '@apollo/client';

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission($operation: String!, $subject: String!, $order: OrderBy, $feature: String) {
    getAgenciesByPermission(operation: $operation, subject: $subject, order: $order, feature: $feature) {
      id
      name
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
    }
  }
`;

export const VALIDATE_BOAC_OFFERING = gql`
  mutation ValidateBoacOffering($fiscalYear: Float!, $boacId: Float!, $salesGroupId: Float!) {
    validateBoacOffering(fiscalYear: $fiscalYear, boacId: $boacId, salesGroupId: $salesGroupId) {
      message
      fields
      section
    }
  }
`;

export const CREATE_WALLET = gql`
  mutation CreateWallet($wallFieldInput: WallFieldInput!) {
    createWallet(wallFieldInput: $wallFieldInput) {
      ... on WalletBoacSalesGroupModel {
        id
        fiscalYear
        boacId
        salesGroupId
        boacModel {
          boac
          agencyCode
          bureauCode
        }
        walletSalesGroupModel {
          name
          id
        }
        subLevelPrefix
        allocationTransferAgency
        agencyIdentifier
        availabilityType
        beginningPeriodOfAvailability
        endingPeriodOfAvailability
        mainAccount
        subAccount
        fiscalStation
        standardDocumentNumber
        bcnObanAsn
        limit
        accountInformation
        isDoD
      }
      ... on WalletUpdateError {
        errors {
          message
          fields
          section
        }
      }
    }
  }
`;

export const GET_WALLET_BY_ID = gql`
  query GetWalletById($id: String!) {
    getWalletById(id: $id) {
      id
      boacId
      salesGroupId
      isDoD
      walletSalesGroupModel {
        id
        name
      }
      fiscalYear
      agencyIdentifier
      availabilityType
      beginningPeriodOfAvailability
      endingPeriodOfAvailability
      mainAccount
      subAccount
      subLevelPrefix
      allocationTransferAgency
      fiscalStation
      standardDocumentNumber
      bcnObanAsn
      limit
      accountInformation
      boacModel {
        boac
        agencyCode
        bureauCode
      }
      updatedAt
      updatedByUser {
        fullName
      }
      createdByUser {
        fullName
      }
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation UpdateWallet($wallFieldInput: UpdateWallFieldInput!) {
    updateWallet(wallFieldInput: $wallFieldInput) {
      ... on WalletBoacSalesGroupModel {
        id
        fiscalYear
        boacModel {
          boac
        }
        walletSalesGroupModel {
          name
        }
      }
      ... on WalletUpdateError {
        errors {
          message
          fields
          section
        }
      }
    }
  }
`;

export const GET_BOACS = gql`
  query GetBoacs($getBoacsArgs: GetBoacsInput!) {
    getBoacs(getBoacsArgs: $getBoacsArgs) {
      boacId
      boac
    }
  }
`;

export const GET_IPACS = gql`
  query GetIpacs($order: OrderBy, $limit: Float, $offset: Float, $salesGroup: String, $filters: FilterType) {
    getIpacs(order: $order, limit: $limit, offset: $offset, salesGroup: $salesGroup, filters: $filters) {
      count
      hasMore
      rows {
        vin
        assetId
        walletAssetId
        fiscalYear
        tagNumber
        makeCode
        makeName
        modelCode
        modelName
        modelYear
        boac
        makeColorCode
        makeColorName
        standardDocumentNumber
        limit
        bcnObanAsn
        accountInformation
        updatedAt
        updatedByUser
      }
    }
  }
`;

export const CREATE_IPAC_VEHICLE = gql`
  mutation CreateIpacVehicle($createIpacVehicleFieldInput: CreateIpacVehicleFieldInput!) {
    createIpacVehicle(createIpacVehicleFieldInput: $createIpacVehicleFieldInput) {
      id
    }
  }
`;

export const DELETE_IPAC_VEHICLE = gql`
  mutation DeleteIpacVehicle($id: String!) {
    deleteIpacVehicle(id: $id)
  }
`;

export const EXPORT_IPAC_TEMPLATE = gql`
  query ExportIpacTemplate(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $salesGroup: String
    $walletBoacSalesGroupId: String
    $filters: FilterType
  ) {
    exportIpacTemplate(
      order: $order
      limit: $limit
      offset: $offset
      salesGroup: $salesGroup
      walletBoacSalesGroupId: $walletBoacSalesGroupId
      filters: $filters
    )
  }
`;

export const IMPORT_IPAC = gql`
  mutation ImportIpacVehicle($importInput: [ImportIpacVehicleFieldInput!]!) {
    importIpacVehicle(importInput: $importInput) {
      ... on BulkWalletUpdateResponse {
        tagNumber
      }
      ... on BulkWalletUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          tagNumber
        }
      }
    }
  }
`;
