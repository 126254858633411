import { Button } from '@gsa/afp-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { APP_STATUS } from './unique-rate-app-helper';
import './style/unique-rate-app-form-step-three.scss';

const UniqueRateAppFormStepThree = ({ draft: data, onPrev, setDidClickNext, onSaveDraft }) => {
  const handleSubmit = () => {
    setDidClickNext(true);
    const uniqueRateAppData = {};
    uniqueRateAppData.variables = {
      id: data.id,
      uniqueRateAppInput: {
        mileageRate: data.mileageRate,
        mileageType: data.mileageType,
        reason: data.reason,
        justification: data.justification,
        monthlyRateType: data.monthlyRateType,
        appType: data.appType,
        status: APP_STATUS.SUBMITTED,
        comment: data.comment,
      },
      uniqueRateAppPocInput:
        data.uniqueRateAppPoc && Object.keys(data.uniqueRateAppPoc).length
          ? {
              customerId: data.uniqueRateAppPoc?.customerId,
              agencyCode: data.uniqueRateAppPoc?.agencyCode,
              bureauCode: data.uniqueRateAppPoc?.bureauCode,
              poc: data.uniqueRateAppPoc?.poc,
              email: data.uniqueRateAppPoc?.email,
              phonenumber: data.uniqueRateAppPoc?.phonenumber,
            }
          : {},
      uniqueRateAppFcsInput:
        data.uniqueRateAppFcs && Object.keys(data.uniqueRateAppFcs).length
          ? {
              address1: data.uniqueRateAppFcs?.address1,
              address2: data.uniqueRateAppFcs?.address2,
              address3: data.uniqueRateAppFcs?.address3,
              city: data.uniqueRateAppFcs?.city,
              state: data.uniqueRateAppFcs?.stateCode,
              zipcode: data.uniqueRateAppFcs?.zip5,
              zipcode4: data.uniqueRateAppFcs?.zip4,
            }
          : {},
      uniqueRateAppVehicleInput:
        data.uniqueRateAppVehicle && Object.keys(data.uniqueRateAppVehicle).length
          ? data.uniqueRateAppVehicle?.map((d) => ({
              vin: d.vin,
              plateNumber: d.plateNumber,
              monthlyRate: d.monthlyRate,
              mileageRate: d.mileageRate,
              leaseRateCode: d.leaseRateCode,
            }))
          : {},
    };
    onSaveDraft(uniqueRateAppData);
  };

  const getUniqueRateAppPocValue = (d, k) => (d?.uniqueRateAppPoc?.[k] ? d?.uniqueRateAppPoc?.[k] : '-');

  const displayAddress = (fcs) => {
    if (!fcs) return null;
    const zipcode4 = fcs.zipcode4 ? `-${fcs.zipcode4}` : '';
    return (
      <div className="usa-label">
        {fcs.address1}
        {fcs.address1 && <br />}
        {fcs.address2}
        {fcs.address2 && <br />}
        {fcs.address3}
        {fcs.address3 && <br />}
        {`${fcs.city}, ${fcs.state} `}
        {`${fcs.zipcode}${zipcode4}`}
      </div>
    );
  };

  const getBillMethod = ({ mileageType, reason }) => {
    if (
      (mileageType === 'Maintenance only mileage rate' && reason === 'Severe use') ||
      (mileageType === 'No mileage rate' && reason === 'Severe use fuel; Onsite M&R, No CSA')
    ) {
      return 'Pass-thru Fuel';
    }

    if (
      (mileageType === 'Fuel only mileage rate' && reason === 'Severe use') ||
      (mileageType === 'No mileage rate' && reason === 'Severe use M&R; Onsite fuel, No CSA')
    ) {
      return 'Pass-thru M&R';
    }

    if (mileageType === 'No mileage rate' && reason === 'Severe use fuel and M&R') {
      return 'Pass-thru fuel and M&R';
    }

    return 'Standard';
  };

  const reqSummary = () => {
    return (
      <>
        <div className="grid-row margin-top-3">
          <div className="grid-col-8">
            <div className="blue-label">Request Summary</div>
            <p className="margin-bottom-4">
              <b>
                {data?.uniqueRateAppVehicle?.length} vehicle
                {data?.uniqueRateAppVehicle?.length > 1 ? 's' : ''}
              </b>{' '}
              will be submitted for unique rate application review.
            </p>
            <p>Customer billing below is based on the mileage rates and reason selection.</p>
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-8">
            <div className="grid-row grid-gap">
              <div className="grid-col-4 billing-method">
                <div className="usa-label margin-bottom-05 blk-label">Billing Method</div>
                <div className="usa-form-group">{getBillMethod(data)}</div>
              </div>
              <div className="grid-col-4 billing-method">
                <div className="usa-label margin-bottom-05 blk-label">Rate Application</div>
                <div className="usa-form-group">
                  {data?.monthlyRateType}
                  {data?.monthlyRateType && <br />}
                  {data?.mileageType}
                </div>
              </div>
              <div className="grid-col-4 billing-method">
                <div className="usa-label margin-bottom-05 blk-label">Card Type</div>
                <div className="usa-form-group">{data?.cardType ? data.cardType : '-'}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-row  margin-top-8">
          <div className="grid-col-8">
            <div className="blue-label margin-bottom-1">Fleet Card Shipment</div>
            <div className="usa-form-group">
              If vehicles are being put on a fuel only or maintenance only rate and not for a severe use reason, a new
              fleet card must be ordered. This also applies if vehicles are being removed from these rates as well as
              removed from a no mileage rate; they will need a full service card.
            </div>
          </div>
        </div>
      </>
    );
  };

  const listData = () => (
    <>
      <div className="grid-row  margin-top-6">
        <div className="grid-col-6">
          <div className="grid-row">
            <div className="grid-col-4">
              <div className="usa-label blk-label">Customer Number</div>
            </div>
            <div className="grid-col-8">
              <div className="usa-label">{getUniqueRateAppPocValue(data, 'customerId')}</div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-4">
              <div className="usa-label blk-label">Agency</div>
            </div>
            <div className="grid-col-8">
              <div className="usa-label">{getUniqueRateAppPocValue(data, 'agencyCode')}</div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-4">
              <div className="usa-label blk-label">Agency Contact Name</div>
            </div>
            <div className="grid-col-8">
              <div className="usa-label">{getUniqueRateAppPocValue(data, 'poc')}</div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-4">
              <div className="usa-label blk-label">Address</div>
            </div>
            <div className="grid-col-8">{displayAddress(data?.uniqueRateAppFcs)}</div>
          </div>
        </div>
      </div>
    </>
  );

  const loadData = () => (
    <>
      {reqSummary()}
      {listData()}
    </>
  );

  return (
    <>
      <div className="unqiue-step-three">
        <div>{Object.keys(data).length > 0 && loadData()}</div>
        <div className="action-button-box-right margin-top-9 margin-bottom-4">
          <Button variant="outline" onClick={onPrev} leftIcon={{ name: 'arrow_back' }} label="Previous" />
          <Button variant="primary" onClick={handleSubmit} leftIcon={{ name: 'check' }} label="Submit" />
        </div>
      </div>
    </>
  );
};

UniqueRateAppFormStepThree.defaultProps = {};

UniqueRateAppFormStepThree.propTypes = {
  draft: PropTypes.shape().isRequired,
  onPrev: PropTypes.func.isRequired,
  setDidClickNext: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
};

export default UniqueRateAppFormStepThree;
