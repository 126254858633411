import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash/fp';
import { stateValue } from 'utilities/stateUtils';
import { firstValue } from 'utilities/formatUtils';
import { calFinal, addTotalColumn, getOmitDeepValue, getValueAndOmit } from '../helper';
import { VERIFY_AND_CREATE_ACQUISITION_FUNDING_FOR_FY } from './operation/mutation/VERIFY_AND_CREATE_ACQUISITION_FUNDING_FOR_FY';
import { GET_HISTORICAL_ACQUISITION_FUNDING_BY_FY } from './operation/query/GET_HISTORICAL_ACQUISITION_FUNDING_BY_FY';
import { GET_ACQUISITION_DATA_BY_FY } from './operation/query/GET_ACQUISITION_DATA_BY_FY';
import { GET_LATEST_ACQUISITION_FUNDING_BY_FY } from './operation/query/GET_LATEST_ACQUISITION_FUNDING_BY_FY';



const useDataController = ({ dispatchBannerMessage, setDispatch, DISPATCH_FNS, pageState }) => {
  const latestFundRef = React.useRef(null);

  const errorBanner = (error) => {
    dispatchBannerMessage({
      setDispatch,
      DISPATCH_FNS,
      error,
    });
  }

  const [fetchHistorical, { loading: historyLoading }] = useLazyQuery(GET_HISTORICAL_ACQUISITION_FUNDING_BY_FY, {
    onCompleted: (historicalData) => {
      const data = _.flow(
        _.get('getHistoricalAcquisitionFundingForFY.rows'),
        addTotalColumn('totalAmountFunded'),
      )(historicalData);

      const itemsCount = _.flow(firstValue, _.getOr(0, 'count'))(historicalData);
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'historyTab.itemsCount', data: itemsCount });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'historyTab.historyRows', data });
    },

    onError: errorBanner,
  });
  const [fetchBalanceData, { loading: balanceLoading }] = useLazyQuery(GET_ACQUISITION_DATA_BY_FY, {
    onCompleted: (balanceData) => {
      let AmountSpent = _.getOr(
        {
          additionalFunding: '0',
          consolidationFunding: '0',
          replacementFunding: '0',
        },
        'getMockAcquisitionDataByFY.amountSpent',
        balanceData,
      );

      if (_.isNull(AmountSpent)) {
        AmountSpent = {
          additionalFunding: '0',
          consolidationFunding: '0',
          replacementFunding: '0',
        };
      }

      setDispatch(DISPATCH_FNS.updateAmountSpent, {
        data: {
          replacement: AmountSpent.replacementFunding,
          consolidation: AmountSpent.consolidationFunding,
          additional: AmountSpent.additionalFunding,
        },
      });

      const spentAndPendingData = getOmitDeepValue('__typename')(balanceData);
      const amountFund = latestFundRef.current;
      const subtractList = ['amountSpent'];
      const sumList = ['amountFund'];
      const rowOrderList = ['replacement', 'consolidation', 'additional', 'total'];
      const data = calFinal({
        dataArray: { ...spentAndPendingData, amountFund },
        subtractList,
        sumList,
        rowOrderList,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'balanceRows', data });
    },
    onError: errorBanner,
  });
  const [fetchLatestFunding, { loading: latestFundLoading }] = useLazyQuery(GET_LATEST_ACQUISITION_FUNDING_BY_FY, {
    onCompleted: (latestFundData) => {
      latestFundRef.current = getValueAndOmit(['fiscalYear', '__typename'])(latestFundData);
      setDispatch(DISPATCH_FNS.reset);
      // use initial state by using latestFundData
      // could abstract to a new DispatchFns   --- refactor needed
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'initialFunding_replacement',
        data: latestFundRef.current.replacementFunding,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'initialFunding_consolidation',
        data: latestFundRef.current.consolidationFunding,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'initialFunding_additional',
        data: latestFundRef.current.additionalFunding,
      });
      setDispatch(DISPATCH_FNS.initialFunding.setTotal, {});

      fetchBalanceData({
        variables: {
          FiscalYear: stateValue(pageState.fiscalYear),
        },
      });

      fetchHistorical({
        variables: {
          FiscalYear: stateValue(pageState.fiscalYear),
          Offset: stateValue(pageState.historyTab.offset),
          Limit: stateValue(pageState.historyTab.limit),
          Order: stateValue(pageState.historyTab.order),
        },
      });

      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'fundingInitialed', data: true });
    },
    onError: (error) => {
      if (_.startsWith('No acquisition data found for given fiscal year', error.message)) {
        setDispatch(DISPATCH_FNS.reset);
        return;
      }
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        error,
      });
    },
  });

  const [updateFunds, { loading: updateFundsLoading }] = useMutation(VERIFY_AND_CREATE_ACQUISITION_FUNDING_FOR_FY, {
    onError: (error) => {
      dispatchBannerMessage({ setDispatch, DISPATCH_FNS, error });
    },
    onCompleted: () => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: `Acquisition funding for fiscal year ${stateValue(pageState.fiscalYear)} has been updated.`,
        type: 'success',
      });
      fetchLatestFunding({
        variables: {
          FiscalYear: stateValue(pageState.fiscalYear),
        },
      });
      fetchHistorical({
        variables: {
          FiscalYear: stateValue(pageState.fiscalYear),
        },
      });
    },
  });

  return {
    updateFunds,
    loading: { updateFundsLoading, balanceLoading, latestFundLoading, historyLoading },
    query: {
      fetchLatestFunding,
      fetchHistorical,
    },
  };
};

export default useDataController;
