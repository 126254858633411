import _ from 'lodash/fp';
import { autoLabelOptions } from './formatUtils';

const createYearsArray = ({ currentFYInt, previousYearCount = 0, futureYearCount = 0 }) =>
  _.range(currentFYInt - previousYearCount, currentFYInt + futureYearCount + 1);

//  {previousYearCount ,totalYearFutureYearCounts)}=>[{label,value}]
export const getYearOptionsRange = _.flow(createYearsArray, autoLabelOptions);

export const getCurrentFiscalYear = () => {
  let fy = new Date().getFullYear();
  // check if current date is past Sept 30th
  if (new Date().getMonth() >= 9) {
    fy += 1;
  }
  return fy;
};
