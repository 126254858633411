import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ENTITY, ROLE_OP, hasAbilityTo } from 'components/role-permission/role-permission';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { DELETE_DRAFT_UNIQUE_RATE_APP } from '../unique-rate-app-helper';

const DeleteDraftModal = ({ onClose, data: { id }, setBannerMsg }) => {
  if (!hasAbilityTo(ROLE_OP.DELETE, ENTITY.UNIQUE_RATE_APP)) onClose();

  const [deleteDraft, { loading: deleting }] = useMutation(DELETE_DRAFT_UNIQUE_RATE_APP, {
    onCompleted: () => {
      setBannerMsg({
        type: 'success',
        message: 'You have successfully deleted the draft application.',
      });
      onClose(true); // doRefetch = true
    },
    onError: (err) => {
      setBannerMsg({
        type: 'error',
        message: (
          <>
            Error occured when deleting the unique rate application. <br />
            {err.message}
          </>
        ),
      });
      onClose();
    },
  });

  const onDelete = () => {
    deleteDraft({ variables: { id } });
  };

  return (
    <DeleteModal
      id="ur-app-delete-modal"
      title="Delete unique rate application"
      onClose={onClose}
      onDelete={onDelete}
      deleting={deleting}
      deleteButtonText="Delete"
    >
      <p data-testid="delete-modal-body">
        Are you sure you want to delete the draft unique rate application? This cannot be undone.
      </p>
    </DeleteModal>
  );
};

DeleteDraftModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setBannerMsg: PropTypes.func.isRequired,
};

export default DeleteDraftModal;
