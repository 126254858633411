import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash/fp';
/* eslint-disable react/prop-types */
const DistributeFundsTitle = ({ title, desc }) => (
  <>
    <div className="grid-row">
      <div className="grid-col-5">
        <div style={{ fontSize: 18, color: '#005ea2', fontWeight: 'bold' }}>{_.upperCase(title)}</div>
        <p>{desc}</p>
      </div>
    </div>
  </>
);

DistributeFundsTitle.defaultProps = {
  desc: null,
};

DistributeFundsTitle.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
};

export default DistributeFundsTitle;
