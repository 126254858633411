/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

export const WalletSubDetail = ({
  standardDocumentNumber,
  bcnObanAsn,
  limit,
  accountInformation,
  isDoD,
  updatedAt,
  updatedByUser,
  createdByUser
}) => {
  const emdash = '\u2014';
  const borderClasses = classnames('border-bottom border-base-lighter padding-y-1');
  return (
    <div className="grid-row grid-gap flex-justify" data-testid="wallets-sub-details">
      <div className="grid-col-6">
        <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
          <strong>Standard doc number (SDN)</strong>
          {standardDocumentNumber || emdash}
        </div>

        { isDoD && (
          <>
            <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
              <strong>BCN OBAN ASN</strong>
              {bcnObanAsn || emdash}
            </div>

            <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
              <strong>Limit</strong>
              {limit || emdash}
            </div>
          </>
        )}

        <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
          <strong>Supplemental accounting information</strong>
          {accountInformation || emdash}
        </div>
      </div>
      <div className="grid-col-6">
        <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
          <strong>Last updated by</strong>
          {updatedByUser?.fullName || createdByUser?.fullName || emdash}
        </div>
        <div className={`${borderClasses} display-flex flex-justify`} data-testid="data-section">
          <strong>Date</strong>
          {moment(updatedAt).format('MM/DD/YYYY . h:mm a') || emdash}
        </div>
      </div>
    </div>
  );
};
