import React from 'react';

export const useBoolToggle = () => {
  const isTrueRef = React.useRef(false);

  React.useEffect(() => {
    if (isTrueRef.current) {
      isTrueRef.current = false;
    }
  }, [isTrueRef.current]);

  const toggleToTrue = () => {
    isTrueRef.current = true;
  };
  return [isTrueRef.current, toggleToTrue];
};
