import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Label, DatePicker, ErrorMessage, Alert, Spinner, Textbox } from '@gsa/afp-component-library';
import moment from 'moment';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { STATUS } from 'components/helpers/constants';
import { UTCDateStrToUS, getTomorrowDate, getTomorrowStr, USDateStrToUTC } from 'components/helpers/afp-bm-date';
import {
  fracToPercent,
  classNameErrBox,
  classNameErrInput,
  classNameErrDatePicker,
} from 'components/helpers/afp-bm-helpers';
import RateStatusBlock from 'components/widgets/rate-status-block';
import useCharCounter from 'components/widgets/char-counter';
import {
  GET_DISTINCT_PURCHASE_TYPES,
  UPDATE_OR_CREATE_PURCHASE_RATE,
  CREATE_NEW_PURCHASE_TYPE,
  GET_PURCHASE_RATE_BY_TYPE_ESD,
} from './components/graphql-queries';
import { processFormData } from './components/helper';
import './style/purchase-rate-table.scss';

// Constants
const SHOW_RADIO = false;
const MAX_LEN_TYPE = 25;
const MAX_LEN_DESC = 1000;
const tomorrow = getTomorrowDate();
const tomorrowStr = getTomorrowStr();

// Logic for Validations in Edit Modal Form Data
const schema = yup.object().shape({
  purchaseType: yup
    .string()
    .required('Please enter a value for purchase type')
    .when('isCreate', {
      is: true,
      then: yup
        .string()
        .test(
          'check duplicate',
          'Purchase type already exists.',
          (val, ctx) => !ctx.parent.distinctTypes?.includes(val.trim().toUpperCase()),
        ),
    }),
  purchaseTypeDesc: yup
    .string()
    .required('Please enter a value for rate application')
    .max(MAX_LEN_DESC, 'Rate application cannot exceed 1,000 characters'),
  effectiveStartDate: yup
    .date('Please enter a valid date')
    .required('Please enter a valid date for Start Date')
    .when(['statusChanged', 'isCreate'], {
      is: (statusChanged, isCreate) => statusChanged || isCreate,
      then: yup
        .date('Please enter a valid date')
        .min(tomorrow, 'New start date must be a future date')
        .transform((value) => (moment(value).isValid() ? value : null))
        .nullable()
        .required('Please enter a valid date'),
    }),
  fixedPercent: yup.string().when('fixedType', {
    is: '1',
    then: yup
      .string()
      .required('Please enter a value for fixed percent rate')
      .matches(
        '^(100(\\.0{0,2})?|[1-9]?[0-9](\\.[0-9]{0,2})?)$',
        'Please enter a percentage between 0 and 100 with up to two decimal places',
      ),
  }),
  flatDollarFee: yup.string().when('fixedType', {
    is: '2',
    then: yup
      .string()
      .required('Please enter a value for flat dollar fee')
      .matches(
        '^[1-9][0-9]{0,4}(?:\\.[0-9]{0,2})?$',
        'Please enter a $ value between 1.00 and 99999.99 with up to two decimal places',
      ),
  }),
});

// Update Modal Component
const PurchaseRateUpdateModal = ({ isCreate, data: modalData, onClose, setBannerMsg, addPendingFilter }) => {
  const {
    purchaseType,
    purchaseRateType,
    purchaseTypeCode,
    purchaseTypeDesc,
    rateStatus,
    fixedPercent,
    flatDollarFee,
    effectiveStartDate,
  } = modalData;

  // React useState
  const [newStatus, setNewStatus] = useState(rateStatus);
  const [fixedType, setFixedType] = useState('1');
  const [saveError, setSaveError] = useState(null);
  const [purchaseTypes, setPurchaseTypes] = useState([]);

  const [typeCounter, setTypeCount] = useCharCounter(MAX_LEN_TYPE);
  const [descCounter, setDescCount] = useCharCounter(MAX_LEN_DESC);

  // useForm declaration
  const { register, handleSubmit, errors, setError: setFormError, control, getValues, setValue, clearErrors } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
        purchaseType,
        purchaseTypeDesc,
        rateStatus,
        fixedPercent: fracToPercent(fixedPercent) || '',
        flatDollarFee,
        effectiveStartDate: effectiveStartDate || tomorrowStr,
      },
    },
  );

  // fetch distinct purchase types for creating new rate
  const [fetchPurchaseTypes, { loading: loadingDistinctTypes }] = useLazyQuery(GET_DISTINCT_PURCHASE_TYPES, {
    onCompleted: ({ getDistinctPurchaseTypes }) => {
      const types = getDistinctPurchaseTypes.map((t) => t.purchaseType);
      setPurchaseTypes(types);
      setValue(
        'distinctTypes',
        types.map((t) => t.toUpperCase()),
      );
    },
    onError: () => setSaveError('Error occurred when retrieving existing purchase types.'),
  });

  useEffect(() => {
    register({ name: 'purchaseRateType' });
    setValue('purchaseRateType', purchaseRateType);
    register({ name: 'purchaseTypeCode' });
    setValue('purchaseTypeCode', purchaseTypeCode);
    register({ name: 'isCreate' });
    setValue('isCreate', isCreate);
    register({ name: 'rateStatus' });
    setValue('rateStatus', rateStatus);
    register({ name: 'statusChanged' });
    setValue('statusChanged', false);
    register({ name: 'distinctTypes' });

    if (isCreate) fetchPurchaseTypes();

    if (modalData) setDescCount(purchaseTypeDesc ? purchaseTypeDesc.length : 0);
  }, []);

  // gql queries
  const [updatePurchaseRate, { loading: loadingUpdate }] = useMutation(UPDATE_OR_CREATE_PURCHASE_RATE, {
    onCompleted: (resData) => {
      const { updateOrCreatePurchaseRate: prData } = resData;
      if (prData.rateStatus !== rateStatus && prData.rateStatus === STATUS.PENDING) {
        addPendingFilter();
      }
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully updated purchase type for <b>{prData.purchaseType}</b>.
          </>
        ),
      });
      onClose();
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occurred when updating the purchase type. Please try again later.
          <br />
          {err.message}
        </>,
      );
    },
  });
  const [createPurchaseType, { loading: loadingCreate }] = useMutation(CREATE_NEW_PURCHASE_TYPE, {
    onCompleted: (resData) => {
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully created <b>{resData.createNewPurchaseRate.purchaseType}</b>.
          </>
        ),
      });
      onClose();
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occurred when creating the purchase type. Please try again later.
          <br />
          {err.message}
        </>,
      );
    },
  });

  const submitFormData = () => {
    const data = getValues();
    if (!data) return;
    setSaveError(null);
    const formattedData = processFormData(data);
    if (isCreate) createPurchaseType(formattedData);
    else updatePurchaseRate(formattedData);
  };

  const [checkStartDate, { loading: loadingCheckStartDate }] = useLazyQuery(GET_PURCHASE_RATE_BY_TYPE_ESD, {
    onCompleted: ({ getPurchaseRateByTypeEffectiveStartDate }) => {
      if (getPurchaseRateByTypeEffectiveStartDate) {
        setFormError(
          'effectiveStartDate',
          {
            type: 'manual',
            message: 'A rate already exists for the start date',
          },
          { shouldFocus: true },
        );
      } else {
        submitFormData();
      }
    },
    onError: (err) =>
      setSaveError({
        field: null,
        fieldMessage: { message: err.message },
      }),
  });

  const onSubmit = () => {
    const newStartDate = getValues('effectiveStartDate');
    if (isCreate || rateStatus === STATUS.NEW || newStartDate === effectiveStartDate) {
      // create or update rate
      submitFormData();
    } else {
      // create new pending rate - check for duplicate first
      checkStartDate({
        variables: {
          purchasetype: purchaseType,
          effectivestartdate: newStartDate,
        },
      });
    }
  };

  const changeStatus = () => {
    if (newStatus === STATUS.ACTIVE) {
      setValue('statusChanged', true);
      setNewStatus(STATUS.PENDING);
    }
  };

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const showErrorMessage = () => {
    if (saveError)
      return (
        <Alert slim type="error" style={{ marginBottom: 18 }}>
          {saveError}
        </Alert>
      );

    if (Object.keys(errors).length)
      return (
        <Alert slim focused={false} type="error" style={{ marginBottom: 18 }}>
          You have {Object.keys(errors).length} error on this form. Please correct before saving.
        </Alert>
      );

    return null;
  };

  const onFieldChange = (e, name) => {
    const { value } = e.target;

    if (name === 'purchaseType') {
      setTypeCount(value.length);
      const isDuplicate = purchaseTypes.map((p) => p.toUpperCase()).includes(value.trim().toUpperCase());
      if (isDuplicate) setFormError(name, { message: 'Purchase type already exists' });
      else clearErrors(name);
    } else if (name === 'purchaseTypeDesc') setDescCount(value.length);
  };

  return (
    <EditModal
      id="purchase-rate-update"
      title={isCreate ? 'Create a Purchase Type' : 'Update a Purchase Type'}
      onClose={onClose}
      formId="purchase-rate-update-modal"
    >
      {loadingDistinctTypes || loadingCheckStartDate || loadingUpdate || loadingCreate ? (
        <div
          id="loading-spinner"
          style={{
            position: 'fixed',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.15)',
          }}
        >
          <div style={{ margin: '300px auto' }}>
            <Spinner />
          </div>
        </div>
      ) : null}
      {showErrorMessage()}
      <p>
        {isCreate
          ? `Please use this window to create a new purchase type and assign its rate application and percent rate.`
          : `Use this window to update existing rate applications and fixed percent rates using the start date.`}
      </p>

      <form onSubmit={handleSubmit(onSubmit)} id="purchase-rate-update-modal">
        <div className="form-ra">
          <div className="usa-form-group">
            <h2 className="margin-bottom-1">{purchaseType}</h2>
            {!isCreate && <RateStatusBlock status={newStatus} />}
          </div>

          <div className={isCreate ? 'display-block' : 'display-none'}>
            <Controller
              control={control}
              name="purchaseType"
              render={({ name, value, onChange, onBlur }) => (
                <div className={classNameErrBox(errors[name])}>
                  <Label htmlFor="purchase-type" required>
                    Purchase type
                  </Label>
                  {createErrorMessage(errors[name])}
                  <Textbox
                    id="purchase-type"
                    data-testid="purchase-type-input"
                    className={classNameErrInput(errors[name])}
                    name={name}
                    value={value}
                    maxLength={MAX_LEN_TYPE}
                    aria-label="Purchase type input required."
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e, name);
                    }}
                    onBlur={onBlur}
                  />
                  {typeCounter}
                </div>
              )}
            />
          </div>

          <div className="margin-top-4">
            <Controller
              control={control}
              name="purchaseTypeDesc"
              defaultValue={purchaseTypeDesc || ''}
              render={({ name, value, onChange, onBlur }) => (
                <div className={classNameErrBox(errors[name])}>
                  <Label htmlFor="purchase-type-desc" required>
                    Rate application
                  </Label>
                  {createErrorMessage(errors[name])}
                  <Textbox
                    id="purchase-type-desc"
                    data-testid="purchase-type-desc-input"
                    className={classNameErrInput(errors[name])}
                    name={name}
                    value={value}
                    maxLength={MAX_LEN_DESC}
                    type="textarea"
                    rows={3}
                    aria-label="Rate Application input required."
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e, name);
                    }}
                    onBlur={onBlur}
                  />
                  {descCounter}
                </div>
              )}
            />
          </div>

          <div className="margin-top-4">
            <label className="usa-label chooseRateFee required margin-top-4 margin-bottom-2">
              Choose a purchase rate {SHOW_RADIO && 'or Fee'}
            </label>
            <div className="usa-radio margin-bottom-1" style={{ display: SHOW_RADIO ? 'block' : 'none' }}>
              <input
                className="usa-radio__input"
                id="fixedType1"
                type="radio"
                ref={register}
                onChange={() => {
                  changeStatus();
                  setFixedType('1');
                }}
                name="fixedType"
                value="1"
                readOnly
                defaultChecked
              />
              <label className="usa-radio__label" htmlFor="fixedType1">
                Fixed Percent
              </label>
            </div>
            <div className="usa-radio margin-bottom-1" style={{ display: SHOW_RADIO ? 'block' : 'none' }}>
              <input
                className="usa-radio__input"
                id="fixedType2"
                type="radio"
                ref={register}
                name="fixedType"
                onChange={() => {
                  changeStatus();
                  setFixedType('2');
                }}
                value="2"
                disabled
              />
              <label className="usa-radio__label" htmlFor="fixedType2">
                Flat Dollar Fee
              </label>
            </div>
          </div>
          {fixedType === '1' ? (
            <div className="usa-form-group margin-top-1 fixed-flatStyle fixedpercentfield" key={1}>
              <fieldset className="fixedFlatStyle">
                <legend className="fixedpercentstyle"> Fixed Percent </legend>
                <div className={`${classNameErrBox(errors.fixedPercent)}`}>
                  <label className="usa-label margin-top-0 required " htmlFor="fixedPercent">
                    Enter fixed percent rate
                  </label>
                  {createErrorMessage(errors.fixedPercent)}
                  <input
                    className={`text-right usa-input--inline form-percent ${classNameErrInput(errors.fixedPercent)}`}
                    id="fixedPercent"
                    data-testid="fixed-percent"
                    name="fixedPercent"
                    type="text"
                    ref={register}
                    maxLength="8"
                    size="8"
                    aria-label="Fixed percent rate input required."
                    onChange={changeStatus}
                  />
                  &nbsp; %
                </div>
              </fieldset>
            </div>
          ) : (
            <div className="usa-form-group margin-top-1 fixedpercentfield" key={2}>
              <fieldset className="fixedFlatStyle">
                <legend>Flat Dollar Fee</legend>
                <div className={classNameErrBox(errors.flatDollarFee)}>
                  <label className="usa-label margin-top-0 required" htmlFor="flatDollarFee">
                    Enter flat dollar fee
                  </label>
                  {createErrorMessage(errors.flatDollarFee)}
                  <input
                    className={`usa-input--inline text-right form-dollar ${classNameErrInput(errors.flatDollarFee)}`}
                    id="flatDollarFee"
                    name="flatDollarFee"
                    ref={register}
                    type="text"
                    maxLength="8"
                    size="8"
                    onChange={changeStatus}
                  />
                </div>
              </fieldset>
            </div>
          )}

          <div className="grid-row margin-top-4">
            <div className="grid-col-4" style={{ minWidth: 300 }}>
              <div className={`usa-form-group ${classNameErrBox(errors.effectiveStartDate)}`}>
                <Label htmlFor="effective-start-date" required={isCreate}>
                  Start date
                </Label>
                <div className="usa-hint" id="effective-start-date-hint">
                  mm/dd/yyyy
                </div>
                {createErrorMessage(errors.effectiveStartDate)}
                <Controller
                  control={control}
                  name="effectiveStartDate"
                  defaultValue={effectiveStartDate || null}
                  render={({ name, value, onBlur }) => (
                    <DatePicker
                      id="effective-start-date"
                      name={name}
                      className={classNameErrDatePicker(errors.effectiveStartDate)}
                      defaultValue={value < tomorrowStr ? null : value}
                      placeholder={UTCDateStrToUS(value)}
                      minDate={tomorrowStr}
                      disabled={rateStatus === STATUS.PENDING}
                      aria-label="Effective Start Date selection input required."
                      onBlur={onBlur}
                      onChange={(val) => {
                        setValue('effectiveStartDate', val ? USDateStrToUTC(val) : null);
                        changeStatus();
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <button aria-label="Submit form" hidden="hidden" type="submit">
          Submit
        </button>
      </form>
    </EditModal>
  );
};

PurchaseRateUpdateModal.defaultProps = {
  data: {},
  isCreate: false,
};

PurchaseRateUpdateModal.propTypes = {
  isCreate: PropTypes.bool,
  data: PropTypes.shape({
    purchaseType: PropTypes.string,
    purchaseTypeCode: PropTypes.string,
    purchaseTypeDesc: PropTypes.string,
    rateStatus: PropTypes.string,
    fixedPercent: PropTypes.string,
    flatDollarFee: PropTypes.string,
    effectiveStartDate: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
  addPendingFilter: PropTypes.func.isRequired,
};

export default PurchaseRateUpdateModal;
