import * as yup from 'yup';
import _ from 'lodash';
import { getTomorrowDate } from 'components/helpers/afp-bm-date';
import { toNumValue } from 'utilities/formatUtils';
import moment from 'moment';
import { isRateStateDirty } from '../../../helper';

import { rateStructures } from '../../store';

export const validationSchema = (pageState) => ({
  service_toggleActiveForm: yup.object().shape({
    reason: yup.string().required('Reason is required'),
  }),
  isCreate: yup.boolean(),
  service_updateOptInServiceForm: yup.object().shape({
    optInSvcName: yup
      .string()
      .required('Opt-in service name is required')
      .when('isCreate', {
        is: () => pageState.service_updateOptInServiceModal.isCreate,
        then: yup
          .string()
          .test(
            ' already exists',
            'Service already exists',
            (val) => !_.includes(pageState.distinctServiceName, _.lowerCase(val)),
          ),
      }),
    optInSvcDesc: yup.string().required('Rate application is required'),
    'rate-oneTimeRate': yup
      .string()
      .when('oneTimeCheckbox', {
        is: true,
        then: yup.string().required('Rate is required'),
      })
      .when('percentageType-oneTimeRate', {
        is: (value) => {
          if (value) return true;
          return false;
        },
        then: yup.string().test('len', 'A percent rate must be between 0 and 100', (val) => toNumValue(val) <= 1),
      }),
    'rate-recurringRate': yup
      .string()
      .when('recurringCheckbox', {
        is: true,
        then: yup.string().required('Rate is required'),
      })
      .when('percentageType-recurringRate', {
        is: (value) => {
          if (value) return true;
          return false;
        },
        then: yup.string().test('len', 'A percent rate must be between 0 and 100', (val) => toNumValue(val) <= 1),
      }),

    rateSection: yup.string().when(['oneTimeCheckbox', 'recurringCheckbox', 'rateStructure'], {
      is: (oneTimeCheckbox, recurringCheckbox, rateStructure) =>
        rateStructure === rateStructures[0] && !oneTimeCheckbox && !recurringCheckbox,
      then: yup.string().required('Rate is required'),
    }),
    // tier_rate: yup.string().required('Rate is required'),

    startDate: yup
      .date()
      .when('isCreate', {
        is: () => pageState.service_table.rowData.isCreate,
        then: yup
          .date()
          .min(getTomorrowDate(), 'New start date must be a future date')
          .transform((value) => (moment(value).isValid() ? value : null))
          .nullable()
          .required('Please enter a valid date for Start Date'),
      })
      .when('isCreate', {
        is: () => !pageState.service_table.rowData.isCreate && isRateStateDirty(pageState),
        then: yup
          .date()
          .transform((value) => (moment(value).isValid() ? value : null))
          .nullable()
          .min(getTomorrowDate(), 'New start date must be a future date'),
      }),
  }),
});
