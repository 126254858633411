import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TabSet, useModal } from '@gsa/afp-component-library';
import { ENTITY, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import RateStatusBlock from 'components/widgets/rate-status-block';
import AFPTable from 'widgets/afp-table-wrapper';
import { UTCDateStrToUS, getTodayStr } from 'components/helpers/afp-bm-date';
import { formatDollar } from 'components/helpers/afp-bm-helpers';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { STATUS } from 'components/helpers/constants';
import AFVFundingSetSurcharge from './afv-funding-set-surcharge';
import DeleteSurchargeModal from './afv-funding-delete-surcharge';
import AFVFundingSurchargeComment from './afv-funding-surcharge-comment';
import { AFV_SURCHARGE_ACTION } from '../afv-funding-helper';
import '../style/afv-funding-surcharge-history.scss';

const ROLE_ENTITY = ENTITY.AFV_SURCHARGE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  update: [ACTIONS.UPDATE, ACTIONS.DELETE],
  delete: [ACTIONS.DELETE],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

const AFVFundingSurchargeHistoryTable = ({ data, refetchSurcharges, onSetSurchargeBannerMsg, readonly, loading }) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { isOpen: isEditModalOpen, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const handleSelectedAction = (label, original) => {
    switch (label) {
      case 'Update':
        openEditModal();
        break;
      case 'Delete':
        openDeleteModal();
        break;
      default:
        break;
    }
    setSelectedRowData(original);
  };

  const getRowActions = (rowData) => {
    if (!rowData?.row?.original) return '–';
    let rowActions;
    const { status, startDate } = rowData.row.original;
    if (status !== STATUS.PENDING && status !== STATUS.RECOMMENDED) rowActions = [];
    else
      rowActions =
        status === STATUS.RECOMMENDED && startDate <= getTodayStr() ? ROW_ACTIONS.delete : ROW_ACTIONS.update;
    return genRowActions(rowActions, rowData, handleSelectedAction);
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Surcharge',
        accessor: 'afvSurchargeAmount',
        Cell: ({ row }) => {
          const { original } = row;

          if (original?.surcharge) {
            return formatDollar(original.surcharge);
          }
          let comment = original.afvRateComments?.find((c) => c.action === AFV_SURCHARGE_ACTION.FINAL_RECOMMEND);
          if (!comment)
            comment = original.afvRateComments
              ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
              ?.find((c) => c.action === AFV_SURCHARGE_ACTION.RECOMMEND);
          return formatDollar(comment?.afvSurchargeAmount);
        },
        sortable: false,
      },
      {
        Header: 'Start date',
        accessor: 'startDate',
        Cell: ({ value }) => UTCDateStrToUS(value),
        sortable: false,
      },
      {
        Header: 'End date',
        accessor: 'endDate',
        Cell: ({ value }) => UTCDateStrToUS(value) || '–',
        sortable: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line
        Cell: ({ value }) => <RateStatusBlock status={value} />,
        sortable: false,
      },
      {
        Header: 'Updated by',
        accessor: 'createdByUserInfo',
        sortable: false,
        // eslint-disable-next-line
        Cell: ({ row, value }) =>
          // eslint-disable-next-line
          value ? <a href={`mailto:${value.email}`}>{value.fullName}</a> : row.original?.createdBy,
      },
    ];
    if (!readonly && hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
      cols.push({
        Header: 'Actions',
        Cell: (rowData) => getRowActions(rowData),
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
        sortable: false,
      });
    return cols;
  }, [data]);

  const renderSubComponent = useCallback(
    ({ row: { original = {} } }) => (
      <div className="padding-0">
        {original.afvRateComments
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((comment, i) => AFVFundingSurchargeComment(comment, i))}
      </div>
    ),
    [data],
  );

  return (
    <>
      <AFPTable
        isLoading={loading}
        expandable
        columns={columns}
        data={data}
        NoDataMessages={{
          title: 'No Surcharges Available',
        }}
        renderRowSubComponent={renderSubComponent}
      />
      {isEditModalOpen && (
        <EditModal
          id="afv-update-surcharge-modal"
          title="Update surcharge"
          onClose={closeEditModal}
          formId="update-surcharge-form"
        >
          <AFVFundingSetSurcharge
            surchargeData={selectedRowData}
            onModalClose={closeEditModal}
            onSetSurchargeBannerMsg={onSetSurchargeBannerMsg}
            refetchSurcharges={refetchSurcharges}
          />
        </EditModal>
      )}
      {isDeleteModalOpen && (
        <DeleteSurchargeModal
          data={selectedRowData}
          onClose={closeDeleteModal}
          setBannerMsg={onSetSurchargeBannerMsg}
          refetchSurcharges={refetchSurcharges}
        />
      )}
    </>
  );
};

AFVFundingSurchargeHistoryTable.defaultProps = {
  row: {},
  refetchSurcharges: undefined,
  onSetSurchargeBannerMsg: undefined,
  readonly: false,
  loading: false,
};

AFVFundingSurchargeHistoryTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  refetchSurcharges: PropTypes.func,
  onSetSurchargeBannerMsg: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
};

const AFVFundingSurchargeHistory = ({ data, refetchSurcharges, onSetSurchargeBannerMsg, readonly, loading }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      const surcharges = data.surcharges || [];

      const rowData = surcharges.map((s) => ({
        ...s,
        afvRateComments: s.afvRateComments.map((comment) => ({
          ...comment,
          id: comment.id,
          author: {
            fullName: comment.createdByUserInfo?.fullName || comment.createdBy,
          },
          comment: comment.comment,
          createdAt: comment.createdAt,
          createdBy: comment.createdBy,
          deletedAt: comment.deletedAt,
        })),
      }));

      setTableData(rowData);
    }
  }, [data]);

  if (readonly)
    return (
      <div id="afv-concurrence-history-table">
        <div id="surcharge-history-table">
          <AFVFundingSurchargeHistoryTable data={tableData} readonly loading={loading} />
        </div>
      </div>
    );

  return (
    <div id="afv-funding-surcharge-history">
      <TabSet
        tabs={[
          {
            heading: 'Surcharge',
            content: (
              <div id="surcharge-history-table">
                <AFVFundingSurchargeHistoryTable
                  data={tableData}
                  refetchSurcharges={refetchSurcharges}
                  onSetSurchargeBannerMsg={onSetSurchargeBannerMsg}
                  loading={loading}
                />
              </div>
            ),
          },
          {
            heading: 'Monthly View',
            content: (
              <div id="monthly-view-table">
                <AFPTable
                  isLoading={false}
                  expandable
                  columns={[]}
                  data={[]}
                  NoDataMessages={{
                    title: 'No Data Available',
                    text: 'this table is still under construction',
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

AFVFundingSurchargeHistory.defaultProps = {
  refetchSurcharges: undefined,
  onSetSurchargeBannerMsg: undefined,
  readonly: false,
  loading: false,
};
AFVFundingSurchargeHistory.propTypes = {
  data: PropTypes.shape([]).isRequired,
  refetchSurcharges: PropTypes.func,
  onSetSurchargeBannerMsg: PropTypes.func,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AFVFundingSurchargeHistory;
