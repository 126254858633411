import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SelectBox, Label, Button, ErrorMessage, PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { classNameErrBox } from 'components/helpers/afp-bm-helpers';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import WelcomeMessage from 'widgets/welcome-message';
import UniqueRateAppFormStepTwo from './unique-rate-app-form-step-two';
import UniqueRateAppThankYou from './unique-rate-app-thankyou';
import { APP_TYPE, CREATE_OR_UPDATE_UNIQUE_RATE_APP } from './unique-rate-app-helper';
import './style/unique-rate-app.scss';

const TITLE = 'Removal Request';

const DRAFT = {
  appType: APP_TYPE.REMOVE,
  cardType: 'Full Service Card',
};
export const REASONS = [
  'Vehicle is no longer assigned to unique rate customer',
  'Customer no longer needs unique rate',
  'Error. The vehicle should not have had the unique rate code',
];
const REASON_OPTIONS = [
  {
    label: '-- Select a reason --',
    value: '',
  },
];
REASON_OPTIONS.push(...REASONS.map((r) => ({ label: r, value: r })));

const UniqueRateAppRemoval = () => {
  useTitle(TITLE);
  const [reason, setReason] = useState('');
  const [isReasonSelected, setIsReasonSelected] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bannerMsg, setBannerMsg] = useState(null);

  const bannerRef = useRef(null);
  const childRef = useRef(null);

  const onSetBannerMsg = (banner) => {
    setBannerMsg(banner);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // gql calls
  const [submitRemovalApp, { loading }] = useMutation(CREATE_OR_UPDATE_UNIQUE_RATE_APP, {
    onCompleted: () => {
      setIsSubmitted(true);
    },
    onError: (err) =>
      onSetBannerMsg({
        type: 'error',
        message: (
          <div>
            Error occurred when submitting removal request.
            <br />
            {err.message}
          </div>
        ),
      }),
  });

  const onSaveDraft = (data) => {
    const appData = {
      variables: {
        ...data.variables,
        id: '',
        uniqueRateAppInput: {
          appType: APP_TYPE.REMOVE,
          reason,
          justification: reason,
          status: 'Submitted',
          mileageType: '',
          mileageRate: '',
          monthlyRateType: '',
          comment: '',
        },
      },
    };
    submitRemovalApp(appData);
  };

  const onValidateReason = (val) => {
    if (val && !isReasonSelected) setIsReasonSelected(true);
    else if (!val && isReasonSelected) setIsReasonSelected(false);
  };

  const onSubmit = async () => {
    if (!reason) setIsReasonSelected(false);
    const valid = await childRef.current.validateRequired();
    if (!reason || !valid) {
      onSetBannerMsg({
        type: 'error',
        message: 'All required fields must be filled and all errors must be corrected before submitting the request.',
      });
    } else childRef.current.onSaveDraftClick();
  };

  if (isSubmitted) return <UniqueRateAppThankYou />;

  return (
    <div className="grid-col">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          {
            location: `${window.AFP_CONFIG.appURLs.bm}/bm/unique-rates-app`,
            label: 'Unique Rate Applications',
          },
        ]}
      />

      <div ref={bannerRef}>
        {bannerMsg && (
          <BannerMessage className="margin-top-2" type={bannerMsg.type} onClose={() => setBannerMsg(null)}>
            {bannerMsg.message}
          </BannerMessage>
        )}
      </div>

      <PageTitle title={TITLE} />
      <div className="unique-rates-app-heading">
        <div className="unique-rates-app-welcome-msg">
          <WelcomeMessage message="Use this page to request a unique rate removal for a customer. Begin by selecting the reason for removal." />
        </div>
      </div>
      <div className="unique-rate-app-form-layout">
        <div className={classNameErrBox(!isReasonSelected)}>
          <div className="unique-rates-app-section-header margin-bottom-1">Reason for Removal</div>
          {!isReasonSelected && <ErrorMessage>Please select a reason.</ErrorMessage>}
          <Label required className="margin-top-2">
            Select reason for removal
          </Label>
          <div>
            <SelectBox
              id="remove-select-reason"
              data-testid="remove-select-reason"
              options={REASON_OPTIONS}
              value={reason}
              onChangeFunc={(e) => {
                setReason(e.target.value);
                onValidateReason(e.target.value);
              }}
            />
          </div>
        </div>

        <UniqueRateAppFormStepTwo
          ref={childRef}
          draft={DRAFT}
          onSaveDraft={onSaveDraft}
          setBannerMsg={onSetBannerMsg}
        />

        <div className="margin-top-4 margin-bottom-8">
          <Link id="cancel-btn" data-testid="cancel-btn" to="./">
            Cancel
          </Link>
          <Button
            id="submit-btn"
            data-testid="submit-btn"
            className="margin-left-3"
            variant="primary"
            disabled={loading}
            onClick={onSubmit}
            label="Submit"
          />
        </div>
      </div>
    </div>
  );
};

export default UniqueRateAppRemoval;
