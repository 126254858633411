import React, { useEffect } from 'react';
import { dispatchBannerMessage } from 'utilities/componentUtils';
import { ResponsiveFilterPanel, Button, useModal } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import { useBoolToggle } from 'hooks/useBoolToggle';
import { getFilterItems } from '../model/category/schemas/filterSchema';
import useDataController from '../model/category/useDataController';
import { handleSelectedAction } from '../model/category/schemas/categoryTableSchema';
import { getCategoryQueryVariables, categoryFilterOnChange } from '../helper';
import CategoryModals from '../components/category/CategoryModals';
import CategoryTable from '../components/category/CategoryTable';

/* eslint-disable react/prop-types */

const CategoryContainer = ({ useTrackedState, useDispatch, DISPATCH_FNS }) => {
  // use openModal and closeModal was used for change the style
  // the change style behavior has been removed it becomes remove which make useModal as a redundant method need to refactor it
  const { openModal, closeModal } = useModal();
  const [isPaginationComponentReset, resetPaginationComponent] = useBoolToggle();
  const setDispatch = useDispatch();
  const pageState = useTrackedState();
  const { mutation, query, loading } = useDataController({
    dispatchBannerMessage,
    setDispatch,
    DISPATCH_FNS,
    pageState,
    closeModal,
  });

  const { fetchCategoryRows } = query;

  useEffect(() => {
    fetchCategoryRows({
      variables: getCategoryQueryVariables({
        pageState,
        rateStatus: pageState.categoryFilter.rateStatus ?? [],
        filter: pageState.categoryFilter.filter.optInSvcCategory,
      }),
    });
  }, []);

  return (
    <>
      <Can I={ROLE_OP.CREATE} a={ENTITY.OPT_IN_CATEGORY}>
        <div className="bm_create_category_service_button">
          <Button
            id="create-category-btn"
            data-testid="create-category-btn"
            onClick={() => handleSelectedAction({ setDispatch, DISPATCH_FNS, openModal })('Create', null)}
            leftIcon={{ name: 'add' }}
            label="Create service category"
          />
        </div>
      </Can>
      <LoadingQueryResult loadingObject={{ ...loading, pageLoading: pageState.categoryFilter.isLoading }}>
        <div className="grid-row grid-gap margin-bottom-4">
          <div className="desktop:grid-col-2 tablet-lg:grid-col-3 tablet:margin-top-4">
            <ResponsiveFilterPanel
              defaultState={{
                rateStatus: pageState.categoryFilter.rateStatus,
                OptInCategoryName: pageState.categoryFilter.filter.OptInCategoryName,
              }}
              items={getFilterItems(pageState)}
              onChange={categoryFilterOnChange({
                pageState,
                setDispatch,
                fetchCategoryRows,
                resetPaginationComponent,
                DISPATCH_FNS,
              })}
            />
          </div>

          <div className="desktop:grid-col-10 tablet-lg:grid-col-9">
            <CategoryTable
              setDispatch={setDispatch}
              DISPATCH_FNS={DISPATCH_FNS}
              pageState={pageState}
              loading={loading}
              openModal={openModal}
              fetchRows={fetchCategoryRows}
              isPaginationComponentReset={isPaginationComponentReset}
              resetPaginationComponent={resetPaginationComponent}
            />
            <CategoryModals
              setDispatch={setDispatch}
              DISPATCH_FNS={DISPATCH_FNS}
              pageState={pageState}
              loading={loading}
              closeModal={closeModal}
              mutation={mutation}
            />
          </div>
        </div>
      </LoadingQueryResult>
    </>
  );
};

export default CategoryContainer;
