/* eslint-disable radix */
/* eslint-disable react/prop-types */
import React from 'react';
import { toCurrency, toPercentage } from 'utilities/formatUtils';
import { Button } from '@gsa/afp-component-library';
// eslint-disable-next-line import/named
import { convertRate } from '../../../helper';

export const columns = ({ setDispatch, DISPATCH_FNS, clearErrors }) => {
  const cols = [
    {
      Header: 'Range',
      accessor: 'lowerRange',
      sortable: false,
      collapsedRowClassName: 'optServiceTypeStyle',
      Cell: ({ row, flatRows }) => {
        const rangeRowUnderneath = flatRows[parseInt(row.id) + 1];
        const upperRange = rangeRowUnderneath
          ? `- ${toCurrency(rangeRowUnderneath.original.lowerRange - 0.01, 2)}`
          : `and above`;
        return `${toCurrency(row.original.lowerRange, 2)} ${upperRange}`;
      },
    },

    {
      sortable: false,
      Header: 'Rate',
      accessor: 'rateValue',
      collapsedRowClassName: 'optServiceTypeStyle',
      Cell: ({ row }) => {
        const { rateValue } = row?.original;
        return (
          <span style={{ whiteSpace: 'nowrap', display: 'block' }}>
            {`${
              row?.original.rateType === '$'
                ? rateValue && toCurrency(rateValue)
                : rateValue && toPercentage(convertRate(rateValue))
            }`}
          </span>
        );
      },
    },

    {
      Header: 'Actions',
      accessor: 'action',
      sortable: false,
      Cell: ({ row }) => (
        <div className="grid-row">
          <div className="grid-col-2">
            <Button
              onClick={() => {
                clearErrors('tier_rate');
                clearErrors('tier_lower_range');
                setDispatch(DISPATCH_FNS.service_updateTierRateRow, {
                  // eslint-disable-next-line react/prop-types
                  currentRow: row?.original,
                });
              }}
              size="sm"
              data-testid="edit-button"
              aria-label="edit-button"
              hidden="hidden"
              type="button"
              variant="unstyled"
              label="Edit"
            />
          </div>
          {row?.id !== '0' && (
            <div className="grid-col-2 grid-offset-1">
              <Button
                size="sm"
                data-testid="delete-button"
                aria-label="delete-button"
                hidden="hidden"
                type="button"
                onClick={() => {
                  clearErrors('tier_rate');
                  clearErrors('tier_lower_range');

                  setDispatch(DISPATCH_FNS.service_editTierRate, {
                    currentIndex: row?.id,
                    isDelete: true,
                  });
                }}
                variant="unstyled"
                label="Delete"
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  return cols;
};
