import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, PageTitle, Breadcrumbs } from '@gsa/afp-component-library';
import { useWalletDetails } from './wallet-details-provider';

const WalletHeader = () => {
  const { alertMessage, getWalletById, wallet } = useWalletDetails();
  const params = useParams();
  const { boacId } = params;
  const { header, message, type } = alertMessage;

  useEffect(() => {
    if (boacId) {
      getWalletById({
        variables: {
          id: boacId,
        },
      });
    }
  }, []);

  const searchParams = window.location.search;
  const walletPagePath = `/bm/wallet${ searchParams ? `${searchParams}` : '' }`;
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>, <Link to={walletPagePath}>Wallet</Link>];

  return (
    <>
      <Breadcrumbs trail={trail} current={wallet ? 'Edit Line of Accounting' : 'Add Line of Accounting'} />
      {message && (
        <div className="margin-top-2" data-testid="wallet-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      )}
      <header>
        <PageTitle className="margin-bottom-0" title={wallet ? 'Edit Line of Accounting' : 'Add Line of Accounting'} />
        {wallet && (
          <h2 className="title-m margin-top-0 margin-bottom-4">
            BOAC {wallet?.boacModel?.boac}-{wallet?.walletSalesGroupModel?.name}
          </h2>
        )}
        <div className="margin-bottom-6 tablet:grid-col-7" data-testid="wallets-detail-page-description">
          {wallet ? (
            <p>Please enter your Treasury Account Symbol below for the BOAC and Fleet offering displayed.</p>
          ) : (
            <p>
              Please use the drop down menu below to select the Fiscal Year of your TAS and IPAC data. You may then
              select your agency and bureau that contains the BOAC(s) you need to add.
            </p>
          )}
        </div>
      </header>
    </>
  );
};

export default WalletHeader;
