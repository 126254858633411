import React from 'react';
import { produce, setAutoFreeze } from 'immer';
import _ from 'lodash/fp';

setAutoFreeze(false);
/* eslint-disable  */

/*  

  @FunctionName: setStateBuilder
  @Author:  Chris Chen
  @Description:
  setStateBuilder use to make the immer process more efficient and less verbose
  the dispatchFns API used creation in  store follows 3 param  
  draft: your state,
  payload: the data param for the state change.
  dispatchFns:if you want to use other functions which is defined in the dispatchFns

  @ example of init setting DISPATCH_FNS in store 
  setItemPerPage(draft, { limit, resetPaginationComponent }, dispatchFns) {
    draft.categoryFilter.limit = limit;
    dispatchFns.resetPagination(draft, resetPaginationComponent);
  },
  resetPagination(draft, resetPaginationComponent) {
    draft.categoryFilter.currentPage = 1;
    draft.categoryFilter.offset = 0;
    resetPaginationComponent();
  },

  @example of usage

    setDispatch(DISPATCH_FNS.setItemPerPage, { limit: itemsPerPage, resetPaginationComponent });


    -----------------------------------------------------------
  
  without builder function all the DISPATCH_FNS needs to write in the following way
  https://github.com/dai-shi/react-tracked/blob/main/examples/07_todolist/src/hooks/useToggleTodo.ts
  ```js
  import { useCallback } from 'react';
import produce from 'immer';

import { useSetState } from '../state';

const useToggleTodo = () => {
  const setState = useSetState();
  const toggleTodo = useCallback((id: number) => {
    setState((s) => produce(s, (draft) => {
      const found = draft.todos.find((todo) => todo.id === id);
      if (found) {
        found.completed = !found.completed;
      }
    }));
  }, [setState]);
  return toggleTodo;
};



export default useToggleTodo;
  );
};

@usage

  const toggleTodo = useToggleTodo();
  toggleTodo(id)
  ```
  vs

export const DISPATCH_FNS = {
  toggleTodo(draft, { id }) {
     const found = draft.todos.find((todo) => todo.id === id);
      if (found) {
        found.completed = !found.completed;
      }
  },
}

@usage
 setDispatch(DISPATCH_FNS.toggleTodo, {  id });
 
   */

export const useStateBuilder = (useSetState, DISPATCH_FNS) => {
  const setPageState = useSetState();
  return React.useCallback(
    (dispatchFn, payload) => {
      setPageState(
        produce((draft) => {
          dispatchFn(draft, payload, DISPATCH_FNS);
        }),
      );
    },
    [setPageState],
  );
};
// DISPATCH_FNS core function, a universal set function

// set proxy draft state by use path

// @@ switch to immer and react-tracked
export const setProxyState = (draft, path, data) => {
  const pathArr = path.split('.');
  const [first, ...rest] = pathArr;
  if (rest.length === 0) {
    draft[first] = data;
  } else {
    setProxyState(draft[first], rest.join('.'), data);
  }
};

export const stateValue = _.identity;
