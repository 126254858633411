import { setHeaderCell } from 'utilities/formatUtils';
import CurrencyCell from 'components/widgets/CurrencyCell';

const columns = [
  {
    Header: 'Category',
    accessor: 'category',
    Cell: setHeaderCell,
    disableSortBy: true,
    sortable: false,
  },
  {
    Header: 'Amount funded ',
    accessor: 'amountFund',
    sortable: false,
    disableSortBy: true,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Amount spent',
    accessor: 'amountSpent',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Pending requisitions',
    accessor: 'amountPending',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Projected balance ',
    accessor: 'projectedBalance',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
];

export default columns;
