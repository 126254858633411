import React from 'react';
import { trimmedVal } from 'utilities/formatUtils';
import { Textbox } from '@gsa/afp-component-library';
/* eslint-disable react/prop-types */

const TrimmedInput = ({ value, ...restProps }) => {
  return <Textbox value={trimmedVal(value)} {...restProps} />;
};

export default TrimmedInput;
