import { gql } from '@apollo/client';

export const GET_ACQUISITION_DATA_BY_FY = gql`
  query getMockAcquisitionDataByFY($FiscalYear: String!) {
    getMockAcquisitionDataByFY(fiscalYear: $FiscalYear) {
      amountSpent {
        replacementFunding
        consolidationFunding
        additionalFunding
      }
      amountPending {
        replacementFunding
        consolidationFunding
        additionalFunding
      }
    }
  }
`;
