import { gql } from '@apollo/client';

const OptInCategoryResponse = gql`
  fragment OptInCategoryResponseFields on OptInCategory {
    rateStatus
    optInCategoryName
    optInCategoryDesc
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
    comment
  }
`;

export const REACTIVATE_OPT_IN_CATEGORY = gql`
  mutation reactivateOptInCategory($optInCategoryName: String!, $ratestatus: String!, $comment: String!) {
    reactivateOptInCategory(optInCategoryName: $optInCategoryName, ratestatus: $ratestatus, comment: $comment) {
      ...OptInCategoryResponseFields
    }
  }
  ${OptInCategoryResponse}
`;
