import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import WalletProvider from './wallet-provider';
import WalletFilterProvider from './filters/filter-provider';
import WalletHeader from './wallet-header';
import WalletsListing from './wallets-listing';

const Wallet = () => {
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

  return (
    <>
      <WalletProvider>
        <WalletFilterProvider>
          <Breadcrumbs trail={trail} current="Wallet" />
          <div className="margin-bottom-3">
            <WalletHeader />
          </div>
          <WalletsListing />
        </WalletFilterProvider>
      </WalletProvider>
    </>
  );
};

export default Wallet;
