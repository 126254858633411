import React from 'react';
import _ from 'lodash/fp';
import { Label, Button } from '@gsa/afp-component-library';
import RateInputComponent from 'components/widgets/RateInput';
import { safeParseFloat } from 'utilities/formatUtils';
import { isInValidateInputs } from '../../helper';
// remove the first item
const labelNameFn = (name) => _.flow(_.split('_'), _.drop(1))(name);
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */

const TierRateCalculator = ({
  setDispatch,
  pageState,
  DISPATCH_FNS,
  register,
  errors,
  setError,
  clearErrors,
  rateStructure = 'tierRate',
}) => {
  return (
    <>
      <div className="rate-calculator">
        <div>
          <Label>Rate type</Label>
          <div className="grid-row">
            <div className="grid-col-3">
              <div className="usa-radio margin-bottom-1">
                <input
                  className="usa-radio__input"
                  type="radio"
                  id={`dollarType-${rateStructure}`}
                  data-testid={`dollarType-${rateStructure}`}
                  name={`dollarType-${rateStructure}`}
                  checked={pageState.service_table.rowData[`${rateStructure}`].rateType === '$'}
                  aria-label="Dollar ($) radio button"
                  onChange={() => {
                    clearErrors('tier_rate');
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateType`,
                      data: '$',
                    });
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateValue`,
                      data: '',
                    });
                  }}
                />
                <label className="usa-radio__label" htmlFor={`dollarType-${rateStructure}`}>
                  Dollar ($)
                </label>
              </div>
            </div>
            <div className="grid-col-3">
              <div className="usa-radio margin-bottom-1">
                <input
                  className="usa-radio__input"
                  type="radio"
                  id={`percentageType-${rateStructure}`}
                  data-testid={`percentageType-${rateStructure}`}
                  name={`percentageType-${rateStructure}`}
                  checked={pageState.service_table.rowData[`${rateStructure}`].rateType === '%'}
                  aria-label="Percent (%) radio button"
                  onChange={() => {
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateType`,
                      data: '%',
                    });
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateValue`,
                      data: '',
                    });
                  }}
                />
                <label className="usa-radio__label" htmlFor={`percentageType-${rateStructure}`}>
                  Percent (%)
                </label>
              </div>
            </div>
          </div>
          <div className="grid-row  margin-bottom-2">
            <div className="grid-col-5">
              <div className="tablet:grid-col-12">
                <RateInputComponent
                  setDispatch={setDispatch}
                  dispatchFn={DISPATCH_FNS.updateStateByPath}
                  value={pageState.service_table.rowData[`${rateStructure}`].lowerRange}
                  path={`service_table.rowData.${rateStructure}.lowerRange`}
                  name="tier_lower_range"
                  labelNameFn={labelNameFn}
                  register={register}
                  errors={errors}
                  allowNegative={false}
                  required
                  readOnly={pageState.service_table.rowData[`${rateStructure}`].selectedLowerRangeFloat == 0}
                  {...register}
                />
              </div>
            </div>
            <div className="grid-col-5  grid-offset-1">
              <div className="tablet:grid-col-12">
                <RateInputComponent
                  setDispatch={setDispatch}
                  dispatchFn={DISPATCH_FNS.updateStateByPath}
                  value={pageState.service_table.rowData[`${rateStructure}`].rateValue}
                  path={`service_table.rowData.${rateStructure}.rateValue`}
                  name="tier_rate"
                  isPercentage={pageState.service_table.rowData[`${rateStructure}`].rateType === '%'}
                  register={register}
                  errors={errors}
                  labelNameFn={labelNameFn}
                  allowNegative={false}
                  required
                  {...register}
                />
              </div>
            </div>
          </div>
          <div className="grid-row">
            {pageState.service_table.rowData[`${rateStructure}`].isEdit ? (
              <>
                {' '}
                <div className="grid-col-2 margin-top-1">
                  <Button
                    size="sm"
                    data-testid="cancel-button"
                    aria-label="cancel-button"
                    hidden="hidden"
                    type="button"
                    variant="unstyled"
                    label="Cancel"
                    onClick={() => {
                      clearErrors('tier_rate');
                      clearErrors('tier_lower_range');
                      setDispatch(DISPATCH_FNS.service_resetTierRateInput);
                    }}
                  />
                </div>
                <div className="grid-col-3">
                  <Button
                    size="sm"
                    data-testid="update-button"
                    aria-label="update-button"
                    hidden="hidden"
                    type="button"
                    variant="outline"
                    label="Update"
                    leftIcon={{ name: 'edit', type: '', className: '' }}
                    onClick={() => {
                      clearErrors('tier_rate');
                      clearErrors('tier_lower_range');
                      if (isInValidateInputs({ pageState, setError, rateStructure })) {
                        return;
                      }

                      const currentIndex = _.findIndex(
                        ({ lowerRange }) =>
                          lowerRange == pageState.service_table.rowData[`${rateStructure}`].selectedLowerRangeFloat,
                        pageState.service_table.rowData[`${rateStructure}`].ratesRows,
                      );

                      const floatCurrentLowerRange = safeParseFloat(
                        pageState.service_table.rowData[`${rateStructure}`].lowerRange,
                        2,
                      );
                      if (currentIndex !== 0) {
                        if (pageState.service_table.rowData[`${rateStructure}`].selectedLowerRangeFloat > 0) {
                          const previousRow =
                            pageState.service_table.rowData[`${rateStructure}`].ratesRows[currentIndex - 1];
                          const nextRow =
                            pageState.service_table.rowData[`${rateStructure}`].ratesRows[currentIndex + 1];
                          if (floatCurrentLowerRange <= safeParseFloat(previousRow.lowerRange, 2)) {
                            setError(`tier_lower_range`, {
                              type: 'manual',
                              message:
                                'Lower range of a tier must be greater than the lower range of the previous tier',
                            });
                            return;
                          }

                          if (nextRow && floatCurrentLowerRange >= safeParseFloat(nextRow.lowerRange, 2)) {
                            setError(`tier_lower_range`, {
                              type: 'manual',
                              message:
                                'Lower range of a tier must not be greater than the upper range of that same tier',
                            });
                            return;
                          }
                        }
                      }

                      setDispatch(DISPATCH_FNS.service_editTierRate, {
                        currentIndex,
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="grid-col-3">
                <Button
                  size="sm"
                  data-testid="add-button"
                  aria-label="add-button"
                  hidden="hidden"
                  type="button"
                  variant="outline"
                  label="Add"
                  onClick={() => {
                    clearErrors('tier_rate');
                    clearErrors('tier_lower_range');
                    if (isInValidateInputs({ pageState, setError, rateStructure })) {
                      return;
                    }
                    const isExistingLowerRange =
                      _.findIndex(
                        ({ lowerRange }) =>
                          safeParseFloat(lowerRange, 2) ==
                          safeParseFloat(pageState.service_table.rowData[`${rateStructure}`].lowerRange, 2),
                        pageState.service_table.rowData[`${rateStructure}`].ratesRows,
                      ) !== -1;
                    if (isExistingLowerRange) {
                      setError(`tier_lower_range`, {
                        type: 'manual',
                        message: `Tier lower range already exists`,
                      });
                      return;
                    }
                    // error checking end

                    // search if the first lowerRange larger than the insert lower range
                    const rateIndex = _.findIndex(
                      ({ lowerRange }) => {
                        return (
                          safeParseFloat(lowerRange, 2) >
                          safeParseFloat(pageState.service_table.rowData[`${rateStructure}`].lowerRange, 2)
                        );
                      },

                      pageState.service_table.rowData[`${rateStructure}`].ratesRows,
                    );
                    // if rateIndex exist,an new row will be insert  between rows or it will be add at the bottom
                    const currentIndex =
                      rateIndex > -1 ? rateIndex : pageState.service_table.rowData[`${rateStructure}`].ratesRows.length;

                    setDispatch(DISPATCH_FNS.service_editTierRate, {
                      currentIndex,
                      isCreate: true,
                    });
                  }}
                  leftIcon={{ name: 'add', type: '', className: '' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TierRateCalculator;
