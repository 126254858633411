import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, FilterPanel, Typeahead } from '@gsa/afp-component-library';
import { usePegasysFilter } from './filters/pegasys-filter-provider';
import { usePegasysContext } from './pegasys-provider';
import {returnUpdatedFilters, returnClearedFilters} from './pegasys-common-util';

const { FilterPanelFilterShape } = FilterPanel;
let typeaheadData = [];
const PegasysTypeaheadItem = ({ filter }) => {
  typeaheadData = filter?.typeaheadData;
  const wsfieldTypes = ['wsDocNumber', 'wsVin', 'wsDocType', 'wsStatus', 'wsDate'];
  const [tpState, setTpState] = useState([]);
  const { setFlatFileFilters, ffFilters, setWsFilters, wsFilters } = usePegasysFilter();
  const [tpFilterValue, setTpFilterValue] = useState('');
  const { ffOptionsLoading } = usePegasysContext();
  
  useEffect(() => {
    setTpState([]);
    setTpFilterValue('');
  }, []);


  if (ffOptionsLoading) {
    return <Spinner />;
  }

  const fetchValues = (value, filterId) => {
    const options = (filterId === "wsVin" || filterId === "ffVin") ? typeaheadData.vin : typeaheadData.documentNumbers;
    const res = options?.filter((elements, index) => {
      return elements !== null && options.indexOf(elements) === index;
    }) || [];

    if (res.find((val) => val.toLocaleLowerCase().includes(value.toLocaleLowerCase()))) {
      setTpState(res.filter((el) => el.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
    } else {
      setTpState([]);
    }
  };

  // Clears TypeAhead Filter and returns remaining applied filters
  const onClear = () => {
    if (wsfieldTypes.includes(filter.id)) {
      const updatedList = returnClearedFilters(wsFilters, filter);
      setWsFilters(updatedList);
    } else {
      const updatedList = returnClearedFilters(ffFilters, filter);
      setFlatFileFilters(updatedList);
    }
  };

  if (typeaheadData) {
    return (
      <Typeahead
        {...filter}
        label='Search'
        filterValue={tpFilterValue}
        onOptionEnter={(value) => {
          setTpFilterValue(value);
          if (wsfieldTypes.includes(filter.id)) {
            const returnedObj = returnUpdatedFilters(value, wsFilters, filter);
            setWsFilters({...returnedObj});
          } else {
            const returnedObj = returnUpdatedFilters(value, ffFilters, filter);
            setFlatFileFilters({...returnedObj});
          }
        }}
        id={`typeahead-${filter.id}`}
        typeaheadValues={tpState}
        fetchTypeaheadValues={(accessor, value) => {
          fetchValues(value, filter.id);
        }}
        onClear={onClear}
      />
    );
  }

  return null;
};

PegasysTypeaheadItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default PegasysTypeaheadItem;
