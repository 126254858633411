import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

export const CommentModal = ({
                                id,
                                variant,
                                title,
                                onClose,
                                onComment,
                                commenting,
                                commentButtonText,
                                commentButtonVariant,
                                cancelButtonText,
                                children,
                            }) => (
                              <div className="afp-modal-wrapper" id={id}>
                                <div className="afp-modal-overlay">
                                  <Modal
                                    variant={variant}
                                    title={
                                      <h1 className="margin-bottom-1" data-testid="comment-modal-title">
                                        {title}
                                      </h1>
                }
                                    onClose={onClose}
                                    actions={
                                      <>
                                        <Button variant="unstyled" onClick={onClose} id="cancel-btn" label={cancelButtonText} />
                                        {commentButtonText && (
                                        <Button variant={commentButtonVariant} onClick={onComment} disabled={commenting} label={commentButtonText} />
                        )}
                                      </>
                }
                                  >
                                    {children}
                                  </Modal>
                                </div>
                              </div>
);

CommentModal.defaultProps = {
    variant: "large",
    commenting: false,
    commentButtonText: "",
    commentButtonVariant: "primary",
    cancelButtonText: "Cancel",
};

CommentModal.propTypes = {
    id: PropTypes.string.isRequired,
    variant: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onComment: PropTypes.func.isRequired,
    commenting: PropTypes.bool,
    commentButtonText: PropTypes.string,
    commentButtonVariant: PropTypes.string,
    cancelButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
};
