import { parsePercent } from '../../../components/helpers/afp-bm-helpers';
import { STATUS } from '../../../components/helpers/constants';

// Gnerate gql query from form data
export const processFormData = (formData) => {
  const {
    purchaseTypeDesc,
    purchaseRateType,
    purchaseTypeCode,
    effectiveStartDate: effectivestartdate,
    isCreate,
    rateStatus,
    statusChanged,
  } = formData;
  const purchasetype = formData.purchaseType?.trim();
  const fixedPercent = formData.fixedPercent ? parsePercent(formData.fixedPercent) : null;
  const flatDollarFee = formData.flatDollarFee || null;

  let PurchaseRateInput;
  if (isCreate)
    PurchaseRateInput = {
      purchaseTypeDesc,
      fixedPercent,
      flatDollarFee,
    };
  else if (rateStatus === STATUS.ACTIVE && !statusChanged)
    PurchaseRateInput = {
      purchaseTypeDesc,
      purchaseRateType,
      purchaseTypeCode,
    };
  else
    PurchaseRateInput = {
      purchaseTypeDesc,
      purchaseRateType,
      purchaseTypeCode,
      fixedPercent,
      flatDollarFee,
    };

  return {
    variables: {
      purchasetype,
      effectivestartdate,
      PurchaseRateInput,
    },
  };
};

export const dummy = undefined;
