import { gql } from '@apollo/client';

export const GET_STANDARD_ITEM = gql`
  query getStandardItems(
    $filters: FilterType!
    $order: OrderBy!
    $offset: Int
    $limit: Int
  ) {
    getStandardItems(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemNumber
        title
        fedStandardCode
        vehicleType
        year
      }
      count
      hasMore
    }
  }
`;

export const GET_CATALOG_CODES = gql`
  query GetStandardCodes(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardCodes(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        code
        title
        additionalProps
        description
        tags
        metadata {
          id
          category
          description
          parentCategory
          categoryOfTags
          requiresApproval
          allowNewEntries
          propertiesSchema
        }
      }
      count
      hasMore
    }
  }
`;
