import React from 'react';
import _ from 'lodash/fp';
import FundingAdjustmentForm from './FundingAdjustmentForm';
import NewFundingForm from './NewFundingForm';

/* eslint-disable  */
const AcquisitionCalculator = ({ pageState, setDispatch, DISPATCH_FNS, updateFunds }) => {
  return (
    <div className="acquisition-calculator">
      <div className="margin-y-2">
        <h4 className="acquisition-calculator-heading">{_.upperCase('Update Funding')}</h4>
      </div>
      <p>Enter a positive or negative number to add or remove funding for each category.</p>
      <FundingAdjustmentForm pageState={pageState} setDispatch={setDispatch} DISPATCH_FNS={DISPATCH_FNS} />
      <NewFundingForm
        pageState={pageState}
        setDispatch={setDispatch}
        DISPATCH_FNS={DISPATCH_FNS}
        updateFunds={updateFunds}
      />
    </div>
  );
};

export default AcquisitionCalculator;
