import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useState } from 'react';
import {
  GET_BATCH_ERRORS,
  GET_PEGASYS_WS_ERRORS,
  GET_DISTINCT_WS_VINS_AND_DOCS,
  GET_DISTINCT_BATCH_VINS_AND_DOCS,
} from '../../services/data-layer/shared-billing';

export const PegasysContext = createContext();

const initialState = {
  ffTypeaheadDataObj: {
    vin: [],
    documentNumbers: [],
  },
  wsTypeaheadDataObj: {
    vin: [],
    documentNumbers: [],
  },
  alertMessage: { message: '', type: '', header: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FF_TYPEAHEAD_DATA':
      return { ...state, ffTypeaheadDataObj: action.payload };
    case 'SET_WS_TYPEAHEAD_DATA':
      return { ...state, wsTypeaheadDataObj: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function PegasysProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [wsErrResponse, setWsErrResponse] = useState([]);
  const [batchErrResponse, setBatchErrResponse] = useState([]);

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
    });
  };

  const [fetchBatchFileData, { data: pegasysBatchErrors,  loading: ffDataLoading  }] = useLazyQuery(GET_BATCH_ERRORS, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'AND', value: [] },
      order: [['id', 'DESC']],
      offset: 0,
      limit: 10,
    },
    onError: (error) => setRequestError(error, 'getErrorsListing'),
  });

  const [fetchWsData, { data: receivedWsData, loading: wsDataLoading }] = useLazyQuery(GET_PEGASYS_WS_ERRORS, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'AND', value: [] },
      order: [['createdAt', 'DESC']],
      offset: 0,
      limit: 10,
    },
    onError: (error) => setRequestError(error, 'getErrorsListing'),
  });

  const [fetchAllWsVINSAndDocs, { loading: wsOptionsLoading }] = useLazyQuery(GET_DISTINCT_WS_VINS_AND_DOCS, {
    fetchPolicy: 'c',
    onCompleted: (response) => {
      if (response) {
        dispatchAction('SET_WS_TYPEAHEAD_DATA', {
          vin: response.getDistinctWSVinAndDocNbr.distinctVinArrayStr,
          documentNumbers: response.getDistinctWSVinAndDocNbr.docNbrArrayStr,
        });
      }
    }  
  });

  const [fetchAllBatchVINSAndDocs,  { loading: ffOptionsLoading }] = useLazyQuery(GET_DISTINCT_BATCH_VINS_AND_DOCS, {
    fetchPolicy: 'c',
    onCompleted: (response) => {
      if (response) {
        dispatchAction('SET_FF_TYPEAHEAD_DATA', {
          vin: response.getDistinctBatchVinAndDocNbr.distinctVinArrayStr,
          documentNumbers: response.getDistinctBatchVinAndDocNbr.docNbrArrayStr,
        });
      }
    }  
  });

  return (
    <PegasysContext.Provider
      value={{
        ...state,
        fetchBatchFileData,
        ffOptionsLoading,
        fetchWsData,
        receivedWsData,
        pegasysBatchErrors,
        wsOptionsLoading,
        dispatchAction,
        setWsErrResponse,
        wsErrResponse,
        setBatchErrResponse,
        batchErrResponse,
        wsDataLoading,
        ffDataLoading,
        fetchAllWsVINSAndDocs,
        fetchAllBatchVINSAndDocs,
        ...props,
      }}
    >
      {children}
    </PegasysContext.Provider>
  );
}

export default PegasysProvider;

PegasysProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const usePegasysContext = () => useContext(PegasysContext);
