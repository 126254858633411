import React from 'react';
import { ToggleActiveModal } from '../ToggleActiveModal';
import { UpdateOptInCategoryModal } from './UpdateOptInCategoryModal';

/* eslint-disable react/prop-types */
const CategoryModals = ({ pageState, setDispatch, DISPATCH_FNS, closeModal, loading, mutation }) => {
  const { reactivateActive, deactivateActive, createNewOptInCategory, updateOptInCategory } = mutation;

  return (
    <>
      {pageState.category_toggleActiveModal.show && (
        <ToggleActiveModal
          onClose={() => {
            setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal });
          }}
          setDispatch={setDispatch}
          DISPATCH_FNS={DISPATCH_FNS}
          pageState={pageState}
          deactivateActive={deactivateActive}
          reactivateActive={reactivateActive}
          formId="category-toggleActiveForm"
          isDeactivate={pageState.category_toggleActiveModal.isDeactivate}
          loading={loading}
        />
      )}
      {pageState.category_updateOptInCategoryModal.show && (
        <UpdateOptInCategoryModal
          onClose={() => {
            setDispatch(DISPATCH_FNS.category_updateOptInCategoryModal, { show: false, closeModal });
          }}
          setDispatch={setDispatch}
          DISPATCH_FNS={DISPATCH_FNS}
          pageState={pageState}
          createNewOptInCategory={createNewOptInCategory}
          updateOptInCategory={updateOptInCategory}
          formId="category-updateOptInCategoryModal"
          isCreate={pageState.category_updateOptInCategoryModal.isCreate}
          loading={loading}
        />
      )}
    </>
  );
};

export default CategoryModals;
