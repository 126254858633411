import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const UniqueRateAppThankyou = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="minh-viewport">
      <div className="grid-row">
        <div className="grid-col-8">
          <h1>Thank you for your submission</h1>
          <p>
            Your request has been submitted for review. You will be notified once the request is approved. In the
            meantime, if you want to modify or track the status of your request you can do so by{' '}
            <Link to="/bm/unique-rates-app">clicking here</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UniqueRateAppThankyou;
