import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import { stateValue } from 'utilities/stateUtils';
import WelcomeMessage from 'widgets/welcome-message';
import AlertMessage from './AlertMessage';
/* eslint-disable react/prop-types */

// need to add welcomeMessage to the page state in store if you want to see welcomeMessage

const Header = ({ _setDispatch, _pageState, DISPATCH_FNS, useTrackedState = null, useDispatch = null }) => {
  let pageState;
  let setDispatch;
  if (useTrackedState && useDispatch) {
    pageState = useTrackedState();
    setDispatch = useDispatch();
  } else {
    pageState = _pageState;
    setDispatch = _setDispatch;
  }
  useTitle(pageState.currentPage);

  return (
    <>
      <Breadcrumbs
        current={pageState.currentPage}
        // current={getHeaderCaseState(pageState.currentPage)}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <AlertMessage
        className="margin-top-2"
        type={stateValue(pageState.bannerMsgState.type)}
        onClose={() => {
          setDispatch(DISPATCH_FNS.bannerMsgState.setTypeContent, { type: null, content: null });
        }}
        content={stateValue(pageState.bannerMsgState.content)}
      />

      {stateValue(pageState.welcomeMessage) && (
        <>
          <PageTitle title={pageState.currentPage} />
          <WelcomeMessage className="textStyle" message={stateValue(pageState.welcomeMessage)} />
        </>
      )}
    </>
  );
};

export default Header;
