import React from 'react';
import AFPTable from 'widgets/afp-table-wrapper';
import { Pagination } from '@gsa/afp-component-library';
import { updateSort } from 'utilities/bmAppUtilities';
import { handlePaginationChange, getServiceQueryVariables } from '../../helper';
import { columns as serviceColumns } from '../../model/service/schemas/serviceTableSchema';
import ServiceRowSubComponent from './ServiceRowSubComponent';

/* eslint-disable react/prop-types */
const ServiceTable = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  loading,
  fetchRows,
  isPaginationComponentReset,
  resetPaginationComponent,
  openModal,
}) => {
  const onSort = (orderStr) => {
    if (orderStr) {
      const order = updateSort({ originalSort: pageState.serviceFilter.order, orderStr });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'serviceFilter.order', data: order });

      fetchRows({
        variables: getServiceQueryVariables({
          pageState,
          order,
          filter: pageState.serviceFilter.filter.optInSvcCategory,
        }),
      });
    }
  };
  return (
    <>
      <div id="optin-service-table">
        <AFPTable
          isLoading={loading.categoryTableLoading}
          columns={serviceColumns({ pageState, setDispatch, DISPATCH_FNS, openModal })}
          data={pageState.service_table.rows}
          NoDataMessages={{
            title: 'No Service Available',
            text: 'There are no matches for the filtered values at left.',
          }}
          onSort={onSort}
          expandable
          defaultSort={pageState.serviceFilter.order}
          renderRowSubComponent={ServiceRowSubComponent}
          fullWidth
          className="service-table"
        />
        <div className="padding-y-4">
          <Pagination
            onPageChange={handlePaginationChange({
              setDispatch,
              DISPATCH_FNS,
              pageState,
              resetPaginationComponent,
              fetchRows,
              filterName: 'serviceFilter',
            })}
            variant="advanced"
            currentPage={pageState.serviceFilter.currentPage}
            itemsCount={pageState.service_itemsCount}
            itemsPerPage={pageState.serviceFilter.limit}
            isReset={isPaginationComponentReset}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceTable;
