import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, AddressFormGroup } from '@gsa/afp-component-library';

function checkPOBox(value) {
  const str = value
    .split('')
    .filter((ch) => ch !== '.' && ch !== ' ')
    .join('');
  return !str.match(/POBox[0-9]/i);
}

const schema = yup.object().shape({
  address1: yup.string().nullable().test('address1', 'PO Box is not allowed', checkPOBox),
  address2: yup.string().nullable().test('address2', 'PO Box is not allowed', checkPOBox),
  address3: yup.string().nullable().test('address3', 'PO Box is not allowed', checkPOBox),
  city: yup.string().nullable(),
  stateCode: yup
    .string()
    .nullable()
    .matches(/^[A-Z]{2}$/, 'State is required'), // prettier-ignore
  zipCode: yup
    .string()
    .nullable()
    .matches(/^\d{5}(?:-\d{4})?$/, 'Please use the correct format'),
});

const AddressForm = (props) => {
  const { id, disabled, defaultValues, stateOptions, onChange: onFormChange, onError: onFormError } = props;

  // useForm declaration
  const { errors, register, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const errorsLen = Object.keys(errors).length;

  useEffect(() => {
    onFormError(errorsLen);
  }, [errorsLen]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues]);

  const handleFieldChange = (fieldName, value) => {
    let newVal = value;
    if (fieldName === 'zipCode') {
      if (value?.length > 5 && value[5] !== '-') newVal = `${value.substring(0, 5)}-${value.substring(5)}`;
    }
    onFormChange({
      ...getValues(),
      [fieldName]: newVal || '',
    });
  };

  return (
    <Form id={id} size="large">
      <AddressFormGroup
        hasCountry={false}
        hasCompany
        hasAddressLine3
        inputsDisabled={disabled}
        onFieldChange={handleFieldChange}
        companyProps={{
          name: 'address1',
          label: 'Contact name',
          errorMessage: errors.address1?.message,
          inputRef: register,
          value: defaultValues.address1,
        }}
        addressLine1Props={{
          name: 'address2',
          errorMessage: errors.address2?.message,
          inputRef: register,
          value: defaultValues.address2,
        }}
        addressLine2Props={{
          name: 'address3',
          errorMessage: errors.address3?.message,
          inputRef: register,
          value: defaultValues.address3,
        }}
        cityTownProps={{
          name: 'city',
          errorMessage: errors.city?.message,
          inputRef: register,
          value: defaultValues.city,
        }}
        stateProps={{
          name: 'stateCode',
          options: stateOptions,
          errorMessage: errors.stateCode?.message,
          ref: register('stateCode', { required: true }),
          defaultValue: defaultValues.stateCode,
          value: defaultValues.stateCode,
        }}
        zipCodeProps={{
          name: 'zipCode',
          errorMessage: errors.zipCode?.message,
          inputRef: register,
          value: defaultValues.zipCode,
        }}
      />
    </Form>
  );
};

AddressForm.defaultProps = {
  id: 'ur-app-address',
  disabled: false,
  defaultValues: {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateCode: '',
    zipCode: '',
  },
  stateOptions: [],
};

AddressForm.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValues: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    stateCode: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  stateOptions: PropTypes.shape([]),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AddressForm;
