import React from 'react';

export const CREATE_WALLET_SUCCESS_MESSAGE = {
  body: () => (
    <>
      You have successfully created a new Wallet.
    </>
  ),
};

export const CREATE_WALLET_DOD_LEASING_SUCCESS_MESSAGE = {
  body: () => (
    <>
      Record has been successfully created. Edit vehicle level IPAC information below.
    </>
  ),
};

export const CREATE_WALLET_API_VALIDATION_ERROR_MESSAGE = {
  body: () => (
    <>
      This form has errors. Please correct all fields outlined in red before submitting.
    </>
  ),
};

export const EXISTING_ASSOCIATION_ERROR_MESSAGE = {
  body: () => (
    <>
      Fleet offering association already exists for this BOAC.
    </>
  ),
};