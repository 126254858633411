import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ResponsiveFilterPanel, Spinner } from '@gsa/afp-component-library';
import './style/unique-rate-filter.scss';
import { GET_DISTINCT_UNIQUE_RATE_TYPE, GET_UR_START_DATE_RANGE } from './unique-rate-helper';
import { getUTCFYInt, getFYUTCDateRange } from '../../components/helpers/afp-bm-date';
import { STATUS, DEFAULT_FILTER_STATE } from '../../components/helpers/constants';

const FilterItems = {
  status: {
    id: 'rateStatus',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: [STATUS.ACTIVE, STATUS.PENDING, STATUS.NEW, STATUS.EXPIRED, STATUS.INACTIVE],
    className: 'StatusFilter',
    noChip: true,
  },
  uniqueRateType: {
    id: 'uniqueRateType',
    title: 'Unique rate type',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
  fiscalYear: {
    id: 'fiscalYear',
    title: 'Fiscal year',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
};

const UniqueRateFilter = ({ onFilter, timestamp }) => {
  const [filterOptions, setFilterOptions] = useState({});

  const genSelectOptions = (arr, field = null) =>
    arr.map((a) => {
      const val = field ? a[field] : a;
      return { value: val, label: val };
    });

  const onFilterAPIError = (key) =>
    setFilterOptions((prev) => ({
      ...prev,
      [key]: [],
    }));

  const [fetchDistinctUniqueRateTypes, { loading: loadingURT }] = useLazyQuery(GET_DISTINCT_UNIQUE_RATE_TYPE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getDistinctUniqueRate }) => {
      setFilterOptions((prev) => {
        return {
          ...prev,
          uniqueRateType: genSelectOptions(getDistinctUniqueRate, 'uniqueRateType'),
        };
      });
    },
    onError: () => onFilterAPIError('UniqueRateCodeType'),
  });

  const [fetchUniqueRateStartDateRange, { loading: loadingFY }] = useLazyQuery(GET_UR_START_DATE_RANGE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getURStartDateRange: dates }) => {
      if (dates) {
        const startYear = getUTCFYInt(dates[0].startDate);
        const endYear = getUTCFYInt(dates[1].startDate);
        const yearList = [];
        for (let i = endYear; i >= startYear; i -= 1)
          yearList.push({
            value: i,
            label: i.toString(),
          });
        setFilterOptions((prev) => {
          return { ...prev, fiscalYear: yearList };
        });
      }
    },
    onError: () => onFilterAPIError('fiscalYear'),
  });

  useEffect(() => {
    fetchDistinctUniqueRateTypes();
    fetchUniqueRateStartDateRange();
  }, [timestamp]);

  const buildFilters = useCallback((state) => {
    const filters = {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'OR', value: [] },
    };

    Object.keys(state).forEach((key) => {
      const value = state[key];
      switch (key) {
        case 'rateStatus':
          value?.forEach((val) => {
            filters.virtualFilter.value.push({
              key,
              operator: 'EQ',
              value: val,
            });
          });
          break;
        case 'uniqueRateType':
          filters.filters.value.push({ key, operator: 'EQ', value });
          break;
        case 'fiscalYear':
          {
            const { startDate, endDate } = getFYUTCDateRange(value);
            filters.filters.value.push(
              { key: 'startDate', operator: 'LTE', value: endDate },
              {
                operator: 'OR',
                value: [
                  { key: 'endDate', operator: 'EQ', value: null },
                  { key: 'endDate', operator: 'GTE', value: startDate },
                ],
              },
            );
          }
          break;
        default:
      }
    });

    Object.keys(filters).forEach((key) => {
      if (!filters[key].value.length) delete filters[key];
    });

    return { filters, state };
  }, []);

  return (
    <div>
      {loadingURT || loadingFY ? (
        <div style={{ position: 'absolute', top: '-40px', width: '100%' }}>
          <Spinner />
        </div>
      ) : null}
      <ResponsiveFilterPanel
        defaultState={DEFAULT_FILTER_STATE}
        items={FilterItems}
        filterOptions={filterOptions}
        onChange={(state) => onFilter(buildFilters(state))}
      />
    </div>
  );
};

UniqueRateFilter.defaultProps = {
  timestamp: Date.now(),
};
UniqueRateFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  timestamp: PropTypes.number,
};

export default UniqueRateFilter;
