export function getCSVRows(data) {
  return data?.split('\r\n');
}

function parseRow(row) {
  const len = row.length;
  let chars = '';

  if (row[0] === '"') {
    let i = 1;
    while (i < len) {
      if (row[i] === '"') {
        if (i === len - 1) return [chars];
        if (row[i + 1] === ',') return [chars, ...parseRow(row.substring(i + 2))];
        if (row[i + 1] === '"') {
          chars = `${chars}"`;
          i += 1;
        } else return []; // format error
      } else chars = `${chars}${row[i]}`; // row[i] !== '"'
      i += 1;
    }
    return []; // format error
  }

  // row[0] !== '"'
  let j = 0;
  while (j < len) {
    if (row[j] === ',') return [chars, ...parseRow(row.substring(j + 1))];
    if (row[j] === '"') {
      if (j < len - 1 && row[j + 1] === '"') {
        chars = `${chars}"`;
        j += 1;
      } else return []; // format error
    } else chars = `${chars}${row[j]}`; // row[j] !== '"'
    j += 1;
  }
  return [chars];
}

export function parseCSVRow(row) {
  if (!row || typeof row !== 'string') return null;
  return parseRow(row);
}
