import React from 'react';
import { Icon } from '@gsa/afp-component-library';
import { formatDollar } from 'components/helpers/afp-bm-helpers';
import { formatLocaleString } from 'components/helpers/afp-bm-date';
import { AFV_SURCHARGE_ACTION } from '../afv-funding-helper';

const AFVFundingSurchargeComment = (comment, i) => {
  if (!comment) return null;

  const commentStyle = {
    border: '1px solid #e9e9e9',
    backgroundColor: '#fff',
    margin: ' 0.75rem 5rem 0.75rem 0',
    padding: '0.5rem 2rem',
  };
  const commentType = {
    [AFV_SURCHARGE_ACTION.ACCEPT]: 'Customer accepted surcharge',
    [AFV_SURCHARGE_ACTION.DISCUSS]: 'Customer requested discussion',
    [AFV_SURCHARGE_ACTION.DECLINE]: 'Customer declined surcharge',
    [AFV_SURCHARGE_ACTION.SUGGEST]: 'Customer suggested surcharge',
    [AFV_SURCHARGE_ACTION.SET]: 'GSA set surcharge',
    [AFV_SURCHARGE_ACTION.APPROVE]: 'GSA finalized surcharge',
    [AFV_SURCHARGE_ACTION.ADJUST]: 'GSA adjusted surcharge',
    [AFV_SURCHARGE_ACTION.RECOMMEND]: 'GSA recommended surcharge',
    [AFV_SURCHARGE_ACTION.FINAL_RECOMMEND]: 'GSA recommended surcharge',
  };

  const getTimeStr = (t) => {
    if (!t) return '–';
    return formatLocaleString(new Date(t).toLocaleString());
  };

  const { id, action, afvSurchargeAmount, createdAt, createdByUserInfo, comment: afvComment } = comment;

  const openComment = () => {
    const url = `${window.AFP_CONFIG.appURLs.bm}/bm/afv-funding/customer/${id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div style={commentStyle} key={`${id}-${i}`}>
      <div className="display-flex flex-justify margin-top-1 text-bold">
        <div>
          {commentType[action]}: {formatDollar(afvSurchargeAmount)}
        </div>
        <div>{getTimeStr(createdAt)}</div>
      </div>
      <div className="display-flex flex-align-center margin-y-2">
        <Icon iconName="account_circle" className="usa-icon--size-3 margin-right-1" onClick={openComment} />
        <a href={`mailto:${createdByUserInfo?.email}`}>{createdByUserInfo?.fullName || '–'}</a>
      </div>
      <div className="margin-bottom-1">{afvComment}</div>
    </div>
  );
};

export default AFVFundingSurchargeComment;
