import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_BUREAUS_BY_PERMISSION } from '../wallet.gql';
import { ENTITY, ROLE_OP } from '../../../components/role-permission/role-permission';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } = FilterPanel;

const BureauFilterItem = ({ filter }) => {
  const [agencyCode, setAgencyCode] = useState();
  const [burueauCode, setBurueauCode] = useState();
  const [options, setOptions] = useState([]);

  const { getFilterFromState, mergedFilters, clearOneFilter } = useFilterPanel();

  const [getBureaus, { data: bureauOptions, loading: bureauOptionsLoading }] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const agencyFilter =
      getFilterFromState('$boacModel.agency_code$') || getFilterFromState('boacModel.agency_code') || {};
    setAgencyCode(agencyFilter.value);
    const bureauFilter =
      getFilterFromState('$boacModel.bureau_code$') || getFilterFromState('boacModel.bureau_code') || {};
    setBurueauCode(bureauFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (agencyCode) {
      getBureaus({
        variables: {
          agencyCode,
          operation: ROLE_OP.VIEW,
          subject: ENTITY.WALLET,
        },
      });
    }
  }, [agencyCode]);

  useEffect(() => {
    if (bureauOptions?.getBureausByPermission) {
      const formattedBureauOptions = sortBy(bureauOptions.getBureausByPermission, 'id').map((bureauDetails) => ({
        value: bureauDetails.id,
        label: `${bureauDetails.id} - ${bureauDetails.name}`,
      }));
      setOptions(formattedBureauOptions);
    }
  }, [bureauOptions]);

  if (bureauOptionsLoading) {
    return <Spinner />;
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: burueauCode || [],
        }}
      />
    </div>
  );
};

BureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BureauFilterItem;
