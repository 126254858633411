import { STATUS } from 'components/helpers/constants';

export const statusOptions = [STATUS.ACTIVE, STATUS.PENDING, STATUS.NEW, STATUS.EXPIRED, STATUS.INACTIVE];

export const getFilterItems = (pageState) => ({
  status: {
    id: 'rateStatus',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: statusOptions,
    className: 'StatusFilter',
    noChip: true,
  },
  serviceCategory: {
    id: 'optInSvcCategory',
    title: 'Category',
    type: 'checkbox-list',
    expanded: true,
    options: pageState.serviceFilter.options.optInSvcCategory,
    className: 'serviceCategoryFilter',
    noChip: true,
  },
});
