import { gql } from '@apollo/client';

const OptInCategoryResponse = gql`
  fragment OptInCategoryResponseFields on OptInCategory {
    rateStatus
    optInCategoryName
    optInCategoryDesc
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
  }
`;

export const UPDATE_OPT_IN_CATEGORY = gql`
  mutation updateOptInCategory($prevOptInCategoryName: String!, $optInCategoryInput: OptInCategoryInput!) {
    updateOptInCategory(prevOptInCategoryName: $prevOptInCategoryName, optInCategoryInput: $optInCategoryInput) {
      ...OptInCategoryResponseFields
    }
  }
  ${OptInCategoryResponse}
`;
