import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, Textbox, Button, ErrorMessage } from '@gsa/afp-component-library';
import { UTCDateStrToUS, getTodayStr } from 'components/helpers/afp-bm-date';
import { formatDollar, classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import BannerMessage from 'widgets/banner-message';
import { AFV_SURCHARGE_ACTION } from '../afv-funding-helper';
import '../style/afv-funding.scss';

const MAX_COMMENTS_LENGTH = 500;

export default function AfvSurchargeConcurrence({ data, onSubmit }) {
  const [concur, setConcur] = useState(true);
  const [comments, setComments] = useState('');
  const [commentsError, setCommentsError] = useState('');
  const [commentsCount, setCommentsCount] = useState(MAX_COMMENTS_LENGTH);

  const showForm = data && !data.responseId && data.startDate > getTodayStr();

  const onCommentChange = (e) => {
    const { value } = e.target;
    setComments(value);
    setCommentsCount(MAX_COMMENTS_LENGTH - value.length);
    if (value) setCommentsError('');
  };

  const onClickSubmit = () => {
    if (!comments) setCommentsError('Comments are required');
    else if (comments.length > MAX_COMMENTS_LENGTH)
      setCommentsError(`Comments exceed ${MAX_COMMENTS_LENGTH}-character limit`);
    else onSubmit({ concur, comments });
  };

  const displayDescText = (action) => {
    switch (action) {
      case AFV_SURCHARGE_ACTION.SET:
      case AFV_SURCHARGE_ACTION.ADJUST:
      case AFV_SURCHARGE_ACTION.APPROVE:
      case AFV_SURCHARGE_ACTION.RECOMMEND:
        return <div>Based on our analysis, your recommended surcharge is displayed below.</div>;
      case AFV_SURCHARGE_ACTION.FINAL_RECOMMEND:
      default:
        return null;
    }
  };

  const displayNewSurcharge = (rate, startDate) => (
    <div className="grid-row grid-gap">
      <div className="grid-col-2 grid-offset-05">
        <Label>New surcharge</Label>
        <div className="afv-concurrence-new-surcharge">{formatDollar(rate)}</div>
      </div>
      <div className="grid-col-2">
        <Label>Start date</Label>
        <Textbox value={UTCDateStrToUS(startDate) || ''} aria-label="New surcharge start date" readonly />
      </div>
    </div>
  );

  const concurrenceForm = (action) => {
    if (action !== AFV_SURCHARGE_ACTION.RECOMMEND && action !== AFV_SURCHARGE_ACTION.FINAL_RECOMMEND) return null;
    return (
      <div className="usa-form" style={{ maxWidth: '100%' }}>
        {action === AFV_SURCHARGE_ACTION.FINAL_RECOMMEND && !concur && (
          <BannerMessage type="warning" showClose={false} className="margin-bottom-4">
            By not accepting the suggested AFV surcharge you are agreeing to manage AFV orders as needed to ensure your
            AFV funding is sufficient.
          </BannerMessage>
        )}
        <div className="usa-radio margin-bottom-1">
          <input
            className="usa-radio__input"
            type="radio"
            id="concurrence-radio-yes"
            data-testid="concurrence-radio-yes"
            name="concurrenceYes"
            checked={concur}
            aria-label="Concurrence radio button yes"
            onChange={() => setConcur(true)}
          />
          <label className="usa-radio__label" htmlFor="concurrence-radio-yes">
            Yes, I accept the recommended surcharge
          </label>
        </div>
        <div className="usa-radio margin-bottom-1">
          <input
            className="usa-radio__input"
            type="radio"
            id="concurrence-radio-no"
            data-testid="concurrence-radio-no"
            name="concurrenceNo"
            checked={!concur}
            aria-label="Concurrence radio button no"
            onChange={() => setConcur(false)}
          />
          <label className="usa-radio__label" htmlFor="concurrence-radio-no">
            No, I want to discuss my surcharge with a GSA Fleet representative
          </label>
        </div>
        <div className={`margin-top-2 ${classNameErrBox(commentsError)}`}>
          <Label required>Comments</Label>
          {commentsError && <ErrorMessage>{commentsError}</ErrorMessage>}
          <Textbox
            data-testid="concurrence-comment"
            style={{ maxWidth: '800px' }}
            className={classNameErrInput(commentsError)}
            value={comments}
            maxLength={MAX_COMMENTS_LENGTH}
            type="textarea"
            rows={3}
            aria-label="Comments"
            onChange={onCommentChange}
          />
          <div className="usa-hint usa-character-count__message">
            {commentsCount < MAX_COMMENTS_LENGTH
              ? `${formatDollar(commentsCount, '', 0)} characters remaining`
              : `${formatDollar(MAX_COMMENTS_LENGTH, '', 0)} characters allowed`}
          </div>
        </div>
        <div className="grid-col-2">
          <Button
            size="sm"
            variant="primary"
            data-testid="concurrence-submit-button"
            // className="fund-save-btn"
            aria-label="Submit button"
            onClick={onClickSubmit}
            label="Submit"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {showForm && displayDescText(data?.action)}
      {displayNewSurcharge(data?.afvSurchargeAmount, data?.startDate)}
      {showForm && <div className="margin-top-4">{concurrenceForm(data?.action)}</div>}
    </div>
  );
}

AfvSurchargeConcurrence.propTypes = {
  data: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
};
