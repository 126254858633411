import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Spinner, FileUpload } from '@gsa/afp-component-library';
import { read, utils } from 'xlsx';
import { useWalletDetails } from '../wallet-details-provider';
import { extractMonthYear } from '../helpers/utils';
import { useImport } from '../state/hook';
import { ipacReportConfig } from '../forms/consts';

export const coercionBoolean = (value) => {
  if (value.toLowerCase() === 'yes') {
    return true;
  }
  return typeof value === 'string' ? !!+value : !!value;
};

export const coercionString = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value.toString();
};

const ModalActions = ({ closeModal, onSubmit }) => {
  const { importIpacLoading } = useWalletDetails();
  return (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={closeModal}
        data-testid="ipac-import-modal-cancel-button"
        label="Cancel"
      />
      <Button
        variant="primary"
        type="submit"
        data-testid="ipac-import-modal-submit-button"
        disabled={importIpacLoading}
        label="Submit"
        onClick={(e) => onSubmit(e)}
      />
      {importIpacLoading && <Spinner size="small" className="display-inline-block margin-left-2" />}
    </>
  );
};

ModalActions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const IpacUploadModal = ({
  isOpen,
  closeModal,
  fileState,
  setFileState,
  fileImportErrorState,
  setFileImportErrorState,
}) => {
  const { alertMessage } = useWalletDetails();
  const { setData } = useImport();
  const { header, message, type, context } = alertMessage;
  const fileTypes = ['.xlsx', '.xls'];
  const maxFileSize = 30;

  const onModalClose = () => {
    setFileState(null);
    closeModal();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!fileState) {
      setFileImportErrorState('This is a required field');
      return;
    }
    if (fileImportErrorState) {
      return;
    }

    const data = await fileState.arrayBuffer();
    const wb = read(data, { cellDates: true, dateNF: 'mm/dd/yyyy' });
    const rawJson = utils.sheet_to_json(wb.Sheets.Vehicles, {
      raw: false,
      header: ipacReportConfig.mapping.map((m) => m[0]),
    });

    // validate headers
    const headerObject = rawJson?.[0];
    const mismatchedHeader =
      headerObject &&
      Object.keys(headerObject).find((k) => {
        return headerObject[k].trim().replace('*', '') !== k.trim().replace('*', '');
      });

    if (mismatchedHeader || !headerObject) {
      setFileState(null);
      return;
    }

    let processError = false;
    // process the data from the file
    const processedData = rawJson.slice(1).map((rowJson) => {
      const row = {};
      // map the data to the expected format
      try {
        ipacReportConfig.mapping.forEach((value) => {
          const [colLabel, colProp, colType] = value;
          let rawVal = rowJson[colLabel];
          if (rawVal) {
            rawVal = rawVal.trim();
          } else {
            rawVal = '';
          }
          switch (colType) {
            case 'boolean':
              row[colProp] = coercionBoolean(rawVal);
              break;
            // input can be a string or number, convert to string
            case 'string | number':
              row[colProp] = coercionString(rawVal);
              break;
            case 'number':
              if (rawVal.length > 0) row[value[1] || colProp] = parseInt(rawVal, 10);
              else row[value[1] || colProp] = undefined;
              break;
            case 'tagFormat':
              if (rawVal && rawVal.length > 0) row[colProp] = extractMonthYear(rawVal);
              else row[colProp] = null;
              break;
            case 'float':
              if (rawVal.length > 0)
                row[value[1] || colProp] = +parseFloat(Number(rawVal.replace(/[^0-9.-]+/g, ''))).toFixed(2);
              else row[value[1] || colProp] = 0.0;
              break;
            case 'date':
              if (rawVal.length > 0) row[colProp] = new Date(rawVal);
              else row[colProp] = undefined;
              break;
            // readonly means that column will be ignored from the sheet
            case 'readonly':
              break;
            default:
              row[colProp] = rawVal;
          }
        });
      } catch (err) {
        processError = true;
      }
      return row;
    });

    if (processedData?.length && !processError) {
      setData(processedData);
    }

    onModalClose();
  };

  return isOpen ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Upload Vehicle level IPAC Information</h2>}
          actions={<ModalActions closeModal={onModalClose} onSubmit={onSubmit} />}
          onClose={onModalClose}
          variant="large"
        >
          {context === 'walletIpacVehiclesModal' && message && (
            <div className="margin-bottom-2" data-testid="ipac-vehicles-modal-alert">
              <Alert type={type} heading={header || null}>
                {message}
              </Alert>
            </div>
          )}
          <FileUpload
            required
            label="Importing file"
            defaultValue={fileState}
            acceptableFiles={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]}
            acceptableFilesLabel={` Accept ${fileTypes[0]} and ${fileTypes[1]} file below
          ${maxFileSize} MB`}
            fileSizeLimit={maxFileSize} // MB
            onChange={(file) => {
              setFileState(file);
              setFileImportErrorState(!file && 'This is a required field');
            }}
            errorMessage={fileImportErrorState}
          />
        </Modal>
      </div>
    </div>
  ) : null;
};

export default IpacUploadModal;

IpacUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  fileState: PropTypes.shape({
    arrayBuffer: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  setFileState: PropTypes.func.isRequired,
  fileImportErrorState: PropTypes.objectOf(PropTypes.object).isRequired,
  setFileImportErrorState: PropTypes.func.isRequired,
};
