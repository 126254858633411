import React from 'react';
import Header from 'components/header/Header';
import OptInTabs from './components/OptInTabs';
import { Provider, useTrackedState, useDispatch, DISPATCH_FNS } from './model/store';
import './style/opt-in.scss';

const OptInAndRental = () => {
  return (
    <div id="opt-in" className="margin-bottom-6">
      <Header useDispatch={useDispatch} useTrackedState={useTrackedState} DISPATCH_FNS={DISPATCH_FNS} />
      <OptInTabs useDispatch={useDispatch} useTrackedState={useTrackedState} DISPATCH_FNS={DISPATCH_FNS} />
    </div>
  );
};

const OptInAndRentalIndexPage = () => {
  return (
    <Provider>
      <OptInAndRental />
    </Provider>
  );
};

export default OptInAndRentalIndexPage;
