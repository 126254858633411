import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_WALLET,
  UPDATE_WALLET,
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_WALLET_BY_ID,
  GET_BOACS,
  VALIDATE_BOAC_OFFERING,
  GET_IPACS,
  CREATE_IPAC_VEHICLE,
  DELETE_IPAC_VEHICLE,
  EXPORT_IPAC_TEMPLATE,
  IMPORT_IPAC,
} from './wallet-details.gql';
import { CREATE_WALLET_API_VALIDATION_ERROR_MESSAGE, EXISTING_ASSOCIATION_ERROR_MESSAGE } from './messages';
import { isLeasingType } from './helpers/utils';

export const WalletDetailsContext = createContext();

const initialState = {
  walletsList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  agencies: [],
  bureaus: [],
  alertMessage: { message: '', type: '', header: null },
  apiValidationErrors: [],
  createdWallet: '',
  walletVehicles: [],
  ipacExport: '',
  selectedFiscalYear: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_WALLET':
      return { ...state, wallet: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_AGENCIES':
      return { ...state, agencies: action.payload };
    case 'SET_BUREAUS':
      return { ...state, bureaus: action.payload };
    case 'SET_SCOPED_BOACS':
      return { ...state, scopedBoacs: action.payload };
    case 'SET_BOAC_OFFERING_ASSOCIATION':
      return { ...state, boacOfferingAssociation: action.payload };
    case 'SET_API_VALIDATION_ERRORS':
      return { ...state, apiValidationErrors: action.payload };
    case 'SET_CREATED_WALLETS':
      return { ...state, createdWallet: action.payload };
    case 'SET_WALLET_VEHICLES':
      return { ...state, ipacVehicles: action.payload };
    case 'SET_UPDATED_IPAC_VEHICLE':
      return { ...state, updatedIpacVehicle: action.payload };
    case 'SET_IPAC_EXPORT':
      return { ...state, ipacExport: action.payload };
    case 'SET_DELETED_IPAC_VEHICLE':
      return { ...state, deletedIpacVehicle: action.payload };
    case 'SET_FISCAL_YEAR':
      return { ...state, selectedFiscalYear: action.payload };
    default:
      throw new Error('Invalid action');
  }
};
function WalletDetailsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const clearAlerts = () => {
    dispatchAction('SET_ALERT_MESSAGE', initialState.alertMessage);
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  // Get wallet by id
  const [getWalletById, { loading: walletLoading }] = useLazyQuery(GET_WALLET_BY_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'wallet'),
    onCompleted: (responseData) => {
      if (responseData?.getWalletById) {
        dispatchAction('SET_WALLET', responseData.getWalletById);
      }
    },
  });

  // Validate existing BOAC-Fleet offering.
  const [validateBoacOffering, { loading: validatingBoacOffering }] = useMutation(VALIDATE_BOAC_OFFERING, {
    onError: (error) => setRequestError(error, 'validateBoacOffering'),
    onCompleted: (response) => {
      dispatchAction('SET_API_VALIDATION_ERRORS', response?.validateBoacOffering);
      if (response.validateBoacOffering.length > 0) {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'error',
          message: EXISTING_ASSOCIATION_ERROR_MESSAGE.body(),
        });
      } else {
        clearAlerts();
      }
    },
  });

  // Add wallet.
  const [createWallet, { loading: createWalletLoading }] = useMutation(CREATE_WALLET, {
    onError: (error) => setRequestError(error, 'createWallet'),
    onCompleted: (response) => {
      const createWalletResponse = response?.createWallet?.[0];
      const apiErrors = createWalletResponse.errors;
      const isDod = createWalletResponse?.isDoD;
      const salesGroupId = createWalletResponse?.salesGroupId;
      if (apiErrors) {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'error',
          message: CREATE_WALLET_API_VALIDATION_ERROR_MESSAGE.body(),
        });
        dispatchAction('SET_API_VALIDATION_ERRORS', apiErrors);
      } else if (isLeasingType(salesGroupId) && isDod) {
        // Remain on the page for the IPAC vehicle section
        clearAlerts();
        dispatchAction('SET_CREATED_WALLETS', createWalletResponse?.id);
        dispatchAction('SET_BOAC_OFFERING_ASSOCIATION', {
          ...createWalletResponse,
          agency: createWalletResponse?.boacModel?.agencyCode,
          bureau: createWalletResponse?.boacModel?.bureauCode,
        });
      } else {
        history.push({
          pathname: `/bm/wallet`,
          search: window.location.search,
          state: {
            createdWallet: createWalletResponse,
          },
        });
      }
    },
  });

  // Update wallet.
  const [updateWallet, { loading: updateWalletLoading }] = useMutation(UPDATE_WALLET, {
    onError: (error) => setRequestError(error, 'updateWallet'),
    onCompleted: (response) => {
      const apiErrors = response?.updateWallet?.[0]?.errors;
      if (apiErrors) {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'error',
          message: CREATE_WALLET_API_VALIDATION_ERROR_MESSAGE.body(),
        });
        dispatchAction('SET_API_VALIDATION_ERRORS', apiErrors);
      } else {
        history.push({
          pathname: `/bm/wallet`,
          search: window.location.search,
          state: {
            updatedWallet: response?.updateWallet?.[0],
          },
        });
      }
    },
  });

  const [getAgenciesByPermission, { loading: agenciesLoading }] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'getAgenciesByPermission'),
    onCompleted: (responseData) => {
      if (responseData?.getAgenciesByPermission) {
        dispatchAction('SET_AGENCIES', responseData.getAgenciesByPermission);
      }
    },
  });

  const [getBureausByPermission, { loading: bureausLoading }] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'getBureausByPermission'),
    onCompleted: (responseData) => {
      if (responseData?.getBureausByPermission) {
        dispatchAction('SET_BUREAUS', responseData.getBureausByPermission);
      }
    },
  });

  const [getScopedBoacs, { loading: scopedBoacsLoading }] = useLazyQuery(GET_BOACS, {
    fetchPolicy: 'network-only',
    onError: (error) => setRequestError(error, 'getScopedBoacs'),
    onCompleted: (responseData) => {
      if (responseData?.getBoacs) {
        dispatchAction('SET_SCOPED_BOACS', responseData.getBoacs);
      }
    },
  });

  const [getIpacVehiclesList, { loading: ipacVehiclesLoading, refetch: refetchIpacVehicles }] = useLazyQuery(
    GET_IPACS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'getScopedBoacs'),
      onCompleted: (responseData) => {
        if (responseData?.getIpacs) {
          dispatchAction('SET_WALLET_VEHICLES', responseData.getIpacs);
        }
      },
    },
  );

  // create IPAC vehicle
  const [createIpacVehicle, { loading: createIpacVehicleLoading }] = useMutation(CREATE_IPAC_VEHICLE, {
    onError: (error) => setRequestError(error, 'walletIpacVehiclesModal'),
    onCompleted: (response) => {
      dispatchAction('SET_UPDATED_IPAC_VEHICLE', response?.createIpacVehicle);
      refetchIpacVehicles();
    },
  });

  // delete IPAC vehicle
  const [deleteIpacVehicle, { loading: deleteIpacVehicleLoading }] = useMutation(DELETE_IPAC_VEHICLE, {
    onError: (error) => setRequestError(error, 'walletIpacVehiclesModal'),
    onCompleted: (response) => {
      refetchIpacVehicles();
      dispatchAction('SET_DELETED_IPAC_VEHICLE', response?.deleteIpacVehicle);
    },
  });

  const [exportIpacTemplate, { loading: exportIpacTemplateLoading }] = useLazyQuery(EXPORT_IPAC_TEMPLATE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'getScopedBoacs'),
    onCompleted: (responseData) => {
      if (responseData?.exportIpacTemplate) {
        dispatchAction('SET_IPAC_EXPORT', responseData.exportIpacTemplate);
      }
    },
  });

  const [importIpac, { loading: importIpacLoading }] = useMutation(IMPORT_IPAC, {
    onError: (error) => setRequestError(error, 'ipacImportModal'),
    onCompleted: (response) => {
      dispatchAction('SET_UPDATED_IPAC_VEHICLE', response?.createIpacVehicle);
      refetchIpacVehicles();
    },
  });

  const setFiscalYear = (fiscalYear) => {
    dispatchAction('SET_FISCAL_YEAR', fiscalYear);
  };

  return (
    <WalletDetailsContext.Provider
      value={{
        ...state,
        dispatchAction,
        getWalletById,
        walletLoading,
        createWallet,
        createWalletLoading,
        getAgenciesByPermission,
        agenciesLoading,
        getBureausByPermission,
        bureausLoading,
        updateWallet,
        updateWalletLoading,
        getScopedBoacs,
        scopedBoacsLoading,
        validateBoacOffering,
        validatingBoacOffering,
        getIpacVehiclesList,
        ipacVehiclesLoading,
        createIpacVehicle,
        createIpacVehicleLoading,
        deleteIpacVehicle,
        deleteIpacVehicleLoading,
        exportIpacTemplate,
        exportIpacTemplateLoading,
        importIpac,
        importIpacLoading,
        setFiscalYear,
        ...props,
      }}
    >
      {children}
    </WalletDetailsContext.Provider>
  );
}

export default WalletDetailsProvider;

WalletDetailsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useWalletDetails = () => useContext(WalletDetailsContext);
