import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import { CommentModal } from 'components/modals/comment-modal/comment-modal';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import {useMutation} from '@apollo/client';
import {ADD_OR_UPDATE_WS_ERR_COMMENT, ADD_OR_UPDATE_FF_ERR_COMMENT} from '../../services/data-layer/shared-billing';
import {WEB_SERVICES} from './pegasys-common-util';
import { usePegasysContext } from './pegasys-provider';

const PegasysErrorCommentModal = ({ onClose, data: { controlLogId, documentNumber, comment }, setBannerMsg, serviceType }) => {
    const [commentToUpdate, setCommentToUpdate] = useState(comment);
    const [commentsCount, setCommentsCount] = useState(500);
    const [errors, setErrors] = useState(null);
    const [saveError, setSaveError] = useState(null);

    const { setWsErrResponse, wsErrResponse, setBatchErrResponse, batchErrResponse } = usePegasysContext();
    const modalTitle = 'Add comment';
    const query = serviceType === WEB_SERVICES ? ADD_OR_UPDATE_WS_ERR_COMMENT : ADD_OR_UPDATE_FF_ERR_COMMENT;
    const [updateErrorComment, { loading: commenting }] = useMutation(query, {
        onCompleted: () => {
            const message = (
              <>
                You have successfully updated comment for <b>{documentNumber}</b>.
              </>
            );
            setBannerMsg({ type: 'success', message });
            onClose();

        },
        onError: (err) => {
            setSaveError(
              <>
                Error occurred when updating the comment for {documentNumber}. Please try again later.
                <br />
                {err.message}
              </>,
            );
            setBannerMsg({ type: 'error', saveError });
            onClose();

        }
        });

    const onSubmit = () => {
      let updatedVariables = {};

      if (serviceType === WEB_SERVICES) {
        updatedVariables = { pegasysSoapControlLogId: controlLogId };
        const wsUpdatedData = wsErrResponse.map((li) => {
          const errorObj = li;
          if (errorObj.pegasysSoapControlLogId === controlLogId) {
            if (errorObj?.wsControlLogActionModel?.comment) {
              errorObj.wsControlLogActionModel.comment = commentToUpdate;
            }
          }
          return errorObj;
        });
        setWsErrResponse(wsUpdatedData);
      } else {
        updatedVariables = { batchCorrelationMapId: controlLogId };
        const ffUpdatedData = batchErrResponse.map((li) => {
          const errorObj = li;
          if (errorObj.id === controlLogId) {
            if (errorObj?.batchCorrelationMapActionModel?.comment) {
              errorObj.batchCorrelationMapActionModel.comment = commentToUpdate;
            }
          }
          return errorObj;
        });
        setBatchErrResponse(ffUpdatedData);

      }

      setCommentToUpdate(commentToUpdate)
      const variables = { ...updatedVariables, comment: commentToUpdate };
      updateErrorComment({ variables });
      onClose();
    };


    const onCommentChange = (e) => {
        const { value } = e.target;
        setCommentToUpdate(value);
        setCommentsCount(500 - value.length);
        if (commentToUpdate.length > 1) setErrors(false);
    };

    return (
      <CommentModal
        id='pegasys-error-delete-modal'
        title={modalTitle}
        onClose={onClose}
        onComment={onSubmit}
        commenting={commenting}
        commentButtonText='Submit'
      >
        <p className='margin-top-0 margin-bottom-5' data-testid='comment-modal-body'>
          Enter Status Information Below
        </p>
        <div className={classNameErrBox(errors)}>
          <Label htmlFor='activation-comment' required>
            Please provide your comment
          </Label>
          {errors && <ErrorMessage>Please provide your comment</ErrorMessage>}
          <Textbox
            name='comment'
            className={classNameErrInput(errors)}
            id='activation-comment'
            data-testid='activation-comment-input'
            value={commentToUpdate}
            type='textarea'
            maxLength={500}
            rows={3}
            onChange={onCommentChange}
            onBlur={(e) => {if (!e.target.value) setErrors(true);}}
          />
          <div className='usa-hint usa-character-count__message'>
            {commentsCount < 500 ? `${commentsCount} characters remaining` : '500 characters allowed'}
          </div>
        </div>
      </CommentModal>
    );
};

PegasysErrorCommentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.shape({
        controlLogId: PropTypes.number.isRequired,
        documentNumber: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired
    }).isRequired,
    setBannerMsg: PropTypes.func.isRequired,
    serviceType: PropTypes.string.isRequired
};

export default PegasysErrorCommentModal;
