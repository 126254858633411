import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { GET_WALLETS_LIST } from './wallet.gql';


export const WalletContext = createContext();

const initialState = {
  walletsList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { message: '', type: '', header: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_WALLETS_LIST':
      return { ...state, walletsList: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };  
    default:
      throw new Error('Invalid action');
  }
};

function WalletProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
    });
  };

  // Get wallets pools list.
  const [getWalletsList, { loading: walletsListLoading }] = useLazyQuery(GET_WALLETS_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'walletListing'),
    onCompleted: (responseData) => {
      if (responseData?.getWallets) {
        dispatchAction('SET_WALLETS_LIST', responseData.getWallets);
      }
    },
  });

  return (
    <WalletContext.Provider
      value={{
        ...state,
        getWalletsList,
        walletsListLoading,
        dispatchAction,
        ...props,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export default WalletProvider;

WalletProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useWallet = () => useContext(WalletContext);
