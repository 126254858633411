import React, { useState, useRef } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import WelcomeMessage from 'widgets/welcome-message';
import AfvFundingPage from '../afv-funding-table';
import '../style/afv-funding.scss';

const TITLE = 'AFV Funding Summary';

const AfvFundingContent = () => {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const bannerRef = useRef(null);

  // Banner message
  const setBannerMsg = (message) => {
    setMsg(message);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="grid-col margin-bottom-6">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <div ref={bannerRef}>
        {msg && (
          <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
            {msg.message}
          </BannerMessage>
        )}
      </div>
      <BannerMessage type="info" showClose={false} className="margin-top-2">
        Data accurate as of {new Date().toLocaleString()}
      </BannerMessage>
      <PageTitle title={TITLE} />
      <WelcomeMessage
        className="leaseintro"
        message="Use this page to view the AFV funding summary, and to navigate to
          the AFV Surcharge and Funding page where you can manage the AFV surcharge,
          and view the AFV surcharge history."
      />
      <AfvFundingPage setBannerMsg={setBannerMsg} />
    </div>
  );
};

export default AfvFundingContent;
