import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Label, DatePicker, ErrorMessage, Textbox, Alert, Icon, Spinner } from '@gsa/afp-component-library';
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { STATUS } from 'components/helpers/constants';
import { UTCDateStrToUS, getTomorrowDate, getTomorrowStr, USDateStrToUTC } from 'components/helpers/afp-bm-date';
import { classNameErrBox, classNameErrInput, classNameErrDatePicker } from 'components/helpers/afp-bm-helpers';
import useCharCounter from 'components/widgets/char-counter';
import {
  URTypeMatchList,
  IsShowFieldBlock,
  IsURFieldDisabled,
  IsURFieldRequired,
  GET_DISTINCT_UNIQUE_RATE_TYPE,
  GET_UNIQUE_RATE_BY_START_DATE,
  UPDATE_UNIQUE_RATE,
  CREATE_UNIQUE_RATE,
} from './unique-rate-helper';
import './style/unique-rate-update-modal.scss';


const MAX_LEN_DESC = 200;
const MAX_LEN_TYPE = 25;
const tomorrowDate = getTomorrowDate();
const tomorrowStr = getTomorrowStr();

// Form validation schema
const schema = yup.object().shape({
  uniqueRateType: yup
    .string()
    .required('Type is a required field')
    .matches('^[a-zA-Z0-9 -]{1,50}$', "Type must be no more than 50 characters of letters, numbers, space, or '-'.")
    .when('isCreate', {
      is: true,
      then: yup
        .string()
        .test(
          'check duplicate',
          'Type already exists.',
          (val, ctx) => !URTypeMatchList(val?.trim(), ctx.parent.distinctTypes),
        ),
    }),
  uniqueRateDesc: yup
    .string()
    .required('Description is a required field')
    .max(200, 'Description cannot exceed 200 characters'),
  monthlyRateFactor: yup
    .string()
    .nullable()
    .matches(/(^\d+\.?\d*$)|(^\d*\.?\d+$)/, 'Invalid decimal number')
    .when(['uniqueRateType'], {
      is: (uniqueRateType) => IsURFieldRequired('monthlyRateFactor', uniqueRateType),
      then: yup.string().nullable().required('Monthly rate is a required field'),
    }),
  mileageRateFactor: yup
    .string()
    .nullable()
    .matches(/(^\d+\.?\d*$)|(^\d*\.?\d+$)/, 'Invalid decimal number')
    .when(['uniqueRateType'], {
      is: (uniqueRateType) => IsURFieldRequired('mileageRateFactor', uniqueRateType),
      then: yup.string().nullable().required('Mileage rate is a required field'),
    }),
  thresholdAmount: yup
    .string()
    .nullable()
    .matches(/^[1-9]\d*$/, 'Please enter a whole dollar amount')
    .when(['uniqueRateType'], {
      is: (uniqueRateType) => IsShowFieldBlock('threshold', uniqueRateType),
      then: yup.string().nullable().required('Threshold amount is a required field'),
    }),
  startDate: yup.date().when(['isCreate', 'hasFieldChanged'], {
    is: (isCreate, hasFieldChanged) => isCreate || hasFieldChanged,
    then: yup
      .date()
      .min(tomorrowDate, 'Start date must be a future date')
      .required('Start date must be updated as any rate changes')
      .typeError('Please enter a start date for this unique rate'),
  }),
});

// Update Modal Component
const UniqueRateUpdateModal = ({ onClose, setBannerMsg, data, isCreate }) => {
  if (!hasSomeAbilitiesTo([ROLE_OP.UPDATE, ROLE_OP.CREATE], ENTITY.UNIQUE_RATE)) onClose();

  const {
    uniqueRateType,
    rateStatus,
    uniqueRateDesc,
    monthlyRateFactor,
    mileageRateFactor,
    thresholdAmount,
    exceedsThreshold,
    startDate,
  } = data;

  const [saveError, setSaveError] = useState(null);
  
  const [typeCounter, setTypeCount] = useCharCounter(MAX_LEN_TYPE);
  const [descCounter, setDescCount] = useCharCounter(MAX_LEN_DESC);
  // useForm declaration
  const { register, handleSubmit, errors, setError: setFormError, control, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      uniqueRateType,
      uniqueRateDesc,
      monthlyRateFactor,
      mileageRateFactor,
      thresholdAmount,
      exceedsThreshold,
      startDate: startDate || tomorrowStr,
    },
  });

  // gql calls
  const [checkStartDate, { loading: loadingCheckStartDate }] = useLazyQuery(GET_UNIQUE_RATE_BY_START_DATE, {
    onCompleted: (resData) => {
      if (resData?.getUniqueRateByStartDate) {
        setFormError('startDate', { message: 'A rate already exists for the start date' }, { shouldFocus: true });
      } else {
        // eslint-disable-next-line
        createOrUpdateUR();
      }
    },
    onError: (err) => {
      setSaveError(
        <>
          Network error. Please try again later.
          <br />
          {err.message}
        </>,
      );
    },
  });

  const [updateUniqueRate, { loading: loadingUpdate }] = useMutation(UPDATE_UNIQUE_RATE, {
    onCompleted: (resData) => {
      const { updateOrCreateUniquePendingRate: urData } = resData;
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully updated unique rate <b>{urData.uniqueRateType}</b> which will take effect on{' '}
            {UTCDateStrToUS(urData.startDate)}.
          </>
        ),
      });
      onClose(true); // doRefetch=true
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occured when updating the unique rate. Please try again later.
          <br />
          {err.message}
        </>,
      );
    },
  });

  const [createUniqueRate, { loading: loadingCreate }] = useMutation(CREATE_UNIQUE_RATE, {
    onCompleted: (resData) => {
      const { createNewUniqueRate: urData } = resData;
      setBannerMsg({
        type: 'success',
        message: (
          <>
            You have successfully created unique rate type <b>{urData.uniqueRateType}</b> which will take effect on{' '}
            {UTCDateStrToUS(urData.startDate)}.
          </>
        ),
      });
      onClose(true); // doRefetch=true
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occured when creating the unique rate type.
          <br />
          {err.message}
        </>,
      );
      setBannerMsg({
        type: 'error',
        message: (
          <>
            Error occured when creating unique rate type <b>{getValues('uniqueRateType')}</b> with start date of{' '}
            {UTCDateStrToUS(getValues('startDate'))}.
            <br />
            {err.message}
          </>
        ),
      });
      onClose(true); // doRefetch=true
    },
  });

  const [fetchDistincTypes, { loading: loadingDistinctTypes }] = useLazyQuery(GET_DISTINCT_UNIQUE_RATE_TYPE, {
    onCompleted: (resData) => {
      setValue(
        'distinctTypes',
        resData.getDistinctUniqueRate.map((d) => d.uniqueRateType),
      );
    },
    onError: (err) => {
      setSaveError(
        <>
          Error occured when retrieving existing unique rate types.
          <br />
          {err.message}
        </>,
      );
    },
  });

  useEffect(() => {
    register({ name: 'isCreate' });
    register({ name: 'distinctTypes' });
    register({ name: 'hasFieldChanged' });
    setValue('isCreate', isCreate);
    setValue('distinctTypes', []);
    setValue('hasFieldChanged', false);

    if (isCreate) fetchDistincTypes();
    
    if (data) setDescCount(uniqueRateDesc ? uniqueRateDesc.length : 0);
  }, []);

  const createOrUpdateUR = () => {
    const {
      uniqueRateType: uniqueRateTypeSubmit,
      uniqueRateDesc: uniqueRateDescSubmit,
      monthlyRateFactor: monthlyRateFactorSubmit,
      mileageRateFactor: mileageRateFactorSubmit,
      thresholdAmount: thresholdAmountSubmit,
      exceedsThreshold: exceedsThresholdSubmit,
      startDate: startDateSubmit,
    } = getValues();
    const updateURData = {
      variables: {
        uniqueRateType: uniqueRateTypeSubmit.trim(),
        uniqueRateInput: {
          uniqueRateDesc: uniqueRateDescSubmit,
          monthlyRateFactor: monthlyRateFactorSubmit,
          mileageRateFactor: mileageRateFactorSubmit,
          thresholdAmount: thresholdAmountSubmit,
          exceedsThreshold: exceedsThresholdSubmit,
          startDate: startDateSubmit,
        },
      },
    };

    if (isCreate) {
      createUniqueRate({ ...updateURData });
    } else {
      updateUniqueRate(updateURData);
    }
  };

  const onFieldChange = (fieldName, val) => {
    setValue(fieldName, val || null);
    setValue('hasFieldChanged', true);
  };

  const onSubmit = () => {
    const newStartDate = getValues('startDate');
    if (isCreate || rateStatus === STATUS.NEW || newStartDate === startDate) {
      createOrUpdateUR();
    } else {
      // create new pending rate - check for duplicate first
      const query = {
        uniqueRateType,
        startDate: newStartDate,
      };
      checkStartDate({ variables: query });
    }
  };

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const showErrorMessage = () => {
    if (saveError)
      return (
        <Alert slim type="error" style={{ marginBottom: 18 }}>
          {saveError}
        </Alert>
      );

    if (Object.keys(errors).length)
      return (
        <Alert slim focused={false} type="error" style={{ marginBottom: 18 }}>
          You have {Object.keys(errors).length} error on this form. Please correct before saving.
        </Alert>
      );

    return null;
  };

  const onDescriptionChange = (e, name) => {
    const { value } = e.target;
    setValue(name, value || null);
    setValue('hasFieldChanged', true);

    if (name === 'uniqueRateType') {
      setTypeCount(value.length);
      
    } else if (name === 'uniqueRateDesc') setDescCount(value.length);
  };

  return (
    <EditModal
      id="unique-rate-update"
      title={isCreate ? 'Create a New Unique Rate' : `Update Unique Rate: ${uniqueRateType}`}
      onClose={onClose}
      formId="unique-rate-update-form"
    >
      {loadingDistinctTypes || loadingCheckStartDate || loadingCreate || loadingUpdate ? (
        <div
          id="loading-spinner"
          style={{
            position: 'fixed',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.15)',
          }}
        >
          <div style={{ margin: '300px auto' }}>
            <Spinner />
          </div>
        </div>
      ) : null}
      {showErrorMessage()}
      <p>
        {isCreate
          ? `Use this window to create a new unique rate. You may apply rate factors if needed.`
          : `Use this window to update rate factors and other details. Saving an update with a start date in the future will generate a pending rate record.`}
      </p>

      <form onSubmit={handleSubmit(onSubmit)} id="unique-rate-update-form">
        <div className="form-ra">
          <div className="grid-container">
            <div style={isCreate ? {} : { display: 'none' }}>
              <div className="grid-col-8">
                <Controller
                  control={control}
                  name="uniqueRateType"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={classNameErrBox(errors[name])}>
                      <Label htmlFor="unique-rate-type" required>
                        Enter a new unique rate type
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Textbox
                        id="unique-rate-type"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter unique rate type"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          onDescriptionChange(e, name)
                          onBlur();
                        }}
                        onBlur={onBlur}
                      />
                      {typeCounter}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="grid-row">
              <div className="grid-col-12">
                <Controller
                  control={control}
                  name="uniqueRateDesc"
                  render={({ name, value, onChange, onBlur }) => (
                    <div className={`usa-form-group ${classNameErrBox(errors[name])}`}>
                      <Label htmlFor="description" required>
                        Description
                      </Label>
                      {createErrorMessage(errors[name])}
                      <Textbox
                        id="description"
                        className={classNameErrInput(errors[name])}
                        aria-label="enter unique rate description"
                        name={name}
                        type="textarea"
                        rows={2}
                        maxLength={200}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          onDescriptionChange(e, name);
                        }}
                        onBlur={onBlur}
                      />
                      {descCounter}
                    </div>
                  )}
                />
              </div>
            </div>

            {IsShowFieldBlock('threshold', uniqueRateType) && (
              <div className="grid-row grid-gap">
                <div className="grid-col-4">
                  <Controller
                    control={control}
                    name="thresholdAmount"
                    render={({ name, value, onBlur }) => (
                      <div className={classNameErrBox(errors[name])}>
                        <Label htmlFor="threshold-amount" required>
                          Threshold amount
                        </Label>
                        {createErrorMessage(errors[name])}
                        <Icon
                          iconName="attach_money"
                          className={`bm-prefix-icon-dollar ${errors[name] && 'bm-prefix-icon-dollar-error'}`}
                        />
                        <Textbox
                          id="threshold-amount"
                          className={classNameErrInput(errors[name])}
                          aria-label="enter high cost threshold amount"
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onFieldChange(name, e.target.value);
                          }}
                          onBlur={onBlur}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            )}

            {IsShowFieldBlock('factors', uniqueRateType) && (
              <div className="grid-row grid-gap bm-form-row">
                <div className="grid-col-4">
                  <Controller
                    control={control}
                    name="monthlyRateFactor"
                    render={({ name, value, onBlur }) => (
                      <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                        <Label
                          htmlFor="monthly-rate-factor"
                          required={IsURFieldRequired('monthlyRateFactor', uniqueRateType)}
                        >
                          Monthly rate factor
                        </Label>
                        {createErrorMessage(errors[name])}
                        <Textbox
                          id="monthly-rate-factor"
                          className={classNameErrInput(errors[name])}
                          aria-label="enter monthly rate factor"
                          name={name}
                          disabled={IsURFieldDisabled(name, uniqueRateType)}
                          value={value}
                          onChange={(e) => onFieldChange(name, e.target.value)}
                          onBlur={onBlur}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="grid-col-4 rates">
                  <Controller
                    control={control}
                    name="mileageRateFactor"
                    render={({ name, value, onBlur }) => (
                      <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                        <Label
                          htmlFor="mileage-rate-factor"
                          required={IsURFieldRequired('mileageRateFactor', uniqueRateType)}
                        >
                          Mileage rate factor
                        </Label>
                        {createErrorMessage(errors[name])}
                        <Textbox
                          id="mileage-rate-factor"
                          className={classNameErrInput(errors[name])}
                          aria-label="enter mileage rate factor"
                          name={name}
                          disabled={IsURFieldDisabled(name, uniqueRateType)}
                          value={value}
                          onChange={(e) => onFieldChange(name, e.target.value)}
                          onBlur={onBlur}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            )}

            <div className="grid-row grid-gap">
              <div className="grid-col-4" style={{ minWidth: 300 }}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ name, value, onBlur }) => (
                    <div className={classNameErrBox(errors[name])}>
                      <Label
                        htmlFor="start-date"
                        required={!IsURFieldDisabled('startDate', uniqueRateType) || rateStatus === STATUS.PENDING}
                      >
                        Start date
                      </Label>
                      <div className="usa-hint" id="start-date-hint">
                        mm/dd/yyyy
                      </div>
                      {createErrorMessage(errors[name])}
                      <DatePicker
                        id="start-date"
                        className={classNameErrDatePicker(errors[name])}
                        aria-label="enter start date"
                        name={name}
                        defaultValue={value < tomorrowStr ? null : value}
                        placeholder={UTCDateStrToUS(value)}
                        disabled={IsURFieldDisabled(name, uniqueRateType) || rateStatus === STATUS.PENDING}
                        minDate={tomorrowStr}
                        onChange={(val) => {
                          onFieldChange(name, USDateStrToUTC(val));
                          onBlur();
                        }}
                        onBlur={onBlur}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </EditModal>
  );
};

UniqueRateUpdateModal.defaultProps = {
  data: {},
  isCreate: false,
};

UniqueRateUpdateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
  data: PropTypes.shape({
    uniqueRateType: PropTypes.string.isRequired.nullable,
    rateStatus: PropTypes.string.isRequired.nullable,
    uniqueRateDesc: PropTypes.string.isRequired.nullable,
    monthlyRateFactor: PropTypes.string.isRequired.nullable,
    mileageRateFactor: PropTypes.string.isRequired.nullable,
    startDate: PropTypes.string.isRequired.nullable,
    thresholdAmount: PropTypes.string.isRequired.nullable,
    exceedsThreshold: PropTypes.string.isRequired.nullable,
  }),
  isCreate: PropTypes.bool,
};

export default UniqueRateUpdateModal;
