import numeral from 'numeral';
import _, { isNaN, isNull, isNil, mapValues, transform, startCase, curry, keyBy, flow, isFinite } from 'lodash/fp';
import { stateValue } from 'utilities/stateUtils';
import moment from 'moment-timezone';

export const mapValuesWithKey = mapValues.convert({ cap: false });

export const transformWithKey = transform.convert({ cap: false });

export const setHeaderCell = ({ value }) => startCase(value);

export const arrayToObject = curry((k, v, a) => flow(keyBy(k), mapValues(v))(a));

export const toNumValue = (num) => numeral(num).value();

export const safeParseFloat = (num, defaultVal = null) => {
  if (isNil(num) || isNull(num)) {
    return defaultVal;
  }
  if (_.isString(num)) {
    const parsedNum = toNumValue(num);
    return isFinite(parsedNum) ? parsedNum : defaultVal;
  }

  if (_.isNumber(num)) {
    return isFinite(num) ? num : defaultVal;
  }
  return defaultVal;
};

export const textToFloat = (text, decimal = 2) => {
  const parsed = toNumValue(text);
  if (isNaN(parsed)) {
    return null;
  }
  return parsed.toFixed(decimal);
};

export const toCurrency = (num) => numeral(num).format('$0,0.00');
export const toPercentage = (num) => numeral(num).format('0,0.00%');

export const displayValue = (rateType, rateValue) => {
  if (rateType === '%') {
    return textToFloat(rateValue * 100);
  }
  return rateValue;
};

const zeroBuilder = (length) => {
  return new Array(length + 1).join('0');
};

export const toFixedFn = (number, decimal = 2) =>
  decimal === 0 ? numeral(number).format('0') : numeral(number).format(`0.${zeroBuilder(decimal)}`);

// string-> string
export const labelText = _.flow(_.startCase, _.capitalize);

export const labelTextFromPath = _.flow(_.replace('.', '-'), labelText);

export const omitDeep = _.curry((omitField, obj) =>
  mapValuesWithKey((val) => {
    if (_.isObject(val)) {
      // eslint-disable-next-line
      delete val[`${omitField}`];
      omitDeep(omitField, val);
    }
    return val;
  }, obj),
);

export const autoLabelOption = (x) => {
  const value = _.isNil(x.value) ? x : x.value;
  return { value, label: x.label || _.startCase(value) };
};

// [x]->[{value:x,label:x}]
export const autoLabelOptions = _.map(autoLabelOption);

export const getHeaderCase = (string) => _.flow(_.split(' '), _.map(_.upperFirst), _.join(' '))(string);

// pageState -> string
export const getHeaderCaseState = _.flow(stateValue, getHeaderCase);

// pageState -> string
export const pageStateStartCase = _.flow(stateValue, _.startCase);

// {key:val}->val
export const firstValue = _.flow(_.values, _.head);

// bool->{key:val}-> bool
export const hasValue = (bool = true) => _.flow(_.values, _.some(_.isEqual(bool)));

// !!! will turn on the mutable, please make sure you 100% understand why you want to use it(e.g want to use mutable to optimize the js speed and you are 100% it will not cause problem) before you use it
const mutable = _.convert({ immutable: false });

export const mutableMerge = mutable.merge;

export const utcDateFormat = 'YYYY-MM-DDThh:mm:ssZ';
export const MMDDYYYDateFormat = 'MM/DD/YYYY';
export const shortDateFormat = 'YYYY-MM-DD';

export const isRightFormatDate = ({ dateVal, format = MMDDYYYDateFormat }) => moment(dateVal, format, true).isValid();

export const ToUTCFormatDate = ({ dateVal, format = null }) => moment.utc(dateVal).format(format);

// we will expend the function in the future now it is only trim the space
export const trimmedVal = (val) => _.trimStart(val);
