import { gql } from '@apollo/client';

export const VALIDATE_ADDRESS = gql`
  query ValidateAddress($address: String!, $address2: String, $city: String!, $state: String!, $zip: String!) {
    validateAddress(address: $address, address2: $address2, city: $city, state: $state, zip: $zip) {
      address
      address2
      city
      state
      zip
      updates
    }
  }
`;
