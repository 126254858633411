import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

export const EditModal = ({
  id,
  variant,
  title,
  onClose,
  formId,
  subtitle,
  children,
  saveButtonText,
  cancelButtonText,
  customizeButtonText,
  showAlert,
  alert,
  disabledSaveButton,
  customizeButtonProps,
}) => (
  <div className="afp-modal-wrapper" id={id}>
    <div className="afp-modal-overlay">
      <Modal
        showAlert={showAlert}
        alert={alert}
        variant={variant}
        title={
          <>
            <h1 className="margin-bottom-0 margin-top-6">{title}</h1>
            {subtitle && <h1 className="margin-bottom-0 margin-top-0">{subtitle}</h1>}
          </>
        }
        onClose={onClose}
        actions={
          <>
            <Button variant="unstyled" onClick={onClose} id="cancel-btn" label={cancelButtonText} />
            {customizeButtonText ? (
              <Button {...customizeButtonProps} label={customizeButtonText} />
            ) : (
              <Button type="submit" form={formId} disabled={disabledSaveButton} label={saveButtonText} />
            )}
          </>
        }
      >
        {children}
      </Modal>
    </div>
  </div>
);

EditModal.defaultProps = {
  variant: 'extra-large',
  formId: '',
  subtitle: '',
  children: null,
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
  customizeButtonText: null,
  customizeButtonProps: null,
  showAlert: false,
  alert: null,
  disabledSaveButton: false,
};

EditModal.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  formId: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  saveButtonText: PropTypes.string,
  customizeButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  customizeButtonProps: PropTypes.instanceOf(Object),
  showAlert: PropTypes.bool,
  alert: PropTypes.element,
  disabledSaveButton: PropTypes.bool,
};
