import { gql } from '@apollo/client';

const PaginatedOptInCategoryResponse = gql`
  fragment PaginatedOptInCategoryFields on PaginatedOptInCategoryResponse {
    rows {
      rateStatus
      optInCategoryName
      optInCategoryDesc
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      dataSource
      dmlFlag
      comment
      lastUpdateUserInfo {
        fullName
        email
      }
    }
    count
    hasMore
  }
`;

export const GET_OPT_IN_CATEGORY = gql`
  query getOptInCategory($virtualFilter: FilterType, $filters: FilterType, $offset: Int, $limit: Int, $order: OrderBy) {
    getOptInCategory(virtualFilter: $virtualFilter, filters: $filters, offset: $offset, limit: $limit, order: $order) {
      ...PaginatedOptInCategoryFields
    }
  }
  ${PaginatedOptInCategoryResponse}
`;
