import React, { useState, useRef } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import WelcomeMessage from 'widgets/welcome-message';
import UniqueRatePage from '../unique-rate-table';

const TITLE = 'Unique Lease Rates';

const UniqueRateContent = () => {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const bannerRef = useRef(null);

  // callback function for child component to set banner message
  const onSetMsg = (message) => {
    setMsg(message);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="grid-col margin-bottom-6">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <div ref={bannerRef}>
        {msg && (
          <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
            {msg.message}
          </BannerMessage>
        )}
      </div>
      <PageTitle title={TITLE} />
      <WelcomeMessage
        className="leaseintro"
        message="You may use the unique lease rates table to view and update existing unique rates. You may also create new unique rates. "
      />
      <UniqueRatePage setBannerMsg={onSetMsg} />
    </div>
  );
};

export default UniqueRateContent;
