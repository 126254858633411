import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, SelectBox, ErrorMessage } from '@gsa/afp-component-library';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';

export const CUSTOMERS = [
  { value: '', label: '-- Select a customer --' },
  { value: '9901700', label: '9901700' },
  { value: '9902161', label: '9902161' },
  { value: '9902108', label: '9902108' },
  { value: '9903609', label: '9903609' },
  {
    value: '010100012031301160914114421',
    label: '010100012031301160914114421',
  },
  {
    value: '010100012031302161103144054',
    label: '010100012031302161103144054',
  },
  {
    value: '010100012031302161104065202',
    label: '010100012031302161104065202',
  },
  {
    value: '010100012031305161123081220',
    label: '010100012031305161123081220',
  },
  { value: '0101000210340010000000000', label: '0101000210340010000000000' },
];

const schema = yup.object().shape({
  customerNumber: yup.string(),
  contactName: yup
    .string()
    .nullable()
    .matches(/^[A-Za-z.-\s,]+$/, 'Invalid name'),
  contactEmail: yup.string().email('Invalid email'),
  contactPhone: yup.string().matches(/^[0-9]{10}$|^\+[0-9]{8,}$|^$/, 'Invalid phone number'),
});

const CustomerForm = (props) => {
  const {
    id,
    customerDisabled,
    contactDisabled,
    defaultValues,
    agencyOptions,
    bureauOptions,
    onChangeCustomer,
    onChange: onFormChange,
    onError: onFormError,
  } = props;

  // useForm declaration
  const {
    errors,
    // setError: setFormError,
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const errorsLen = Object.keys(errors).length;
  React.useEffect(() => {
    onFormError(errorsLen);
  }, [errorsLen]);

  const genId = (fieldName) => {
    if (id) return `${id}-${fieldName}`;
    return fieldName;
  };

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const handleFieldChange = (fieldName, value) => {
    onFormChange({
      ...getValues(),
      [fieldName]: value || '',
    });
  };

  const renderTextbox = ({ name, value, onChange, onBlur }) => {
    const ariaLabel = name.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    return (
      <div>
        <input
          id={genId(name)}
          data-testid={genId(name)}
          className={`usa-input ${classNameErrInput(errors[name])}`}
          aria-label={ariaLabel}
          disabled={contactDisabled}
          name={name}
          value={value || ''}
          onChange={(e) => {
            handleFieldChange(name, e.target.value);
            onChange(e.target.value);
          }}
          onBlur={onBlur}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="grid-row grid-gap">
        <div className="grid-col-3">
          <Label required className="margin-top-2">
            Customer Number
          </Label>
          <div className={errors.customerNumber && 'border-left-1 border-red padding-left-2'}>
            <Controller
              control={control}
              name="customerNumber"
              defaultValue=""
              render={({ name, value, onChange, onBlur }) => {
                if (customerDisabled) return <div className="margin-top-2">{value}</div>;
                return (
                  <div>
                    <SelectBox
                      id={genId(name)}
                      data-testid={genId(name)}
                      aria-label="select customer number"
                      options={CUSTOMERS}
                      name={name}
                      value={value}
                      onChangeFunc={(e) => {
                        onChangeCustomer(e.target.value);
                        setValue(name, e.target.value);
                        onChange(e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  </div>
                );
              }}
            />
            {createErrorMessage(errors.customerNumber)}
          </div>
        </div>
        <div className="grid-col-4">
          <Label required className="margin-top-2">
            Agency
          </Label>
          <div className={errors.customerAgency && 'border-left-1 border-red padding-left-2'}>
            <Controller
              control={control}
              name="customerAgency"
              defaultValue=""
              render={({ name, value }) => (
                <div>
                  <SelectBox
                    id={genId(name)}
                    data-testid={genId(name)}
                    aria-label="customer agency"
                    options={agencyOptions}
                    name={name}
                    value={value || ''}
                  />
                </div>
              )}
            />
            {createErrorMessage(errors.customerAgency)}
          </div>
        </div>
        <div className="grid-col-4">
          <Label required className="margin-top-2">
            Bureau
          </Label>
          <div className={errors.customerBureau && 'border-left-1 border-red padding-left-2'}>
            <Controller
              control={control}
              name="customerBureau"
              defaultValue=""
              render={({ name, value }) => (
                <div>
                  <SelectBox
                    id={genId(name)}
                    data-testid={genId(name)}
                    aria-label="customer bureau"
                    options={bureauOptions}
                    name={name}
                    value={value || ''}
                  />
                </div>
              )}
            />
            {createErrorMessage(errors.customerBureau)}
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-3 factors">
          <div className={classNameErrBox(errors.contactName)}>
            <Label required className="margin-top-2">
              Point of contact
            </Label>
            {createErrorMessage(errors.contactName)}
            <Controller control={control} name="contactName" defaultValue="" render={renderTextbox} />
          </div>
        </div>
        <div className="grid-col-4 factors">
          <div className={classNameErrBox(errors.contactEmail)}>
            <Label required className="margin-top-2">
              Email
            </Label>
            {createErrorMessage(errors.contactEmail)}
            <Controller control={control} name="contactEmail" defaultValue="" render={renderTextbox} />
          </div>
        </div>
        <div className="grid-col-4 factors">
          <div className={classNameErrBox(errors.contactPhone)}>
            <Label required className="margin-top-2">
              Phone
            </Label>
            {createErrorMessage(errors.contactPhone)}
            <Controller control={control} name="contactPhone" defaultValue="" render={renderTextbox} />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerForm.defaultProps = {
  id: 'ur-app-customer',
  customerDisabled: false,
  contactDisabled: false,
  defaultValues: {},
  agencyOptions: [],
  bureauOptions: [],
};

CustomerForm.propTypes = {
  id: PropTypes.string,
  customerDisabled: PropTypes.bool,
  contactDisabled: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  agencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  bureauOptions: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeCustomer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // setBannerMsg: PropTypes.func.isRequired,
};

export default CustomerForm;
