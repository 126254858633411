import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { STATUS } from 'components/helpers/constants';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import {
  DELETE_PURCHASE_RATE_TYPE,
  DEACTIVATE_PURCHASE_TYPE,
  REACTIVATE_PURCHASE_TYPE,
} from './components/graphql-queries';

const PurchaseRateDeleteModal = ({ onClose, data: { purchaseType, rateStatus, effectiveStartDate }, setBannerMsg }) => {
  const [comment, setComment] = useState('');
  const [commentsCount, setCommentsCount] = useState(200);
  const [errors, setErrors] = useState(null);

  // gql APIs
  const getOnCompletedFn = (verb) => () => {
    const message = (
      <>
        You have successfully {verb} <b>{purchaseType}</b>.
      </>
    );
    setBannerMsg({ type: 'success', message });
    onClose();
  };
  const getOnErrorFn = (verb) => (err) => {
    const message = (
      <>
        Error occurred when {verb} <b>{purchaseType}</b>
        <br />
        {err.message}
      </>
    );
    setBannerMsg({ type: 'error', message });
    onClose();
  };
  const [deletePurchaseType, { loading: deleting }] = useMutation(DELETE_PURCHASE_RATE_TYPE, {
    onCompleted: getOnCompletedFn('deleted'),
    onError: getOnErrorFn('deleting'),
  });
  const [deactivatePurchaseType, { loading: deactivating }] = useMutation(DEACTIVATE_PURCHASE_TYPE, {
    onCompleted: getOnCompletedFn('deactivated'),
    onError: getOnErrorFn('deactivating'),
  });
  const [reactivatePurchaseType, { loading: reactivating }] = useMutation(REACTIVATE_PURCHASE_TYPE, {
    onCompleted: getOnCompletedFn('reactivated'),
    onError: getOnErrorFn('reactivating'),
  });

  const VARIATION = {
    [STATUS.PENDING]: {
      title: 'Delete a Purchase Rate',
      instruction: 'By selecting the delete option below, you will be permanently deleting this pending purchase rate.',
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deletePurchaseType,
    },
    [STATUS.NEW]: {
      title: 'Delete a Purchase Type',
      instruction: 'By selecting the delete option below, you will be permanently deleting this purchase type.',
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deletePurchaseType,
    },
    [STATUS.ACTIVE]: {
      title: 'Deactivate a Purchase Type',
      instruction: (
        <>
          You are about to deactivate <b>{purchaseType}</b>. Please provide a reason for deactivation in the box below.
        </>
      ),
      buttonText: 'Deactivate',
      isReasonRequired: true,
      gqlAPI: deactivatePurchaseType,
    },
    [STATUS.INACTIVE]: {
      title: 'Reactivate a Purchase Type',
      instruction: (
        <>
          You are about to reactivate <b>{purchaseType}</b>. Please provide a reason for reactivation in the box below.
        </>
      ),
      buttonText: 'Reactivate',
      isReasonRequired: true,
      gqlAPI: reactivatePurchaseType,
    },
  };

  const onSubmit = () => {
    if (VARIATION[rateStatus].isReasonRequired && !comment) setErrors(true);
    else {
      const variables = {
        purchasetype: purchaseType,
        ratestatus: rateStatus,
        comment,
        effectivestartdate: effectiveStartDate,
      };
      VARIATION[rateStatus].gqlAPI({ variables });
    }
  };

  const onCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
    setCommentsCount(200 - value.length);
    if (comment.length > 1) setErrors(false);
  };

  // unknown rate status
  if (!VARIATION[rateStatus]) {
    onClose();
    return null;
  }

  return (
    <DeleteModal
      id="unique-rate-delete-modal"
      title={VARIATION[rateStatus].title}
      onClose={onClose}
      onDelete={onSubmit}
      deleting={deleting || deactivating || reactivating}
      deleteButtonText={VARIATION[rateStatus].buttonText}
    >
      <p className="margin-top-0 margin-bottom-5" data-testid="delete-modal-body">
        {VARIATION[rateStatus].instruction}
      </p>
      {VARIATION[rateStatus].isReasonRequired && (
        <div className={classNameErrBox(errors)}>
          <Label htmlFor="activation-comment" required>
            Please provide reason
          </Label>
          {errors && <ErrorMessage>Please provide a reason</ErrorMessage>}
          <Textbox
            name="comment"
            className={classNameErrInput(errors)}
            id="activation-comment"
            data-testid="activation-comment-input"
            value={comment}
            type="textarea"
            maxLength={200}
            rows={3}
            onChange={onCommentChange}
            onBlur={(e) => {
              if (VARIATION[rateStatus].isReasonRequired && !e.target.value) setErrors(true);
            }}
          />
          <div className="usa-hint usa-character-count__message">
            {commentsCount < 200 ? `${commentsCount} characters remaining` : '200 characters allowed'}
          </div>
        </div>
      )}
    </DeleteModal>
  );
};

PurchaseRateDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    purchaseType: PropTypes.string.isRequired,
    rateStatus: PropTypes.string.isRequired,
    effectiveStartDate: PropTypes.string.isRequired,
  }).isRequired,
  setBannerMsg: PropTypes.func.isRequired,
};

export default PurchaseRateDeleteModal;
