import React from 'react';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { Alert } from '@gsa/afp-component-library';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import { hasValue } from 'utilities/formatUtils';
import UpdateOptInCategoryForm from './UpdateOptInCategoryForm';

/* eslint-disable react/prop-types */

export const UpdateOptInCategoryModal = ({ onClose, isCreate, loading, pageState, ...formProps }) => {
  return (
    <EditModal
      id="opt-in-update-modal"
      title={`${isCreate ? 'Create' : 'Update'} Service Category`}
      onClose={onClose}
      formId={formProps?.formId}
      showAlert={!!pageState.category_modalAlert.content}
      alert={
        <Alert slim focused={false} type={pageState.category_modalAlert.type} style={{ marginBottom: 18 }}>
          {pageState.category_modalAlert.content}
        </Alert>
      }
      disabledSaveButton={hasValue()(loading)}
    >
      {isCreate ? (
        <p className="margin-top-0 margin-bottom-5" data-testid="toggle-active-modal-body">
          Please use this window to create a new service category and add its description.
        </p>
      ) : (
        <p className="margin-top-0 margin-bottom-5" data-testid="toggle-active-modal-body">
          Please use this window to update the existing description for the service category.
        </p>
      )}
      <LoadingQueryResult loadingObject={loading}>
        <UpdateOptInCategoryForm {...formProps} isCreate={isCreate} pageState={pageState} />
      </LoadingQueryResult>
    </EditModal>
  );
};
