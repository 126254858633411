import { gql } from '@apollo/client';

export const CREATE_NEW_OPT_IN_SERVICE = gql`
  mutation createOptInSvc($optInSvcInput: OptInSvcInput!, $optInSvcRatesAssoc: OptInSvcRatesAssoc!) {
    createOptInSvc(optInSvcInput: $optInSvcInput, optInSvcRatesAssoc: $optInSvcRatesAssoc) {
      optInSvcName
      optInSvcSalesId
      optInSvcCategory
      startDate
      endDate
    }
  }
`;
