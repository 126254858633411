import React from 'react';
import PropTypes from 'prop-types';

import { AFPTable, Spinner } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash/fp';
import NoDataComponent from './no-data-component';

const LoadingComponent = () => (
  <div data-testid="table-loading" style={{ position: 'absolute', width: '100%' }}>
    <Spinner />
  </div>
);

// when do we do not need to use the LoadingComponent set isLoading = false
const AFPTableWrapper = ({ isLoading, onError, data, NoDataMessages, CustomizeNoDataFn, noDataIndicator, ...rest }) => {
  if (onError) {
    return <NoDataComponent title="Cannot Display the Data" text="Due to server error, data cannot be displayed." />;
  }
  if (isLoading) return <LoadingComponent />;

  if (noDataIndicator || isEmpty(data)) {
    if (CustomizeNoDataFn) {
      return CustomizeNoDataFn();
    }
    return <NoDataComponent title={NoDataMessages?.title || undefined} text={NoDataMessages?.text || undefined} />;
  }

  return <AFPTable data={data} {...rest} />;
};

AFPTableWrapper.defaultProps = {
  onError: false,
  NoDataMessages: { title: null, text: null },
  data: [],
  isLoading: false,
  CustomizeNoDataFn: null,
  noDataIndicator: false,
};

export const NoDataMessagesShape = {
  title: PropTypes.string,
  text: PropTypes.string,
};

AFPTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
  onError: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  NoDataMessages: PropTypes.shape(NoDataMessagesShape),
  CustomizeNoDataFn: PropTypes.func,
  noDataIndicator: PropTypes.bool,
};

export default AFPTableWrapper;
