import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import { Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import { memo } from 'react-tracked';
import { validationSchema } from '../../model/category/schemas/formSchema';
import { maxLengthMap } from '../../helper';

/* eslint-disable react/prop-types */

const ToggleActiveForm = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  formId,
  isDeactivate,
  deactivateActive,
  reactivateActive,
}) => {
  const { handleSubmit, trigger, register, errors } = useForm({
    resolver: yupResolver(validationSchema.category_toggleActiveForm),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });

  const validatedSubmit = (data) => {
    isDeactivate
      ? deactivateActive({
          variables: {
            optInCategoryName: pageState.category_table?.rowData?.optInCategoryName,
            ratestatus: pageState.category_table?.rowData?.rateStatus,
            comment: data?.reason,
          },
        })
      : reactivateActive({
          variables: {
            optInCategoryName: pageState.category_table?.rowData?.optInCategoryName,
            ratestatus: pageState.category_table?.rowData?.rateStatus,
            comment: data?.reason,
          },
        });
  };

  const onSubmit = async (data) => {
    const result = await trigger(['reason']);
    if (result) {
      validatedSubmit(data);
    }
  };
  const reasonValue = pageState.category_toggleActiveForm.reason;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <div className="form-ra">
        <div className="display-block">
          <div className={`usa-form-group ${classNameErrBox(errors?.reason)}`}>
            <Label htmlFor="reason" required>
              Please provide reason
            </Label>
            {errors?.reason && <ErrorMessage>{errors?.reason.message}</ErrorMessage>}
            <Textbox
              id="reason"
              data-testid="reason-input"
              className={classNameErrInput(errors?.reason)}
              name="reason"
              type="textarea"
              maxLength={maxLengthMap.textarea}
              rows={3}
              aria-label="Please provide reason required."
              value={reasonValue}
              onChange={(e) => {
                setDispatch(DISPATCH_FNS.updateStateByPath, {
                  path: 'category_toggleActiveForm.reason',
                  data: e.target.value,
                });
              }}
              inputRef={register}
            />
            <div className="usa-hint usa-character-count__message">
              {reasonValue.length <= maxLengthMap.textarea
                ? `${maxLengthMap.textarea - reasonValue.length} characters remaining`
                : `${maxLengthMap.textarea} characters allowed`}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default memo(ToggleActiveForm);
