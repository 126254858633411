import { useAppAbility } from "@gsa/afp-shared-ui-utils";

export const ENTITY = {
  PURCHASE_RATE: "PurchaseRate",
  LEASE_RATE: "LeaseRate",
  UNIQUE_RATE: "UniqueRate",
  UNIQUE_RATE_APP: "UniqueRateApp",
  AFV_SURCHARGE: "AFVSurcharge",
  ACQUISITION_FUND: "AcquisitionFund",
  OPT_IN_SERVICE: "OptInService",
  OPT_IN_CATEGORY: "OptInCategory",
  VEHICLE_REPLACEMENT: "VehicleReplacementCriteria",
  WALLET: 'Wallet',
  PEGASYS_ERROR: 'PegasysError',
};
export const ROLE_OP = {
  VIEW: "view",
  REVIEW: "review",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  APPROVE: "approve",
  MANAGE: "manage",
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate",
  CERTIFY: "certify",
  COMMENT: "comment",
  MARK_RESOLVED: "mark as resolved"
};

export const hasAbilityTo = (operation, entity) => {
  const ability = useAppAbility();
  return ability.can(operation, entity);
};

export const hasSomeAbilitiesTo = (operations, entity) => {
  const ability = useAppAbility();
  return operations.some((op) => ability.can(op, entity));
};
