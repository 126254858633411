import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { StepIndicator, Button, PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import WelcomeMessage from 'widgets/welcome-message';
import BannerMessage from 'widgets/banner-message';
import UniqueRateAppFormStepOne from './unique-rate-app-form-step-one';
import UniqueRateAppFormStepTwo from './unique-rate-app-form-step-two';
import UniqueRateAppFormStepThree from './unique-rate-app-form-step-three';
import UniqueRateAppThankYou from './unique-rate-app-thankyou';
import {
  CREATE_OR_UPDATE_UNIQUE_RATE_APP,
  GET_SUPPORTING_DOC_URL,
  DELETE_SUPPORTING_DOC,
  COPY_SUPPORTING_DOC,
  STEPS,
  APP_TYPE,
  APP_STATUS,
} from './unique-rate-app-helper';
import './style/unique-rate-app.scss';

const TITLE = 'Unique Rate Application Form';

const UniqueRateApp = () => {
  useTitle(TITLE);
  const location = useLocation();
  const history = useHistory();
  if (location.pathname.match(/update/) && (!location.state || location.state.copy || location.state.add))
    history.replace('/bm/unique-rates-app/create');

  const [currStep, setCurrStep] = useState(STEPS[0]);
  const [draft, setDraft] = useState(null); // eslint-disable-line
  const [newDraft, setNewDraft] = useState(null);
  const [excludedVehicles, setExcludedVehicles] = useState([]);
  const [didClickNext, setDidClickNext] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [bannerMsg, setBannerMsg] = useState(null);
  const [isLoadingDraft, setIsLoadingDraft] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  const bannerRef = useRef(null);
  const childRef = useRef();

  useEffect(() => {
    if (location.state) {
      const { data, copy = false, add = false, thankyou = false } = location.state;

      if (copy) {
        // create new ur-app via Copy Request
        data.comment = '';
        data.uniqueRateAppVehicle = undefined;
        data.id = '';
        data.status = APP_STATUS.DRAFT;
        data.appType = APP_TYPE.APPLY;
      }

      if (add) {
        // create new ADD_VEHICLES request
        setExcludedVehicles([...data.uniqueRateAppVehicle]);
        data.comment = '';
        data.uniqueRateAppVehicle = undefined;
        data.id = '';
        data.status = APP_STATUS.DRAFT;
        data.appType = APP_TYPE.ADD_VEHICLE;
      }

      setDraft(data);
      if (data?.supportingDocument) {
        const {
          metadata: { name },
          metadataId,
        } = data.supportingDocument;
        setSelectedFile({ name, metadataId });
      }

      if (add || data?.appType === APP_TYPE.ADD_VEHICLE) {
        setNewDraft(data);
      }
      if (thankyou) {
        setSubmitted(true);
      }
    }

    setIsLoadingDraft(false);
  }, [location]);

  const onPrev = () => {
    setCurrStep(STEPS[STEPS.indexOf(currStep) - 1]);
  };

  const onNext = () => {
    setCurrStep(STEPS[STEPS.indexOf(currStep) + 1]);
  };

  const onSetBannerMsg = (banner) => {
    setBannerMsg(banner);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // gql calls
  // gql call to delete supporting document
  const [deleteSupportingDoc, { loading: loadingDeleteDoc }] = useMutation(DELETE_SUPPORTING_DOC);
  const deleteMOA = async (id) => {
    return deleteSupportingDoc({ variables: { id } })
      .then(() => ({ success: true }))
      .catch((error) => ({ success: false, error }));
  };

  // gql call to upload supporting document
  const [getSupportingDocURL, { loading: loadingDocURL }] = useMutation(GET_SUPPORTING_DOC_URL);
  const uploadMOA = async (appId, file) => {
    // get signed url for uploading
    return getSupportingDocURL({
      variables: {
        docInput: {
          model: 'UniqueRateApplication',
          modelId: appId,
          documentType: 0,
          documentName: 'moa',
          fileName: file.name,
          fileMimeType: file.type,
          description: '',
          size: file.size,
        },
      },
    })
      .then(async ({ data }) => {
        // uploading file to s3 bucket
        return axios
          .put(data.uploadSupportingBMDocument.signedUrl, file, { headers: { 'Content-Type': file.type } })
          .then(() => ({ success: true, data: data.uploadSupportingBMDocument }))
          .catch((error) => {
            deleteMOA({ id: data.uploadSupportingBMDocument.id });
            return { success: false, error };
          });
      })
      .catch((error) => ({ success: false, error }));
  };

  // gql call to copy supporting document
  const [copySupportingDoc, { loading: loadingCopy }] = useMutation(COPY_SUPPORTING_DOC);
  const copyMOA = async (appId, metadata) => {
    // get signed url for uploading
    return copySupportingDoc({
      variables: {
        sourceMetadataId: metadata.id,
        docInput: {
          model: 'UniqueRateApplication',
          modelId: appId,
          documentType: 0,
          documentName: 'moa',
          fileName: metadata.name,
          fileMimeType: metadata.fileMimeType,
          description: metadata.description,
          size: metadata.size,
        },
      },
    })
      .then(({ data }) => ({ success: true, data: data.copySupportingBMDocument }))
      .catch((error) => ({ success: false, error }));
  };

  // gql call to save the unique rate application
  const [createOrUpdateUniqueRateApplications, { loading: loadingSave }] = useMutation(
    CREATE_OR_UPDATE_UNIQUE_RATE_APP,
    {
      onCompleted: async (resData) => {
        let { createOrUpdateUniqueRateApplications: uniqueRateAppData } = resData;
        let uploadError = null;
        const isCopyMOA = (location.state?.copy && !newDraft) || location.state?.add;

        // handle document upload in step-one or step-two for add
        if (currStep.step === STEPS[0].step || (currStep.step === STEPS[1].step && location.state?.add)) {
          const curDraft = newDraft || draft;
          if (curDraft?.supportingDocument) {
            // with existing MOA
            if (!selectedFile?.metadataId) {
              // existing MOA has been deleted or replaced
              // remove existing MOA - if not copy or add
              let res = { success: true };
              if (!isCopyMOA) res = await deleteMOA(curDraft.supportingDocument.id);
              if (res.success) {
                let supportingDocument = null;
                // replace MOA - uploading new MOA
                if (selectedFile) {
                  const res2 = await uploadMOA(uniqueRateAppData.id, selectedFile);
                  if (res2.success) {
                    supportingDocument = { ...res2.data };
                  } else uploadError = res2.error;
                }
                uniqueRateAppData = {
                  ...uniqueRateAppData,
                  supportingDocument,
                };
              } else uploadError = res.error;
            } else if (isCopyMOA) {
              // no change to existing MOA - copy MOA for copy or add
              const resCopy = await copyMOA(uniqueRateAppData.id, curDraft?.supportingDocument.metadata);
              uniqueRateAppData = {
                ...uniqueRateAppData,
                supportingDocument: resCopy.success ? { ...resCopy.data } : null,
              };
              if (!resCopy.success) uploadError = resCopy.error;
            }
          } else if (selectedFile) {
            // without existing MOA - add new MOA
            const res = await uploadMOA(uniqueRateAppData.id, selectedFile);
            if (res.success) {
              uniqueRateAppData = {
                ...uniqueRateAppData,
                supportingDocument: { ...res.data }, // new doc
              };
            } else uploadError = res.error;
          }

          // update selected file
          setSelectedFile(
            uniqueRateAppData.supportingDocument
              ? {
                  name: uniqueRateAppData.supportingDocument.metadata.name,
                  metadataId: uniqueRateAppData.supportingDocument.metadataId,
                }
              : null,
          );
        }

        // update draft form data
        setNewDraft(uniqueRateAppData);

        if (uploadError) {
          onSetBannerMsg({
            type: 'error',
            message: (
              <div>
                Application form data has been saved. However, error occurred when updating MOA document. Please try
                again later.
                <br />
                {uploadError.message}
              </div>
            ),
          });
          return;
        }

        const successMsg = {
          type: 'success',
          message: (
            <div>
              Your draft request has been saved. You may retrieve this draft from the unique rate application request
              listings <Link to="/bm/unique-rates-app">here</Link>
            </div>
          ),
        };

        if (didClickNext) {
          setDidClickNext(false);
          if (currStep.step === STEPS[STEPS.length - 1].step) {
            // submit application
            history.push({
              pathname: '/bm/unique-rates-app/create',
              state: { thankyou: true },
            });
          } else {
            // save and continue
            onNext();
            onSetBannerMsg(successMsg);
          }
        } else {
          // save as draft
          onSetBannerMsg(successMsg);
        }
      }, // end of onCompleted callback function
      onError: (err) =>
        onSetBannerMsg({
          type: 'error',
          message: (
            <div>
              Error occurred when saving a draft
              <br />
              {err.message}
            </div>
          ),
        }),
    },
  );

  const onSaveDraft = (uniqueRateAppDraftData) => createOrUpdateUniqueRateApplications(uniqueRateAppDraftData);

  if (isSubmitted) return <UniqueRateAppThankYou />;

  return (
    <div className="grid-col">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          {
            location: `${window.AFP_CONFIG.appURLs.bm}/bm/unique-rates-app`,
            label: 'Unique Rate Applications',
          },
        ]}
      />

      <div ref={bannerRef}>
        {bannerMsg && (
          <BannerMessage className="margin-top-2" type={bannerMsg.type} onClose={() => setBannerMsg(null)}>
            {bannerMsg.message}
          </BannerMessage>
        )}
      </div>

      <PageTitle title={TITLE} />
      <div className="unique-rates-app-heading">
        <div className="unique-rates-app-welcome-msg">
          <WelcomeMessage
            className="leaseintro"
            message="You may use the form to request a unique rate for your customer."
          />
        </div>
        {currStep?.step !== 3 && !(currStep?.step === 1 && draft?.appType === APP_TYPE.ADD_VEHICLE) && (
          <div className="unique-rates-app-save-draft">
            <Button
              variant="outline"
              disabled={loadingSave || loadingDocURL || loadingDeleteDoc || loadingCopy}
              onClick={() => childRef.current.onSaveDraftClick()}
              label="Save as draft"
            />
          </div>
        )}
      </div>
      <div className="unique-rate-app-form-layout">
        <StepIndicator
          counterSize="big"
          heading={{
            current: currStep?.step,
            text: currStep?.title,
          }}
          steps={[
            {
              label: 'Select Unique Rates and Upload MOA',
              status: `${currStep.status[0]}`,
            },
            {
              label: 'Enter Customer and Vehicle Information',
              status: `${currStep.status[1]}`,
            },
            {
              label: 'Review Summary and Submit',
              status: `${currStep.status[2]}`,
            },
          ]}
          title={currStep?.title}
        />

        <hr />
        {draft?.comment && (
          <>
            <h4 className="review-comment-head">REVIEW COMMENTS</h4>
            <div className="review-note">{draft.comment}</div>
          </>
        )}

        {currStep?.step === 1 && !isLoadingDraft && (
          <UniqueRateAppFormStepOne
            draft={newDraft || draft}
            onNext={onNext}
            setDidClickNext={setDidClickNext}
            onSaveDraft={onSaveDraft}
            onSelectFile={setSelectedFile}
            disabled={loadingSave || loadingDocURL || loadingDeleteDoc || loadingCopy}
            ref={childRef}
            loading={loadingSave || loadingDocURL || loadingDeleteDoc || loadingCopy}
            isCopy={location.state?.copy}
          />
        )}

        {currStep?.step === 2 && newDraft && (
          <UniqueRateAppFormStepTwo
            draft={newDraft}
            excludedVehicles={excludedVehicles}
            onPrev={onPrev}
            onNext={() => setDidClickNext(true)}
            onSaveDraft={onSaveDraft}
            setBannerMsg={onSetBannerMsg}
            ref={childRef}
          />
        )}

        {currStep?.step === 3 && newDraft && (
          <UniqueRateAppFormStepThree
            draft={newDraft}
            onPrev={onPrev}
            setDidClickNext={setDidClickNext}
            setBannerMsg={onSetBannerMsg}
            onSaveDraft={onSaveDraft}
          />
        )}
      </div>
    </div>
  );
};

export default UniqueRateApp;
