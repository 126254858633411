import React, { useState, useEffect } from 'react';
import { TextInput, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { useWalletDetails } from '../wallet-details-provider';
import { CREATE_WALLET_BASIC_FIELDS, generateWalletIpacFields } from './consts';

const WalletIpacFormFields = () => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const { createdWallet, boacOfferingAssociation } = useWalletDetails();

  const [ipacFieldsSchema, setIpacFieldsSchema] = useState(generateWalletIpacFields(getValues()));
  const watchFiscalStation = watch(ipacFieldsSchema.fiscalStation.name);
  const watchAgency = watch(CREATE_WALLET_BASIC_FIELDS.agency.name);

  useEffect(() => {
    setIpacFieldsSchema(
      generateWalletIpacFields(!isEmpty(getValues()) ? getValues() : boacOfferingAssociation, createdWallet),
    );
  }, [watchAgency, watchFiscalStation]);

  useEffect(() => {
    if (createdWallet) {
      const newFormSchema = generateWalletIpacFields(
        !isEmpty(getValues()) ? getValues() : boacOfferingAssociation,
        true,
      );
      setIpacFieldsSchema(newFormSchema);
    }
  }, [createdWallet]);

  return (
    <div className="grid-row">
      <div className="tablet:grid-col-6">
        <div className="grid-row grid-gap">
          {Object.values(ipacFieldsSchema)
            .filter((ipacFilter) => ipacFilter.visible)
            .map((ipacField) => {
              if (ipacField?.readOnly) {
                return (
                  <div className={`grid-col-${ipacField?.gridColumns ? ipacField.gridColumns : '6'}`}>
                    <div className="text-bold margin-top-2">
                      {ipacField?.label} {ipacField?.required && <RequiredFieldIndicator />}
                      {ipacField?.toolTip && ipacField.toolTip}
                    </div>
                    {boacOfferingAssociation[ipacField?.name] || <>&ndash;</>}
                  </div>
                );
              }
              return (
                <>
                  <div className={`grid-col-${ipacField?.gridColumns ? ipacField.gridColumns : '6'}`}>
                    <Controller
                      name={ipacField?.name}
                      control={control}
                      render={({ value, onChange, onBlur }) => {
                        return (
                          <div key={ipacField?.name}>
                            <TextInput
                              data-testid={`wallet-create-${ipacField?.name}`}
                              inputClass="maxw-full"
                              type={ipacField?.type}
                              name={ipacField?.name}
                              hint={ipacField?.hint || ''}
                              id={ipacField?.id}
                              disabled={ipacField?.disabled}
                              value={value}
                              maxLength={ipacField?.maxLength}
                              onChange={(e) => {
                                onChange(e);
                                setValue(ipacField?.name, e.target.value);
                              }}
                              onBlur={onBlur}
                              label={
                                <span className="text-bold">
                                  {ipacField?.label} {ipacField?.required && <RequiredFieldIndicator />}
                                  {ipacField?.toolTip && ipacField.toolTip}
                                </span>
                              }
                              errorMessage={
                                errors && errors[ipacField?.name] && !ipacField?.disabled
                                  ? errors[ipacField?.name].message
                                  : null
                              }
                              aria-invalid={
                                errors && errors[ipacField?.name] && !ipacField?.disabled ? 'true' : 'false'
                              }
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WalletIpacFormFields;
