import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash/fp';
import { firstValue } from 'utilities/formatUtils';
import { GET_OPT_IN_CATEGORY } from './operation/query/GET_OPT_IN_CATEGORY';
import { DEACTIVATE_ACTIVE_OPT_IN_CATEGORY } from './operation/mutation/DEACTIVATE_ACTIVE_OPT_IN_CATEGORY';
import { REACTIVATE_OPT_IN_CATEGORY } from './operation/mutation/REACTIVATE_OPT_IN_CATEGORY';
import { CREATE_NEW_OPT_IN_CATEGORY } from './operation/mutation/CREATE_NEW_OPT_IN_CATEGORY';
import { UPDATE_OPT_IN_CATEGORY } from './operation/mutation/UPDATE_OPT_IN_CATEGORY';
import { DISTINCT_OPT_IN_CATEGORY } from './operation/query/DISTINCT_OPT_IN_CATEGORY';
import { getCategoryQueryVariables } from '../../helper';

const useDataController = ({ dispatchBannerMessage, setDispatch, DISPATCH_FNS, pageState, closeModal }) => {
  const updateModalAlert = (error) => {
    setDispatch(DISPATCH_FNS.updateModalAlert, {
      type: 'error',
      content: _.getOr('Unknown Error', 'message', error),
    });
  };

  const OnErrorUpdateFilterLoadingStatus = (error) => {
    dispatchBannerMessage({
      setDispatch,
      DISPATCH_FNS,
      error,
    });

    setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'categoryFilter', loadingStatus: false });
  };

  const [fetchDistinctOptInCategory, { loading: distinctOptInCategoryFilterLoading }] = useLazyQuery(
    DISTINCT_OPT_IN_CATEGORY,
    {
      onCompleted: (resData) => {
        const serviceCategories = _.flow(firstValue, _.map('optInCategoryName'))(resData);
        setDispatch(DISPATCH_FNS.updateStateByPath, {
          path: 'categoryFilter.options.OptInCategoryName',
          data: serviceCategories,
        });

        setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'categoryFilter', loadingStatus: false });
      },

      onError: OnErrorUpdateFilterLoadingStatus,
    },
  );

  const [getCategoryRows, { loading: categoryTableLoading }] = useLazyQuery(GET_OPT_IN_CATEGORY, {
    onCompleted: (categoryRows) => {
      fetchDistinctOptInCategory();
      const rowData = _.flow(firstValue, _.getOr([], 'rows'))(categoryRows);
      const itemsCount = _.flow(firstValue, _.getOr(0, 'count'))(categoryRows);
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'category_table.rows', data: rowData });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'category_itemsCount', data: itemsCount });
    },

    onError: OnErrorUpdateFilterLoadingStatus,
  });
  //  have several step and we will combine the loading to one process
  //  full loaded if fetchDistinctOptInCategory is complete or onError   getCategoryRows is onError
  const fetchCategoryRows = (args) => {
    setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'categoryFilter', loadingStatus: true });
    getCategoryRows(args);
  };
  const [reactivateActive, { loading: reactivateActiveLoading }] = useMutation(REACTIVATE_OPT_IN_CATEGORY, {
    onError: updateModalAlert,
    onCompleted: (data) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: `You have successfully reactivated   ${firstValue(data)?.optInCategoryName}`,
        type: 'success',
      });
      fetchCategoryRows({
        variables: getCategoryQueryVariables({ pageState, filter: pageState.categoryFilter.filter.OptInCategoryName }),
      });
      setDispatch(DISPATCH_FNS.category_resetToggleActiveForm);
      setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal });
    },
  });

  const [deactivateActive, { loading: deactivateActiveLoading }] = useMutation(DEACTIVATE_ACTIVE_OPT_IN_CATEGORY, {
    onError: updateModalAlert,
    onCompleted: (data) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: `You have successfully deactivated  ${firstValue(data)?.optInCategoryName}`,
        type: 'success',
      });
      fetchCategoryRows({
        variables: getCategoryQueryVariables({
          pageState,
          filter: pageState.categoryFilter.filter.OptInCategoryName,
        }),
      });
      setDispatch(DISPATCH_FNS.category_resetToggleActiveForm);
      setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal });
    },
  });

  const [createNewOptInCategory, { loading: createNewOptInCategoryLoading }] = useMutation(CREATE_NEW_OPT_IN_CATEGORY, {
    onError: updateModalAlert,
    onCompleted: (res) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: `Service Category  ${firstValue(res)?.optInCategoryName} has been created.`,
        type: 'success',
      });
      fetchCategoryRows({
        variables: getCategoryQueryVariables({
          pageState,
          filter: pageState.categoryFilter.filter.OptInCategoryName,
        }),
      });
      setDispatch(DISPATCH_FNS.category_updateOptInCategoryModal, { show: false, closeModal });
    },
  });

  const [updateOptInCategory, { loading: updateOptInCategoryLoading }] = useMutation(UPDATE_OPT_IN_CATEGORY, {
    onError: updateModalAlert,
    onCompleted: (res) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: `Service Category  ${firstValue(res)?.optInCategoryName} has been updated.`,
        type: 'success',
      });
      fetchCategoryRows({ variables: getCategoryQueryVariables({ pageState }) });
      setDispatch(DISPATCH_FNS.category_updateOptInCategoryModal, { show: false, closeModal });
    },
  });

  return {
    mutation: { reactivateActive, deactivateActive, createNewOptInCategory, updateOptInCategory },
    query: { fetchCategoryRows },
    loading: {
      categoryTableLoading,
      deactivateActiveLoading,
      reactivateActiveLoading,
      createNewOptInCategoryLoading,
      updateOptInCategoryLoading,
      distinctOptInCategoryFilterLoading,
    },
    forTest: {
      getCategoryRows,
      fetchDistinctOptInCategory,
      OnErrorUpdateFilterLoadingStatus,
    },
  };
};

export default useDataController;
