import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import { Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import { memo } from 'react-tracked';
import TrimmedInput from 'components/wrappers/TrimmedInput';
import { maxLengthMap } from '../../helper';
import { validationSchema } from '../../model/category/schemas/formSchema';

/* eslint-disable react/prop-types */

const UpdateOptInCategoryForm = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  formId,
  isCreate,
  createNewOptInCategory,
  updateOptInCategory,
}) => {
  const { handleSubmit, trigger, register, errors } = useForm({
    resolver: yupResolver(validationSchema.category_updateOptInCategoryForm),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });
  const validatedSubmit = (data) => {
    isCreate
      ? createNewOptInCategory({
          variables: {
            optInCategoryInput: {
              optInCategoryName: data.serviceCategory,
              optInCategoryDesc: data.description,
            },
          },
        })
      : updateOptInCategory({
          variables: {
            prevOptInCategoryName: pageState.category_updateOptInCategoryForm.prevOptInCategoryName,
            optInCategoryInput: {
              optInCategoryName: data.serviceCategory,
              optInCategoryDesc: data.description,
            },
          },
        });
  };

  const onSubmit = async (data) => {
    const result = await trigger(['description', 'serviceCategory']);
    if (result) {
      validatedSubmit(data);
    }
  };
  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);
  const serviceCategoryValue = pageState.category_table?.rowData.optInCategoryName;
  const descriptionValue = pageState.category_table?.rowData.optInCategoryDesc;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <div className="form-ra">
        <div className="display-block">
          <div className={`usa-form-group ${classNameErrBox(errors?.serviceCategory)}`}>
            <Label htmlFor="serviceCategory" required>
              Service Category
            </Label>
            {createErrorMessage(errors?.serviceCategory)}
            <TrimmedInput
              readOnly={!isCreate}
              id="service-category"
              data-testid="service-category-input"
              className={classNameErrInput(errors?.serviceCategory)}
              name="serviceCategory"
              maxLength={maxLengthMap.input}
              aria-label="Service Category required."
              value={serviceCategoryValue}
              onChange={(e) => {
                setDispatch(DISPATCH_FNS.updateStateByPath, {
                  path: 'category_table.rowData.optInCategoryName',
                  data: e.target.value,
                });
              }}
              inputRef={register}
            />
            <div className="usa-hint usa-character-count__message">
              {serviceCategoryValue.length <= maxLengthMap.input
                ? `${maxLengthMap.input - serviceCategoryValue.length} characters remaining`
                : `${maxLengthMap.input} characters allowed`}
            </div>
          </div>
        </div>
      </div>
      <div className="form-ra margin-top-3">
        <div className="display-block">
          <div className={`usa-form-group ${classNameErrBox(errors?.description)}`}>
            <Label htmlFor="description" required>
              Description
            </Label>
            {createErrorMessage(errors?.description)}
            <Textbox
              id="description"
              data-testid="description"
              className={classNameErrInput(errors?.description)}
              name="description"
              type="textarea"
              maxLength={maxLengthMap.textarea}
              rows={3}
              aria-label="Description  required."
              value={descriptionValue}
              onChange={(e) => {
                setDispatch(DISPATCH_FNS.updateStateByPath, {
                  path: 'category_table.rowData.optInCategoryDesc',
                  data: e.target.value,
                });
              }}
              inputRef={register}
            />
            <div className="usa-hint usa-character-count__message">
              {descriptionValue.length <= maxLengthMap.textarea
                ? `${maxLengthMap.textarea - descriptionValue.length} characters remaining`
                : `${maxLengthMap.textarea} characters allowed`}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default memo(UpdateOptInCategoryForm);
