import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ResponsiveFilterPanel, Spinner } from '@gsa/afp-component-library';
import {
  APP_TYPE,
  APP_TYPE_LABEL,
  APP_STATUS,
  DEFAULT_APP_FILTER_STATE,
  GET_DISTINCT_CUSTOMER_ID,
  GET_DISTINCT_AGENCY_CODE,
} from './unique-rate-app-helper';
import './style/unique-rate-app-filter.scss';

const FilterItems = {
  status: {
    id: 'status',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: [APP_STATUS.SUBMITTED, APP_STATUS.APPROVED, APP_STATUS.DRAFT, APP_STATUS.REJECTED, APP_STATUS.REWORK],
    className: 'StatusFilter',
    noChip: true,
  },
  appType: {
    id: 'appType',
    title: 'Request Type',
    type: 'checkbox-list',
    expanded: true,
    options: Object.values(APP_TYPE_LABEL),
    className: 'StatusFilter',
    noChip: true,
  },

  agencyCode: {
    id: 'agencyCode',
    title: 'Agency',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
  customerId: {
    id: 'customerId',
    title: 'Customer Number',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
};

const UniqueRateAppFilter = ({ onFilter, timestamp, allAgencies }) => {
  const [filterOptions, setFilterOptions] = useState({});

  const onFilterAPIError = (key) =>
    setFilterOptions((prev) => ({
      ...prev,
      [key]: [],
    }));

  const [fetchAgencies, { loading: loadingAgencies }] = useLazyQuery(GET_DISTINCT_AGENCY_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ getDistinctAgencyCode }) => {
      setFilterOptions((prev) => {
        return {
          ...prev,
          agencyCode: getDistinctAgencyCode
            ?.filter((aC) => aC.agencyCode)
            .map((d) => ({
              value: d.agencyCode ? d.agencyCode.toString() : '',
              label: allAgencies[d.agencyCode],
            })),
        };
      });
    },
    onError: () => onFilterAPIError('Agencies'),
  });

  const [fetchCustomerNumber, { loading: loadingCustomerNum }] = useLazyQuery(GET_DISTINCT_CUSTOMER_ID, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getDistinctCustomerId }) => {
      setFilterOptions((prev) => {
        return {
          ...prev,
          customerId: getDistinctCustomerId?.map((d) => ({
            value: d.customerId,
            label: d.customerId,
          })),
        };
      });
    },
    onError: () => onFilterAPIError('DistinctCustomerId'),
  });

  useEffect(() => {
    fetchCustomerNumber();
  }, [timestamp]);

  useEffect(() => {
    if (Object.keys(allAgencies).length) {
      fetchAgencies();
    }
  }, [allAgencies]);

  const buildFilters = useCallback((state) => {
    const label2value = (key, label) => {
      if (key === 'appType') {
        let value = null;
        Object.values(APP_TYPE).forEach((type) => {
          if (APP_TYPE_LABEL[type] === label) value = type;
        });
        return value;
      }
      return label;
    };

    const filters = {
      filters: { operator: 'AND', value: [] },
      pocFilters: { operator: 'AND', value: [] },
    };

    Object.keys(state).forEach((key) => {
      const value = state[key];
      const filter = { operator: 'OR', value: [] };
      switch (key) {
        case 'status':
        case 'appType':
          value?.forEach((val) => {
            filter.value.push({
              key,
              operator: 'EQ',
              value: label2value(key, val),
            });
          });
          filters.filters.value.push(filter);
          break;
        case 'customerId':
        case 'agencyCode':
          filters.pocFilters.value.push({ key, operator: 'EQ', value });
          break;
        default:
      }
    });

    Object.keys(filters).forEach((key) => {
      if (!filters[key].value.length) delete filters[key];
    });

    return { filters, state };
  }, []);

  return (
    <div>
      {loadingAgencies || loadingCustomerNum ? (
        <div style={{ position: 'absolute', top: '-40px', width: '100%' }}>
          <Spinner />
        </div>
      ) : null}
      <ResponsiveFilterPanel
        defaultState={DEFAULT_APP_FILTER_STATE}
        items={FilterItems}
        filterOptions={filterOptions}
        onChange={(state) => onFilter(buildFilters(state))}
      />
    </div>
  );
};

UniqueRateAppFilter.defaultProps = {
  timestamp: Date.now(),
};
UniqueRateAppFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  timestamp: PropTypes.number,
  allAgencies: PropTypes.shape({}).isRequired,
};

export default UniqueRateAppFilter;
