import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useWalletDetails } from '../wallet-details-provider';
import WalletIpacVehiclesFields from './wallet-ipac-vehicles-fields';
import { IPAC_VEHICLES_FIELDS } from './consts';

const alphanumericOrEmptyRegExp = /^[a-zA-Z0-9]*$/i;

const WalletIpacVehiclesForm = ({ asset }) => {
  const { createIpacVehicle, createdWallet, wallet } = useWalletDetails();
  const generateIpacVehiclesFormSchema = () =>
    yup.object().shape({
      [IPAC_VEHICLES_FIELDS.standardDocumentNumber.name]: yup
        .string()
        .required()
        .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
        .max(20, 'Must be 20 characters or less.')
        .label(IPAC_VEHICLES_FIELDS.standardDocumentNumber.label),
      [IPAC_VEHICLES_FIELDS.bcnObanAsn.name]: yup
        .string()
        .required()
        .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
        .max(6, 'Must be 6 characters or less.')
        .label(IPAC_VEHICLES_FIELDS.bcnObanAsn.label),
      [IPAC_VEHICLES_FIELDS.limit.name]: yup
        .string()
        .required()
        .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
        .min(4, 'Must be 4 characters.')
        .max(4, 'Must be 4 characters.')
        .label(IPAC_VEHICLES_FIELDS.limit.label),
      [IPAC_VEHICLES_FIELDS.accountInformation.name]: yup
        .string()
        .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
        .max(54, 'Must be 54 characters or less.')
        .label(IPAC_VEHICLES_FIELDS.accountInformation.label),
    });

  const methods = useForm({
    resolver: yupResolver(generateIpacVehiclesFormSchema()),
    defaultValues: {
      [IPAC_VEHICLES_FIELDS.standardDocumentNumber.name]: asset?.standardDocumentNumber || '',
      [IPAC_VEHICLES_FIELDS.bcnObanAsn.name]: asset?.bcnObanAsn || '',
      [IPAC_VEHICLES_FIELDS.limit.name]: asset?.limit || '',
      [IPAC_VEHICLES_FIELDS.accountInformation.name]: asset?.accountInformation || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (values) => {
    createIpacVehicle({
      variables: {
        createIpacVehicleFieldInput: {
          walletBoacSalesGroupId: createdWallet || wallet?.id,
          assetId: asset.assetId,
          ...values,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="ipac-vehicles-form"
        id="ipac-vehicles-form"
        onSubmit={(e) => {
          e.stopPropagation();
          return methods.handleSubmit(onSubmit)(e);
        }}
      >
        <WalletIpacVehiclesFields />
      </form>
    </FormProvider>
  );
};

export default WalletIpacVehiclesForm;

WalletIpacVehiclesForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    standardDocumentNumber: PropTypes.string.isRequired,
    bcnObanAsn: PropTypes.string,
    limit: PropTypes.string,
    accountInformation: PropTypes.string,
  }).isRequired,
};
