import React from 'react';
import { getTSDateStrToUS } from 'components/helpers/afp-bm-date';
import { getDateLabel, getUserLabel } from './helper';

/* eslint-disable react/prop-types */
const UpdateInformation = ({ original = {} }) => {
  return (
    <>
      <div className="grid-row bm-subrow-grid-row">
        <div className="tablet:grid-col bm-subrow-title">{getUserLabel(original)}</div>
        <div className="tablet:grid-col text-right">
          {original.lastUpdateUserInfo ? (
            <a href={`mailto:${original.lastUpdateUserInfo.email}`}>{original.lastUpdateUserInfo.fullName}</a>
          ) : (
            original.updatedByUser || original.createdByUser
          )}
        </div>
      </div>
      <div className="grid-row bm-subrow-grid-row">
        <div className="tablet:grid-col bm-subrow-title">{getDateLabel(original)}</div>
        <div className="tablet:grid-col text-right">
          {getTSDateStrToUS(original.deletedAt || original.updatedAt || original.createdAt)}
        </div>
      </div>
    </>
  );
};

export default UpdateInformation;
