import { gql } from '@apollo/client';
import { getFYInt, getTodayStr, getFYUTCDateRange } from 'components/helpers/afp-bm-date';
import { CalcProjectToCollect } from './components/afv-funding-calculations';

export const CURRENT_FY = getFYInt().toString();

// GraphQL Schemas
export const AFVFundingResponse = gql`
  fragment AFVFundingResponseFields on AFVFunding {
    id
    agencyCode
    agencyIndicator
    startDate
    endDate
    surcharge
    isFinalized
    createdBy
    createdAt
    updatedBy
    updatedAt
    deletedAt
    dmlFlag
    dataSource
  }
`;

export const CREATE_OR_UPDATE_AFV_FUNDING = gql`
  mutation createOrUpdateAfvFunding(
    $id: String!
    $afvFundingInput: AFVFundingInput!
    $afvRateCommentInput: AFVRateCommentInput!
  ) {
    createOrUpdateAfvFunding(id: $id, afvFundingInput: $afvFundingInput, afvRateCommentInput: $afvRateCommentInput) {
      ...AFVFundingResponseFields
    }
  }
  ${AFVFundingResponse}
`;

export const DELETE_AFV_FUNDING = gql`
  mutation deleteAfvFunding($id: String!) {
    deleteAfvFunding(id: $id)
  }
`;

export const GET_AFV_SURCHARGES_BY_AGENCY = gql`
  query getAFVSurchargesByAgency($fiscalYear: String!, $agencyCode: String!, $agencyIndicator: String) {
    getAFVSurchargesByAgency(fiscalYear: $fiscalYear, agencyCode: $agencyCode, agencyIndicator: $agencyIndicator) {
      id
      agencyCode
      agencyIndicator
      startDate
      endDate
      surcharge
      isFinalized
      createdBy
      createdAt
      afvRateComments {
        id
        responseId
        action
        startDate
        endDate
        comment
        afvSurchargeAmount
        afvFundingId
        createdAt
        createdBy
        updatedAt
        updatedBy
        createdByUserInfo {
          fullName
          email
        }
      }
      createdByUserInfo {
        fullName
        email
      }
    }
  }
`;

export const GET_FINALIZED_AFV_SURCHARGES = gql`
  query getFinalizedAFVSurcharges($fiscalYear: String!) {
    getFinalizedAFVSurcharges(fiscalYear: $fiscalYear) {
      agencyCode
      agencyIndicator
      startDate
      endDate
      surcharge
      isFinalized
    }
  }
`;

export const GET_AFV_RATE_COMMENT = gql`
  query getAFVRateComment($commentId: String!) {
    getAFVRateComment(commentId: $commentId) {
      id
      action
      afvFundingId
      responseId
      comment
      afvSurchargeAmount
      startDate
      endDate
      createdAt
      createdBy
      createdByUserInfo {
        fullName
        email
      }
      surcharge {
        agencyCode
        agencyIndicator
      }
    }
  }
`;
export const CREATE_AFV_RATE_COMMENT = gql`
  mutation createAFVRateComment($responseToId: String!, $afvRateCommentInput: AFVRateCommentInput!) {
    createAFVRateComment(responseToId: $responseToId, afvRateCommentInput: $afvRateCommentInput) {
      id
      action
      afvFundingId
      responseId
      comment
      afvSurchargeAmount
      startDate
      endDate
      createdAt
      createdBy
      surcharge {
        agencyCode
        agencyIndicator
      }
    }
  }
`;

export const GET_ALL_AMOUNT_FUNDED = gql`
  query getAFVFYFunds($fiscalYear: String!) {
    getAFVFYFunds(fiscalYear: $fiscalYear) {
      agencyCode
      agencyIndicator
      fiscalYear
      effectiveDate
      amountFunded
    }
  }
`;
export const GET_AMOUNT_FUNDED_BY_AGENCY = gql`
  query getAFVFYFundByAgency($fiscalYear: String!, $agencyCode: String!, $agencyIndicator: String) {
    getAFVFYFundByAgency(fiscalYear: $fiscalYear, agencyCode: $agencyCode, agencyIndicator: $agencyIndicator) {
      agencyCode
      agencyIndicator
      fiscalYear
      effectiveDate
      amountFunded
    }
  }
`;
export const CREATE_UPDATE_AMOUNT_FUNDED = gql`
  mutation createOrUpdateAFVFYFund(
    $fiscalYear: String!
    $agencyCode: String!
    $agencyIndicator: String!
    $amountFunded: String!
  ) {
    createOrUpdateAFVFYFund(
      fiscalYear: $fiscalYear
      agencyCode: $agencyCode
      agencyIndicator: $agencyIndicator
      amountFunded: $amountFunded
    ) {
      agencyCode
      agencyIndicator
      fiscalYear
      effectiveDate
      amountFunded
    }
  }
`;

export const GET_MOCK_DATA_FOR_BILLING = gql`
  query getMockDataByFY($fiscalYear: String!) {
    getMockDataByFY(fiscalYear: $fiscalYear) {
      agencyCode
      agencyIndicator
      fiscalYear
      amountCollected
    }
  }
`;
export const GET_MOCK_DATA_FOR_BILLING_BY_AGENCY = gql`
  query getMockDataByFYAgency($fiscalYear: String!, $agencyCode: String!, $agencyIndicator: String) {
    getMockDataByFYAgency(fiscalYear: $fiscalYear, agencyCode: $agencyCode, agencyIndicator: $agencyIndicator) {
      agencyCode
      agencyIndicator
      fiscalYear
      amountCollected
    }
  }
`;

export const GET_MOCK_DATA_FOR_STORE = gql`
  query getMockDataByFY($fiscalYear: String!) {
    getMockDataByFY(fiscalYear: $fiscalYear) {
      agencyCode
      agencyIndicator
      fiscalYear
      amountSpent
      projectToSpend
    }
  }
`;
export const GET_MOCK_DATA_FOR_STORE_BY_AGENCY = gql`
  query getMockDataByFYAgency($fiscalYear: String!, $agencyCode: String!, $agencyIndicator: String) {
    getMockDataByFYAgency(fiscalYear: $fiscalYear, agencyCode: $agencyCode, agencyIndicator: $agencyIndicator) {
      agencyCode
      agencyIndicator
      fiscalYear
      amountSpent
      projectToSpend
    }
  }
`;

export const GET_MOCK_DATA_FOR_VMS = gql`
  query getMockDataNumVehicles {
    getMockDataNumVehicles {
      agencyCode
      agencyIndicator
      numVehicles
    }
  }
`;

export const GET_MOCK_DATA_FOR_VMS_BY_AGENCY = gql`
  query getMockDataNumVehiclesByAgency($agencyCode: String!, $agencyIndicator: String) {
    getMockDataNumVehiclesByAgency(agencyCode: $agencyCode, agencyIndicator: $agencyIndicator) {
      agencyCode
      agencyIndicator
      numVehicles
    }
  }
`;

// AFV Funding Data Preparation
const findSurcharge = (fundings, fiscalYear) => {
  if (fundings.length === 1) {
    const dateStr = fiscalYear === CURRENT_FY ? getTodayStr() : getFYUTCDateRange(fiscalYear).endDate;
    const surchargeObj = fundings[0].surcharges.find((sur) => {
      return dateStr >= sur.startDate && (!sur.endDate || dateStr <= sur.endDate);
    });
    return parseFloat(surchargeObj?.surcharge) || 0;
  }
  return 0;
};

export const MergeAgencyData = (viewOnly, fiscalYear, agencyInfo, fundingData) => {
  const { agencyCode, indicators } = agencyInfo;
  const { dataSurcharges, dataAmountFunded, dataVMS, dataStore, dataBilling } = fundingData;
  if (!dataSurcharges) return null;
  if (!viewOnly && !(dataAmountFunded && dataVMS && dataStore && dataBilling)) return null;

  // TODO: refactor code when mock api's being replaced
  const result = { ...agencyInfo, fiscalYear, fundings: [] };
  indicators.forEach((ind) => {
    const fund = dataAmountFunded?.find((i) => i.agencyCode === agencyCode && i.agencyIndicator === ind);
    const vms = dataVMS?.find((i) => i.agencyCode === agencyCode && i.agencyIndicator === ind);
    const billing = dataBilling?.find((i) => i.agencyCode === agencyCode && i.agencyIndicator === ind);
    const store = dataStore?.find((i) => i.agencyCode === agencyCode && i.agencyIndicator === ind);
    const surcharges = dataSurcharges
      .filter((s) => s.agencyCode === agencyCode && s.agencyIndicator === ind)
      .sort((s1, s2) => (s1.startDate < s2.startDate ? -1 : 1));
    const projectToCollect =
      fiscalYear === CURRENT_FY
        ? CalcProjectToCollect({
            amountCollected: billing?.amountCollected,
            numVehicles: vms?.numVehicles,
            surcharges,
            newSurcharge: null,
            numMon: 0,
          })
        : null;

    // TODO: update the condition for inclusion of agencies
    if (vms || surcharges?.length)
      result.fundings.push({
        fiscalYear,
        agencyCode,
        name: agencyInfo.name,
        shortName: agencyInfo.shortName,
        agencyIndicator: ind,
        amountFunded: fund?.amountFunded,
        numVehicles: vms?.numVehicles,
        amountCollected: billing?.amountCollected,
        amountSpent: store?.amountSpent,
        remainingFunds: fund?.amountFunded && store?.amountSpent ? fund.amountFunded - store.amountSpent : null,
        balance: billing?.amountCollected && store?.amountSpent ? billing.amountCollected - store.amountSpent : null,
        projectToCollect,
        projectToSpend: store?.projectToSpend,
        projectedBalance: projectToCollect && store?.projectToSpend ? projectToCollect - store.projectToSpend : null,
        surcharges,
      });
  });
  if (!result.fundings.length) return null;

  const calcTotal = (field) => result.fundings.reduce((sum, f) => sum + (parseFloat(f[field]) || 0.0), 0.0);

  return {
    ...result,
    totalNumVehicles: result.fundings.reduce((sum, f) => sum + (f.numVehicles || 0), 0),
    totalSurcharges: findSurcharge(result.fundings, fiscalYear),
    totalAmountFunded: calcTotal('amountFunded'),
    totalAmountCollected: calcTotal('amountCollected'),
    totalAmountSpent: calcTotal('amountSpent'),
    totalRemainingFunds: calcTotal('remainingFunds'),
    totalBalance: calcTotal('balance'),
    totalProjectToCollect: calcTotal('projectToCollect'),
    totalProjectToSpend: calcTotal('projectToSpend'),
    totalProjectedBalance: calcTotal('projectedBalance'),
  };
};

// AFV Surcharge Comment actions
export const AFV_SURCHARGE_ACTION = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  SUGGEST: 'SUGGEST',
  DISCUSS: 'DISCUSS',
  SET: 'SET',
  APPROVE: 'APPROVE',
  ADJUST: 'ADJUST',
  RECOMMEND: 'RECOMMEND',
  FINAL_RECOMMEND: 'FINAL_RECOMMEND',
};
