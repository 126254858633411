import React, { useMemo } from 'react';
import { Can } from '@gsa/afp-shared-ui-utils';
import { FilterTableFrame, Button } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import WalletListingTable from './wallet-listing-table';
import WalletSidebar from './wallet-sidebar';
import { ENTITY, ROLE_OP } from '../../components/role-permission/role-permission';

const AddLineOfAccounting = () => {
  const history = useHistory();
  return (
    <Can I={ROLE_OP.CREATE} a={ENTITY.WALLET}>
      <div className="display-flex flex-justify-end">
        <Button
          label="Line of Accounting"
          leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
          variant="primary"
          onClick={() => {
            history.push('wallet/add');
          }}
          data-testid="create-wallet-button"
        />
      </div>
    </Can>
  )
}

const WalletsListing = () => {
  const FilterGrid = useMemo(
    () => FilterTableFrame(null, AddLineOfAccounting, WalletSidebar, WalletListingTable),
    [],
  );

  return <FilterGrid filterToggle />;
};

export default WalletsListing;