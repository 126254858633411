import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import {
  AppProvider,
  PageFocus,
  Unauthorized,
  NotFound,
  InternalError,
  PublicRoute,
  PrivateRoute,
  FeatureProvider,
} from '@gsa/afp-shared-ui-utils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import OptInAndRental from './pages/opt-in-and-rental';
import LeaseRatePage from './pages/lease-rate/lease-rate-content';
import PurchaseRatePage from './pages/rates';
import UniqueRateApp from './pages/unique-rate-app';
import UniqueRateAppRemoval from './pages/unique-rate-app/unique-rate-app-removal';
import UniqueRateAppPage from './pages/unique-rate-app/unique-rate-app-content/listings-content';
import UniqueRateAppReview from './pages/unique-rate-app/unique-rate-app-review';
import UniqueRatePage from './pages/unique-rate/unique-rate-content';
import AfvFundingPage from './pages/afv-funding/afv-funding-content';
import AfvSurchargeFundingPage from './pages/afv-funding/afv-surcharge-funding';
import AfvSurchargeCustomerPage from './pages/afv-funding/afv-surcharge-customer';
import AfvAcquisitionFunding from './pages/acquisition-funding';
import PegasysErrorPage from './pages/pegasys-error-handling';
import Wallet from './pages/wallet';
import CreateWallet from './pages/wallet-details/create-wallet';
import UpdateWallet from './pages/wallet-details/update-wallet';
import dataStore from './services/data-store';
import Layout from './utilities/Layout';
import GET_ENABLED_BM_FEATURES from './featureQuery';
import '@gsa/afp-component-library/dist/css/index.css';
import './App.scss';

function App() {
  return (
    <ApolloProvider client={dataStore}>
      <AppProvider>
        <FeatureProvider featureQuery={GET_ENABLED_BM_FEATURES}>
          <Layout>
            <Router>
              <PageFocus />
              <Switch>
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.PURCHASE_RATE}
                  exact
                  path={["/", "/bm/purchase-rates"]}
                  component={PurchaseRatePage}
                  featureName="AFP_BM_PURCHASE_TYPE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.LEASE_RATE}
                  exact
                  path="/bm/lease-rates"
                  component={LeaseRatePage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.UNIQUE_RATE}
                  exact
                  path="/bm/unique-rates"
                  component={UniqueRatePage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app"
                  component={UniqueRateAppPage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.CREATE}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app/create"
                  component={UniqueRateApp}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.UPDATE}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app/update"
                  component={UniqueRateApp}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.REVIEW}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app/review"
                  component={UniqueRateAppReview}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app/view"
                  component={UniqueRateAppReview}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.CREATE}
                  subject={ENTITY.UNIQUE_RATE_APP}
                  exact
                  path="/bm/unique-rates-app/remove"
                  component={UniqueRateAppRemoval}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.AFV_SURCHARGE}
                  exact
                  path="/bm/afv-funding"
                  component={AfvFundingPage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.UPDATE}
                  subject={ENTITY.AFV_SURCHARGE}
                  exact
                  path="/bm/afv-funding/surcharge"
                  component={AfvSurchargeFundingPage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.REVIEW}
                  subject={ENTITY.AFV_SURCHARGE}
                  exact
                  path={["/bm/afv-funding/customer", "/bm/afv-funding/customer/:commentId"]}
                  component={AfvSurchargeCustomerPage}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.ACQUISITION_FUND}
                  exact
                  path={["/bm/requisition-funding"]}
                  component={AfvAcquisitionFunding}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.OPT_IN_SERVICE}
                  exact
                  path={["/bm/opt-in"]}
                  component={OptInAndRental}
                  featureName="AFP_BM_LEASE_UNIQUE_APP_AFV_RATE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.PEGASYS_ERROR}
                  exact
                  path={["/bm/pegasys-errors"]}
                  component={PegasysErrorPage}
                  featureName="AFP_BM_PURCHASE_TYPE"
                />
                <PrivateRoute
                  operation={ROLE_OP.VIEW}
                  subject={ENTITY.WALLET}
                  exact
                  path={["/bm/wallet"]}
                  component={Wallet}
                />
                <PrivateRoute
                  operation={ROLE_OP.CREATE}
                  subject={ENTITY.WALLET}
                  exact
                  path={["/bm/wallet/add"]}
                  component={CreateWallet}
                />
                <PrivateRoute
                  operation={ROLE_OP.UPDATE}
                  subject={ENTITY.WALLET}
                  exact
                  path={[`/bm/wallet/:${encodeURIComponent('boacId')}`]}
                  component={UpdateWallet}
                />
                <PublicRoute title="Access Denied" exact path="/unauthorized" component={Unauthorized} />
                <PublicRoute title="Internal Error" exact path="/internal-error" component={InternalError} />
                <PublicRoute title="Sorry for the Detour" path="*" component={NotFound} />
              </Switch>
            </Router>
          </Layout>
        </FeatureProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default App;
