import { gql } from '@apollo/client';

const PaginatedAcquisitionFundingResponse = gql`
  fragment PaginatedAcquisitionFundingResponseFields on PaginatedAcquisitionFundingResponse {
    rows {
      createdByUser
      updatedByUser
      createdAt
      updatedAt
      replacementFunding
      consolidationFunding
      additionalFunding
      createdByUserInfo {
        fullName
        email
      }
    }
    count
    hasMore
  }
`;

export const GET_HISTORICAL_ACQUISITION_FUNDING_BY_FY = gql`
  query getHistoricalAcquisitionFundingForFY($FiscalYear: String!, $Offset: Int, $Limit: Int, $Order: OrderBy) {
    getHistoricalAcquisitionFundingForFY(fiscalYear: $FiscalYear, offset: $Offset, limit: $Limit, order: $Order) {
      ...PaginatedAcquisitionFundingResponseFields
    }
  }
  ${PaginatedAcquisitionFundingResponse}
`;
