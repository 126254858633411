import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Label, Textbox, Button, Icon } from '@gsa/afp-component-library';
import { getFYInt } from 'components/helpers/afp-bm-date';
import { formatDollar, validateDollar } from 'components/helpers/afp-bm-helpers';
import { CalcProjectToCollect, CalcSurchargeToFYEnd } from './afv-funding-calculations';
import '../style/afv-funding.scss';

export default function AfvSurchargeCalculator({ data, onSetSurcharge }) {
  const [type, setType] = useState('surcharge'); // 'surcharge' or 'balance'
  const [surcharge, setSurcharge] = useState('');
  const [balance, setBalance] = useState('');

  const currentFY = getFYInt().toString();
  const { amountCollected, numVehicles, projectToSpend, surcharges } = data || {};
  const spending = parseFloat(projectToSpend);

  let defaultMon = 1;
  if (data?.fiscalYear >= currentFY) {
    const nextYear = moment(`${data?.fiscalYear}-10-01`);
    defaultMon = nextYear.diff(moment(), 'month');
    if (defaultMon < 1) defaultMon = 1;
    else if (defaultMon > 12) defaultMon = 12;
  }
  const [months, setMonths] = useState(defaultMon);

  useEffect(() => {
    onSetSurcharge(null);
    setSurcharge('');
    setBalance('');
  }, [data]);

  const validateMonths = (val) => {
    if (val === '') return true;
    if (!val) return false;
    return val.match(/^1[0-2]$|^[1-9]$/);
  };

  const onChangeType = (newType) => {
    setType(newType);
    setSurcharge('');
    setBalance('');
  };

  const onCalculate = () => {
    if (!months) return;
    if (type === 'balance' && surcharge) {
      const res = CalcProjectToCollect({
        amountCollected,
        numVehicles,
        surcharges,
        newSurcharge: surcharge,
        numMon: months,
      });
      if (res) {
        setBalance(formatDollar(res - spending, ''));
        onSetSurcharge({ surcharge, months });
      }
    } else if (type === 'surcharge' && balance) {
      const res = CalcSurchargeToFYEnd({
        amountCollected,
        numVehicles,
        surcharges,
        projectToCollect: parseFloat(balance) + spending,
        numMon: months,
      });
      if (res) {
        setSurcharge(formatDollar(res, ''));
        onSetSurcharge({ surcharge: res.toFixed(2), months });
      }
    }
  };

  if (data?.fiscalYear < currentFY || !data?.numVehicles || !data?.projectToSpend) return null;

  return (
    <div className="surcharge-calculator">
      <div className="margin-y-2">
        <h4 className="surcharge-calculator-heading">SURCHARGE AND BALANCE CALCULATOR</h4>
      </div>
      <div className="usa-form">
        <Label>Choose what you want to calculate</Label>
        <div className="usa-radio margin-bottom-1">
          <input
            className="usa-radio__input"
            type="radio"
            id="type-surcharge"
            data-testid="type-surcharge"
            name="calcType"
            checked={type === 'surcharge'}
            aria-label="Surcharge radio button"
            onChange={() => onChangeType('surcharge')}
          />
          <label className="usa-radio__label" htmlFor="type-surcharge">
            Surcharge
          </label>
        </div>
        <div className="usa-radio margin-bottom-1">
          <input
            className="usa-radio__input"
            type="radio"
            id="type-balance"
            data-testid="type-balance"
            name="calcType"
            checked={type === 'balance'}
            aria-label="Projected balance radio button"
            onChange={() => onChangeType('balance')}
          />
          <label className="usa-radio__label" htmlFor="type-balance">
            Projected balance
          </label>
        </div>
      </div>

      <div className="grid-row grid-gap flex-end-row">
        {type === 'surcharge' ? (
          <div className="grid-col-2 grid-offset-05">
            <Label>Projected balance</Label>
            <div className="usa-form-group dollar-input">
              <Icon iconName="attach_money" className="prefix-icon-dollar" />
              <Textbox
                data-testid="calc-balance"
                value={formatDollar(balance, '', -1)}
                className="text-right"
                aria-label="Projected balance input"
                onChange={(e) => {
                  if (!e.target.value) setBalance('');
                  const val = e.target.value?.split(',').join('');
                  if (validateDollar(val)) setBalance(val);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="grid-col-2">
            <Label>Surcharge</Label>
            <div className="usa-form-group dollar-input">
              <Icon iconName="attach_money" className="prefix-icon-dollar" />
              <Textbox
                data-testid="calc-surcharge"
                value={formatDollar(surcharge, '', -1)}
                className="text-right"
                aria-label="Surcharge input"
                onChange={(e) => {
                  if (!e.target.value) setSurcharge('');
                  const val = e.target.value?.split(',').join('');
                  if (validateDollar(val)) setSurcharge(val);
                }}
              />
            </div>
          </div>
        )}
        <div className="grid-col-2">
          <Label>Remaining months</Label>
          <div className="usa-form-group dollar-input">
            <Textbox
              data-testid="calc-months"
              name="calcMonths"
              value={months}
              className="text-right"
              aria-label="Remaining months input"
              onChange={(e) => {
                if (validateMonths(e.target.value)) setMonths(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid-col-2">
          <Button
            size="sm"
            variant="primary"
            data-testid="calc-btn"
            className="fund-save-btn"
            aria-label="Calculate button"
            onClick={onCalculate}
            label="Calculate"
          />
        </div>

        {type === 'surcharge' ? (
          <div className="grid-col-2">
            <Label>Surcharge</Label>
            <div className="usa-form-group dollar-input">
              <Icon iconName="attach_money" className="prefix-icon-dollar" />
              <Textbox
                data-testid="calc-surcharge"
                value={surcharge}
                className="text-right"
                readOnly="readonly"
                aria-label="Calculated surcharge"
              />
            </div>
          </div>
        ) : (
          <div className="grid-col-2 grid-offset-05">
            <Label>Projected balance</Label>
            <div className="usa-form-group dollar-input">
              <Icon iconName="attach_money" className="prefix-icon-dollar" />
              <Textbox
                data-testid="calc-balance"
                value={balance}
                className="text-right"
                readOnly="readonly"
                aria-label="Calculated projected balance"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

AfvSurchargeCalculator.propTypes = {
  data: PropTypes.shape().isRequired,
  onSetSurcharge: PropTypes.func.isRequired,
};
