import { STATUS } from 'components/helpers/constants';

export const statusOptions = [STATUS.ACTIVE, STATUS.INACTIVE];

export const getFilterItems = (pageState) => ({
  status: {
    id: 'rateStatus',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: statusOptions,
    className: 'StatusFilter',
    noChip: true,
  },
  OptInCategoryName: {
    id: 'OptInCategoryName',
    title: 'Category',
    type: 'checkbox-list',
    expanded: true,
    options: pageState.categoryFilter.options.OptInCategoryName,
    className: 'categoryFilter',
    noChip: true,
  },
});
