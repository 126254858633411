import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Label, SelectDropdown, Textbox, Icon, Spinner, CounterTag, PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import { getTodayStr, getFYInt } from 'components/helpers/afp-bm-date';
import { formatDollar } from 'components/helpers/afp-bm-helpers';
import { GET_AGENCY_BY_ID } from '../unique-rate-app/unique-rate-app-helper';
import {
  CURRENT_FY,
  AFV_SURCHARGE_ACTION,
  GET_AFV_SURCHARGES_BY_AGENCY,
  GET_AFV_RATE_COMMENT,
  CREATE_AFV_RATE_COMMENT,
  GET_MOCK_DATA_FOR_STORE_BY_AGENCY,
  GET_MOCK_DATA_FOR_VMS_BY_AGENCY,
} from './afv-funding-helper';
import AfvSurchargeConcurrence from './components/afv-surcharge-concurrence';
import AFVFundingSurchargeHistory from './components/afv-funding-surcharge-history';
import './style/afv-funding.scss';

const TITLE = 'AFV Surcharge Management';

export default function AfvSurchargeFunding() {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const [comment, setComment] = useState(undefined);
  const [fiscalYear, setFiscalYear] = useState(undefined);
  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);

  const [agencyName, setAgencyName] = useState(undefined);
  const [numVehicles, setNumVehicles] = useState(undefined);
  const [projectToSpend, setProjectToSpend] = useState('');
  const [displaySurcharge, setDisplaySurcharge] = useState('');
  const [historyData, setHistoryData] = useState(null);

  const [thankyouMsg, setThankyouMsg] = useState('');

  const history = useHistory();

  const bannerRef = useRef(null);
  const setBannerMsg = (message) => {
    setMsg(message);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { commentId } = useParams();
  if (!commentId?.match(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/))
    history.replace('/bm/afv-funding');

  const getSurchargeStatus = (isFinalized, startDate, endDate, todayStr) => {
    if (!isFinalized) return 'Recommended';
    if (todayStr < startDate) return 'Pending';
    if (todayStr > endDate) return 'Expired';
    return 'Active';
  };

  const onGqlError = (err, message) => {
    setBannerMsg({
      type: 'error',
      message: (
        <>
          {message}
          <br />
          {err.message}
        </>
      ),
    });
  };
  const [fetchAgency, { loading: loadingAgency }] = useLazyQuery(GET_AGENCY_BY_ID, {
    onCompleted: ({ getAgency }) => {
      if (getAgency?.name) setAgencyName(getAgency.name);
    },
    onError: (err) => onGqlError(err, 'Error occurred when retrieving agency information'),
  });
  const [fetchSurcharges, { loading: loadingSurcharges }] = useLazyQuery(GET_AFV_SURCHARGES_BY_AGENCY, {
    onCompleted: ({ getAFVSurchargesByAgency }) => {
      setHistoryData({
        surcharges: getAFVSurchargesByAgency.map((surcharge) => ({
          ...surcharge,
          status: getSurchargeStatus(surcharge.isFinalized, surcharge.startDate, surcharge.endDate, getTodayStr()),
        })),
      });

      if (fiscalYear === CURRENT_FY) {
        const dateStr = getTodayStr();
        const item = getAFVSurchargesByAgency.find(
          (s) => s.isFinalized && dateStr >= s.startDate && dateStr <= s.endDate,
        );
        setDisplaySurcharge(formatDollar(item?.surcharge, ''));
      }
    },
    onError: (err) => onGqlError(err, 'Error occurred when retrieving surcharges'),
  });

  const [fetchVMSData, { loading: loadingVMSData }] = useLazyQuery(GET_MOCK_DATA_FOR_VMS_BY_AGENCY, {
    onCompleted: ({ getMockDataNumVehiclesByAgency }) => {
      if (getMockDataNumVehiclesByAgency?.length) setNumVehicles(getMockDataNumVehiclesByAgency[0].numVehicles);
    },
    onError: (err) => onGqlError(err, 'Error occurred when retrieving number of vehicles from VMS'),
  });

  const [fetchStoreData, { loading: loadingStoreData }] = useLazyQuery(GET_MOCK_DATA_FOR_STORE_BY_AGENCY, {
    onCompleted: ({ getMockDataByFYAgency }) => {
      if (getMockDataByFYAgency?.length)
        setProjectToSpend(formatDollar(getMockDataByFYAgency[0].projectToSpend || '', ''));
    },
    onError: (err) => onGqlError(err, 'Error occurred when retrieving spendings from Store'),
  });

  const [fetchComment, { loading: loadingComment }] = useLazyQuery(GET_AFV_RATE_COMMENT, {
    onCompleted: ({ getAFVRateComment }) => {
      if (getAFVRateComment) setComment(getAFVRateComment);
      else history.replace('/bm/afv-funding');
    },
    onError: (err) => onGqlError(err, 'Error occurred when retrieving comment'),
  });

  const [createComment, { loading: loadingCreateComment }] = useMutation(CREATE_AFV_RATE_COMMENT, {
    onCompleted: ({ createAFVRateComment }) => {
      switch (createAFVRateComment.action) {
        case AFV_SURCHARGE_ACTION.ACCEPT:
          setThankyouMsg('Thank you for your confirmation. This has successfully been submitted to GSA Fleet.');
          break;
        case AFV_SURCHARGE_ACTION.DISCUSS:
          setThankyouMsg(
            'Thank you for your response.  GSA Fleet has been notified that you wish to discuss this surcharge.',
          );
          break;
        case AFV_SURCHARGE_ACTION.SUGGEST:
          setThankyouMsg(
            'Thank you for your suggestion.  This has successfully been submitted to GSA Fleet for review.',
          );
          break;
        case AFV_SURCHARGE_ACTION.DECLINE:
          setThankyouMsg(
            'By not accepting the suggested AFV surcharge you have agreed to manage AFV orders as needed to ensure your AFV funding is sufficient.',
          );
          break;
        default:
          setThankyouMsg('Thank you for submitting your request');
      }
    },
    onError: (err) =>
      setBannerMsg({
        type: 'error',
        message: (
          <div>
            Error occurred when saving comment
            <br />
            {err.message}
          </div>
        ),
      }),
  });

  useEffect(() => {
    fetchComment({ variables: { commentId } });

    const curYear = getFYInt();
    const endYear = curYear + 1;
    const startYear = curYear - 2;
    const yearList = [];
    for (let i = endYear; i >= startYear; i -= 1) {
      const year = i.toString();
      yearList.push({ value: year, label: year });
    }
    setFiscalYearOptions(yearList);
    setFiscalYear(CURRENT_FY);
  }, []);

  useEffect(() => {
    if (!comment) return;
    const variables = {
      agencyCode: comment.surcharge.agencyCode,
      agencyIndicator: comment.surcharge.agencyIndicator,
    };
    fetchAgency({ variables: { id: comment.surcharge.agencyCode } });
    fetchVMSData({ variables });
    fetchStoreData({
      variables: {
        ...variables,
        fiscalYear: CURRENT_FY,
      },
    });
  }, [comment]);

  useEffect(() => {
    if (!comment || !fiscalYear) return;
    fetchSurcharges({
      variables: {
        fiscalYear,
        agencyCode: comment.surcharge.agencyCode,
        agencyIndicator: comment.surcharge.agencyIndicator,
      },
    });
  }, [comment, fiscalYear]);

  const onSubmit = ({ concur, comments }) => {
    const variables = {
      responseToId: commentId,
      afvRateCommentInput: {
        action: concur ? AFV_SURCHARGE_ACTION.ACCEPT : AFV_SURCHARGE_ACTION.DISCUSS,
        comment: comments,
        afvSurchargeAmount: comment.afvSurchargeAmount,
        startDate: comment.startDate,
      },
    };
    createComment({ variables });
  };

  const showBreadcrums = () => (
    <Breadcrumbs
      current={TITLE}
      path={[
        {
          location: `${window.AFP_CONFIG.appURLs.home}/home`,
          label: 'Home',
        },
      ]}
    />
  );
  const showBannerMsg = () => (
    <div ref={bannerRef}>
      {msg && (
        <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
          {msg.message}
        </BannerMessage>
      )}
    </div>
  );

  if (thankyouMsg)
    return (
      <div id="afv-surcharge-funding" className="afv-surcharge-thankyou">
        {showBreadcrums()}
        <h1>Thank you</h1>
        <p>{thankyouMsg}</p>
      </div>
    );

  if (loadingComment || loadingAgency || loadingStoreData || loadingVMSData)
    return (
      <div id="afv-surcharge-funding" className="margin-bottom-6">
        {showBreadcrums()}
        {showBannerMsg()}
        <div className="grid-row">
          <div>
            <PageTitle title={TITLE} />
            <div className="margin-y-8">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div id="afv-surcharge-funding" className="margin-bottom-6">
      {showBreadcrums()}
      {showBannerMsg()}
      {(comment?.action === AFV_SURCHARGE_ACTION.FINAL_RECOMMEND ||
        comment?.action === AFV_SURCHARGE_ACTION.SET ||
        comment?.action === AFV_SURCHARGE_ACTION.ADJUST ||
        comment?.action === AFV_SURCHARGE_ACTION.APPROVE) && (
        <BannerMessage type="info" className="margin-top-2">
          There’s a new update to your recommended surcharge. Please review accordingly.
        </BannerMessage>
      )}

      <div className="grid-row">
        <div>
          <PageTitle title={TITLE} />
          <h2>
            {agencyName &&
              `${agencyName} - ${
                comment?.surcharge?.agencyIndicator === 'N/A' ? 'No Indicator' : comment?.surcharge?.agencyIndicator
              }`}
          </h2>
          <p>
            Assigned Vehicles
            <span className="margin-left-1">
              <CounterTag count={numVehicles || ''} />
            </span>
          </p>
        </div>
      </div>

      <div className="grid-row grid-gap">
        <div className="grid-col-2">
          <Label>Projected to spend</Label>
          <div className="dollar-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
            <Textbox
              id="project-to-spend"
              name="projectToSpend"
              value={projectToSpend}
              className="text-right"
              readonly
            />
          </div>
        </div>
        <div className="grid-col-2">
          <Label>Current surcharge</Label>
          <div className="dollar-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
            <Textbox
              id="current-surcharge"
              name="surcharge"
              value={displaySurcharge || 'N/A'}
              className="text-right"
              readonly
            />
          </div>
        </div>
      </div>

      <h2 className="title-m-bold margin-top-6">Your recommended surcharge</h2>
      {loadingCreateComment && <Spinner />}
      <AfvSurchargeConcurrence data={comment} onSubmit={onSubmit} />

      <h2 className="title-m-bold margin-top-6">Surcharge History</h2>
      <div className="grid-col-2">
        <SelectDropdown
          required
          id="fiscal-year"
          data-testid="fiscal-year"
          name="fiscalYear"
          value={fiscalYear}
          label="Fiscal year"
          options={fiscalYearOptions}
          onChange={(e) => {
            setFiscalYear(e.target.value);
          }}
        />
      </div>
      <div className="margin-top-1">
        <AFVFundingSurchargeHistory data={historyData} readonly loading={loadingSurcharges} />
      </div>
    </div>
  );
}
