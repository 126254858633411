import { gql } from '@apollo/client';

export const PaginatedOptInServiceResponse = gql`
  fragment PaginatedOptInServiceFields on PaginatedOptInServiceResponse {
    rows {
      optInSvcId
      optInSvcName
      optInSvcDesc
      optInSvcCategory
      rateStatus
      startDate
      endDate
      optInSvcSalesId
      optInServiceAssociationObj {
        tierLowerRange
        optInFixedRate
        optInFlatDollar
        optInBillingFreq
      }
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      lastUpdateUserInfo {
        fullName
        email
      }
      comment
      createdAt
      dataSource
      dmlFlag
    }
    count
    hasMore
  }
`;

export const GET_OPT_IN_SERVICE = gql`
  query getOptInService(
    $associationFilter: FilterType
    $virtualFilter: FilterType
    $filters: FilterType
    $offset: Int
    $limit: Int
    $order: OrderBy
  ) {
    getOptInService(
      associationFilter: $associationFilter
      virtualFilter: $virtualFilter
      filters: $filters
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...PaginatedOptInServiceFields
    }
  }
  ${PaginatedOptInServiceResponse}
`;
