import React from 'react';
import { Can } from '@gsa/afp-shared-ui-utils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import { dispatchBannerMessage } from 'utilities/componentUtils';
import { getFYInt } from 'components/helpers/afp-bm-date';
import { stateValue } from 'utilities/stateUtils';
import DistributeFundsTitle from '../components/DistributeFundsTitle';
import InitialFundingForm from '../components/InitialFundingForm';
import AcquisitionCalculator from '../components/AcquisitionCalculator';
import AcquisitionTabs from '../components/AcquisitionTabs';
import useDataController from '../model/useDataController';
import FiscalYearSelect from '../components/FiscalYearSelect';

/* eslint-disable react/prop-types */

const currentFYInt = getFYInt();

const FormContainer = ({ pageState, setDispatch, DISPATCH_FNS }) => {
  const { updateFunds, loading, query } = useDataController({
    dispatchBannerMessage,
    setDispatch,
    DISPATCH_FNS,
    pageState,
  });

  const { fetchLatestFunding, fetchHistorical } = query;

  const { balanceLoading, historyLoading, ...pageLoading } = loading;

  // The initial will pull current Fiscal year data
  React.useEffect(() => {
    fetchLatestFunding({
      variables: {
        FiscalYear: currentFYInt.toString(),
      },
    });
  }, []);

  return (
    <>
      <FiscalYearSelect
        pageState={pageState}
        setDispatch={setDispatch}
        DISPATCH_FNS={DISPATCH_FNS}
        fetchLatestFunding={fetchLatestFunding}
        currentFYInt={currentFYInt}
      />
      <LoadingQueryResult loadingObject={pageLoading}>
        <Can I={ROLE_OP.MANAGE} a={ENTITY.ACQUISITION_FUND}>
          <DistributeFundsTitle
            title="DISTRIBUTE FUNDS"
            desc="Distribute dollar amount by category to set the total."
          />
          <InitialFundingForm
            pageState={pageState}
            setDispatch={setDispatch}
            DISPATCH_FNS={DISPATCH_FNS}
            updateFunds={updateFunds}
          />

          {stateValue(pageState.fundingInitialed) && (
            <AcquisitionCalculator
              pageState={pageState}
              setDispatch={setDispatch}
              DISPATCH_FNS={DISPATCH_FNS}
              updateFunds={updateFunds}
            />
          )}
        </Can>
        <AcquisitionTabs
          pageState={pageState}
          setDispatch={setDispatch}
          DISPATCH_FNS={DISPATCH_FNS}
          tabLoading={{ balanceLoading, historyLoading }}
          fetchRows={fetchHistorical}
        />
      </LoadingQueryResult>
    </>
  );
};

export default FormContainer;
