import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Icon, Spinner, ComboBox } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { ENTITY } from 'components/role-permission/role-permission';
import { genRowActions, getActions } from 'components/role-permission/row-action';
import AfpTable from 'widgets/afp-table-wrapper';
import BannerMessage from 'widgets/banner-message';
import { GET_CATALOG_CODES, GET_STANDARD_ITEM } from 'services/data-layer/catalog-standards';
import {
  MOVE_SIN_NUM_FUEL_TYPE_TO_NEW_LRC,
  DELETE_LEASE_RATE_CODE_SIN_NUM_FUEL_TYPE,
  ADD_SIN_NUM_FUEL_TYPE_TO_LRC,
  VERIFY_LEASE_RATE_ASSOC_EXIST,
} from '../lease-rate-helper';
import './sin-fuel-type-table.scss';

const ROLE_ENTITY = ENTITY.LEASE_RATE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = [ACTIONS.REMOVE, ACTIONS.MOVE];
const ROW_ACTIONS_MOVE = [ACTIONS.MOVE];

export const standardItemFilters = {
  offset: 0,
  limit: 100,
  order: 'standardItemNumber',
  filters: {
    operator: '$exact',
    key: 'status',
    value: 'Active',
  },
};

export const catalogCodeFilters = {
  filters: {
    operator: 'AND',
    value: [{ operator: 'EQ', key: '$metadata.category$', value: 'Fuel Type' }],
  },
};

export const SinFuelTypeTable = ({
  leaseRateCode,
  leaseRateAssociation: lrcAssociation,
  distinctCodes,
  onlyMove,
  setRefresh,
}) => {
  const [bannerMsg, setBannerMsg] = useState(null);
  const [movedSinAndFuelType, setMovedSinAndFuelType] = useState(false);
  const [leaseRateAssociation, setLeaseRateAssociation] = useState([...lrcAssociation]);
  const [tableRows, setTableRows] = useState([]);
  const [sinOptions, setSinOptions] = useState();
  const [fuelTypeOptions, setFuelTypeOptions] = useState();
  const [selectedSIN, setSelectedSIN] = useState('');
  const [selectedFuel, setSelectedFuel] = useState('');
  const [leaseRateAssociationToBeMoved, setLeaseRateAssociationToBeMoved] = useState(null);

  const [createLeaseRateAssociationForExistingLRC, { data: addSinMutationData, loading: loadingAdd }] = useMutation(
    ADD_SIN_NUM_FUEL_TYPE_TO_LRC,
    {
      onError: (err) =>
        setBannerMsg({
          type: 'error',
          message: (
            <div>
              Error occured when adding{' '}
              <b>
                {selectedSIN} - {selectedFuel}
              </b>{' '}
              to Lease Rate Code <b>{leaseRateCode}</b>.
              <br />
              {err.message}
            </div>
          ),
        }),
    },
  );

  const [moveSinNumberFuelTypeToNewLeaseRateCode, { data: moveSinMutationData, loading: loadingMove }] = useMutation(
    MOVE_SIN_NUM_FUEL_TYPE_TO_NEW_LRC,
    {
      onError: (err) =>
        setBannerMsg({
          type: 'error',
          message: (
            <div>
              Error occured when moving{' '}
              <b>
                {movedSinAndFuelType.standardItemNumber} - {movedSinAndFuelType.fuelType}
              </b>{' '}
              to the Lease Rate Code <b>{movedSinAndFuelType.newLeaseRateCode}</b>.
              <br />
              {err.message}
            </div>
          ),
        }),
    },
  );

  const [deleteLeaseRateCodeSinNumberFuelType, { data: removeSinFuelTypeData, loading: loadingRemove }] = useMutation(
    DELETE_LEASE_RATE_CODE_SIN_NUM_FUEL_TYPE,
    {
      onError: (err) =>
        setBannerMsg({
          type: 'error',
          message: (
            <div>
              Error occured when removing the combination from Lease Rate Code <b>{leaseRateCode}</b>.
              <br />
              {err.message}
            </div>
          ),
        }),
    },
  );

  const [getStandardItems] = useLazyQuery(GET_STANDARD_ITEM, {
    variables: standardItemFilters,
    onCompleted: (data) => {
      if (data?.getStandardItems) {
        const options = [...new Set(data.getStandardItems.rows.map((d) => d.standardItemNumber))]
          .sort()
          .map((x) => ({ value: x, label: x }));
        setSinOptions(options);
      }
    },
    onError: () => {
      setBannerMsg({
        type: 'error',
        message: 'Error occured when retrieving Standard Item Numbers. Please try again later.',
      });
    },
  });

  const [getCatalogCodes] = useLazyQuery(GET_CATALOG_CODES, {
    variables: catalogCodeFilters,
    onCompleted: (data) => {
      if (data?.getStandardCodes) {
        const options = [...new Set(data.getStandardCodes.rows.map((d) => d.additionalProps.shortHand))]
          .sort()
          .map((x) => ({ value: x, label: x }));
        setFuelTypeOptions(options);
      }
    },
    onError: () => {
      setBannerMsg({
        type: 'error',
        message: 'Error occured when retrieving fuel types. Please try again later.',
      });
    },
  });

  useEffect(() => {
    getStandardItems();
    getCatalogCodes();
  }, []);

  useEffect(() => {
    if (onlyMove) setTableRows([...leaseRateAssociation]);
    else if (sinOptions?.length && fuelTypeOptions?.length) {
      setTableRows([
        {
          isAddRow: true,
          fuelType: 'add',
          standardItemNumber: 'add',
        },
        ...leaseRateAssociation,
      ]);
    }
  }, [leaseRateAssociation, sinOptions, fuelTypeOptions]);

  useEffect(() => {
    if (!addSinMutationData) return;
    const { standardItemNumber, fuelType } = addSinMutationData.createLeaseRateAssociationForExistingLRC;

    // success message for add
    setBannerMsg({
      type: 'success',
      message: (
        <div>
          You have successfully added{' '}
          <b>
            {standardItemNumber} - {fuelType}
          </b>{' '}
          to Lease Rate Code <b>{leaseRateCode}</b>.
        </div>
      ),
    });
    setRefresh(true);

    // add new sin-fuel to table
    const updatedLeaseRateAssociation = [...leaseRateAssociation];
    updatedLeaseRateAssociation.push({
      fuelType,
      standardItemNumber,
    });
    setLeaseRateAssociation(updatedLeaseRateAssociation);
  }, [addSinMutationData]);

  useEffect(() => {
    if (!moveSinMutationData && !removeSinFuelTypeData) return;

    const { standardItemNumber: movedSin, fuelType: movedFuelType } = movedSinAndFuelType;

    // success message for move
    if (moveSinMutationData) {
      setBannerMsg({
        type: 'success',
        message: (
          <div>
            You have successfully moved{' '}
            <b>
              {movedSin} - {movedFuelType}
            </b>{' '}
            to Lease Rate Code <b>{movedSinAndFuelType.newLeaseRateCode}</b>.
          </div>
        ),
      });
    }

    // success message for remove
    if (removeSinFuelTypeData) {
      setBannerMsg({
        type: 'success',
        message: (
          <div>
            You have successfully removed{' '}
            <b>
              {movedSin} - {movedFuelType}
            </b>{' '}
            from Lease Rate Code <b>{leaseRateCode}</b>.
          </div>
        ),
      });
    }

    // update table - remove one match
    const ind = leaseRateAssociation.findIndex(
      ({ standardItemNumber, fuelType }) => standardItemNumber === movedSin && fuelType === movedFuelType,
    );
    const updatedLeaseRateAssociation = [...leaseRateAssociation];
    updatedLeaseRateAssociation.splice(ind, 1);
    setLeaseRateAssociation(updatedLeaseRateAssociation);
  }, [moveSinMutationData, removeSinFuelTypeData]);

  const onSort = (val) => {
    const res = val.split(' ');
    const accessor = res[0].split('`')[1];
    const order = res[1] === 'DESC' ? 1 : -1;
    setLeaseRateAssociation([...leaseRateAssociation].sort((a, b) => (a[accessor] < b[accessor] ? order : -order)));
  };

  const displayLRCs = (lrcs) => {
    const items = [...new Set(lrcs)];
    switch (items.length) {
      case 0:
        return '';
      case 1:
        return items[0];
      case 2:
        return `${items[0]} and ${items[1]}`;
      default:
        return `${items.slice(0, items.length - 1).join(', ')}, and ${items[items.length - 1]}`;
    }
  };

  const getSinFuelInputs = () => ({
    sin: document.querySelector(`#sin-fuel-type-table div[data-testid='sin-options'] input`).value,
    fuel: document.querySelector(`#sin-fuel-type-table div[data-testid='fuel-type-options'] input`).value,
  });

  const onCompleteAdd = () => {
    const { sin, fuel } = getSinFuelInputs();
    if (sin && fuel)
      createLeaseRateAssociationForExistingLRC({
        variables: {
          leaseRateCode,
          sinNumber: sin,
          fuelType: fuel,
        },
      });
  };

  const [verifyCodeOnAdd] = useLazyQuery(VERIFY_LEASE_RATE_ASSOC_EXIST, {
    onCompleted: (res) => {
      if (!res) return;

      const dupLrcodes = res.verifyLeaseRateAssocExist.map((item) => item.leaseRateCode).sort();
      if (dupLrcodes.find((lrc) => leaseRateCode === lrc)) {
        setBannerMsg({
          type: 'error',
          message: 'This Standard Item Number and fuel type combination already exists in this Lease Rate Code.',
        });
      } else if (dupLrcodes.length) {
        setBannerMsg({
          type: 'error',
          message: (
            <div className="margin-bottom-1">
              This Standard Item Number and fuel type combination already exists within the Lease Rate Code
              {dupLrcodes.length > 1 ? 's' : ''} <b>{displayLRCs(dupLrcodes)}</b>. and cannot be added to a second Lease
              Rate Code.
            </div>
          ),
        });
      } else {
        onCompleteAdd();
      }
    },
  });

  const doAdd = () => {
    const { sin, fuel } = getSinFuelInputs();
    if (!sin || !fuel) {
      setBannerMsg({
        type: 'error',
        message: 'Standard Item Number and fuel type are required.',
      });
    } else {
      verifyCodeOnAdd({
        variables: {
          sinNumber: sin,
          fuelType: fuel,
        },
      });
    }
  };

  const onCompleteMove = () => {
    const { oldLeaseRateCode, newLeaseRateCode, sinNumber, fuelType } = leaseRateAssociationToBeMoved;

    const moveSinAndFuelTypeData = {
      variables: {
        oldLeaseRateCode,
        newLeaseRateCode,
        sinNumber,
        fuelType,
      },
    };
    moveSinNumberFuelTypeToNewLeaseRateCode(moveSinAndFuelTypeData);

    setMovedSinAndFuelType({
      standardItemNumber: sinNumber,
      fuelType,
      newLeaseRateCode,
    });

    setRefresh(true);
  };

  const [verifyCodeOnMove] = useLazyQuery(VERIFY_LEASE_RATE_ASSOC_EXIST, {
    onCompleted: (res) => {
      if (!res) return;
      const lrcodes = res.verifyLeaseRateAssocExist
        .map((item) => item.leaseRateCode)
        .filter((lrc) => lrc !== leaseRateCode)
        .sort();
      if (lrcodes.length === 0) {
        // no duplicate - move
        onCompleteMove();
      } else if (lrcodes.find((lrc) => lrc === leaseRateAssociationToBeMoved.newLeaseRateCode)) {
        // duplicate found in target - error
        setBannerMsg({
          type: 'error',
          message: (
            <div className="margin-bottom-1">
              This Standard Item Number and fuel type combination already exists within{' '}
              <b>{leaseRateAssociationToBeMoved.newLeaseRateCode}</b>.
            </div>
          ),
        });
      } else {
        // duplicate found in other codes - warning
        setBannerMsg({
          type: 'warning',
          message: (
            <div className="margin-bottom-1">
              This Standard Item Number and fuel type combination already exists within the Lease Rate Code
              {lrcodes.length > 1 ? 's' : ''} <b>{displayLRCs(lrcodes)}</b>. Are you sure you want to proceed in moving
              this combination to <b>{leaseRateAssociationToBeMoved.newLeaseRateCode}</b>?
              <div>
                <Button
                  className="margin-right-3"
                  variant="unstyled"
                  onClick={onCompleteMove}
                  label="Yes, complete this action"
                />
                <Button variant="unstyled" onClick={() => setBannerMsg(null)} label="No, cancel action" />
              </div>
            </div>
          ),
        });
      }
    },
  });

  const onMoveSave = async ({ id, values }) => {
    const { fuelType: originalFuelType, standardItemNumber: originalStandardItemNumber } = values;

    const selectedValue = document.querySelector(`#sin-fuel-type-table tr[data-testid='sub-component-${id}'] input`);

    if (!distinctCodes.find((v) => v.value === selectedValue.value)) {
      setBannerMsg({
        type: 'error',
        message: 'Please select a valid Lease Rate Code.',
      });
      return;
    }

    setLeaseRateAssociationToBeMoved({
      id,
      oldLeaseRateCode: leaseRateCode,
      newLeaseRateCode: selectedValue.value,
      sinNumber: originalStandardItemNumber,
      fuelType: originalFuelType,
    });

    verifyCodeOnMove({
      variables: {
        sinNumber: originalStandardItemNumber,
        fuelType: originalFuelType,
      },
    });
  };

  const onCompleteRemove = (row) => {
    const { standardItemNumber: sinNumber, fuelType } = row.values;

    const deleteLrcSinNumberFuelTypeData = {
      variables: {
        leaseRateCode,
        sinNumber,
        fuelType,
      },
    };
    deleteLeaseRateCodeSinNumberFuelType(deleteLrcSinNumberFuelTypeData);

    setMovedSinAndFuelType({
      standardItemNumber: sinNumber,
      fuelType,
    });
    setRefresh(true);
  };

  const handleSelectedAction = (label, original, row) => {
    if (label === 'Move') {
      if (!row.isExpanded) {
        row.getToggleRowExpandedProps().onClick();
        setBannerMsg(null);
      }
    } else if (label === 'Remove') {
      if (row.isExpanded) row.getToggleRowExpandedProps().onClick();
      const { standardItemNumber, fuelType } = original;
      setBannerMsg({
        type: 'warning',
        message: (
          <div>
            Please move the combination{' '}
            <b>
              {standardItemNumber} - {fuelType}
            </b>{' '}
            to another Lease Rate Code. Otherwise, all of its vehicles may no longer correspond to a Lease Rate Code.{' '}
            <div>
              <Button
                className="margin-right-3"
                variant="unstyled"
                onClick={() => onCompleteRemove(row)}
                label="Yes, complete this action"
              />
              <Button variant="unstyled" onClick={() => setBannerMsg(null)} label="No, cancel action" />
            </div>
          </div>
        ),
      });
    }
  };

  // render move-combination-block in expanded row
  const renderRowSubComponent = useCallback(({ row }) => {
    if (row.original.isAddRow) return null;
    return (
      <div className="sin-fuel-move-container">
        <div data-testid={onlyMove ? 'move-only-input' : 'move-input'}>
          <p>Move this combination to another Lease Rate Code</p>
          <Icon iconName="arrow_forward" className="usa-icon--size-3 margin-x-1" />
          <ComboBox
            options={distinctCodes.map((d) => ({ label: d.value, value: d.value }))}
            onChange={(value) => value}
          />
        </div>
        <div>
          <Button
            onClick={() => onMoveSave(row)}
            data-testid={onlyMove ? 'move-only-save-btn' : 'move-save-btn'}
            leftIcon={{ name: 'check' }}
            label="Save"
          />
          <button
            type="button"
            title="Cancel"
            onClick={row.getToggleRowExpandedProps().onClick}
            data-testid={onlyMove ? 'move-only-cancel-btn' : 'move-cancel-btn'}
            className="move-cancel-btn"
          >
            <Icon className="iconStyle usa-icon--size-3" iconName="cancel" />
          </button>
        </div>
      </div>
    );
  }, []);

  const columns = [
    {
      Header: 'Standard Item Number',
      accessor: 'standardItemNumber',
      classNames: 'cell-left',
      Cell: ({ row }) => {
        // eslint-disable-next-line
        if (row.original.isAddRow)
          return (
            <div style={{ paddingRight: '2rem' }} data-testid="sin-options">
              <ComboBox options={sinOptions} onChange={(value) => setSelectedSIN(value || '')} />
            </div>
          );
        return (
          <div className="original-cell">
            <p className="item-number margin-0">{row.values.standardItemNumber}</p>
          </div>
        );
      },
    },
    {
      Header: 'Fuel type',
      accessor: 'fuelType',
      Cell: ({ row }) => {
        // eslint-disable-next-line
        if (row.original.isAddRow)
          return (
            <div style={{ paddingRight: '2rem' }} data-testid="fuel-type-options">
              <ComboBox options={fuelTypeOptions} onChange={(value) => setSelectedFuel(value || '')} />
            </div>
          );
        return (
          <div className="original-cell">
            <p className="margin-0">{row.values.fuelType}</p>
          </div>
        );
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      cellClassName: 'cell-right',
      headerClassName: 'cell-right',
      Cell: (rowData) => {
        // eslint-disable-next-line
        if (rowData.row.original.isAddRow)
          return (
            <div className="add-assoc-btn">
              <Button variant="outline" onClick={doAdd} leftIcon={{ name: 'add' }} label="Add" />
            </div>
          );
        if (onlyMove) return genRowActions(ROW_ACTIONS_MOVE || [], rowData, handleSelectedAction);
        return genRowActions(ROW_ACTIONS || [], rowData, handleSelectedAction);
      },
    },
  ];
  const isLoading = loadingAdd || loadingMove || loadingRemove;
  return (
    <>
      {isLoading && (
        <div style={{ top: '50px', width: '100%' }}>
          <Spinner />
        </div>
      )}

      {bannerMsg && (
        <BannerMessage className="margin-bottom-2" type={bannerMsg.type} onClose={() => setBannerMsg(null)}>
          {bannerMsg.message}
        </BannerMessage>
      )}

      <div id="sin-fuel-type-table">
        <AfpTable
          testId="sin-fuel-type"
          isLoading={isLoading}
          columns={columns}
          data={tableRows || []}
          renderRowSubComponent={renderRowSubComponent}
          onSort={onSort}
          fullWidth
        />
      </div>
    </>
  );
};

SinFuelTypeTable.defaultProps = {
  onlyMove: false,
  row: { value: {}, original: {} },
};

SinFuelTypeTable.propTypes = {
  leaseRateCode: PropTypes.string.isRequired,
  leaseRateAssociation: PropTypes.instanceOf(Array).isRequired,
  distinctCodes: PropTypes.instanceOf(Array).isRequired,
  onlyMove: PropTypes.bool,
  setRefresh: PropTypes.func.isRequired,
  row: PropTypes.shape({
    values: PropTypes.shape({
      standardItemNumber: PropTypes.string,
      fuelType: PropTypes.string,
    }),
    original: PropTypes.shape({
      isAddRow: PropTypes.bool,
    }),
  }),
};
