import React from 'react';
import { Pagination } from '@gsa/afp-component-library';
import AFPTable from 'widgets/afp-table-wrapper';
import { useBoolToggle } from 'hooks/useBoolToggle';
import { updateSort } from 'utilities/bmAppUtilities';
import { stateValue } from 'utilities/stateUtils';
import historyTableColumns from '../model/schemas/historyTableSchema';

export const handlePaginationChange = ({
  setDispatch,
  DISPATCH_FNS,
  pageState,
  resetPaginationComponent,
  fetchRows,
}) => (currentPage, itemsPerPage) => {
  const offSet = (currentPage - 1) * itemsPerPage;
  if (itemsPerPage !== pageState.historyTab.limit) {
    window.scrollTo({ top: 200, behavior: 'smooth' });
    setDispatch(DISPATCH_FNS.setItemPerPage, {
      limit: itemsPerPage,
      resetPaginationComponent,
      tableName: `historyTab`,
    });
  } else {
    setDispatch(DISPATCH_FNS.updateStateByPath, {
      path: `historyTab.currentPage`,
      data: currentPage,
    });
  }

  setDispatch(DISPATCH_FNS.updateStateByPath, {
    path: `historyTab.offset`,
    data: offSet,
  });
  setDispatch(DISPATCH_FNS.updateStateByPath, {
    path: `selectedTabId`,
    data: 'history-tab',
  });

  fetchRows({
    variables: {
      FiscalYear: stateValue(pageState.fiscalYear),
      Offset: offSet,
      Limit: itemsPerPage,
      Order: stateValue(pageState.historyTab.order),
    },
  });
};

/* eslint-disable react/prop-types */
const HistoryTable = ({ pageState, setDispatch, DISPATCH_FNS, historyLoading, fetchRows, historyRowsData }) => {
  const [isPaginationComponentReset, resetPaginationComponent] = useBoolToggle();

  const onSort = (orderStr) => {
    if (orderStr) {
      const Order = updateSort({ originalSort: pageState.historyTab.order, orderStr });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'historyTab.order',
        data: Order,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: `selectedTabId`,
        data: 'history-tab',
      });
      fetchRows({
        variables: {
          FiscalYear: stateValue(pageState.fiscalYear),
          Offset: stateValue(pageState.historyTab.offset),
          Limit: stateValue(pageState.historyTab.limit),
          Order,
        },
      });
    }
  };
  return (
    <div className="grid-col-12">
      <AFPTable
        isLoading={historyLoading}
        columns={historyTableColumns}
        onSort={onSort}
        data={historyRowsData}
        NoDataMessages={{
          title: 'No Data Available',
          text: `There's no data available`,
        }}
        defaultSort={pageState.historyTab.order}
        fullWidth
      />
      {!historyLoading && (
        <div className="padding-y-4">
          <Pagination
            onPageChange={handlePaginationChange({
              setDispatch,
              DISPATCH_FNS,
              pageState,
              resetPaginationComponent,
              fetchRows,
            })}
            variant="advanced"
            currentPage={pageState.historyTab.currentPage}
            itemsCount={pageState.historyTab.itemsCount}
            itemsPerPage={pageState.historyTab.limit}
            isReset={isPaginationComponentReset}
          />
        </div>
      )}
    </div>
  );
};

export default HistoryTable;
