import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Spinner, Alert } from '@gsa/afp-component-library';
import { getCurrentFiscalYear } from 'utilities/dateUtils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import { isEmpty } from 'lodash';
import { scrollInto } from 'utilities/bmAppUtilities';
import WalletBasicInfoFormFields from './wallet-basic-info-form-fields';
import WalletTasFormFields from './wallet-tas-form-fields';
import BoacOfferingFormFields from './boac-offering-form-fields';
import WalletIpacFormFields from './wallet-ipac-form-fields';
import WalletIpacVehiclesTable from '../wallet-ipac-vehicles-table';
import { useWalletDetails } from '../wallet-details-provider';
import {
  CREATE_WALLET_BASIC_FIELDS,
  generateWalletTasFields,
  CREATE_WALLET_ASSOCIATION_FIELDS,
  generateWalletIpacFields,
  isDod,
} from './consts';
import { applyApiValidation, isLeasingType } from '../helpers/utils';
import { CREATE_WALLET_DOD_LEASING_SUCCESS_MESSAGE } from '../messages';

const sectionHeaderClasses = classnames('title-s-caps text-primary margin-bottom-1');

const CREATE_WALLET_TAS_FIELDS = generateWalletTasFields();
const CREATE_WALLET_IPAC_FIELDS = generateWalletIpacFields();

const PURCHASE_SALES_GROUP_ID = 1;

const ManageWalletForm = () => {
  const history = useHistory();

  const {
    getAgenciesByPermission,
    createWallet,
    updateWallet,
    createWalletLoading,
    updateWalletLoading,
    wallet,
    boacOfferingAssociation,
    defaultValues,
    apiValidationErrors,
    createdWallet,
  } = useWalletDetails();

  const alphanumericOrEmptyRegExp = /^[a-zA-Z0-9]*$/i;
  const alphanumericOrEmptyOrMiddleSpace = /^(?! )(?!\s)(?!.* {2})[a-zA-Z0-9\s]*?(?<!\s)$/;

  const generateWalletFormSchema = () => 
    yup.object().shape(
      {
        [CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]: yup
          .number()
          .when([], {
            is: () => !wallet && !boacOfferingAssociation,
            then: yup.number().required(),
          })
          .label(CREATE_WALLET_BASIC_FIELDS.fiscalYear.label),
        [CREATE_WALLET_BASIC_FIELDS.agency.name]: yup
          .string()
          .when([], {
            is: () => !wallet && !boacOfferingAssociation,
            then: yup.string().required(),
          })
          .label(CREATE_WALLET_BASIC_FIELDS.agency.label),
        [CREATE_WALLET_BASIC_FIELDS.bureau.name]: yup
          .string()
          .when([], {
            is: () => !wallet && !boacOfferingAssociation,
            then: yup.string().required(),
          })
          .label(CREATE_WALLET_BASIC_FIELDS.bureau.label),
        [CREATE_WALLET_TAS_FIELDS.agencyIdentifier.name]: yup
          .string()
          .label(CREATE_WALLET_TAS_FIELDS.agencyIdentifier.label),
        [CREATE_WALLET_TAS_FIELDS.availabilityType.name]: yup
          .string()
          .label(CREATE_WALLET_TAS_FIELDS.availabilityType.label),
        [CREATE_WALLET_TAS_FIELDS.beginningPeriodOfAvailability.name]: yup
          .number()
          .when(CREATE_WALLET_TAS_FIELDS.availabilityType.name, (availabilityType, schema) => {
            return !availabilityType
              ? schema.min(1, `${CREATE_WALLET_TAS_FIELDS.beginningPeriodOfAvailability.label} is a required field`)
              : schema;
          })
          .test('Beginning Period', 'BPOA must be the same as or before EPOA', (bpoa, testWallet) => {
            return testWallet.parent.endingPeriodOfAvailability && bpoa
              ? testWallet.parent.endingPeriodOfAvailability >= bpoa
              : true;
          })
          .label(CREATE_WALLET_TAS_FIELDS.beginningPeriodOfAvailability.label),
        [CREATE_WALLET_TAS_FIELDS.endingPeriodOfAvailability.name]: yup
          .number()
          .when([CREATE_WALLET_TAS_FIELDS.availabilityType.name], (availabilityType, schema) => {
            return !availabilityType
              ? schema.min(1, `${CREATE_WALLET_TAS_FIELDS.endingPeriodOfAvailability.label} is a required field`)
              : schema;
          })
          .test('Ending Period', 'EPOA must be the same as or after BPOA', (epoa, testWallet) => {
            return testWallet.parent.beginningPeriodOfAvailability && epoa
              ? epoa >= testWallet.parent.beginningPeriodOfAvailability
              : true;
          })
          .label(CREATE_WALLET_TAS_FIELDS.endingPeriodOfAvailability.label),
        [CREATE_WALLET_TAS_FIELDS.mainAccount.name]: yup
          .string()
          .required()
          .max(4, 'Must be 4 characters.')
          .min(4, 'Must be 4 characters.')
          .matches(/^[0-9]*$/, 'Must be 0000-9999')
          .label(CREATE_WALLET_TAS_FIELDS.mainAccount.label),
        [CREATE_WALLET_TAS_FIELDS.subAccount.name]: yup
          .string()
          .required()
          .max(3, 'Must be 3 characters.')
          .min(3, 'Must be 3 characters')
          .matches(/^[0-9]*$/, 'Must be 000-999')
          .label(CREATE_WALLET_TAS_FIELDS.subAccount.label),
        [CREATE_WALLET_TAS_FIELDS.subLevelPrefix.name]: yup
          .string()
          .matches(/^[0-9]*$/, 'Must be 00-99')
          .when(CREATE_WALLET_TAS_FIELDS.subLevelPrefix.name, {
            is: (exists) => !!exists,
            then: yup.string().max(2, 'Must be 00-99').min(2, 'Must be 00-99'),
            otherwise: yup.string(),
          })
          .label(CREATE_WALLET_TAS_FIELDS.subLevelPrefix.label),
        [CREATE_WALLET_TAS_FIELDS.allocationTransferAgency.name]: yup
          .string()
          .matches(/^[0-9]*$/, 'Must be 000-999')
          .when(CREATE_WALLET_TAS_FIELDS.allocationTransferAgency.name, {
            is: (exists) => !!exists,
            then: yup.string().max(3, 'Must be 000-999').min(3, 'Must be 000-999'),
            otherwise: yup.string(),
          })
          .label(CREATE_WALLET_TAS_FIELDS.allocationTransferAgency.label),
        [CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name]: yup
          .string()
          .when([], {
            is: () => !wallet && !boacOfferingAssociation,
            then: yup.string().required(),
          })
          .label(CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.label),
        [CREATE_WALLET_IPAC_FIELDS.fiscalStation.name]: yup
          .string()
          .max(6, 'Must be 6 characters or less')
          .when(CREATE_WALLET_IPAC_FIELDS.standardDocumentNumber.name, (standardDocumentNumber, schema) => {
            return standardDocumentNumber && (isDod(boacOfferingAssociation?.agency) || isDod(wallet?.boacModel?.agencyCode))
              ? schema.required(`${CREATE_WALLET_IPAC_FIELDS.fiscalStation.label} field is required to enable IPAC`)
              : schema;
          })
          .when(CREATE_WALLET_IPAC_FIELDS.bcnObanAsn.name, (bcnObanAsn, schema) => {
            return bcnObanAsn && (isDod(boacOfferingAssociation?.agency) || isDod(wallet?.boacModel?.agencyCode))
              ? schema.required(`${CREATE_WALLET_IPAC_FIELDS.fiscalStation.label} field is required to enable IPAC`)
              : schema;
          })
          .when(CREATE_WALLET_IPAC_FIELDS.limit.name, (limit, schema) => {
            return limit && (isDod(boacOfferingAssociation?.agency) || isDod(wallet?.boacModel?.agencyCode))
              ? schema.required(`${CREATE_WALLET_IPAC_FIELDS.fiscalStation.label} field is required to enable IPAC`)
              : schema;
          })
          .when(CREATE_WALLET_IPAC_FIELDS.accountInformation.name, (accountInformation, schema) => {
            return accountInformation && (isDod(boacOfferingAssociation?.agency) || isDod(wallet?.boacModel?.agencyCode))
              ? schema.required(`${CREATE_WALLET_IPAC_FIELDS.fiscalStation.label} field is required to enable IPAC`)
              : schema;
          })
          .label(CREATE_WALLET_IPAC_FIELDS.fiscalStation.label),
        [CREATE_WALLET_IPAC_FIELDS.standardDocumentNumber.name]: yup
          .string()
          .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
          .max(17, 'Must be 17 characters or less')
          .when(CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, (fiscalStation, schema) => {
            return fiscalStation
              ? schema.required(
                  `${CREATE_WALLET_IPAC_FIELDS.standardDocumentNumber.label} is a required field since the FSN is supplied`,
                )
              : schema;
          })
          .label(CREATE_WALLET_IPAC_FIELDS.standardDocumentNumber.label),
        [CREATE_WALLET_IPAC_FIELDS.bcnObanAsn.name]: yup
          .string()
          .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
          .max(6, 'Must be 6 characters or less')
          .when(CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, (fiscalStation, schema) => {
            return fiscalStation
              ? schema.required(
                  `${CREATE_WALLET_IPAC_FIELDS.bcnObanAsn.label} is a required field since the FSN is supplied`,
                )
              : schema;
          })
          .label(CREATE_WALLET_IPAC_FIELDS.bcnObanAsn.label),
        [CREATE_WALLET_IPAC_FIELDS.limit.name]: yup
          .string()
          .matches(alphanumericOrEmptyRegExp, 'No special characters allowed')
          .when(CREATE_WALLET_IPAC_FIELDS.limit.name, {
            is: (exists) => !!exists,
            then: yup.string().min(4, 'Must be 4 characters').max(4, 'Must be 4 characters'),
            otherwise: yup.string(),
          })
          .when(CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, (fiscalStation, schema) => {
            return fiscalStation
              ? schema.required(
                  `${CREATE_WALLET_IPAC_FIELDS.limit.label} is a required field since the FSN is supplied`,
                )
              : schema;
          })
          .label(CREATE_WALLET_IPAC_FIELDS.limit.label),
        [CREATE_WALLET_IPAC_FIELDS.accountInformation.name]: yup
          .string()
          .matches(alphanumericOrEmptyOrMiddleSpace, 'No special characters allowed')
          .max(54, 'Must be 54 characters or less')
          .label(CREATE_WALLET_IPAC_FIELDS.accountInformation.label),
      },
      [
        [CREATE_WALLET_TAS_FIELDS.subLevelPrefix.name, CREATE_WALLET_TAS_FIELDS.subLevelPrefix.name],
        [
          CREATE_WALLET_TAS_FIELDS.allocationTransferAgency.name,
          CREATE_WALLET_TAS_FIELDS.allocationTransferAgency.name,
        ],
        [CREATE_WALLET_IPAC_FIELDS.limit.name, CREATE_WALLET_IPAC_FIELDS.limit.name],
        [CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, CREATE_WALLET_IPAC_FIELDS.standardDocumentNumber.name],
        [CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, CREATE_WALLET_IPAC_FIELDS.bcnObanAsn.name],
        [CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, CREATE_WALLET_IPAC_FIELDS.limit.name],
        [CREATE_WALLET_IPAC_FIELDS.fiscalStation.name, CREATE_WALLET_IPAC_FIELDS.accountInformation.name],
      ],
    );

  const methods = useForm({
    resolver: yupResolver(generateWalletFormSchema()),
    defaultValues: {
      ...defaultValues,
      [CREATE_WALLET_BASIC_FIELDS.fiscalYear.name]: getCurrentFiscalYear(),
      [CREATE_WALLET_TAS_FIELDS.beginningPeriodOfAvailability.name]: 0,
      [CREATE_WALLET_TAS_FIELDS.endingPeriodOfAvailability.name]: 0,
      [CREATE_WALLET_TAS_FIELDS.mainAccount.name]: '',
      [CREATE_WALLET_TAS_FIELDS.subAccount.name]: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getAgenciesByPermission({
      variables: {
        operation: ROLE_OP.CREATE,
        subject: ENTITY.WALLET,
      },
    });
  }, []);

  useEffect(() => {
    if (apiValidationErrors.length > 0) {
      scrollInto('main-content');
      applyApiValidation(apiValidationErrors, methods.setError);
    }
  }, [apiValidationErrors]);

  const onSubmit = (values) => {
    if (wallet) {
      updateWallet({
        variables: {
          wallFieldInput: {
            ...values,
            id: wallet.id,
          },
        },
      });
    } else {
      createWallet({
        variables: {
          wallFieldInput: {
            ...values,
            agencyCode: boacOfferingAssociation.agency,
            bureauCode: boacOfferingAssociation.bureau,
            beginningPeriodOfAvailability:
              values.beginningPeriodOfAvailability === 0 ? null : values.beginningPeriodOfAvailability,
            endingPeriodOfAvailability:
              values.endingPeriodOfAvailability === 0 ? null : values.endingPeriodOfAvailability,
            fiscalYear: Number(boacOfferingAssociation?.fiscalYear),
            boacId: Number(boacOfferingAssociation?.boacId),
            salesGroupId: Number(boacOfferingAssociation?.salesGroupId),
          },
        },
      });
    }
  };

  const isEditMode = !isEmpty(wallet);
  const isAssociationSelected = !isEmpty(boacOfferingAssociation);

  return (
    <FormProvider {...methods}>
      <form data-testid="create-wallet-form" id="create-wallet-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <div data-testid="basic-info" id="basic-info" className="margin-bottom-8">
          <h2 className={sectionHeaderClasses}>Organizational Information</h2>
          <WalletBasicInfoFormFields />
        </div>
        <div data-testid="boac-association-info" id="boac-association-info" className="margin-bottom-8">
          <h2 className={sectionHeaderClasses}>BOAC association</h2>
          <BoacOfferingFormFields />
        </div>
        {(isAssociationSelected || isEditMode) && (
          <>
            <div data-testid="tas-info" id="tas-info" className="margin-bottom-8">
              <h2 className={sectionHeaderClasses}>Treasury Account Symbol (TAS)</h2>
              <WalletTasFormFields />
            </div>
            {((isEditMode && wallet?.salesGroupId !== PURCHASE_SALES_GROUP_ID) ||
              (isAssociationSelected && Number(boacOfferingAssociation?.salesGroupId) !== PURCHASE_SALES_GROUP_ID)) && (
              <div data-testid="ipac-boac-info" id="ipac-boac-info" className="margin-bottom-6">
                <h2 className={sectionHeaderClasses}>BOAC IPAC information</h2>

                {isDod(boacOfferingAssociation?.agency) || isDod(wallet?.boacModel?.agencyCode) ? (
                  <p>
                    Data must be populated below to enable IPAC for your BOAC. <br /> If you choose IPAC, all fields
                    except the supplemental accounting information must be supplied.
                  </p>
                ) : (
                  <p>
                    Provide supplemental accounting data that should be displayed on your IPAC statement. <br /> Entry
                    below is for existing IPAC users.
                  </p>
                )}

                <WalletIpacFormFields />
              </div>
            )}
            {!createdWallet && (
              <div className="margin-top-4 margin-bottom-8">
                <Button
                  id="cancel-wallet"
                  type="button"
                  variant="unstyled"
                  className="margin-right-3"
                  onClick={() => {
                    history.push({
                      pathname: `/bm/wallet`,
                      search: window.location.search,
                    });
                  }}
                  label="Cancel"
                />
                <Button id="submit-tas" type="submit" form="create-wallet-form" label="Submit" />
              </div>
            )}
            {((createdWallet &&
              boacOfferingAssociation?.id &&
              boacOfferingAssociation?.isDoD &&
              isLeasingType(Number(boacOfferingAssociation?.salesGroupId))) ||
              (isEditMode && isLeasingType(wallet?.salesGroupId) && wallet?.isDoD)) && (
              <div id={`ipac-vehicle-info-wrapper${isEditMode ? '-edit' : ''}`}>
                {!isEditMode && (
                  <Alert type="success" silm>
                    {CREATE_WALLET_DOD_LEASING_SUCCESS_MESSAGE.body()}
                  </Alert>
                )}
                <div data-testid="ipac-vehicle-info" id="ipac-vehicle-info" className="margin-bottom-8 margin-top-3">
                  <h2 className={sectionHeaderClasses}>Vehicle level IPAC information</h2>
                  <p>
                    Please use this section only if you require IPAC data at the individual vehicle level rather than
                    the BOAC level.
                    <br />
                    Any vehicles omitted will default to the BOAC level data displayed above.
                  </p>
                  <WalletIpacVehiclesTable />
                </div>
              </div>
            )}
            {createdWallet && (
              <div className="margin-top-4 margin-bottom-8">
                <Button
                  id="wallet-back"
                  type="button"
                  variant="unstyled"
                  className="margin-right-3"
                  onClick={() => {
                    history.push({
                      pathname: `/bm/wallet`,
                      search: window.location.search,
                    });
                  }}
                  label="Go back"
                />
              </div>
            )}
          </>
        )}
      </form>
      {(createWalletLoading || updateWalletLoading) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </FormProvider>
  );
};

export default ManageWalletForm;
