import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Can } from '@gsa/afp-shared-ui-utils';
import { Pagination } from '@gsa/afp-component-library';
import { useHistory, useLocation } from 'react-router-dom';
import RateStatusBlock from 'components/widgets/rate-status-block';
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import AfpTable from 'widgets/afp-table-wrapper';
import ButtonActions from 'components/actions/button-actions';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { DEFAULT_NO_ITEMS_PER_PAGE, DEFAULT_PAGINATION_OPTIONS, DoReset } from 'components/helpers/pagination-helpers';
import UniqueRateAppFilter from './unique-rate-app-filter';
import DeleteDraftModal from './components/delete-draft-modal';
import {
  GET_UNIQUE_RATE_APPS,
  GET_AGENCIES,
  APP_STATUS,
  APP_TYPE_LABEL,
  APP_TYPE,
  STATUS_BLOCK_TYPE,
} from './unique-rate-app-helper';
import './style/unique-rate-app-table.scss';

const ROLE_ENTITY = ENTITY.UNIQUE_RATE_APP;
const ACTIONS = getActions(ROLE_ENTITY);
const commonActions = {
  [APP_STATUS.DRAFT]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [APP_STATUS.REWORK]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [APP_STATUS.REJECTED]: [ACTIONS.VIEW],
};
const ROW_ACTIONS = {
  [APP_TYPE.APPLY]: {
    ...commonActions,
    [APP_STATUS.SUBMITTED]: [ACTIONS.REVIEW, ACTIONS.VIEW, ACTIONS.COPY_REQUEST],
    [APP_STATUS.APPROVED]: [ACTIONS.VIEW, ACTIONS.COPY_REQUEST, ACTIONS.ADD_VEHICLES],
  },
  [APP_TYPE.ADD_VEHICLE]: {
    ...commonActions,
    [APP_STATUS.SUBMITTED]: [ACTIONS.REVIEW, ACTIONS.VIEW],
    [APP_STATUS.APPROVED]: [ACTIONS.VIEW],
  },
  [APP_TYPE.REMOVE]: {
    ...commonActions,
    [APP_STATUS.SUBMITTED]: [ACTIONS.REVIEW, ACTIONS.VIEW],
    [APP_STATUS.APPROVED]: [ACTIONS.VIEW],
  },
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS[APP_TYPE.APPLY]);

const DEFAULT_ORDER = [['submissionDate', 'DESC']];
const DEFAULT_TABLE_ORDER = DEFAULT_ORDER;

const UniqueRateAppPage = ({ setBannerMsg }) => {
  const [limit, setLimit] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [offset, setOffset] = useState(0);
  const [numberofItems, setnumberofItems] = useState(DEFAULT_NO_ITEMS_PER_PAGE);
  const [curPage, setcurPage] = useState(1);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [tableOrder, setTableOrder] = useState(DEFAULT_TABLE_ORDER);
  const [allAgencies, setAgencies] = useState({});
  const [filterTS] = useState(Date.now());
  // eslint-disable-next-line
  const [filters, setFilters] = useState({
    filters: {
      operator: 'AND',
      value: [{ key: 'status', operator: 'EQ', value: APP_STATUS.SUBMITTED }],
    },
    limit: DEFAULT_NO_ITEMS_PER_PAGE,
    offset: 0,
    order: DEFAULT_ORDER,
  });
  const [isReset, setResetPagination] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [responseData, setResponseData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });

  const [deleteData, setDeleteData] = useState(null);
  const [showError, setShowError] = useState(false);

  const [getData, { loading, data, refetch }] = useLazyQuery(GET_UNIQUE_RATE_APPS, {
    fetchPolicy: 'network-only',
    variables: { ...filters, limit, offset, order },
    onError: (err) => {
      setShowError(true);
      setBannerMsg({
        type: 'error',
        message: (
          <>
            Error occured when loading unique rate data. <br />
            {err.message}
          </>
        ),
      });
    },
  });

  const [fetchAgencies, { loading: loadingAgencies }] = useLazyQuery(GET_AGENCIES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAgencies }) => {
      const collectAgencies = {};
      getAgencies.forEach((d) => {
        collectAgencies[d.id] = d.name;
      });
      setAgencies(collectAgencies);
    },
  });

  useEffect(() => {
    getData();
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (data?.getUniqueRateApplications) {
      const rows = data.getUniqueRateApplications.rows.map((row) => {
        return {
          ...row,
          uniqueRateAppPoc: row?.uniqueRateAppPoc
            ? {
                ...row.uniqueRateAppPoc,
                agencyName: allAgencies?.[row.uniqueRateAppPoc?.agencyCode]
                  ? allAgencies[row.uniqueRateAppPoc?.agencyCode]
                  : '-',
              }
            : undefined,
        };
      });
      setResponseData({ ...data.getUniqueRateApplications, rows });
    }
  }, [data, allAgencies]);

  useEffect(() => {
    if (location.state?.reviewStatus) {
      const { status, message } = location.state.reviewStatus;

      switch (status) {
        case APP_STATUS.APPROVED:
          setBannerMsg({
            type: 'success',
            message: <>{message}</>,
          });
          break;

        case APP_STATUS.REJECTED:
          setBannerMsg({
            type: 'error',
            message: <>{message}</>,
          });
          break;

        case APP_STATUS.REWORK:
          setBannerMsg({
            type: 'info',
            message: <>{message}</>,
          });
          break;

        default:
          break;
      }
    }
  }, [location]);

  const onSort = (val) => {
    const res = val.split(' ');
    const col = res[0].split('`')[1];
    setOrder([[...col.split('.'), res[1]]]);
    setTableOrder([[col, res[1]]]);
  };

  const onCloseDeleteDraftModal = (doRefetch = false) => {
    if (doRefetch === true) refetch({ variables: { limit, offset } });
    setDeleteData(null);
  };

  const handleSelectedAction = (label, original) => {
    const codeData = { ...original };

    switch (label) {
      case ACTIONS.UPDATE.label:
        history.push({
          pathname: '/bm/unique-rates-app/update',
          state: { data: codeData },
        });
        break;
      case ACTIONS.ADD_VEHICLES.label:
        history.push({
          pathname: '/bm/unique-rates-app/create',
          state: { data: codeData, add: true },
        });
        break;
      case ACTIONS.COPY_REQUEST.label:
        history.push({
          pathname: '/bm/unique-rates-app/create',
          state: { data: codeData, copy: true },
        });
        break;
      case ACTIONS.VIEW.label:
        history.push({
          pathname: '/bm/unique-rates-app/view',
          state: { data: codeData },
        });
        break;
      case ACTIONS.REVIEW.label:
        history.push({
          pathname: '/bm/unique-rates-app/review',
          state: { data: codeData },
        });
        break;
      case ACTIONS.DELETE.label:
        setDeleteData(codeData);
        break;
      default:
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    let off = 0;
    if (itemsPerPage !== numberofItems) {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setcurPage(1);
      setnumberofItems(itemsPerPage);
      DoReset(setResetPagination);
    } else {
      off = (currentPage - 1) * itemsPerPage;
    }

    setLimit(itemsPerPage);
    setOffset(off);
  };

  const getUniqueRateAppPocValue = (o, k) => (o?.uniqueRateAppPoc ? o?.uniqueRateAppPoc[k] : '-');

  const columns = [
    {
      Header: 'Agency',
      accessor: 'uniqueRateAppPoc.agencyCode',
      Cell: ({ row: { original = {} } }) => getUniqueRateAppPocValue(original, 'agencyName'),
      sortable: true,
    },
    {
      Header: 'Customer Number',
      accessor: 'uniqueRateAppPoc.customerId',
      Cell: ({ row: { original = {} } }) => getUniqueRateAppPocValue(original, 'customerId'),
      sortable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      className: 'statusStyle',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={STATUS_BLOCK_TYPE[value]}>{value}</RateStatusBlock>,
      sortable: true,
    },
    {
      Header: 'Request Type',
      accessor: 'appType',
      className: 'statusStyle',
      // eslint-disable-next-line
      Cell: ({ value }) => APP_TYPE_LABEL[value] || value,
      sortable: true,
    },
    {
      Header: 'Submitted date',
      accessor: 'submissionDate',
      Cell: ({ value }) => UTCDateStrToUS(value),
      sortable: true,
    },
    {
      Header: 'Requested by',
      accessor: 'createdByUserInfo',
      // eslint-disable-next-line
      Cell: ({ value }) => (value ? <a href={`mailto:${value.email}`}>{value.fullName}</a> : '-'),
      sortable: false,
    },
  ];
  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    columns.push({
      Header: 'Actions',
      Cell: (rowData) => {
        const actions = ROW_ACTIONS[rowData.row.original?.appType]?.[rowData.row.original?.status] || [];
        return genRowActions(actions, rowData, handleSelectedAction);
      },
      sortable: false,
    });

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Bureau</div>
              <div className="tablet:grid-col text-right">{getUniqueRateAppPocValue(original, 'bureauCode')}</div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Customer point of contact</div>
              <div className="tablet:grid-col text-right">{getUniqueRateAppPocValue(original, 'poc')}</div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Email</div>
              <div className="tablet:grid-col text-right">{getUniqueRateAppPocValue(original, 'email')}</div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Phone</div>
              <div className="tablet:grid-col text-right">{getUniqueRateAppPocValue(original, 'phonenumber')}</div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Approved date</div>
              <div className="tablet:grid-col text-right">
                <div>
                  {original?.statu === APP_STATUS.APPROVED && original?.updatedAt
                    ? UTCDateStrToUS(original?.updatedAt)
                    : '-'}
                </div>
              </div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Unique rate type</div>
              <div className="tablet:grid-col text-right">{original?.mileageType}</div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Reason</div>
              <div className="tablet:grid-col text-right">{original?.reason}</div>
            </div>
            <div className="grid-row bm-subrow-grid-row">
              <div className="tablet:grid-col bm-subrow-title">Last edited by</div>
              <div className="tablet:grid-col text-right">
                <div>
                  {original?.updatedByUserInfo ? (
                    <a href={`mailto:${original.updatedByUserInfo.email}`}>{original.updatedByUserInfo.fullName}</a>
                  ) : (
                    original?.updatedByUser
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const createActions = [
    {
      icon: 'add',
      label: 'New unique rate request',
    },
    {
      icon: 'cancel',
      label: 'Removal request',
    },
  ];

  const handleCreateAction = (label) => {
    switch (label) {
      case 'New unique rate request':
        history.push({
          pathname: '/bm/unique-rates-app/create',
        });
        break;
      case 'Removal request':
        history.push({
          pathname: '/bm/unique-rates-app/remove',
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Can I={ROLE_OP.CREATE} a={ROLE_ENTITY}>
        <div className="bm_create_rate_button">
          <ButtonActions
            actions={createActions}
            onSelectAction={(action) => {
              handleCreateAction(action.label);
            }}
            label="Create new request"
          />
        </div>
      </Can>
      <div className="grid-row grid-gap">
        <div className="desktop:grid-col-2 tablet-lg:grid-col-3 unique-rate-filter-app-container standard-table-filters-wrapper">
          <UniqueRateAppFilter
            onFilter={(filterValue) => {
              setOffset(0);
              setcurPage(1);
              DoReset(setResetPagination);
              setFilters(filterValue.filters);
            }}
            timestamp={filterTS}
            allAgencies={allAgencies}
          />
        </div>
        <div className="desktop:grid-col-10 unique-rate-app-table-container tablet-lg:grid-col-9">
          <AfpTable
            onError={showError}
            isLoading={loading || loadingAgencies}
            expandable
            columns={columns}
            data={responseData.rows || []}
            NoDataMessages={{
              title: 'No Request Available',
              text: 'There are no matches for the filtered values at left.',
            }}
            renderRowSubComponent={renderRowSubComponent}
            onSort={onSort}
            defaultSort={tableOrder}
            fullWidth
          />

          <div className="padding-y-4">
            <Pagination
              itemsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
              onPageChange={handlePaginationChange}
              variant="advanced"
              currentPage={curPage}
              itemsCount={responseData?.count}
              itemsPerPage={numberofItems}
              isReset={isReset}
            />
          </div>
          {deleteData && (
            <DeleteDraftModal onClose={onCloseDeleteDraftModal} data={deleteData} setBannerMsg={setBannerMsg} />
          )}
        </div>
      </div>
    </div>
  );
};

UniqueRateAppPage.defaultProps = {
  row: { original: {} },
};

UniqueRateAppPage.propTypes = {
  setBannerMsg: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({}),
  }),
};

export default UniqueRateAppPage;
