import React from 'react';

export const CREATE_WALLET_SUCCESS_MESSAGE = {
  body: (boac, fiscalYear, salesGroup) => (
    <>
      You have successfully created record: <strong>FY: </strong>
      {fiscalYear}&nbsp;&nbsp;<strong>BOAC: </strong>
      {boac}&nbsp;&nbsp;<strong>Offering: </strong>
      {salesGroup}
    </>
  ),
};

export const UPDATE_WALLET_SUCCESS_MESSAGE = {
  body: (boac, fiscalYear, salesGroup) => (
    <>
      You have successfully updated record: <strong>FY: </strong>
      {fiscalYear}&nbsp;&nbsp;<strong>BOAC: </strong>
      {boac}&nbsp;&nbsp;<strong>Offering: </strong>
      {salesGroup}
    </>
  ),
};
