import React from 'react';
import { Button } from '@gsa/afp-component-library';

const alertMap = {
  success: {
    getContent: (reportName) => (
      <span>
        Your <span className="text-bold">{reportName}</span> has successfully been processed.
      </span>
    ),
  },
  validationError: {
    heading: 'We found some issues with your data',
    getContent: (downloadErrorFile, totalCount, errorCount) => (
      <>
        <span>
          Out of {totalCount}, we were not able to process <span className="text-bold">{errorCount}</span>. Download the
          error log to view the details. Once you address all the errors, please re-upload your report to validate the
          data.
        </span>
        <br />
        <div className="padding-top-4">
          <Button
            data-testid="download-error-log-button"
            type="button"
            variant="outline"
            onClick={() => downloadErrorFile()}
            leftIcon={{ name: 'file_download', type: '', className: '' }}
            label="Download error log"
          />
        </div>
      </>
    ),
  },
  templateError: {
    getContent: (reportName) => (
      <span>
        The file you attempted to upload is not a <span className="text-bold">{reportName}</span> template or the file
        layout has been altered. Please upload a <span className="text-bold">{reportName}</span> template generated from
        GSAFleet.gov.
      </span>
    ),
  },
};

// eslint-disable-next-line import/prefer-default-export
export { alertMap };
