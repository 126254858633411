import RateStatusBlock from 'components/widgets/rate-status-block';
import { STATUS } from 'components/helpers/constants';
import { ENTITY, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';

const ROLE_ENTITY = ENTITY.OPT_IN_CATEGORY;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.UPDATE, ACTIONS.DEACTIVATE],
  [STATUS.INACTIVE]: [ACTIONS.ACTIVATE],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

export const handleSelectedAction = ({ setDispatch, DISPATCH_FNS, openModal }) => (label, original) => {
  const rowData = { ...original };
  const actionMap = {
    [`${ACTIONS.ACTIVATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_table.rowData',
        data: rowData,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_toggleActiveModal.isDeactivate',
        data: false,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.toggleActiveModal, { show: true, openModal });
      }, 0);
    },
    [`${ACTIONS.DEACTIVATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_table.rowData',
        data: rowData,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_toggleActiveModal.isDeactivate',
        data: true,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.toggleActiveModal, { show: true, openModal });
      }, 0);
    },
    [`${ACTIONS.CREATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_table.rowData',
        data: { optInCategoryDesc: '', optInCategoryName: '' },
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_updateOptInCategoryModal.isCreate',
        data: true,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.category_updateOptInCategoryModal, { show: true, openModal });
      }, 0);
    },
    [`${ACTIONS.UPDATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_updateOptInCategoryForm.prevOptInCategoryName',
        data: rowData?.optInCategoryName,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_table.rowData',
        data: rowData,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'category_updateOptInCategoryModal.isCreate',
        data: false,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.category_updateOptInCategoryModal, { show: true, openModal });
      }, 0);
    },
  };
  actionMap[label]();
};

export const columns = ({ setDispatch, DISPATCH_FNS, openModal }) => {
  const cols = [
    {
      Header: 'Category',
      accessor: 'optInCategoryName',
      sortable: true,
      collapsedRowClassName: 'optInCategoryTypeStyle',
    },
    {
      Header: 'Description',
      accessor: 'optInCategoryDesc',
      sortable: false,
      collapsedRowClassName: 'optInCategoryTypeStyle',
    },
    {
      Header: 'Status',
      accessor: 'rateStatus',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={value} />,
      sortable: false,
    },
  ];

  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    cols.push({
      Header: 'Actions',
      accessor: 'action',
      sortable: false,
      Cell: (rowData) =>
        genRowActions(
          ROW_ACTIONS[rowData.row.original?.rateStatus] || [],
          rowData,
          handleSelectedAction({ setDispatch, DISPATCH_FNS, openModal }),
          true,
        ),
    });

  return cols;
};
