export const getDateLabel = (data) => {
  if (data.deletedAt) return 'Deactivated date';
  if (data.updatedAt) return 'Updated date';
  return 'Created date';
};

export const getUserLabel = (data) => {
  if (data.deletedAt) return 'Deactivated by';
  if (data.updatedAt) return 'Updated by';
  return 'Created by';
};
