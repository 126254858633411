import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { STATUS } from 'components/helpers/constants';

const RateStatusBlock = ({ status, children }) => {
  let variant;
  switch (status) {
    case STATUS.ACTIVE:
      variant = 'Ready-Gray';
      break;
    case STATUS.PENDING:
    case STATUS.RECOMMENDED:
      variant = 'Warning-Gray';
      break;
    case STATUS.EXPIRED:
      variant = 'Urgent-Gray';
      break;
    case STATUS.NEW:
      variant = 'Info-Gray';
      break;
    case STATUS.DELETED:
    case STATUS.INACTIVE:
    default:
      variant = 'Inactive-Gray';
      break;
  }

  return <StatusBadge variant={variant}>{children || status}</StatusBadge>;
};

RateStatusBlock.defaultProps = {
  status: '',
  children: undefined,
};
RateStatusBlock.propTypes = {
  status: PropTypes.string,
  children: PropTypes.node,
};

export default RateStatusBlock;
