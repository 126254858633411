export const STATUS = {
  ACTIVE: "Active",
  PENDING: "Pending",
  DELETED: "Deleted",
  EXPIRED: "Expired",
  NEW: "New",
  INACTIVE: "Inactive",
  RECOMMENDED: "Recommended",
  ARCHIVED: "Archived"
};

export const DEFAULT_FILTER_STATE = { rateStatus: [STATUS.ACTIVE] };

// constants for file uploading
export const AWS_REGION = "us-east-1";
export const LOCALSTACK_URL = "http://localhost:4566";
export const AWS_BUCKET_NAME_BM = "afp-doc-store-dev";
