import React from 'react';
import _ from 'lodash/fp';
import { Label, SelectDropdown } from '@gsa/afp-component-library';
import RateInputComponent from 'components/widgets/RateInput';
import { autoLabelOptions } from 'utilities/formatUtils';

const frequencyOptions = autoLabelOptions(['Monthly', 'Quarterly', 'Annually']);

const labelNameFn = (name) => _.split('-', name)[0];
/* eslint-disable react/prop-types */

const RateCalculator = ({ setDispatch, pageState, DISPATCH_FNS, register, errors, rateStructure = 'oneTimeRate' }) => {
  return (
    <>
      <div className="rate-calculator">
        <div>
          <Label>Rate type</Label>
          <div className="grid-row">
            <div className="grid-col-3">
              <div className="usa-radio margin-bottom-1">
                <input
                  className="usa-radio__input"
                  type="radio"
                  id={`dollarType-${rateStructure}`}
                  data-testid={`dollarType-${rateStructure}`}
                  name={`dollarType-${rateStructure}`}
                  checked={pageState.service_table.rowData[`${rateStructure}`].rateType === '$'}
                  aria-label="Dollar ($) radio button"
                  onChange={() => {
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateType`,
                      data: '$',
                    });
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateValue`,
                      data: '',
                    });
                  }}
                />
                <label className="usa-radio__label" htmlFor={`dollarType-${rateStructure}`}>
                  Dollar ($)
                </label>
              </div>
            </div>
            <div className="grid-col-3">
              <div className="usa-radio margin-bottom-1">
                <input
                  className="usa-radio__input"
                  type="radio"
                  id={`percentageType-${rateStructure}`}
                  data-testid={`percentageType-${rateStructure}`}
                  name={`percentageType-${rateStructure}`}
                  checked={pageState.service_table.rowData[`${rateStructure}`].rateType === '%'}
                  aria-label="Percent (%) radio button"
                  onChange={() => {
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateType`,
                      data: '%',
                    });
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: `service_table.rowData.${rateStructure}.rateValue`,
                      data: '',
                    });
                  }}
                  ref={register}
                />
                <label className="usa-radio__label" htmlFor={`percentageType-${rateStructure}`}>
                  Percent (%)
                </label>
              </div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-5">
              <div className="tablet:grid-col-12">
                <RateInputComponent
                  setDispatch={setDispatch}
                  dispatchFn={DISPATCH_FNS.updateStateByPath}
                  value={pageState.service_table.rowData[`${rateStructure}`].rateValue}
                  path={`service_table.rowData.${rateStructure}.rateValue`}
                  name={`rate-${rateStructure}`}
                  isPercentage={pageState.service_table.rowData[`${rateStructure}`].rateType === '%'}
                  register={register}
                  errors={errors}
                  labelNameFn={labelNameFn}
                  allowNegative={false}
                  required
                  {...register}
                />
              </div>
            </div>
            {rateStructure !== 'oneTimeRate' && (
              <div className="grid-col-5 grid-offset-1">
                <SelectDropdown
                  required
                  id="rateStructure"
                  data-testid="rateStructure"
                  name="rateStructure"
                  value={pageState.service_table.rowData[`${rateStructure}`].frequency}
                  label="Frequency"
                  options={frequencyOptions}
                  onChange={(e) => {
                    setDispatch(DISPATCH_FNS.updateStateByPath, {
                      path: 'service_table.rowData.recurringRate.frequency',
                      data: e.target.value,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCalculator;
