import React from 'react';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { Alert } from '@gsa/afp-component-library';
import LoadingQueryResult from 'components/wrappers/LoadingQueryResult';
import RequiredNotation from 'components/widgets/required-notation';
import { hasValue } from 'utilities/formatUtils';
import UpdateOptInServiceForm from './UpdateOptInServiceForm';

/* eslint-disable react/prop-types */
export const UpdateOptInServiceModal = ({ onClose, isCreate, loading, pageState, ...formProps }) => {
  const isCreateLiteral = isCreate
    ? 'Create New'
    : `Update`
  return (
    <EditModal
      id="opt-in--service-update-modal"
      title={`${isCreateLiteral} Service`}
      onClose={onClose}
      formId={formProps?.formId}
      showAlert={!!pageState.service_modalAlert.content}
      alert={
        <Alert slim focused={false} type={pageState.service_modalAlert.type} style={{ marginBottom: 16 }}>
          {pageState.service_modalAlert.content}
        </Alert>
      }
      disabledSaveButton={hasValue()(loading)}
    >
      <div className="grid-row">
        <div className="grid-col-9">
          {isCreate ? (
            <span className="margin-top-0" data-testid="toggle-active-modal-body">
              Please use this page to create a new service within an existing category. You will also assign the service
              rate and rate application on this page.
            </span>
          ) : (
            <span className="margin-top-0" data-testid="toggle-active-modal-body">
              Please use this page to update the service rate or existing rate application. Saving an update with a
              start date in the future will generate a pending rate record.
            </span>
          )}
        </div>
      </div>
      <span style={{ display: 'block' }} className="margin-top-1" data-testid="toggle-active-modal-body">
        <RequiredNotation />
      </span>
      <LoadingQueryResult loadingObject={loading}>
        <UpdateOptInServiceForm {...formProps} isCreate={isCreate} pageState={pageState} />
      </LoadingQueryResult>
    </EditModal>
  );
};
