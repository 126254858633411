import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash/fp';
import { firstValue } from 'utilities/formatUtils';
import { DISTINCT_OPT_IN_CATEGORY } from '../category/operation/query/DISTINCT_OPT_IN_CATEGORY';
import { GET_OPT_IN_SERVICE } from './operation/query/GET_OPT_IN_SERVICE';
import { REACTIVATE_OPT_IN_SERVICE } from './operation/mutation/REACTIVATE_OPT_IN_SERVICE';
import { DEACTIVATE_OPT_IN_SERVICE } from './operation/mutation/DEACTIVATE_OPT_IN_SERVICE';
import { DISTINCT_OPT_IN_SVC_CATEGORY } from './operation/query/DISTINCT_OPT_IN_SVC_CATEGORY';
import { DISTINCT_OPT_IN_SVC_NAME } from './operation/query/DISTINCT_OPT_IN_SVC_NAME';
import { DISTINCT_OPT_IN_SALES_CODES } from './operation/query/DISTINCT_OPT_IN_SALES_CODES';
import { UPDATE_OPT_IN_SERVICE } from './operation/mutation/UPDATE_OPT_IN_SERVICE';
import { CREATE_NEW_OPT_IN_SERVICE } from './operation/mutation/CREATE_NEW_OPT_IN_SERVICE';
import { DELETE_OPT_IN_SERVICE } from './operation/mutation/DELETE_OPT_IN_SERVICE';
import { GET_DIST_SERVICE_NAME_AND_START_DATE } from './operation/query/GET_DIST_SERVICE_NAME_AND_START_DATE';
import { getServiceQueryVariables, createSalesCodeOptions } from '../../helper';

const useDataController = ({ dispatchBannerMessage, setDispatch, DISPATCH_FNS, pageState, closeModal }) => {
  const updateArgs = React.useRef(null);
  const OptInSalesCodeObj = React.useRef(null);

  const dispatchErrorMessage = (error) => {
    dispatchBannerMessage({
      setDispatch,
      DISPATCH_FNS,
      error,
    });
  };

  const turnOffLoadingAndDisplayError = (error) => {
    setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'serviceFilter', loadingStatus: false });
    dispatchErrorMessage(error);
  };

  // the DistinctOptInCategory will use the query from the category page and use the categoryFilter.options.OptInCategoryName to store the data
  // that options will used on the update and create modal for the category name drop down
  // the data result will used by update modal, we assume the loading will finish when user navigate to the update modal and we are not set any loading indicator
  // if we find data not loaded problem we will add the loader here
  const [fetchDistinctOptInCategory] = useLazyQuery(DISTINCT_OPT_IN_CATEGORY, {
    onCompleted: (resData) => {
      const serviceCategories = _.flow(firstValue, _.map('optInCategoryName'))(resData);
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'categoryFilter.options.OptInCategoryName',
        data: serviceCategories,
      });
    },

    onError: dispatchErrorMessage,
  });

  const mapAndLowerCase = ({ optInSvcName }) => _.lowerCase(optInSvcName);

  const onCompleteFetchDistinctOptInServiceName = (resData) => {
    const serviceNames = _.flow(firstValue, _.map(mapAndLowerCase))(resData);
    setDispatch(DISPATCH_FNS.updateStateByPath, {
      path: 'distinctServiceName',
      data: serviceNames,
    });
    fetchDistinctOptInCategory();
  };

  const [fetchDistinctOptInServiceName] = useLazyQuery(DISTINCT_OPT_IN_SVC_NAME, {
    onCompleted: onCompleteFetchDistinctOptInServiceName,
    onError: dispatchErrorMessage,
  });
  const [fetchDistinctOptInServiceCategory, { loading: distinctOptInServiceFilterLoading }] = useLazyQuery(
    DISTINCT_OPT_IN_SVC_CATEGORY,
    {
      onCompleted: (resData) => {
        const serviceCategories = _.flow(firstValue, _.map('optInSvcCategory'))(resData);
        setDispatch(DISPATCH_FNS.updateStateByPath, {
          path: 'serviceFilter.options.optInSvcCategory',
          data: serviceCategories,
        });
        setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'serviceFilter', loadingStatus: false });
        fetchDistinctOptInServiceName();
      },

      onError: turnOffLoadingAndDisplayError,
    },
  );

  const [getServiceRows, { loading: serviceTableLoading }] = useLazyQuery(GET_OPT_IN_SERVICE, {
    onCompleted: (resData) => {
      fetchDistinctOptInServiceCategory();
      const rowsData = _.flow(firstValue, _.getOr([], 'rows'))(resData);
      const itemsCount = _.flow(firstValue, _.getOr(0, 'count'))(resData);
      // AFP-111829 - add optInSalesCode to each row base on optInSvcSalesId of that row
      const newRowsData = rowsData.map((row) => ({
        ...row,
        optInSalesCode: OptInSalesCodeObj.current ? OptInSalesCodeObj.current[`${row.optInSvcSalesId}`] : {},
      }));
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'service_table.rows', data: newRowsData });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'service_itemsCount', data: itemsCount });
    },

    onError: turnOffLoadingAndDisplayError,
  });

  const fetchServiceRows = (args) => {
    setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'serviceFilter', loadingStatus: true });
    getServiceRows(args);
  };

  const [fetchDistinctOptInSalesCode, { loading: fetchDistinctOptInSalesCodeLoading }] = useLazyQuery(
    DISTINCT_OPT_IN_SALES_CODES,
    {
      onCompleted: (resData) => {
        setDispatch(DISPATCH_FNS.updateStateByPath, {
          path: 'distinctOptInSalesCodeOptions',
          data: _.flow(firstValue, createSalesCodeOptions)(resData),
        });

        // AFP-111829 convert the distinctOptInSalesCodeOptions data to a object keyBy assign the distinctOptInSalesCodeOptions
        OptInSalesCodeObj.current = _.keyBy('code', resData?.getDistinctSalesCodes);

        fetchServiceRows({
          variables: getServiceQueryVariables({
            pageState,
            rateStatus: pageState.serviceFilter.rateStatus ?? [],
            filter: pageState.serviceFilter.filter.optInSvcCategory,
          }),
        });
      },

      onError: turnOffLoadingAndDisplayError,
    },
  );
  const initialLoading = (args) => {
    setDispatch(DISPATCH_FNS.updateFilterLoadingStatus, { filterName: 'serviceFilter', loadingStatus: true });
    fetchDistinctOptInSalesCode(args);
  };
  const updateOnComplete = ({ isCreate = false }) => (res) => {
    const content = isCreate
      ? `Service ${firstValue(res)?.optInSvcName} has been created.`
      : `Service ${firstValue(res)?.optInSvcName}  has been updated.`;
    dispatchBannerMessage({
      setDispatch,
      DISPATCH_FNS,
      content,
      type: 'success',
    });
    fetchServiceRows({
      variables: getServiceQueryVariables({ pageState, filter: pageState.serviceFilter.filter.optInSvcCategory }),
    });
    setDispatch(DISPATCH_FNS.service_updateOptInServiceModal, { show: false, closeModal });
  };
  const onErrorUpdateModalAlert = (error) => {
    setDispatch(DISPATCH_FNS.updateModalAlert, {
      type: 'error',
      content: _.getOr('Unknown Error', 'message', error),
      isCategory: false,
    });
  };

  const [reactivateActive, { loading: reactivateActiveLoading }] = useMutation(REACTIVATE_OPT_IN_SERVICE, {
    onError: onErrorUpdateModalAlert,
    onCompleted: (data) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: (
          <>
            You have successfully reactivated <b>{firstValue(data)?.optInSvcName}.</b>
          </>
        ),
        type: 'success',
      });
      fetchServiceRows({
        variables: getServiceQueryVariables({ pageState, filter: pageState.serviceFilter.filter.optInSvcCategory }),
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'service_toggleActiveForm.reason', data: '' });
      setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal, isCategory: false });
    },
  });

  const [deactivateActive, { loading: deactivateActiveLoading }] = useMutation(DEACTIVATE_OPT_IN_SERVICE, {
    onError: onErrorUpdateModalAlert,
    onCompleted: (data) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: (
          <>
            You have successfully deactivated <b>{firstValue(data)?.optInSvcName}.</b>
          </>
        ),
        type: 'success',
      });
      fetchServiceRows({
        variables: getServiceQueryVariables({
          pageState,
          filter: pageState.serviceFilter.filter.optInSvcCategory,
        }),
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'service_toggleActiveForm.reason', data: '' });
      setDispatch(DISPATCH_FNS.toggleActiveModal, { show: false, closeModal, isCategory: false });
    },
  });

  const [createNewOptInService, { loading: createNewOptInServiceLoading }] = useMutation(CREATE_NEW_OPT_IN_SERVICE, {
    onError: onErrorUpdateModalAlert,
    onCompleted: updateOnComplete({ isCreate: true }),
  });

  const [updateOptInService, { loading: updateOptInServiceLoading }] = useMutation(UPDATE_OPT_IN_SERVICE, {
    onError: onErrorUpdateModalAlert,
    onCompleted: updateOnComplete({ isCreate: false }),
  });
  const [getServiceNameAndStartDate, { loading: serviceNameAndStartDateLoading }] = useLazyQuery(
    GET_DIST_SERVICE_NAME_AND_START_DATE,
    {
      onCompleted: (resData) => {
        const sameNameStartDateExist = _.reject(
          { optInSvcId: pageState.service_table.rowData.optInSvcId },
          firstValue(resData),
        );
        if (_.isEmpty(sameNameStartDateExist)) {
          setDispatch(DISPATCH_FNS.updateStateByPath, {
            path: 'service_table.rowData.error.startDate',
            data: false,
          });
          pageState.service_table.rowData.isCreate
            ? createNewOptInService({
                variables: updateArgs.current,
              })
            : updateOptInService({
                variables: updateArgs.current,
              });
        } else {
          setDispatch(DISPATCH_FNS.service_toggleRowDataError, {
            isError: true,
          });
        }
      },

      onError: turnOffLoadingAndDisplayError,
    },
  );
  const getServiceNameAndStartDateThenUpdate = ({ variables }) => {
    updateArgs.current = variables;
    setTimeout(() => {
      getServiceNameAndStartDate({
        variables: {
          optInSvcName: variables.optInSvcInput.optInSvcName,
          startDate: variables.optInSvcInput.startDate,
        },
      });
    }, 0);
  };

  const [deleteService, { loading: deleteServiceLoading }] = useMutation(DELETE_OPT_IN_SERVICE, {
    onError: turnOffLoadingAndDisplayError,
    onCompleted: (data) => {
      dispatchBannerMessage({
        setDispatch,
        DISPATCH_FNS,
        content: (
          <>
            You have successfully deleted <b>{firstValue(data)?.optInSvcName}.</b>
          </>
        ),
        type: 'success',
      });
      fetchServiceRows({
        variables: getServiceQueryVariables({
          pageState,
          filter: pageState.serviceFilter.filter.optInSvcCategory,
        }),
      });

      setDispatch(DISPATCH_FNS.service_updateDeleteModal, { show: false, closeModal });
    },
  });
  return {
    mutation: { reactivateActive, deleteService, deactivateActive, createNewOptInService, updateOptInService },
    query: {
      fetchServiceRows,
      initialLoading,
      getServiceNameAndStartDate,
      getServiceNameAndStartDateThenUpdate,
      fetchDistinctOptInServiceName,
    },
    loading: {
      serviceNameAndStartDateLoading,
      serviceTableLoading,
      distinctOptInServiceFilterLoading,
      reactivateActiveLoading,
      deactivateActiveLoading,
      updateOptInServiceLoading,
      createNewOptInServiceLoading,
      fetchDistinctOptInSalesCodeLoading,
      deleteServiceLoading,
    },
    forTest: {
      onCompleteFetchDistinctOptInServiceName,
      getServiceRows,
      fetchDistinctOptInServiceCategory,
    },
  };
};

export default useDataController;
