import { atom } from 'recoil';

const ImportStateKeys = {
  Type: 'importType',
  CurrentStep: 'importCurrentStep',
  MappedData: 'importMappedData',
  ProcessedResults: 'importProcessedResults',
  ProcessedResponse: 'importProcessedResponse',
};

// Report type selected
const importTypeConfig = atom({
  key: ImportStateKeys.Type,
  default: {
    type: 'DEFAULT',
  },
});

// Imported Data
const importMappedData = atom({
  key: 'importMappedData',
  default: [],
});

const importProcessedResults = atom({
  key: ImportStateKeys.ProcessedResults,
  default: [],
});

const importProcessedResponse = atom({
  key: ImportStateKeys.ProcessedResponse,
  default: [],
});

export {
  importTypeConfig,
  importProcessedResults,
  importProcessedResponse,
  importMappedData,
  ImportStateKeys,
};
