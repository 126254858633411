import { gql } from '@apollo/client';

export const GET_LATEST_ACQUISITION_FUNDING_BY_FY = gql`
  query Query($FiscalYear: String!) {
    getLatestAcquisitionFundingForFY(fiscalYear: $FiscalYear) {
      fiscalYear
      replacementFunding
      consolidationFunding
      additionalFunding
    }
  }
`;
