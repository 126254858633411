import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Spinner, FileUpload, Alert } from '@gsa/afp-component-library';
import { EditModal } from 'components/modals/edit-modal/edit-modal';
import { STATUS } from 'components/helpers/constants';
import { getTodayStr } from 'components/helpers/afp-bm-date';
import exportCSVFile from 'components/file-export/client-side-csv-export';
import exportFile from 'components/file-export/client-side-file-export';
import { getCSVRows, parseCSVRow } from 'components/file-export/parse-csv';
import BannerMessage from 'widgets/banner-message';
import RequiredNotation from 'components/widgets/required-notation';
import { GET_LEASE_RATES, BULK_UPDATE_LEASE_RATE, LRTemplateColumns, parseData2CSV } from './lease-rate-helper';
import './style/lease-rate-update-modal.scss';

const FILE_SIZE_LIMIT = 1; // MB

const LeaseRateBulkUpdateModal = ({ onClose, setBannerMsg }) => {
  const [updateData, setUpdateData] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [toasterMsg, setToasterMsg] = useState(null);

  // gql calls
  const variables = {
    virtualFilter: {
      operator: 'OR',
      value: [{ key: 'rateStatus', operator: 'EQ', value: STATUS.ACTIVE }],
    },
    limit: 9999,
    offset: 0,
    order: [['leaseRateCode', 'ASC']],
  };
  const getOnErrorFn = (msg) => (err) => {
    setToasterMsg({
      type: 'error',
      message: (
        <>
          {msg} <br /> {err.message}
        </>
      ),
    });
  };

  const [exportLRs, { loading: loadingExportLRs }] = useLazyQuery(GET_LEASE_RATES, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: ({ getLeaseRates }) => {
      const { headers, subheaders, items } = parseData2CSV(getLeaseRates.rows, LRTemplateColumns, true);
      exportCSVFile(headers, items, `Fleet Lease Rates Template ${moment().format('YYYY-MM-DD-HHmmss')}`, subheaders);
    },
    onError: getOnErrorFn('Error occurred when downloading lease rates.'),
  });

  const [fetchLRs, { data: lrData, loading: loadingFetchLRs }] = useLazyQuery(GET_LEASE_RATES, {
    fetchPolicy: 'network-only',
    variables,
    onError: getOnErrorFn('Error occurred when fetching lease rates.'),
  });

  const [bulkUpdateLRs, { loading: loadingBulkUpdate }] = useMutation(BULK_UPDATE_LEASE_RATE, {
    onCompleted: () => {
      setBannerMsg({ type: 'success', message: 'Bulk lease rates have been updated successfully.' });
      onClose(true); // refetch table data
    },
    onError: getOnErrorFn('Error occurred when updating lease rates.'),
  });

  // file reader and callback function to handle uploaded csv file
  const reader = new FileReader();
  reader.onload = () => {
    setCsvData(reader.result);
    if (!reader.result) setToasterMsg({ type: 'error', message: 'Uploaded file error: empty file' });
  };

  const downloadTemplate = () => {
    exportLRs();
  };

  const exportValidationErrors = (errors) => () => {
    exportFile(
      errors.map((err) => `Row ${err.rowNum}: [${err.errors.join('] [')}]`).join('\r\n'),
      'text/plain;charset=utf-8;',
      'Lease rate bulk update validation errors.txt',
    );
  };

  const onBulkUpdate = () => {
    if (fileUploadError) {
      setToasterMsg({ type: 'error', message: 'Please fix uploaded file error.' });
    } else if (!selectedFile) {
      setFileUploadError('Lease rate file is required');
    } else if (updateData.length) {
      bulkUpdateLRs({ variables: { bulkLeaseRateInputs: updateData } });
    }
  };

  const prepareAPIData = (csvRowData, leaseRates) => {
    const fields = LRTemplateColumns.map((col) => col.accessor);
    const lrate = leaseRates.find((lr) => lr[fields[0]] === csvRowData[0]);
    if (!lrate) return { status: 'error', message: 'Lease rate record not found' };

    if (fields.every((f, i) => lrate[f] === csvRowData[i])) return { status: 'no change' };

    if (csvRowData[fields.length - 1] <= getTodayStr())
      return { status: 'error', message: 'New start date must be a future date' };

    const leaseRateInput = {};
    for (let i = 1; i < fields.length; i += 1) {
      leaseRateInput[fields[i]] = LRTemplateColumns[i].isNumber ? Number(csvRowData[i]) : csvRowData[i];
    }
    return { status: 'ok', data: { leaseRateCode: csvRowData[0], leaseRateInput } };
  };

  useEffect(() => {
    setUpdateData([]);
    setFileUploadError(null);
    setToasterMsg(null);

    // read uploaded file
    if (selectedFile) {
      fetchLRs();
      reader.readAsText(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    const leaseRates = lrData?.getLeaseRates?.rows;
    if (!csvData || !leaseRates) return;

    // validate headers and subheaders
    const [headerRow, subheaderRow, ...data] = getCSVRows(csvData);
    const { headers, subheaders } = parseData2CSV([], LRTemplateColumns, true);
    if (headerRow !== headers.join(',')) {
      setToasterMsg({ type: 'error', message: 'Uploaded file error: invalid header row' });
      return;
    }
    if (subheaderRow !== subheaders.join(',')) {
      setToasterMsg({ type: 'error', message: 'Uploaded file error: invalid subheader row' });
      return;
    }

    // validate csv row data
    const lrcodes = leaseRates.map((lr) => lr.leaseRateCode);
    const errors = [];
    const newLRData = data
      .map((row, i) => {
        const rowData = parseCSVRow(row);
        if (!rowData || rowData.every((cell) => !cell)) return null; // empty row

        let csvRowData = null;
        const rowErr = [];
        if (rowData.length !== LRTemplateColumns.length) rowErr.push('Invalid number of columns');
        else {
          csvRowData = LRTemplateColumns.map((col, j) => {
            if (col.parser) {
              const val = col.parser(rowData[j], lrcodes);
              if (val) return val;
              rowErr.push(`Invalid ${col.header}`);
            } else if (col.regex && !col.regex.test(rowData[j])) {
              rowErr.push(`Invalid ${col.header}`);
            }
            return rowData[j];
          });
        }

        if (rowErr.length > 0) {
          errors.push({ rowNum: i + 3, errors: rowErr });
          return null;
        }

        const res = prepareAPIData(csvRowData, leaseRates);
        if (res.status === 'ok') return res.data;
        if (res.status === 'error') errors.push({ rowNum: i + 3, errors: [res.message] });
        return null; // error OR no change
      })
      .filter((a) => a); // remove nulls - empty rows or no change

    // data validation error - downloadable error file
    if (errors?.length) {
      setToasterMsg({
        type: 'error',
        message: (
          <>
            <div>
              Lease rates <b>cannot</b> be updated because of the invalid data.
            </div>
            <div className="margin-y-1">
              <Button variant="outline" label="Download error log" onClick={exportValidationErrors(errors)} />
            </div>
          </>
        ),
      });
      return;
    }

    // nothing to update
    if (!newLRData.length) {
      setToasterMsg({
        type: 'error',
        message: 'No changes detected. No lease rate will be updated.',
      });
      return;
    }

    // check duplication LRC
    const counts = {};
    newLRData.forEach(({ leaseRateCode }) => {
      if (counts[leaseRateCode]) counts[leaseRateCode] += 1;
      else counts[leaseRateCode] = 1;
    });
    const duplicates = Object.keys(counts)
      .map((lrc) => (counts[lrc] > 1 ? lrc : undefined))
      .filter((a) => a);
    if (duplicates.length) {
      setToasterMsg({
        type: 'error',
        message: `Duplicate Lease Rate Codes found: ${duplicates.join(', ')}`,
      });
      return;
    }

    // check start date
    const { startDate } = newLRData[0].leaseRateInput;
    if (newLRData.some((lrd) => lrd.leaseRateInput.startDate !== startDate)) {
      setToasterMsg({
        type: 'error',
        message: 'All lease rates to be updated must have the same start date.',
      });
      return;
    }

    // all checks passed
    setUpdateData(newLRData);
    setToasterMsg({
      type: 'info',
      message: `Pending rates will be created for ${newLRData.length} Lease Rate Codes.`,
    });
  }, [csvData, lrData]);

  return (
    <EditModal
      id="lease-rate-bulk-update"
      title="Bulk Update Lease Rates and Factors"
      customizeButtonText="Update"
      customizeButtonProps={{ onClick: onBulkUpdate }}
      onClose={onClose}
      showAlert={!!toasterMsg}
      alert={
        <BannerMessage focused type={toasterMsg?.type} showClose={false}>
          {toasterMsg?.message}
        </BannerMessage>
      }
    >
      {loadingExportLRs || loadingFetchLRs || loadingBulkUpdate ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 99,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.15)',
          }}
        >
          <div style={{ margin: '300px auto' }}>
            <Spinner />
          </div>
        </div>
      ) : null}

      <p>
        Please use this page to perform a bulk update to the rates and factors associated with the active Lease Rate
        Codes.
      </p>
      <p>
        <RequiredNotation />
      </p>

      <div className="bm-section-header margin-top-4">Download lease rate template</div>
      <p>
        Select the download button to generate a template to be used to perform the bulk update. Please carefully follow
        the formatting instructions to avoid errors.
      </p>
      <Button variant="outline" label="Download" data-testid="bulk-update-download-temp" onClick={downloadTemplate} />

      <div className="bm-section-header margin-top-4">Upload lease rate template for bulk update</div>
      <p>Please carefully follow instructions below to avoid a file upload failure.</p>
      <Alert type="info" heading="Update instructions">
        <ul className="padding-left-3 margin-top-2 margin-bottom-0">
          <li>
            Do not add or edit any Lease Rate Codes from the original template. The entire update will fail if they do
            not match exactly.
          </li>
          <li>Formatting requirements are provided for each column in the downloaded template.</li>
          <li>The start date must be the same for all Lease Rate Codes to be updated.</li>
          <li>The bulk update will overwrite any pending rates in the system that have the same start date.</li>
          <li>All cells must be populated; blank cells will not be accepted.</li>
          <li>
            For Lease Rate Codes that do not require an update, leave the corresponding rows unchanged or remove them
            from the CSV file.
          </li>
        </ul>
      </Alert>
      <FileUpload
        required
        label="Upload the updated lease rate file "
        acceptableFiles={['application/vnd.ms-excel']}
        acceptableFilesLabel={`Accepts .csv file up to ${FILE_SIZE_LIMIT} MB`}
        fileSizeLimit={FILE_SIZE_LIMIT} // MB
        onChange={(file) => {
          setSelectedFile(file);
          setFileUploadError(!file && 'Lease rate file is required');
        }}
        errorMessage={fileUploadError}
      />
    </EditModal>
  );
};

LeaseRateBulkUpdateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
};

export default LeaseRateBulkUpdateModal;
