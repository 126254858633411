import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { usePegasysFilter } from './filters/pegasys-filter-provider';
import {usePegasysContext} from './pegasys-provider';
import { USDateStrToUTC } from '../../components/helpers/afp-bm-date';
import PegasysTypeaheadItem from './typeahead-field-component';

const PegasysWsSidebar = () => {
  const { wsFilterStructure, setWsFilters, setWsStructure } = usePegasysFilter();
  const { fetchWsData, wsTypeaheadDataObj} = usePegasysContext();
  const filterStructure = () => {
    return [
      { 
        id: 'wsDocType',
        category: 'Document Type',
        key: 'documentType',
        label: 'Document Type',
        operator: 'IN',
        options: [
          {
            label: 'Order',
            value: 'VH',
          },
          {
            label: 'Receipt',
            value: 'VC',
          },
          {
            label: 'PCAS-I',
            value: 'VID',
          },
          {
            label: 'PCAS-E',
            value: 'VED',
          },
          {
            label: 'Asset Acquisition',
            value: 'F1',
          },
          {
            label: 'Asset Betterment',
            value: 'VB',
          },
          {
            label: 'Asset Correction',
            value: 'F2',
          },
          {
            label: 'Asset Disposal',
            value: 'F3',
          },
          {
            label: 'Asset Transfer',
            value: 'F5',
          },
          {
            label: 'Expense Accrual',
            value: 'VA',
          },
          {
            label: 'FODIS',
            value: 'XF',
          },
        ],
        permanent: false,
        placeholder: 'Select Document Type',
        title: 'Document Type',
        type: 'multiselectdropdown',
        value: [],
      },

      {
        id:"wsDocNumber",
        title: 'Document Number',
        key: 'documentNumber',
        operator: 'STARTWITH',
        component: PegasysTypeaheadItem,
        noResultsText: 'No DocumentNumbers found',
        ariaLabel: 'Default Typeahead',
        promptText: 'Search requires 3 characters.',
        typeaheadData: wsTypeaheadDataObj,
        inputCharNum: 3,
        debounceDelay: 500,
        hideClear: true,
      },

      {
        id: "wsVin",
        title: 'VIN',
        key: 'vin',
        operator: 'STARTWITH',
        component: PegasysTypeaheadItem,
        noResultsText: "No VIN's found",
        ariaLabel: 'Default Typeahead',
        promptText: 'Search requires 3 characters.',
        inputCharNum: 3,
        debounceDelay: 500,
        hideClear: true,
        dataValues: ['123', '345', '567'],
        typeaheadData: wsTypeaheadDataObj,
      },

      {
        id: "wsStatus",
        title: 'Status',
        key: 'errorDataStatus',
        type: 'multiselect',
        value: ['Active'],
        options: [
          { value: 'Active', label: 'Active' },
          { value: 'Archived', label: 'Archived' },
        ],
        permanent: false,
        operator: 'IN',
        hideClear: true,
        expanded: true,
      },

      {
        id: 'wsDate',
        title: 'Date',
        key: 'createdAt',
        type: 'daterange',
        operator: 'BETWEEN',
        options: {
          startDate: {
            key: 'beginning',
            label: 'Start Date',
            hint: 'mm/dd/yyy',
          },
          endDate: {
            key: 'end',
            label: 'End Date',
            hint: 'mm/dd/yyy',
          },
        },
        value: {
          beginning: '',
          end: '',
        },
        hideClear: true,
      },
    ];
  };

  useEffect(() => {
    setWsStructure(filterStructure());
  }, [wsTypeaheadDataObj]);

  const handleWsFilters = (filters) => {
     const filterToApply = {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'AND', value: [] },
      order: [['createdAt', 'DESC']],
      offset: 0,
      limit: 10,
    };
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.key;
      const virtualFilterTypes = ['errorDataStatus', 'record_id', 'batch_type'];
      if (valueObj) {
        if (virtualFilterTypes.includes(selectedKey)) {
          filterToApply.virtualFilter.value.push(valueObj);
        } else if (selectedKey === 'createdAt') {
          const startDate = USDateStrToUTC(valueObj?.value?.beginning);
          const endDate = USDateStrToUTC(valueObj?.value?.end, true);
          filterToApply.filters.value.push({
            operator: 'AND',
            value: [
              { key: 'createdAt', operator: 'GTE', value: startDate },
              { key: 'createdAt', operator: 'LTE', value: endDate },
            ],
          });
        } else {
          filterToApply.filters.value.push(valueObj);
        }
      }
    });
    setWsFilters({ ...filterToApply });
    fetchWsData({ variables: { ...filterToApply }});
  };

  if ((wsFilterStructure?.length > 0 && wsTypeaheadDataObj)) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={wsFilterStructure}
        setQueryFiltersState={(filters) => {
          handleWsFilters(filters);
        }}
        clearButtonLabel="Reset all"
        showClearIcon
        showSearchIcon
      />
    );
  }
  return null;
};

export default PegasysWsSidebar;
