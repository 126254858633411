import { gql } from '@apollo/client';

export const AcquisitionFundingResponse = gql`
  fragment AcquisitionFundingResponseFields on AcquisitionFunding {
    id
    fiscalYear
    replacementFunding
    consolidationFunding
    additionalFunding
    createdAt
    createdByUser
    createdByUserInfo {
      email
    }
    updatedAt
  }
`;

export const VERIFY_AND_CREATE_ACQUISITION_FUNDING_FOR_FY = gql`
  mutation verifyAndCreateAcquisitionFundForFY($acquisitionFundingInput: AcquisitionFundingInput!) {
    verifyAndCreateAcquisitionFundForFY(acquisitionFundingInput: $acquisitionFundingInput) {
      ...AcquisitionFundingResponseFields
    }
  }
  ${AcquisitionFundingResponse}
`;
export default VERIFY_AND_CREATE_ACQUISITION_FUNDING_FOR_FY;
