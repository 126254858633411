import React from 'react';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import UpdateInformation from 'components/subRowComponent/UpdateInformation';
import { STATUS } from 'components/helpers/constants';
import { toCurrency, toPercentage } from 'utilities/formatUtils';
import { isTierRate, convertRate } from '../../helper';

/* eslint-disable react/prop-types */
const ServiceRowSubComponent = ({ row: { original = {} } }) => {
  const generateTierRow = ({ tierLowerRange, optInFixedRate, optInFlatDollar }, ind, array) => {
    const rangeRowUnderneath = array[ind + 1];
    const upperRange = rangeRowUnderneath
      ? `- ${toCurrency(rangeRowUnderneath.tierLowerRange - 0.01, 2)}`
      : `and above`;

    const rateValue = optInFlatDollar ? toCurrency(optInFlatDollar) : toPercentage(convertRate(optInFixedRate * 100));
    return (
      <div className="grid-row bm-subrow-grid-row">
        <div className="tablet:grid-col">{`${toCurrency(tierLowerRange, 2)} ${upperRange}`}</div>
        <div className="tablet:grid-col text-right">{rateValue}</div>
      </div>
    );
  };
  return (
    <div className="grid-container">
      <div className="grid-row">
        <div className="tablet:grid-col-6">
          <div className="grid-row bm-subrow-header">Rate application</div>
          {original.optInSvcDesc && <div className="grid-row">{original.optInSvcDesc}</div>}
        </div>
        <div className="tablet:grid-col-6 padding-left-5">
          <div className="grid-row">
            {original.comment && (
              <div>
                <div className="grid-row bm-subrow-header">
                  Reason for {original?.rateStatus === STATUS.ACTIVE ? 'reactivation' : 'deactivation'}
                </div>
                <div className="grid-row">{original.comment}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* tierLowerRange: '0.0000', optInFixedRate: null, optInFlatDollar: '0.0000' */}
      <div className="grid-row margin-top-2">
        <div className="tablet:grid-col-6">
          {isTierRate(original.optInServiceAssociationObj) && (
            <>
              <div className="grid-row bm-subrow-grid-row">
                <div className="tablet:grid-col bm-subrow-title">Range</div>
                <div className="tablet:grid-col text-right bm-subrow-title">Rate</div>
              </div>
              {original.optInServiceAssociationObj.map(generateTierRow)}
            </>
          )}
        </div>
        <div className="tablet:grid-col-6 padding-left-5">
          {' '}
          <div className="grid-row bm-subrow-grid-row">
            <div className="tablet:grid-col bm-subrow-title">Sales code</div>
            <div className="tablet:grid-col text-right">{`${original.optInSalesCode.code ?? ''}-${original.optInSalesCode.description ?? ''}`}</div>
          </div>
          <div className="grid-row bm-subrow-grid-row">
            <div className="tablet:grid-col bm-subrow-title">End Date</div>
            <div className="tablet:grid-col text-right">
              {`${original.endDate ? UTCDateStrToUS(original.endDate) : '-'}`}
            </div>
          </div>
          <UpdateInformation original={original} />
        </div>
      </div>
    </div>
  );
};

export default ServiceRowSubComponent;
