import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner, Alert, Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import { STATUS } from 'components/helpers/constants';
import { SinFuelTypeTable } from './sin-fuel-type-table/sin-fuel-type-table';
import {
  GET_DISTINCT_LEASE_RATE_CODE,
  DELETE_LEASE_RATE_CODE,
  DEACTIVATE_LEASE_RATE_CODE,
  REACTIVATE_LEASE_RATE_CODE,
} from './lease-rate-helper';

const LeaseRateDeleteModal = ({ onClose, data, handleDelete }) => {
  const { leaseRateCode, leaseRateAssociation, rateStatus, startDate } = data;

  const [distinctCodes, setDistinctCodes] = useState();
  const [comment, setComment] = useState('');
  const [commentsCount, setCommentsCount] = useState(200);
  const [errors, setErrors] = useState(null);
  const [refresh, setRefresh] = useState(false);

  // gql APIs
  const { loading: loadingDistinctCodes, data: distinctCodesData } =
    rateStatus !== STATUS.PENDING && leaseRateAssociation?.length ? useQuery(GET_DISTINCT_LEASE_RATE_CODE) : {};

  const getOnCompletedFn = (verb, item = 'Lease Rate Code', forDate = false, newLRCData = null) => () => {
    const message = (
      <>
        You have successfully {verb} {item} <b>{leaseRateCode}</b>
        {forDate && ` for ${UTCDateStrToUS(startDate)}`}.
      </>
    );
    handleDelete('success', message);
    onClose(true, newLRCData);
  };
  const getOnErrorFn = (verb, item = 'Lease Rate Code', forDate = false) => (err) => {
    const message = (
      <>
        Uable to {verb} {item} <b>{leaseRateCode}</b>
        {forDate && ` for ${UTCDateStrToUS(startDate)}`}.
        <br /> {err.message}
      </>
    );
    handleDelete('error', message);
    onClose();
  };
  const [deleteLeaseRateCode, { loading: deleting }] = useMutation(DELETE_LEASE_RATE_CODE, {
    onCompleted: getOnCompletedFn(
      'deleted',
      rateStatus === STATUS.PENDING ? 'pending lease rate' : undefined,
      rateStatus === STATUS.PENDING,
    ),
    onError: getOnErrorFn(
      'delete',
      rateStatus === STATUS.PENDING ? 'pending lease rate' : undefined,
      rateStatus === STATUS.PENDING,
    ),
  });
  const [deactivateLeaseRateCode, { loading: deactivating }] = useMutation(DEACTIVATE_LEASE_RATE_CODE, {
    onCompleted: getOnCompletedFn('deactivated'),
    onError: getOnErrorFn('deactivate'),
  });
  const [reactivateLeaseRateCode, { loading: reactivating }] = useMutation(REACTIVATE_LEASE_RATE_CODE, {
    onCompleted: getOnCompletedFn('reactivated', undefined, undefined, data),
    onError: getOnErrorFn('reactivate'),
  });

  useEffect(() => {
    if (distinctCodesData) {
      const { getDistinctLeaseRateCode } = distinctCodesData;
      const codes = getDistinctLeaseRateCode
        .map(({ leaseRateCode: code }, index) => ({
          value: code,
          label: code,
          defaultValue: index === 0,
        }))
        .filter(({ value }) => value !== leaseRateCode);

      setDistinctCodes(codes);
    }
  }, [distinctCodesData]);

  const VARIATION = {
    [STATUS.PENDING]: {
      title: `Delete Pending Lease Rate Code ${leaseRateCode}`,
      instruction: (
        <>
          Are you sure you want to delete lease rate <b>{leaseRateCode}</b> for start date{' '}
          <b>{UTCDateStrToUS(startDate)}</b>? This cannot be undone.
        </>
      ),
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deleteLeaseRateCode,
    },
    [STATUS.NEW]: {
      title: `Delete New Lease Rate Code ${leaseRateCode}`,
      instruction: (
        <>
          Are you sure you want to delete new Lease Rate Code <b>{leaseRateCode}</b>? This cannot be undone.
        </>
      ),
      verb: 'delete',
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deleteLeaseRateCode,
    },
    [STATUS.ACTIVE]: {
      title: `Deactivate Lease Rate Code ${leaseRateCode}`,
      instruction:
        'Are you sure you would like to deactivate this Lease Rate Code? Any pending rate under this code will also be deleted.',
      verb: 'deactivate',
      buttonText: 'Deactivate',
      isReasonRequired: true,
      gqlAPI: deactivateLeaseRateCode,
    },
    [STATUS.INACTIVE]: {
      title: `Reactivate Lease Rate Code ${leaseRateCode}`,
      instruction:
        'You are about to reactivate this Lease Rate Code. Please provide a reason for reactivation in the box below.',
      buttonText: 'Reactivate',
      isReasonRequired: true,
      gqlAPI: reactivateLeaseRateCode,
    },
  };

  const onDelete = () => {
    if (VARIATION[rateStatus].isReasonRequired && !comment) setErrors(true);
    else {
      VARIATION[rateStatus].gqlAPI({
        variables: {
          leaseRateCode,
          rateStatus,
          startDate,
          comment,
        },
      });
    }
  };

  const onCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
    setCommentsCount(200 - value.length);
    if (comment.length > 1) setErrors(false);
  };

  // unknown rate status
  if (!VARIATION[rateStatus]) {
    onClose();
    return null;
  }

  return (
    <DeleteModal
      id="lease-rate-delete"
      variant={leaseRateAssociation?.length ? 'extra-large' : 'large'}
      title={VARIATION[rateStatus].title}
      onClose={() => onClose(refresh)}
      onDelete={onDelete}
      deleting={deleting || deactivating || reactivating}
      deleteButtonText={VARIATION[rateStatus].buttonText}
    >
      {loadingDistinctCodes && (
        <div style={{ top: '-50px', width: '100%' }}>
          <Spinner />
        </div>
      )}
      {VARIATION[rateStatus].verb && leaseRateAssociation?.length > 0 ? (
        <Alert slim type="warning">
          <b>Are you sure you’d like to {VARIATION[rateStatus].verb} this Lease Rate Code?</b> You may want to move the
          following Standard Item Numbers and fuel types to another Lease Rate Code. Otherwise, all of their associated
          vehicles may no longer correspond to a Lease Rate Code. Standard Item Numbers and fuel types will not be
          retained with the Lease Rate Code once {VARIATION[rateStatus].verb}d.
        </Alert>
      ) : (
        <div className="margin-top-4 margin-bottom-4" data-testid="delete-modal-body">
          {VARIATION[rateStatus].instruction}
        </div>
      )}
      {!loadingDistinctCodes && distinctCodes && leaseRateAssociation?.length && (
        <SinFuelTypeTable
          leaseRateCode={leaseRateCode}
          leaseRateAssociation={leaseRateAssociation}
          distinctCodes={distinctCodes}
          onlyMove
          setRefresh={setRefresh}
        />
      )}
      {VARIATION[rateStatus].isReasonRequired && (
        <div className="margin-top-4">
          <div className={classNameErrBox(errors)}>
            <Label htmlFor="activation-comment" required>
              Please provide reason
            </Label>
            {errors && <ErrorMessage>Please provide a reason</ErrorMessage>}
            <Textbox
              name="comment"
              className={classNameErrInput(errors)}
              id="activation-comment"
              value={comment}
              type="textarea"
              maxLength={200}
              rows={3}
              onChange={onCommentChange}
            />
            <div className="usa-hint usa-character-count__message">
              {commentsCount < 200 ? `${commentsCount} characters remaining` : '200 characters allowed'}
            </div>
          </div>
        </div>
      )}
    </DeleteModal>
  );
};

LeaseRateDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    leaseRateCode: PropTypes.string,
    leaseRateAssociation: PropTypes.instanceOf(Array),
    rateStatus: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default LeaseRateDeleteModal;
