import { gql } from '@apollo/client';

// Unique Rate Type definitions and functions
export const UR_TYPE = {
  FUEL_ONLY: 'Fuel only mileage rate',
  MAINTENANCE: 'Maintenance only mileage rate',
  HIGH_COST: 'High cost threshold rate',
  NO_MILEAGE: 'No mileage rate',
  NON_DEFAULT: 'Non-default rate assignment',
  MANUAL: 'Manual entry rate',
  OCONUS: 'OCONUS rate',
};
const NO_FIELD_LIST = [UR_TYPE.NO_MILEAGE, UR_TYPE.MANUAL, UR_TYPE.NON_DEFAULT];
const UR_NOT_SHOW_FACTORS_LIST = [UR_TYPE.HIGH_COST, ...NO_FIELD_LIST];
const UR_SHOW_THRESHOLD_LIST = [UR_TYPE.HIGH_COST];
const UR_DISABLED_FIELD_LIST = {
  monthlyRateFactor: [UR_TYPE.FUEL_ONLY, UR_TYPE.MAINTENANCE],
  mileageRateFactor: [],
  startDate: [...NO_FIELD_LIST],
};
const UR_REQUIRED_FIELD_LIST = {
  monthlyRateFactor: [UR_TYPE.OCONUS],
  mileageRateFactor: [UR_TYPE.FUEL_ONLY, UR_TYPE.MAINTENANCE, UR_TYPE.OCONUS],
};

export const URTypeMatch = (type1, type2) => type1?.toUpperCase() === type2?.toUpperCase();
export const URTypeMatchList = (type, list) => list?.some((item) => URTypeMatch(type, item));
export const IsShowFieldBlock = (fieldBlock, type) => {
  switch (fieldBlock) {
    case 'factors':
      return !(type && URTypeMatchList(type, UR_NOT_SHOW_FACTORS_LIST));
    case 'threshold':
      return type && URTypeMatchList(type, UR_SHOW_THRESHOLD_LIST);
    default:
      return false;
  }
};
export const IsURFieldDisabled = (fieldName, type) => type && URTypeMatchList(type, UR_DISABLED_FIELD_LIST[fieldName]);
export const IsURFieldRequired = (fieldName, type) => type && URTypeMatchList(type, UR_REQUIRED_FIELD_LIST[fieldName]);

// GraphQL Schemas
export const PaginatedUniqueRateResponse = gql`
  fragment PaginatedUniqueRateFields on PaginatedUniqueRateResponse {
    rows {
      uniqueRateType
      uniqueRateDesc
      monthlyRateFactor
      mileageRateFactor
      thresholdAmount
      exceedsThreshold
      startDate
      endDate
      lastUpdateUserInfo {
        fullName
        email
      }
      rateStatus
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      dataSource
      dmlFlag
      comment
    }
    count
    hasMore
  }
`;

export const UniqueRatesResponse = gql`
  fragment UniqueRatesResponseFields on UniqueRate {
    uniqueRateType
    uniqueRateDesc
    monthlyRateFactor
    mileageRateFactor
    thresholdAmount
    exceedsThreshold
    startDate
    endDate
    rateStatus
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
    comment
  }
`;

export const GET_UNIQUE_RATES = gql`
  query getUniqueRates($virtualFilter: FilterType, $filters: FilterType, $order: OrderBy, $offset: Int, $limit: Int) {
    getUniqueRates(virtualFilter: $virtualFilter, filters: $filters, order: $order, offset: $offset, limit: $limit) {
      ...PaginatedUniqueRateFields
    }
  }
  ${PaginatedUniqueRateResponse}
`;

export const UPDATE_UNIQUE_RATE = gql`
  mutation updateOrCreateUniquePendingRate($uniqueRateType: String!, $uniqueRateInput: UniqueRateInput!) {
    updateOrCreateUniquePendingRate(uniqueRateType: $uniqueRateType, uniqueRateInput: $uniqueRateInput) {
      ...UniqueRatesResponseFields
    }
  }
  ${UniqueRatesResponse}
`;

export const CREATE_UNIQUE_RATE = gql`
  mutation createNewUniqueRate($uniqueRateType: String!, $uniqueRateInput: UniqueRateInput!) {
    createNewUniqueRate(uniqueRateType: $uniqueRateType, uniqueRateInput: $uniqueRateInput) {
      uniqueRateType
      uniqueRateDesc
      monthlyRateFactor
      mileageRateFactor
      thresholdAmount
      exceedsThreshold
      startDate
      endDate
      rateStatus
      deletedAt
      updatedByUser
      updatedAt
      createdByUser
      createdAt
      dataSource
      dmlFlag
    }
  }
`;

export const DELETE_UNIQUE_RATE = gql`
  mutation deleteUniqueRate($uniqueRateType: String!, $rateStatus: String!, $startDate: String!) {
    deleteUniqueRate(uniqueRateType: $uniqueRateType, rateStatus: $rateStatus, startDate: $startDate)
  }
`;

export const GET_UNIQUE_RATE_BY_START_DATE = gql`
  query getUniqueRateByStartDate($uniqueRateType: String!, $startDate: String!) {
    getUniqueRateByStartDate(uniqueRateType: $uniqueRateType, startDate: $startDate) {
      ...UniqueRatesResponseFields
    }
  }
  ${UniqueRatesResponse}
`;

export const GET_DISTINCT_UNIQUE_RATE_TYPE = gql`
  query {
    getDistinctUniqueRate {
      uniqueRateType
    }
  }
`;

export const GET_UR_START_DATE_RANGE = gql`
  query getURStartDateRange {
    getURStartDateRange {
      startDate
    }
  }
`;

export const DEACTIVATE_ACTIVE_UNIQUE_RATE = gql`
  mutation deactivateActiveUniqueRate(
    $uniqueRateType: String!
    $rateStatus: String!
    $startDate: String!
    $comment: String!
  ) {
    deactivateActiveUniqueRate(
      uniqueRateType: $uniqueRateType
      rateStatus: $rateStatus
      startDate: $startDate
      comment: $comment
    )
  }
`;

export const REACTIVATE_ACTIVE_UNIQUE_RATE = gql`
  mutation reactivateUniqueRate(
    $uniqueRateType: String!
    $rateStatus: String!
    $startDate: String!
    $comment: String!
  ) {
    reactivateUniqueRate(
      uniqueRateType: $uniqueRateType
      rateStatus: $rateStatus
      startDate: $startDate
      comment: $comment
    )
  }
`;
