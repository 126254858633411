import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ErrorMessage, Spinner } from '@gsa/afp-component-library';
import { classNameErrBox } from 'components/helpers/afp-bm-helpers';
import { AddressValidator } from 'components/widgets/AddressValidator/AddressValidator';
import CustomerForm from './components/customer-form';
import AddressForm from './components/address-form';
import VehicleListing from './components/vehicle-listing';
import {
  APP_TYPE,
  GET_CUSTOMER_INFO_FROM_VMS,
  GET_CUSTOMER_VEHICLES_FROM_VMS,
  GET_AGENCIES,
  GET_BUREAUS,
  GET_STATES,
} from './unique-rate-app-helper';
import { RATE_TYPE } from './components/vehicle-listing-table';
import './style/unique-rate-app-form-step-one.scss';

const genYupSchema = (cardType, rateOptions) => {
  const customerSchema = yup
    .object()
    .required('Customer is required')
    .test(
      'customer',
      'Please fill in all required customer information.',
      // eslint-disable-next-line
      function (value) {
        const requiredFields = ['customerNumber', 'contactName', 'contactEmail', 'contactPhone'];
        return requiredFields.every((f) => value[f]);
      },
    );
  const shippingSchema = yup.object().test(
    'shipping',
    'Please fill in all required fields in shipping address.',
    // eslint-disable-next-line
    function (value) {
      const requiredFields = ['address2', 'city', 'stateCode', 'zipCode'];
      return requiredFields.every((f) => value[f]);
    },
  );
  const vehiclesSchema = yup
    .array()
    .nullable()
    .test(
      'vehicles',
      'Please choose a rate for each selected vehicle.',
      // eslint-disable-next-line
      function (value) {
        const { path, createError } = this;
        if (!value?.length)
          return createError({
            path,
            message: 'Vehicle selection is required.',
          });
        if (rateOptions[RATE_TYPE.LRC]) return value.every((v) => v[RATE_TYPE.LRC]);
        if (rateOptions[RATE_TYPE.URMon] || rateOptions[RATE_TYPE.URMil]) {
          return value.every((v) => v[RATE_TYPE.URMon] || v[RATE_TYPE.URMil]);
        }
        return true;
      },
    );

  if (cardType)
    return yup.object().shape({
      customer: customerSchema,
      shipping: shippingSchema,
      vehicles: vehiclesSchema,
    });
  return yup.object().shape({
    customer: customerSchema,
    vehicles: vehiclesSchema,
  });
};

const UniqueRateAppFormStepTwo = forwardRef((props, ref) => {
  const { draft, excludedVehicles, onPrev, onNext, onSaveDraft, setBannerMsg } = props;

  const { appType, monthlyRateType, cardType, uniqueRateAppPoc, uniqueRateAppFcs, uniqueRateAppVehicle } = draft;

  const tableOptions = {};
  if (appType === APP_TYPE.REMOVE) tableOptions.REMOVAL = true;
  else if (appType === APP_TYPE.ADD_VEHICLE) tableOptions.ADD_VEHICLE = true;
  if (monthlyRateType === 'Non-default rate assignment' || monthlyRateType === 'Non-default assignment')
    tableOptions[RATE_TYPE.LRC] = true;
  else if (monthlyRateType === 'Manual entry') {
    tableOptions[RATE_TYPE.URMon] = true;
    tableOptions[RATE_TYPE.URMil] = true;
  }

  const schema = genYupSchema(cardType, tableOptions);

  const customerData = uniqueRateAppPoc
    ? {
        customerNumber: uniqueRateAppPoc.customerId,
        customerAgency: uniqueRateAppPoc.agencyCode,
        customerBureau: uniqueRateAppPoc.bureauCode,
        contactName: uniqueRateAppPoc.poc,
        contactEmail: uniqueRateAppPoc.email,
        contactPhone: uniqueRateAppPoc.phonenumber,
      }
    : {};
  const zip4 = uniqueRateAppFcs?.zipcode4 ? `-${uniqueRateAppFcs.zipcode4}` : '';
  const shippingData = uniqueRateAppFcs
    ? {
        address1: uniqueRateAppFcs.address1,
        address2: uniqueRateAppFcs.address2,
        address3: uniqueRateAppFcs.address3,
        city: uniqueRateAppFcs.city,
        stateCode: uniqueRateAppFcs.state,
        zipCode: `${uniqueRateAppFcs.zipcode}${zip4}`,
      }
    : {};
  const vehicleData = uniqueRateAppVehicle
    ? uniqueRateAppVehicle.map((v) => {
        const item = {
          plateNumber: v.plateNumber,
          vin: v.vin,
        };
        if (tableOptions[RATE_TYPE.LRC] && v.leaseRateCode) item[RATE_TYPE.LRC] = v.leaseRateCode;
        if (tableOptions[RATE_TYPE.URMon] && v.monthlyRate) item[RATE_TYPE.URMon] = v.monthlyRate;
        if (tableOptions[RATE_TYPE.URMil] && v.mileageRate) item[RATE_TYPE.URMil] = v.mileageRate;
        return item;
      })
    : [];

  const [customerNumber, setCustomerNumber] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [agencySelected, setAgencySelected] = useState('');
  const [bureauOptionList, setBureauOptionList] = useState({});
  const [bureauOptions, setBureauOptions] = useState([]);
  const [vehicleList, setVehicleList] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [canSaveDraft, setCanSaveDraft] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [uniqueRateAppDataToBeSaved, setUniqueRateAppDataToBeSaved] = useState(null);
  const [addressToValidate, setAddressToValidate] = useState(null);

  // useForm declaration
  const { errors, clearErrors, control, getValues, reset: resetForm, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const resetCustomer = () => {
    setCustomerNumber(null);
    resetForm({
      customer: {},
      shipping: {},
      vehicles: [],
    });
    setVehicleList(null);
  };

  const filterVehicleList = (list) => {
    if (appType === APP_TYPE.REMOVE) return list.filter((v) => v.vehicleLeaseRateCode);
    if (appType === APP_TYPE.ADD_VEHICLE && excludedVehicles)
      return list.filter((v) => excludedVehicles.every((veh) => veh.vin !== v.id));
    return [...list];
  };

  const onErrorFn = () => {
    resetCustomer();
    setBannerMsg({
      type: 'error',
      message: <div>Error occured when retrieving information of the selected customer.</div>,
    });
  };

  const [fetchCustomerInfo, { loading: loadingCustomerInfo }] = useLazyQuery(GET_CUSTOMER_INFO_FROM_VMS, {
    onCompleted: (data) => {
      if (data?.getCustomer) {
        const {
          customerId,
          firstName,
          lastName,
          customerAgencyCode,
          customerBureauCode,
          email,
          phone,
          address1,
          address2,
          address3,
          city,
          stateCode,
          postalCode,
          vehicles,
        } = data.getCustomer;
        resetForm({
          customer: {
            customerNumber: customerId?.trim(),
            customerAgency: customerAgencyCode,
            customerBureau: customerBureauCode,
            contactName: `${firstName} ${lastName}`,
            contactEmail: email,
            contactPhone: phone,
          },
          shipping: {
            address1,
            address2,
            address3,
            city,
            stateCode,
            zipCode: postalCode,
          },
          vehicles: [],
        });
        setVehicleList(filterVehicleList(vehicles));
        setCustomerNumber(customerId?.trim());
        setAgencySelected(customerAgencyCode || '');
      } else {
        resetCustomer();
        setBannerMsg({
          type: 'error',
          message: <div>Customer not found.</div>,
        });
      }
    },
    onError: onErrorFn,
  });

  const [fetchCustomerVehicles, { loading: loadingCustomerVehicles }] = useLazyQuery(GET_CUSTOMER_VEHICLES_FROM_VMS, {
    onCompleted: (data) => {
      if (data?.getCustomer) {
        const { customerId, vehicles } = data.getCustomer;
        resetForm({
          customer: customerData,
          shipping: shippingData,
          vehicles: vehicleData,
        });
        setVehicleList(filterVehicleList(vehicles));
        setCustomerNumber(customerId?.trim());
        setAgencySelected(customerData.customerAgency || '');
      }
    },
    onError: onErrorFn,
  });

  const [fetchAgencies, { data: agencyData }] = useLazyQuery(GET_AGENCIES);
  const [fetchBureaus, { data: bureauData }] = useLazyQuery(GET_BUREAUS);

  useQuery(GET_STATES, {
    onCompleted: (stateData) => {
      if (stateData) {
        const options = stateData.getStates.map(({ stateName, stateCode }) => ({
          key: `${stateCode}-${stateName}`,
          label: `${stateCode} - ${stateName}`,
          value: stateCode,
        }));

        setStateOptions(options);
      }
    },
  });

  useEffect(() => {
    const numErrors = Object.values(validationError || {}).reduce((sum, n) => sum + n, 0);
    setCanSaveDraft(customerNumber !== null && numErrors === 0);
  }, [customerNumber, validationError]);

  useEffect(() => {
    const agencies = agencyData?.getAgencies;
    const bureaus = bureauData?.getBureaus;
    if (!(agencies && bureaus)) return;

    const agencyOpts = [{ value: '', label: '' }];
    const opts = agencies
      .map((agency) => ({
        value: agency.id,
        label: `${agency.shortName} - ${agency.name}`,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    agencyOpts.push(...opts);
    setAgencyOptions(agencyOpts);

    const bureauOptsList = {};
    opts.forEach(({ value: agencyCode }) => {
      bureauOptsList[agencyCode] = [{ value: '', label: '' }];
      const bureauOpts = bureaus
        .filter((b) => b.agencyCode === agencyCode)
        .map((bureau) => ({
          value: bureau.id,
          label: bureau.name,
          // label: `${bureau.id} - ${bureau.name}`,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));
      bureauOptsList[agencyCode].push(...bureauOpts);
    });
    setBureauOptionList(bureauOptsList);
  }, [agencyData, bureauData]);

  useEffect(() => {
    if (agencySelected && bureauOptionList) setBureauOptions(bureauOptionList[agencySelected] || []);
  }, [agencySelected, bureauOptionList]);

  const onClickSaveDraft = (validateAddress = false) => {
    setBannerMsg(null);
    const { customer, shipping, vehicles: selection } = getValues();
    const uniqueRateAppData = {};
    uniqueRateAppData.variables = {
      id: draft.id,
      uniqueRateAppInput: {
        mileageRate: draft.mileageRate,
        mileageType: draft.mileageType,
        reason: draft.reason,
        justification: draft.justification,
        monthlyRateType: draft.monthlyRateType,
        appType: draft.appType,
        status: draft.status,
        comment: draft.comment,
      },
      uniqueRateAppPocInput:
        customer && Object.keys(customer).length
          ? {
              customerId: customer.customerNumber,
              agencyCode: customer.customerAgency,
              bureauCode: customer.customerBureau,
              poc: customer.contactName,
              email: customer.contactEmail,
              phonenumber: customer.contactPhone,
            }
          : {},
      uniqueRateAppFcsInput:
        shipping && Object.keys(shipping).length
          ? {
              address1: shipping.address1,
              address2: shipping.address2,
              address3: shipping.address3,
              city: shipping.city,
              state: shipping.stateCode,
              zipcode: shipping.zipCode?.substring(0, 5),
              zipcode4: shipping.zipCode?.substring(6, 10),
            }
          : {},
      uniqueRateAppVehicleInput:
        selection?.map((v) => {
          const veh = {
            vin: v.vin,
            plateNumber: v.plateNumber,
          };
          if (v[RATE_TYPE.LRC]) veh.leaseRateCode = v[RATE_TYPE.LRC];
          if (v[RATE_TYPE.URMon]) veh.monthlyRate = v[RATE_TYPE.URMon];
          if (v[RATE_TYPE.URMil]) veh.mileageRate = v[RATE_TYPE.URMil];
          return veh;
        }) || [],
    };

    const addrToValidate = {
      address: shipping.address2,
      address2: shipping.address3,
      city: shipping.city,
      state: shipping.stateCode,
      zip: shipping.zipCode,
      countryCode: 'US',
    };

    setUniqueRateAppDataToBeSaved(uniqueRateAppData);

    if (validateAddress) {
      setAddressToValidate(addrToValidate);
    } else {
      onSaveDraft(uniqueRateAppData);
    }
  };

  const onClickNext = async () => {
    const isRequiredValid = await trigger();
    if (isRequiredValid) {
      onClickSaveDraft(true);
    } else {
      setBannerMsg({
        type: 'error',
        message: 'All required fields must be filled before proceeding to next screen.',
      });
    }
  };

  const onChangeCustomer = (customerNo) => {
    clearErrors();
    if (!customerNo) {
      resetCustomer();
    } else {
      fetchCustomerInfo({ variables: { customerId: customerNo } });
    }
  };

  const onValidationError = (sectionName, errorNo) => {
    setValidationError((prevState) => {
      const newState = { ...prevState };
      newState[sectionName] = errorNo;
      return newState;
    });
  };

  const onValidateRequired = async () => {
    const result = await trigger();
    return result && canSaveDraft;
  };

  const setAddress = (selectedAddress) => {
    const { shipping } = getValues();
    const { address, address2, city, state, zip } = selectedAddress;
    const updatedShippingData = {
      address1: shipping.address1,
      address2: address,
      address3: address2,
      city,
      stateCode: state,
      zipCode: zip,
    };

    resetForm({
      ...getValues(),
      shipping: { ...updatedShippingData },
    });

    onSaveDraft({
      variables: {
        ...uniqueRateAppDataToBeSaved.variables,
        uniqueRateAppFcsInput: {
          address1: updatedShippingData.address1,
          address2: updatedShippingData.address2,
          address3: updatedShippingData.address3,
          city: updatedShippingData.city,
          state: updatedShippingData.stateCode,
          zipcode: updatedShippingData.zipCode.substring(0, 5),
          zipcode4: updatedShippingData.zipCode.substring(6, 10),
        },
      },
    });
    onNext();
  };

  useImperativeHandle(ref, () => ({
    onSaveDraftClick: onClickSaveDraft,
    validateRequired: onValidateRequired,
  }));

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  useEffect(() => {
    fetchAgencies();
    fetchBureaus({ variables: { agencyCode: '' } });
    if (uniqueRateAppPoc?.customerId) {
      fetchCustomerVehicles({
        variables: { customerId: uniqueRateAppPoc.customerId },
      });
    }
  }, []);

  return (
    <div className="padding-top-1 padding-bottom-4">
      {loadingCustomerInfo || loadingCustomerVehicles ? (
        <div className="loading-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className={classNameErrBox(errors.customer)}>
        <div className="unique-rates-app-section-header margin-top-6 margin-bottom-1">Customer Information</div>
        {createErrorMessage(errors.customer)}
        {appType !== APP_TYPE.ADD_VEHICLE && (
          <div>Enter the customer number to retrieve the current rate information.</div>
        )}
        <Controller
          control={control}
          name="customer"
          defaultValue={{}}
          render={({ name, value, onChange }) => (
            <CustomerForm
              id="customer"
              defaultValues={value}
              agencyOptions={agencyOptions}
              bureauOptions={bureauOptions}
              customerDisabled={appType === APP_TYPE.ADD_VEHICLE}
              contactDisabled={customerNumber === null}
              onChangeCustomer={onChangeCustomer}
              onChange={(e) => {
                onChange(e);
                if (errors[name]) trigger();
              }}
              onError={(val) => onValidationError(name, val)}
            />
          )}
        />
      </div>

      {cardType ? (
        <div className={classNameErrBox(errors.shipping)}>
          <div className="unique-rates-app-section-header margin-top-6 margin-bottom-1">
            Fleet Card Shipping Address
          </div>
          {createErrorMessage(errors.shipping)}
          {appType === APP_TYPE.REMOVE && (
            <div>
              If you are removing a modified mileage rate, the vehicle will be assigned a full service fleet card.
            </div>
          )}
          <div>P.O. Boxes are not accepted.</div>
          <Controller
            control={control}
            name="shipping"
            defaultValue={{}}
            render={({ name, value, onChange }) => (
              <AddressForm
                id="shipping"
                defaultValues={value}
                stateOptions={stateOptions}
                disabled={customerNumber === null}
                onChange={(e) => {
                  onChange(e);
                  if (errors[name]) trigger();
                }}
                onError={(val) => onValidationError(name, val)}
              />
            )}
          />
        </div>
      ) : null}

      <div className={classNameErrBox(errors.vehicles)}>
        <div className="unique-rates-app-section-header margin-top-6 margin-bottom-1">Vehicle Information</div>
        {createErrorMessage(errors.vehicles)}
        <p>
          Please select vehicles and if applicable, apply either Lease Rate Code or monthly or mileage rate to submit
          for the unique rate application request.
        </p>
        <Controller
          control={control}
          name="vehicles"
          defaultValue={null}
          render={({ name, onChange }) => (
            <VehicleListing
              customerNumber={customerNumber}
              options={tableOptions}
              vehicleList={vehicleList}
              selected={uniqueRateAppVehicle}
              onChange={(e) => {
                onChange(e);
                if (errors[name]) trigger();
              }}
              onError={(val) => onValidationError(name, val)}
              setBannerMsg={setBannerMsg}
            />
          )}
        />
      </div>

      {appType !== APP_TYPE.REMOVE && (
        <div className="action-button-box-left margin-top-8">
          <Button variant="outline" onClick={onPrev} leftIcon={{ name: 'arrow_back' }} label="Previous" />
          <Button
            disabled={!canSaveDraft}
            variant="primary"
            onClick={onClickNext}
            rightIcon={{ name: 'arrow_forward' }}
            label="Save and continue"
          />
        </div>
      )}

      <AddressValidator addressToValidate={addressToValidate} setAddress={setAddress} />
    </div>
  );
});

UniqueRateAppFormStepTwo.defaultProps = {
  excludedVehicles: [],
  onPrev: undefined,
  onNext: undefined,
};

UniqueRateAppFormStepTwo.propTypes = {
  draft: PropTypes.shape().isRequired,
  excludedVehicles: PropTypes.arrayOf(PropTypes.shape({})),
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onSaveDraft: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
};

export default UniqueRateAppFormStepTwo;
