import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { ResponsiveFilterPanel, Spinner } from '@gsa/afp-component-library';
import { GET_DISTINCT_PURCHASE_TYPES, GET_PR_START_DATE_RANGE } from './components/graphql-queries';
import { getUTCFYInt, getFYUTCDateRange } from '../../components/helpers/afp-bm-date';
import { STATUS, DEFAULT_FILTER_STATE } from '../../components/helpers/constants';
import './style/purchase-rate-filter.scss';

const InitFilterItems = {
  status: {
    id: 'rateStatus',
    title: 'Status',
    type: 'checkbox-list',
    expanded: true,
    options: [STATUS.ACTIVE, STATUS.PENDING, STATUS.NEW, STATUS.EXPIRED, STATUS.INACTIVE],
    className: 'StatusFilter',
    noChip: true,
  },
  purchaseType: {
    id: 'purchaseType',
    title: 'Purchase type',
    type: 'checkbox-list',
    expanded: true,
    options: [],
    className: 'purchaseTypeFilter',
    noChip: true,
  },
  fiscalYear: {
    id: 'fiscalYear',
    title: 'Fiscal year',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
};

const PurchaseRateFilter = ({ onFilter, defaultState, timestamp }) => {
  const [filterItems, setFilterItems] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);

  const [fetchPurchaseTypes, { loading: loadingPT }] = useLazyQuery(GET_DISTINCT_PURCHASE_TYPES, {
    onCompleted: ({ getDistinctPurchaseTypes }) => {
      InitFilterItems.purchaseType.options = getDistinctPurchaseTypes.map((t) => t.purchaseType);
      setFilterItems(InitFilterItems);
    },
    onError: () => {
      setFilterItems(InitFilterItems);
    },
  });

  const [fetchPRStartDateRange, { loading: loadingFY }] = useLazyQuery(GET_PR_START_DATE_RANGE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPrStartDateRange: dates }) => {
      if (dates) {
        const startYear = getUTCFYInt(dates[0].effectiveStartDate);
        const endYear = getUTCFYInt(dates[1].effectiveStartDate);
        const yearList = [];
        for (let i = endYear; i >= startYear; i -= 1)
          yearList.push({
            value: i,
            label: i.toString(),
          });
        setFilterOptions({ fiscalYear: yearList });
      }
    },
    onError: () => setFilterOptions({ fiscalYear: [] }),
  });

  useEffect(() => {
    fetchPurchaseTypes();
    fetchPRStartDateRange();
  }, [timestamp]);

  const buildFilters = useCallback((state) => {
    const filters = {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'OR', value: [] },
    };

    Object.keys(state).forEach((key) => {
      const value = state[key];
      switch (key) {
        case 'rateStatus':
          value?.forEach((val) => {
            filters.virtualFilter.value.push({
              key,
              operator: 'EQ',
              value: val,
            });
          });
          break;
        case 'purchaseType':
          {
            const ptFilter = { operator: 'OR', value: [] };
            value?.forEach((val) => ptFilter.value.push({ key, operator: 'EQ', value: val }));
            if (ptFilter.value.length) filters.filters.value.push(ptFilter);
          }
          break;
        case 'fiscalYear':
          {
            const { startDate, endDate } = getFYUTCDateRange(value);
            filters.filters.value.push(
              { key: 'effectiveStartDate', operator: 'LTE', value: endDate },
              {
                operator: 'OR',
                value: [
                  { key: 'effectiveEndDate', operator: 'EQ', value: null },
                  {
                    key: 'effectiveEndDate',
                    operator: 'GTE',
                    value: startDate,
                  },
                ],
              },
            );
          }
          break;
        default:
      }
    });

    Object.keys(filters).forEach((key) => {
      if (!filters[key].value.length) delete filters[key];
    });
    return { filters, state };
  }, []);

  if (loadingPT || loadingFY)
    return (
      <div style={{ marginTop: 36 }}>
        <Spinner />
      </div>
    );

  if (filterItems && filterOptions)
    return (
      <div>
        <ResponsiveFilterPanel
          defaultState={defaultState}
          items={filterItems}
          filterOptions={filterOptions}
          onChange={(state) => {
            onFilter(buildFilters(state));
          }}
        />
      </div>
    );

  return null;
};

PurchaseRateFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  defaultState: PropTypes.shape(Object),
  timestamp: PropTypes.number,
};
PurchaseRateFilter.defaultProps = {
  defaultState: DEFAULT_FILTER_STATE,
  timestamp: Date.now(),
};

export default PurchaseRateFilter;
