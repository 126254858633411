import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  ComboBox,
  Textbox,
  ErrorMessage,
  Pagination,
  Spinner,
  Icon,
  useModal,
} from '@gsa/afp-component-library';
import BannerMessage from 'widgets/banner-message';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { DoReset } from 'components/helpers/pagination-helpers';
import VehicleListingTable, { RATE_TYPE } from './vehicle-listing-table';
import { GET_DISTINCT_LEASE_RATE_CODE } from '../../lease-rate/lease-rate-helper';
import '../style/vehicle-listing.scss';

const VehicleListing = ({ customerNumber, options, vehicleList, selected, onChange, onError, setBannerMsg }) => {
  const defaultSort = { vin: 'ASC' };
  const [vehicles, setVehicles] = useState(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const [tableRows, setTableRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [resetPagination, setResetPagination] = useState(false);

  const [LRCOptions, setLRCOptions] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [applyToAllRate, setApplyToAllRate] = useState({});
  const [allRateErrors, setAllRateErrors] = useState({});

  const [msg, setMsg] = useState(null);
  const [selectedVIN, setSelectedVIN] = useState(undefined);

  const { isOpen: isModalOpen, openModal, closeModal } = useModal();

  const [fetchLRCodes, { loading: loadingLRCodes }] = useLazyQuery(GET_DISTINCT_LEASE_RATE_CODE, {
    onCompleted: (data) => {
      if (data?.getDistinctLeaseRateCode)
        setLRCOptions(
          data.getDistinctLeaseRateCode.map((item) => ({
            value: item.leaseRateCode,
            label: item.leaseRateCode,
          })),
        );
    },
    onError: (err) => {
      setLRCOptions([]);
      setBannerMsg({
        type: 'error',
        message: (
          <div>
            Unable to retrieve Lease Rate Codes.
            <br />
            {err.message}
          </div>
        ),
      });
    },
  });

  const resetToPageOne = () => {
    setCurrentPage(1);
    DoReset(setResetPagination);
  };

  useEffect(() => {
    if (vehicleList?.length) {
      fetchLRCodes();
    }
  }, []);

  useEffect(() => {
    resetToPageOne();

    if (!vehicleList) {
      setVehicles(null);
      return;
    }

    if (!LRCOptions) fetchLRCodes();

    const list = vehicleList?.map((v) => {
      const item = {
        plateNumber: v.tagNumber,
        vin: v.id,
        prevLRC: v.vehicleLeaseRateCode,
      };
      selected?.forEach((sel) => {
        if (sel.vin === item.vin) {
          if (options[RATE_TYPE.LRC]) item[RATE_TYPE.LRC] = sel.leaseRateCode;
          if (options[RATE_TYPE.URMon])
            item[RATE_TYPE.URMon] = sel.monthlyRate && parseFloat(sel.monthlyRate).toFixed(2);
          if (options[RATE_TYPE.URMil])
            item[RATE_TYPE.URMil] = sel.mileageRate && parseFloat(sel.mileageRate).toFixed(3);
          item.isSelected = true;
        }
      });
      return item;
    });
    setVehicles(list);
    setSelectedCount(list?.filter((v) => v.isSelected).length);
  }, [vehicleList, selected]);

  useEffect(() => {
    if (vehicles) {
      setSelectedCount(vehicles.filter((v) => v.isSelected).length);
      onChange(vehicles.filter((v) => v.isSelected));
    }
  }, [vehicles]);

  useEffect(() => {
    if (!vehicles) return;
    if (selectedVIN) setTableRows(vehicles.filter((v) => v.vin === selectedVIN));
    else setTableRows(vehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
  }, [vehicles, currentPage, itemsPerPage, selectedVIN]);

  useEffect(() => {
    if (selectAll && !options[RATE_TYPE.LRC] && !options[RATE_TYPE.URMil] && !options[RATE_TYPE.URMon])
      setVehicles((prevState) => prevState.map((row) => ({ ...row, isSelected: true })));
  }, [options, selectAll]);

  const handlePaginationChange = (pageNo, numItems) => {
    if (itemsPerPage !== numItems) {
      setItemsPerPage(numItems);
      resetToPageOne();
    } else {
      setCurrentPage(pageNo);
    }
  };

  const setVehicleSelect = (pageNo, isSelected) => {
    if (selectedVIN)
      setVehicles((prevState) =>
        prevState.map((v) => {
          if (v.vin === selectedVIN) return { ...v, isSelected };
          return { ...v };
        }),
      );
    else {
      const start = pageNo < 0 ? 0 : (pageNo - 1) * itemsPerPage;
      const end = pageNo < 0 ? vehicles.length : pageNo * itemsPerPage;
      setVehicles((prevState) =>
        prevState.map((v, i) => {
          if (i < start || i >= end) return { ...v };
          return { ...v, isSelected };
        }),
      );
    }
  };

  const updateRow = (data) => {
    if (!data.vin) return;
    setVehicles((prevState) =>
      prevState.map((row) => {
        if (data.vin !== row.vin) return { ...row };
        return { ...row, ...data };
      }),
    );
  };

  const updateAllSelectedRows = (rates) => {
    setVehicles((prevState) =>
      prevState.map((row) => {
        if (!selectAll && !row.isSelected) return row;
        const newRow = { ...row };
        delete newRow[RATE_TYPE.LRC];
        delete newRow[RATE_TYPE.URMon];
        delete newRow[RATE_TYPE.URMil];
        return { ...newRow, isSelected: true, ...rates };
      }),
    );
  };

  const onRateChange = (type, vin, value) => {
    updateRow({ vin, [type]: value });
  };

  const onOrder = (key, order) => {
    setVehicles((prevState) => {
      return [...prevState].sort((a, b) => {
        const blank = order === 'ASC' ? '_' : '!';
        const aa = a[key] || blank;
        const bb = b[key] || blank;
        return (order === 'ASC') === aa > bb ? 1 : -1;
      });
    });
    resetToPageOne();
  };

  const onCheckAllRows = (e) => {
    if (e.target?.checked) setVehicleSelect(currentPage, true);
    else setVehicleSelect(currentPage, false);
  };

  const onCheckRow = (vin, e) => {
    if (e.target?.checked) updateRow({ vin, isSelected: true });
    else updateRow({ vin, isSelected: false });
  };

  const onAllRateChange = (type, value) => {
    if (value) {
      setApplyToAllRate((prevState) => {
        return { ...prevState, [type]: value };
      });
    }
  };
  const validateUR4All = (type, val) => {
    if (val) {
      const num = Number(val);
      if (Number.isNaN(num) || num <= 0) {
        setAllRateErrors((prevState) => ({
          ...prevState,
          [type]: true,
        }));
        return;
      }
      if (type === RATE_TYPE.URMon) onAllRateChange(type, num.toFixed(2));
      if (type === RATE_TYPE.URMil) onAllRateChange(type, num.toFixed(3));
    }
    if (allRateErrors[type]) {
      const newErrors = { ...allRateErrors };
      delete newErrors[type];
      setAllRateErrors(newErrors);
    }
  };

  const applyRateToAllSelected = () => {
    if (Object.keys(applyToAllRate).length && !Object.keys(allRateErrors).length) {
      updateAllSelectedRows(applyToAllRate);
      setMsg(() => {
        const count = selectAll ? vehicles.length : selectedCount;
        return {
          type: 'success',
          message: `${
            applyToAllRate[RATE_TYPE.LRC] ? 'Lease Rate Code' : 'Unique rate'
          } has been applied to ${count} selected vehicle${count > 1 ? 's' : ''}`,
        };
      });
      return true;
    }
    return false;
  };
  const resetRateForAllSelected = () => {
    updateAllSelectedRows({});
  };

  const applyRateInput = () => (
    <div className="rate-inputs bm-text-input-fix">
      {options[RATE_TYPE.LRC] && (
        <div className="rate-input-box">
          <label htmlFor="all-rate-lrc">Lease Rate Code</label>
          <ComboBox
            id="all-rate-lrc"
            data-testid="all-rate-lrc"
            className="apply-rate-select"
            options={LRCOptions}
            defaultValue={applyToAllRate.LRC || undefined}
            onChange={(val) => onAllRateChange(RATE_TYPE.LRC, val || null)}
          />
        </div>
      )}
      {options[RATE_TYPE.URMon] && (
        <div className="rate-input-box">
          <label htmlFor="all-rate-urmon">Monthly Rate</label>
          <div className="rate-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar-global" />
            <Textbox
              id="all-rate-urmon"
              data-testid="all-rate-urmon"
              defaultValue=""
              value={applyToAllRate[RATE_TYPE.URMon]}
              onChange={(e) => {
                onAllRateChange(RATE_TYPE.URMon, e.target.value || null);
              }}
              onBlur={(e) => {
                validateUR4All(RATE_TYPE.URMon, e.target.value);
              }}
            />
          </div>
          {allRateErrors[RATE_TYPE.URMon] && <ErrorMessage>Invalid number</ErrorMessage>}
        </div>
      )}
      {options[RATE_TYPE.URMil] && (
        <div className="rate-input-box">
          <label htmlFor="all-rate-urmil">Mileage Rate</label>
          <div className="rate-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar-global" />
            <Textbox
              id="all-rate-urmil"
              data-testid="all-rate-urmil"
              defaultValue=""
              value={applyToAllRate[RATE_TYPE.URMil]}
              onChange={(e) => {
                onAllRateChange(RATE_TYPE.URMil, e.target.value || null);
              }}
              onBlur={(e) => {
                validateUR4All(RATE_TYPE.URMil, e.target.value);
              }}
            />
          </div>
          {allRateErrors[RATE_TYPE.URMil] && <ErrorMessage>Invalid number</ErrorMessage>}
        </div>
      )}
    </div>
  );

  const applyRateToAllBox = () => {
    if (options[RATE_TYPE.LRC] || options[RATE_TYPE.URMil] || options[RATE_TYPE.URMon])
      return (
        <div className="apply-rate-to-all margin-top-2 bm-text-input-fix">
          <div>
            Apply rate to <b>all</b> the vehicles
          </div>
          <div className="apply-rate-box">
            {applyRateInput()}
            <Button data-testid="apply-all-rate" variant="outline" onClick={applyRateToAllSelected} label="Apply" />
          </div>
        </div>
      );
    return null;
  };

  const applyRateToSelectedBox = () => (
    <div className="ura-apply-rate-to-selected">
      <span>
        <Icon className="iconStyle usa-icon--size-3" iconName="check" />
        <b>{selectedCount} items selected</b>
      </span>
      {(options[RATE_TYPE.LRC] || options[RATE_TYPE.URMil] || options[RATE_TYPE.URMon]) && (
        <button type="button" className="button-link" data-testid="clear-select-rate" onClick={resetRateForAllSelected}>
          <Icon className="iconStyle usa-icon--size-3" iconName="close" />
          Clear all rate
        </button>
      )}
      {(options[RATE_TYPE.LRC] || options[RATE_TYPE.URMil] || options[RATE_TYPE.URMon]) && (
        <Button
          data-testid="set-select-rate"
          variant="primary"
          onClick={openModal}
          label="Apply rate to selected vehicles"
        />
      )}
    </div>
  );

  const selectVINBox = () => (
    <div className="rate-input-box bm-text-input-fix">
      <label htmlFor="vin-search-box">Search by VIN</label>
      <ComboBox
        id="vin-search-box"
        data-testid="vin-search-box"
        className="vin-search-box"
        options={vehicles.map((v) => ({ value: v.vin, label: v.vin }))}
        defaultValue={selectedVIN || undefined}
        onChange={setSelectedVIN}
      />
    </div>
  );

  if (!vehicles) return null;

  if (!vehicles.length)
    return (
      <p>
        {`No vehicle${options.REMOVAL ? ' with existing rate' : ''} ${
          options.ADD_VEHICLE ? 'to add' : 'found'
        } for this customer`}
      </p>
    );

  if (loadingLRCodes)
    return (
      <div data-testid="spinner">
        <Spinner />
      </div>
    );

  if (!LRCOptions) return null;

  if (LRCOptions.length === 0) return <p>Unable to retrieve Lease Rate Codes. Please try again later</p>;

  return (
    <div id="vehicle-listing">
      <div className="usa-form margin-top-2">
        <p style={{ fontWeight: 'bold', width: '600px' }}>Do all vehicles need to be on the unique rates requested?</p>
        <div className="usa-radio margin-bottom-1" style={{ width: 600 }}>
          <input
            className="usa-radio__input"
            type="radio"
            id="select-all-vehicles-yes"
            data-testid="select-all-vehicles-yes"
            name="calcType"
            checked={selectAll}
            onChange={() => {
              setSelectAll(true);
              setApplyToAllRate({});
              setAllRateErrors({});
            }}
          />
          <label className="usa-radio__label" htmlFor="select-all-vehicles-yes">
            Yes, select all {vehicles.length} vehicles for this customer
          </label>
        </div>
        <div className="usa-radio margin-bottom-3" style={{ width: 600 }}>
          <input
            className="usa-radio__input"
            type="radio"
            id="select-all-vehicles-no"
            data-testid="select-all-vehicles-no"
            name="calcType"
            checked={selectAll ? undefined : 'checked'}
            onChange={() => {
              setSelectAll(false);
              setApplyToAllRate({});
              setAllRateErrors({});
            }}
            false
          />
          <label className="usa-radio__label" htmlFor="select-all-vehicles-no">
            No
          </label>
        </div>
      </div>

      {msg && (
        <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
          {msg.message}
        </BannerMessage>
      )}

      {selectAll ? (
        applyRateToAllBox()
      ) : (
        <div className="apply-rate-to-selected">
          {selectVINBox()}
          {applyRateToSelectedBox()}
        </div>
      )}
      {!selectAll && (
        <div className="ura-vehicle-listing-table" data-testid="vehicle-listing-table">
          <div className="vehicle-listing-box">
            <VehicleListingTable
              id="vehicle-listing-table"
              customerNumber={customerNumber}
              rows={tableRows}
              options={options}
              LRCoptions={LRCOptions}
              defaultSort={defaultSort}
              onCheckAllRows={onCheckAllRows}
              onCheckRow={onCheckRow}
              onRateChange={onRateChange}
              onError={onError}
              onOrder={onOrder}
            />
            {!selectedVIN && (
              <Pagination
                className="padding-top-2"
                onPageChange={handlePaginationChange}
                variant="advanced"
                currentPage={currentPage}
                itemsCount={vehicles.length}
                itemsPerPage={itemsPerPage}
                isReset={resetPagination}
              />
            )}
          </div>
        </div>
      )}
      {isModalOpen && (
        <DeleteModal
          id="bulk-apply-rate-modal"
          data-testid="bulk-apply-rate-modal"
          title="Apply Unique Rate"
          onClose={closeModal}
          onDelete={() => {
            if (applyRateToAllSelected()) closeModal();
          }}
          deleteButtonText="Apply"
          deleteButtonVariant="primary"
        >
          {applyRateInput()}
        </DeleteModal>
      )}
    </div>
  );
};

VehicleListing.defaultProps = {
  customerNumber: '',
  options: {},
  vehicleList: [],
  selected: [],
};

VehicleListing.propTypes = {
  customerNumber: PropTypes.string,
  options: PropTypes.shape({
    REMOVAL: PropTypes.bool,
    ADD_VEHICLE: PropTypes.bool,
  }),
  vehicleList: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
};

export default VehicleListing;
