/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
import React from 'react';
import _ from 'lodash/fp';
import moment from 'moment';
import { ErrorMessage } from '@gsa/afp-component-library';
import { toFixedFn, safeParseFloat } from 'utilities/formatUtils';
import { rateStructures } from './model/store';

import { statusOptions as categoryStatusOptions } from './model/category/schemas/filterSchema';
import { statusOptions as serviceStatusOptions } from './model/service/schemas/filterSchema';

export const filterCreator = ({ filter, filterName }) => {
  const filters = _.flow(
    _.get(`${filterName}`),
    _.map((x) => ({
      operator: 'EQ',
      key: `${filterName}`,
      value: `${x}`,
    })),
  )(filter);
  return {
    operator: 'OR',
    value: [...filters],
  };
};

export const maxLengthMap = {
  input: 25,
  textarea: 200,
};

// when we trigger the filter we use filter:state.x instead of  filter:pageState.categoryFilter.filter.x
// other functions  we will use filter:pageState.categoryFilter.filter.x

export const getCategoryQueryVariables = ({
  pageState,
  rateStatus = null,
  offSet = null,
  itemsPerPage = null,
  order = null,
  filter = null,
}) => {
  //
  const customizeRateStatus = rateStatus?.length === 0 ? categoryStatusOptions : rateStatus;

  const variablesWithoutFilter = {
    virtualFilter: filterCreator({
      filter: {
        rateStatus: customizeRateStatus ?? pageState.categoryFilter.rateStatus,
      },
      filterName: 'rateStatus',
    }),
    offset: _.isNull(offSet) ? pageState.categoryFilter.offset : offSet,
    limit: itemsPerPage ?? pageState.categoryFilter.limit,
    order: order ?? pageState.categoryFilter.order,
  };

  return filter
    ? _.merge(variablesWithoutFilter, {
        filters: filterCreator({
          filter: { optInCategoryName: filter },
          filterName: 'optInCategoryName',
        }),
      })
    : variablesWithoutFilter;
};

export const getServiceQueryVariables = ({
  pageState,
  rateStatus = null,
  offSet = null,
  itemsPerPage = null,
  order = null,
  filter = null,
}) => {
  //
  const customizeRateStatus = rateStatus?.length === 0 ? serviceStatusOptions : rateStatus;
  const variablesWithoutFilter = {
    virtualFilter: filterCreator({
      filter: {
        rateStatus: customizeRateStatus ?? pageState.serviceFilter.rateStatus,
      },
      filterName: 'rateStatus',
    }),
    offset: _.isNull(offSet) ? pageState.serviceFilter.offset : offSet,
    limit: itemsPerPage ?? pageState.serviceFilter.limit,
    order: order ?? pageState.serviceFilter.order,
  };

  return filter
    ? _.merge(variablesWithoutFilter, {
        filters: filterCreator({
          filter: { optInSvcCategory: filter },
          filterName: 'optInSvcCategory',
        }),
      })
    : variablesWithoutFilter;
};

export const handlePaginationChange = ({
  setDispatch,
  DISPATCH_FNS,
  pageState,
  resetPaginationComponent,
  fetchRows,
  filterName,
}) => (currentPage, itemsPerPage) => {
  const offSet = (currentPage - 1) * itemsPerPage;
  if (itemsPerPage !== pageState[`${filterName}`].limit) {
    window.scrollTo({ top: 200, behavior: 'smooth' });
    setDispatch(DISPATCH_FNS.setItemPerPage, {
      limit: itemsPerPage,
      resetPaginationComponent,
      filterName: `${filterName}`,
    });
  } else {
    setDispatch(DISPATCH_FNS.updateStateByPath, {
      path: `${filterName}.currentPage`,
      data: currentPage,
    });
  }

  setDispatch(DISPATCH_FNS.updateStateByPath, {
    path: `${filterName}.offset`,
    data: offSet,
  });

  const variables =
    filterName === 'categoryFilter'
      ? getCategoryQueryVariables({
          pageState,
          offSet,
          rateStatus: pageState[`${filterName}`].rateStatus ?? [],
          itemsPerPage,
          filter: pageState[`${filterName}`].filter.OptInCategoryName,
        })
      : getServiceQueryVariables({
          pageState,
          offSet,
          itemsPerPage,
          rateStatus: pageState[`${filterName}`].rateStatus ?? [],
          filter: pageState[`${filterName}`].filter.optInSvcCategory,
        });

  fetchRows({
    variables,
  });
};

// AFP-111829 updated label, description and value
export const createSalesCodeOptions = (salesCodes) =>
  _.map((salesCode) => ({
    label: `${salesCode?.code}-${salesCode?.description}`,
    value: salesCode?.code,
  }))(salesCodes);

// eslint-disable-next-line eqeqeq
export const convertRate = (rate, decimal = 4) =>
  rate == '0' ? toFixedFn(rate, decimal) : toFixedFn(rate / 100, decimal);

export const getRateValue = (rateType, rateValue, needConvertRate = true) =>
  rateType === '$'
    ? { optInFlatDollar: toFixedFn(rateValue, 4) }
    : { optInFixedRate: needConvertRate ? convertRate(rateValue) : toFixedFn(rateValue, 4) };

export const pageStateToOptInSvcRatesAssoc = (pageState) => {
  const { oneTimeRate, recurringRate, tierRate, rateStructure } = pageState.service_table.rowData;
  const generateRate = (rateRow) => {
    const { checkBox, rateType, rateValue } = rateRow;
    if (checkBox) {
      return getRateValue(rateType, rateValue);
    }
    return null;
  };
  if (rateStructure === rateStructures[0]) {
    const optInSvcOneTimeRatesAssoc = { optInSvcOneTimeRatesAssoc: generateRate(oneTimeRate) } || null;

    const draftOptInSvcRecurringRatesAssoc = generateRate(recurringRate);
    const optInSvcRecurringRatesAssoc = draftOptInSvcRecurringRatesAssoc
      ? {
          optInSvcRecurringRatesAssoc: {
            ...draftOptInSvcRecurringRatesAssoc,
            optInBillingFreq: recurringRate.frequency,
          },
        }
      : null;

    return {
      ...optInSvcOneTimeRatesAssoc,
      ...optInSvcRecurringRatesAssoc,
    };
  }

  if (rateStructure === rateStructures[1]) {
    const { ratesRows } = tierRate;
    const convertTierRate = ({ lowerRange, rateValue, rateType }) => ({
      tierLowerRange: toFixedFn(lowerRange, 0),
      ...getRateValue(rateType, rateValue),
    });

    return { optInSvcTieredRatesAssoc: ratesRows.map(convertTierRate) };
  }

  return null;
};

export const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);
const isAllCheckBoxNotChecked = _.every(_.isEqual(false));
export const validateCheckBoxError = (setError, clearErrors, ev, otherCheckBoxes) => {
  isAllCheckBoxNotChecked([ev.target.checked, ...otherCheckBoxes])
    ? setError(`rateSection`, {
        type: 'manual',
        message: 'Rate is required.',
      })
    : clearErrors('rateSection');
};

export const getAlertContent = (isCreate, isDelete) => {
  const messageMap = {
    delete: 'You have successfully deleted the selected tier',
    update: 'You have successfully updated the tiered structure',
    create: 'A new tier has been successfully added',
  };
  if (isCreate) {
    return messageMap.create;
  }
  if (isDelete) {
    return messageMap.delete;
  }

  return messageMap.update;
};

// arrays -> object||undefined
export const isTierRate = _.find({ optInBillingFreq: 'Varied-rate' });

const isEmptyFieldsErrorChecking = ({ pageState, setError, rateStructure }) => {
  if (pageState.service_table.rowData[`${rateStructure}`].lowerRange === '') {
    setError(`tier_lower_range`, {
      type: 'manual',
      message: `Lower range is required`,
    });
    return true;
  }
  if (pageState.service_table.rowData[`${rateStructure}`].rateValue === '') {
    setError(`tier_rate`, {
      type: 'manual',
      message: `Rate is required`,
    });

    return true;
  }

  return false;
};

const isInValidateRateCheck = ({ pageState, setError, rateStructure }) => {
  if (
    pageState.service_table.rowData[`${rateStructure}`].rateType === '%' &&
    safeParseFloat(pageState.service_table.rowData[`${rateStructure}`].rateValue, 2) > 100
  ) {
    setError(`tier_rate`, {
      type: 'manual',
      message: `A percent rate must be between 0 and 100`,
    });
    return true;
  }
  return false;
};

export const isInValidateInputs = ({ pageState, setError, rateStructure = 'tierRate' }) => {
  if (isEmptyFieldsErrorChecking({ pageState, setError, rateStructure })) {
    return true;
  }
  if (isInValidateRateCheck({ pageState, setError, rateStructure })) {
    return true;
  }
  return false;
};

export const categoryFilterOnChange = ({
  pageState,
  setDispatch,
  fetchCategoryRows,
  resetPaginationComponent,
  DISPATCH_FNS,
}) => (state) => {
  // trigger filter when filter data has been loaded and there is a change on rateStatus
  if (pageState.categoryFilter.isFullyLoaded && !_.isEqual(pageState.categoryFilter.rateStatus, state.rateStatus)) {
    setDispatch(DISPATCH_FNS.updateFilter, {
      itemName: 'rateStatus',
      data: state.rateStatus,
      resetPaginationComponent,
      filterName: 'categoryFilter',
    });
    fetchCategoryRows({
      variables: getCategoryQueryVariables({
        pageState,
        rateStatus: _.getOr([], 'rateStatus', state),
        offSet: 0,
        filter: pageState.categoryFilter.filter.OptInCategoryName,
      }),
    });
  }
  // trigger filter when filter data has been loaded and there is a change on OptInCategoryName
  if (
    pageState.categoryFilter.isFullyLoaded &&
    !_.isEqual(pageState.categoryFilter.filter.OptInCategoryName, state.OptInCategoryName)
  ) {
    setDispatch(DISPATCH_FNS.updateFilter, {
      itemName: 'OptInCategoryName',
      data: state.OptInCategoryName,
      resetPaginationComponent,
      filterName: 'categoryFilter',
    });
    fetchCategoryRows({
      variables: getCategoryQueryVariables({
        pageState,
        rateStatus: _.getOr([], 'rateStatus', state),
        offSet: 0,
        // in the future if we have multiple filter we could change the filter to {filter:{...pageState.categoryFilter.filter,OptInCategoryName:state.OptInCategoryName}
        filter: state.OptInCategoryName,
      }),
    });
  }
};

export const serviceFilterOnChange = ({
  pageState,
  setDispatch,
  fetchServiceRows,
  resetPaginationComponent,
  DISPATCH_FNS,
}) => (state) => {
  // trigger filter when filter data has been loaded and there is a change on rateStatus
  if (pageState.serviceFilter.isFullyLoaded && !_.isEqual(pageState.serviceFilter.rateStatus, state.rateStatus)) {
    setDispatch(DISPATCH_FNS.updateFilter, {
      itemName: 'rateStatus',
      data: state.rateStatus,
      resetPaginationComponent,
      filterName: 'serviceFilter',
    });

    fetchServiceRows({
      variables: getServiceQueryVariables({
        pageState,
        rateStatus: _.getOr([], 'rateStatus', state),
        offSet: 0,
        filter: pageState.serviceFilter.filter.optInSvcCategory,
      }),
    });
  }
  // trigger filter when filter data has been loaded and there is a change on optInSvcCategory
  if (
    pageState.serviceFilter.isFullyLoaded &&
    !_.isEqual(pageState.serviceFilter.filter.optInSvcCategory, state.optInSvcCategory)
  ) {
    setDispatch(DISPATCH_FNS.updateFilter, {
      itemName: 'optInSvcCategory',
      data: state.optInSvcCategory,
      resetPaginationComponent,
      filterName: 'serviceFilter',
    });
    fetchServiceRows({
      variables: getServiceQueryVariables({
        pageState,
        rateStatus: _.getOr([], 'rateStatus', state),
        offSet: 0,
        // in the future if we have multiple filter we could change the filter to {filter:{...pageState.serviceFilter.filter,optInSvcCategory:state.optInSvcCategory}
        filter: state.optInSvcCategory,
      }),
    });
  }
};

export const trimRate = (rate) => {
  if (rate.checkBox === false) {
    return { ...rate, rateValue: '' };
  }
  return { ...rate, rateValue: rate.rateValue == 0 ? '' : toFixedFn(rate.rateValue, 4) };
};

export const isRateStateDirty = (pageState) => {
  const {
    rateStructure,
    oneTimeRate,
    recurringRate,
    tierRate: { ratesRows },
  } = pageState.service_table.rowData;

  const { optInSvcDesc, startDate, optInSvcSalesId, ...originalFormState } = pageState.service_table.originalFormState;
  const currentFormState = {
    rateStructure,
    oneTimeRate: trimRate(oneTimeRate),
    recurringRate: trimRate(recurringRate),
    tierRate: { tierRateRows: _.map(trimRate, ratesRows) },
  };
  return !_.isEqual(originalFormState, currentFormState);
};

export const pageStateToStartDate = (pageState) => {
  if (isRateStateDirty(pageState)) {
    return pageState.service_table.rowData.startDate;
  }
  if (isRateStateDirty(pageState)) {
    return pageState.service_table.rowData.startDate;
  }
  return moment(pageState.service_table.rowData.startDate).isBefore(new Date())
    ? null
    : pageState.service_table.rowData.startDate;
};
