import React from 'react';
import Header from 'components/header/Header';
import { Provider, useTrackedState, useDispatch, DISPATCH_FNS } from './model/store';
import FormContainer from './container/form-container';
import './style/acquisition-funding.scss';
// this is for requisition-funding page
// we changed the route and content name but decide not change the code.
const AcquisitionFundPage = () => {
  const setDispatch = useDispatch();
  const pageState = useTrackedState();
  return (
    <div id="acquisition-funding" className="margin-bottom-6">
      {/* we have to different props because the tabset defect of component library */}
      <Header _setDispatch={setDispatch} _pageState={pageState} DISPATCH_FNS={DISPATCH_FNS} />
      <FormContainer setDispatch={setDispatch} pageState={pageState} DISPATCH_FNS={DISPATCH_FNS} />
    </div>
  );
};

const AcquisitionFundIndexPage = () => {
  return (
    <Provider>
      <AcquisitionFundPage />
    </Provider>
  );
};

export default AcquisitionFundIndexPage;
