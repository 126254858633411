import React from 'react';
import { TabSet } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import { ENTITY, ROLE_OP } from 'components/role-permission/role-permission';
import CategoryContainer from '../container/CategoryContainer';
import ServiceContainer from '../container/ServiceContainer';

/* eslint-disable react/prop-types */
const OptInTabs = ({ useDispatch, useTrackedState, DISPATCH_FNS }) => {
  return (
    <div id="OptIn-Tabs">
      <TabSet
        tabs={[
          {
            heading: 'Service rates',
            content: (
              <Can I={ROLE_OP.VIEW} a={ENTITY.OPT_IN_SERVICE}>
                <div id="opt-in-service" className="grid-row">
                  <div className="grid-col-12">
                    <ServiceContainer
                      useDispatch={useDispatch}
                      useTrackedState={useTrackedState}
                      DISPATCH_FNS={DISPATCH_FNS}
                    />
                  </div>
                </div>
              </Can>
            ),
            tabSelectedWhenOpen: true,
          },
          {
            heading: 'Service categories',
            content: (
              <Can I={ROLE_OP.VIEW} a={ENTITY.OPT_IN_CATEGORY}>
                <div id="opt-in-categories" className="grid-row">
                  <div className="grid-col-12">
                    <CategoryContainer
                      useDispatch={useDispatch}
                      useTrackedState={useTrackedState}
                      DISPATCH_FNS={DISPATCH_FNS}
                    />
                  </div>
                </div>
              </Can>
            ),
            tabSelectedWhenOpen: true,
          },
        ]}
      />
    </div>
  );
};

export default OptInTabs;
