import moment from 'moment-timezone';

// time zone for standard date
export const TIMEZONE = 'America/New_York';

// get UTC date according to TIMEZONE e.g. "2021-05-18T00:00:00.000Z"
export const getUTCDate = (date, offset = 0) => {
  const m = date ? moment(date) : moment();
  return new Date(m.tz(TIMEZONE).add(offset, 'days').format('YYYY-MM-DD'));
};
export const getUTCDateStr = (date, offset = 0) => getUTCDate(date, offset).toISOString();
export const getTodayDate = () => getUTCDate(null, 0);
export const getTodayStr = () => getTodayDate().toISOString();
export const getTomorrowDate = () => getUTCDate(null, 1);
export const getTomorrowStr = () => getTomorrowDate().toISOString();

// convert date string between "05/18/2021" and "2021-05-18T00:00:00.000Z"
export const USDateStrToUTC = (dateStr, isEndDate = false) => {
  if (!/^(0?[1-9]|1[0-2])\/((0?[1-9])|([12]\d))\/\d{4}$/.test(dateStr)) return dateStr;
  const [mon, day, year] = dateStr.split('/');
  return isEndDate ? `${year}-${mon.padStart(2, '0')}-${day.padStart(2, '0')}T23:59:59.000Z` : `${year}-${mon.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00.000Z`;
};
export const UTCDateStrToUS = (dateStr) =>
  dateStr ? `${dateStr.substr(5, 2)}/${dateStr.substr(8, 2)}/${dateStr.substr(0, 4)}` : dateStr;

// get time stamp date according to time zone
export const getTSDateStrToUS = (ts) => moment.utc(ts).tz(TIMEZONE).format('MM/DD/YYYY');

export const tsToDatetTime = (ts) =>
  moment(ts).format('MM/DD/YYYY • h:mm:ss a').replace(',', ' •').replace('am', 'a.m.').replace('pm', 'p.m.');

// fiscal year functions
const FY_OFFSET_DAY = 92;
export const getUTCFYInt = (date = undefined) => moment.utc(date).add(FY_OFFSET_DAY, 'days').year();

export const getFYInt = (date = undefined) => moment.utc(date).tz(TIMEZONE).add(FY_OFFSET_DAY, 'days').year();

export const getFYUTCDateRange = (year) => {
  const yearNum = parseInt(year, 10);
  const num2year = (num) => num.toString().padStart(4, '0');
  return {
    startDate: `${num2year(yearNum - 1)}-10-01T00:00:00.000Z`,
    endDate: `${num2year(yearNum)}-09-30T00:00:00.000Z`,
  };
};

export const formatLocaleString = (localeStr) => {
  const frags = localeStr.split('/');
  for (let i = 0; i < 2; i += 1) {
    frags[i] = frags[i].padStart(2, '0');
  }
  return frags.join('/').replace(',', ' •').replace('AM', 'a.m.').replace('PM', 'p.m.');
};

