import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

export const DeleteModal = ({
  id,
  variant,
  title,
  onClose,
  onDelete,
  deleting,
  deleteButtonText,
  deleteButtonVariant,
  cancelButtonText,
  children,
}) => (
  <div className="afp-modal-wrapper" id={id}>
    <div className="afp-modal-overlay">
      <Modal
        variant={variant}
        title={
          <h1 className="margin-bottom-1" data-testid="delete-modal-title">
            {title}
          </h1>
        }
        onClose={onClose}
        actions={
          <>
            <Button variant="unstyled" onClick={onClose} id="cancel-btn" label={cancelButtonText} />
            {deleteButtonText && (
              <Button variant={deleteButtonVariant} onClick={onDelete} disabled={deleting} label={deleteButtonText} />
            )}
          </>
        }
      >
        {children}
      </Modal>
    </div>
  </div>
);

DeleteModal.defaultProps = {
  variant: 'large',
  deleting: false,
  deleteButtonText: '',
  deleteButtonVariant: 'secondary',
  cancelButtonText: 'Cancel',
};

DeleteModal.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleting: PropTypes.bool,
  deleteButtonText: PropTypes.string,
  deleteButtonVariant: PropTypes.string,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
};
