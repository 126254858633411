import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useImport } from '../state/hook';
import './process-import.css';
import { batchSize } from '../forms/consts';

const ProcessImport = ({ useDataProcessor }) => {
  const { importedData, setProcessedResults, processedResults, setProcessedResponse } = useImport();

  const [count, setcount] = useState(0);
  const [batches, setBatches] = useState([]);
  const { process, processDataForFile, handleSystemErrors } = useDataProcessor(
    (data) => {
      setProcessedResults((results) => [...results, ...data]);
      if (count > 0) {
        setcount(count - 1);
      }
    },
    () => {
      const systemErrors = handleSystemErrors(batches[count - 1]);
      setProcessedResults((response) => [...response, ...systemErrors]);
      if (count > 0) {
        setcount(count - 1);
      }
    },
  );

  useEffect(() => {
    const batchesData = [];
    const data = [...importedData];
    while (data.length > 0) {
      const chunk = data.splice(0, batchSize);
      batchesData.push(chunk);
    }
    setBatches(batchesData);
    setcount(batchesData.length);
  }, [importedData]);

  useEffect(() => {
    if (processedResults.length === importedData.length) {
      setProcessedResponse(processDataForFile(processedResults));
      window.scrollTo({ top: 200, behavior: 'smooth' });
    }
  }, [processedResults]);

  useEffect(() => {
    if (count > 0) {
      process({
        variables: { importInput: batches[count - 1] },
      });
    }
  }, [count]);

  return (
    <div className="progress-import-container">
      <div className="process-import_overlay" />
      <div className="process-import_progress">
        <h2 className="title-l-bold">Please wait</h2>
        <p> We are uploading data and checking for errors.</p>
        <progress max={importedData.length} value={processedResults.length} />
        <p className="title-m-bold padding-y-0 margin-y-0">
          {processedResults.length} records out of {importedData.length} processed
        </p>
      </div>
    </div>
  );
};

export default ProcessImport;

ProcessImport.propTypes = {
  useDataProcessor: PropTypes.func.isRequired,
};
