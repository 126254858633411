import React from 'react';
import numeral from 'numeral';
import _ from 'lodash/fp';
import PropTypes from 'prop-types';

export const CurrencyCell = ({ value, currencySign }) => {
  if (_.isNil(value)) {
    return '-';
  }
  return <span className={value < 0 ? 'minusBalance' : ''}>{numeral(value).format(`${currencySign}0,0.00`)}</span>;
};

CurrencyCell.defaultProps = {
  value: null,
  currencySign: '$',
};

CurrencyCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencySign: PropTypes.string,
};

export default CurrencyCell;
