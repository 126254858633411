import React from 'react';
import { SelectDropdown, PageTitle } from '@gsa/afp-component-library';
import { getYearOptionsRange } from 'utilities/dateUtils';
import { pageStateStartCase } from 'utilities/formatUtils';
import { ENTITY, ROLE_OP, hasAbilityTo } from 'components/role-permission/role-permission';
import { stateValue } from 'utilities/stateUtils';
/* eslint-disable react/prop-types */

const FiscalYearSelect = ({ pageState, setDispatch, DISPATCH_FNS, fetchLatestFunding, currentFYInt }) => {
  const futureYearCount = hasAbilityTo(ROLE_OP.MANAGE, ENTITY.ACQUISITION_FUND) ? 1 : 0;
  const FYOptions = getYearOptionsRange({ currentFYInt, previousYearCount: 2, futureYearCount });
  return (
    <div className="grid-row">
      <div className="grid-col-5">
        <PageTitle title={pageStateStartCase(pageState.currentPage)} />
        <h2 style={{ marginTop: -20 }}>Fiscal year {stateValue(pageState.fiscalYear)}</h2>
      </div>

      <div className="grid-col-4 grid-offset-3">
        <div className="grid-col-4 grid-offset-8">
          <SelectDropdown
            required
            id="fiscal-year"
            data-testid="fiscal-year"
            name="fiscalYear"
            value={stateValue(pageState.fiscalYear)}
            label="Fiscal year"
            options={FYOptions}
            onChange={(e) => {
              setDispatch(DISPATCH_FNS.updateStateByPath, { path: 'fiscalYear', data: e.target.value });
              fetchLatestFunding({
                variables: {
                  FiscalYear: e.target.value,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FiscalYearSelect;
