/* import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'core-js/es/typed-array/from';
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array';
import 'isomorphic-fetch'; */
// TODO fix polyfills.

import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './index.scss';


ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
