import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectBox, Textbox, ErrorMessage, Checkbox, Icon } from '@gsa/afp-component-library';
import SortIcon from './sort-icon';
import '../style/vehicle-listing.scss';

export const RATE_TYPE = {
  LRC: 'lrc',
  // UR: 'UR',
  URMon: 'urmon',
  URMil: 'urmil',
};

const VehicleListingTable = ({
  customerNumber,
  rows,
  options,
  LRCoptions,
  defaultSort,
  onCheckAllRows,
  onCheckRow,
  onRateChange,
  onError,
  onOrder,
}) => {
  const [errors, setErrors] = useState({});
  const [sortOrder, setSortOrder] = useState(defaultSort);

  const onSetOrder = (id, order) => {
    setSortOrder({ [id]: order });
    onOrder(id, order);
  };

  useEffect(() => {
    setSortOrder(defaultSort);
  }, [customerNumber]);

  useEffect(() => {
    onError(Object.keys(errors).length);
  }, [errors]);

  const validateUniqueRate = (type, vin, val) => {
    const inputID = `${type}-${vin}`;
    if (val) {
      const num = Number(val);
      if (Number.isNaN(num) || num <= 0) {
        setErrors((prevState) => ({
          ...prevState,
          [inputID]: true,
        }));
        return;
      }
      if (type === RATE_TYPE.URMon) onRateChange(type, vin, num.toFixed(2));
      if (type === RATE_TYPE.URMil) onRateChange(type, vin, num.toFixed(3));
    }
    if (errors[inputID]) {
      const newErrors = { ...errors };
      delete newErrors[inputID];
      setErrors(newErrors);
    }
  };

  const LRCInput = (vin, value) => {
    const type = RATE_TYPE.LRC;
    const opts = [{ value: '', label: '' }, ...LRCoptions];
    return (
      <SelectBox
        id={`${type}-${vin}`}
        className="rate-select-box"
        aria-label={`select lease rate code for vin number ${vin}`}
        options={opts}
        value={value || ''}
        onChangeFunc={(e) => onRateChange(type, vin, e.target.value || '')}
      />
    );
  };
  const URInput = (type, vin, value) => {
    const inputID = `${type}-${vin}`;
    return (
      <div className="rate-input">
        <Icon iconName="attach_money" className="prefix-icon-dollar-table" />
        <Textbox
          id={inputID}
          name={inputID}
          aria-label={`enter ${type === RATE_TYPE.URMon ? 'monthly' : 'mileage'} rate for vin number ${vin}`}
          className="rate-select-box"
          value={value || ''}
          onChange={(e) => onRateChange(type, vin, e.target.value || '')}
          onBlur={(e) => validateUniqueRate(type, vin, e.target.value || '')}
        />
        {errors[inputID] ? <ErrorMessage>Invalid number</ErrorMessage> : null}
      </div>
    );
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                className="bm-ura-checkbox"
                aria-label="select all rows"
                data-testid="check-all-rows"
                checked={rows?.every((row) => row.isSelected) || false}
                onChange={onCheckAllRows}
              />
            </th>
            <th>
              <SortIcon id="plateNumber" order={sortOrder.plateNumber} label="Plate number" onClick={onSetOrder} />
            </th>
            <th>
              <SortIcon id="vin" order={sortOrder.vin} label="VIN" onClick={onSetOrder} />
            </th>
            <th>
              <SortIcon id="prevLRC" order={sortOrder.prevLRC} label="Current unique rate" onClick={onSetOrder} />
            </th>
            {options[RATE_TYPE.LRC] ? <th>Lease Rate Code</th> : null}
            {options[RATE_TYPE.URMon] ? <th>Monthly rate</th> : null}
            {options[RATE_TYPE.URMil] ? <th>Mileage rate</th> : null}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={`row-${row.vin}`}>
              <td>
                <Checkbox
                  className="bm-ura-checkbox"
                  aria-label={`select vin number ${row.vin}`}
                  value={row.isSelected}
                  data-testid={`check-row-${row.vin}`}
                  checked={row.isSelected}
                  onChange={(e) => onCheckRow(row.vin, e)}
                />
              </td>
              <td className="pad-sort-icon">{row.plateNumber}</td>
              <td className="pad-sort-icon">
                <a
                  href={`${window.AFP_CONFIG.appURLs.vms}/vehicle/${row.vin}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`show vehicle information for vin number ${row.vin} in a new window`}
                >
                  {row.vin}
                </a>
              </td>
              <td className="pad-sort-icon">{row.prevLRC ? `Maintenance only (${row.prevLRC})` : '-'}</td>
              {options[RATE_TYPE.LRC] && (
                <td className="input-cell">{row.isSelected ? LRCInput(row.vin, row.lrc) : ''}</td>
              )}
              {options[RATE_TYPE.URMon] && (
                <td className="input-cell">{row.isSelected ? URInput(RATE_TYPE.URMon, row.vin, row.urmon) : ''}</td>
              )}
              {options[RATE_TYPE.URMil] && (
                <td className="input-cell">{row.isSelected ? URInput(RATE_TYPE.URMil, row.vin, row.urmil) : ''}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

VehicleListingTable.defaultProps = {
  customerNumber: '',
  rows: [],
  options: {},
  LRCoptions: [],
  defaultSort: {},
};

VehicleListingTable.propTypes = {
  customerNumber: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.shape({}),
  LRCoptions: PropTypes.arrayOf(PropTypes.shape({})),
  defaultSort: PropTypes.shape({}),
  onCheckAllRows: PropTypes.func.isRequired,
  onCheckRow: PropTypes.func.isRequired,
  onRateChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onOrder: PropTypes.func.isRequired,
  // setBannerMsg: PropTypes.func.isRequired,
};

export default VehicleListingTable;
