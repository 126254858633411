import React from 'react';

import { STATUS } from 'components/helpers/constants';
import UpdateInformation from 'components/subRowComponent/UpdateInformation';

/* eslint-disable react/prop-types */
const CategoryRowSubComponent = ({ row: { original = {} } }) => {
  return (
    <div className="grid-container">
      <div className="grid-row grid-gap-6">
        <div className="tablet:grid-col">
          {original.comment && (
            <div>
              <div className="grid-row bm-subrow-header">
                Reason for {original?.rateStatus === STATUS.ACTIVE ? 'reactivation' : 'deactivation'}
              </div>
              <div className="grid-row">{original.comment}</div>
            </div>
          )}
        </div>
        <div className="tablet:grid-col">
          <UpdateInformation original={original} />
        </div>
      </div>
    </div>
  );
};

export default CategoryRowSubComponent;
