import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Label,
  SelectDropdown,
  Textbox,
  Button,
  Icon,
  Spinner,
  CounterTag,
  PageTitle,
} from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import { getTodayStr, getFYInt, getFYUTCDateRange } from 'components/helpers/afp-bm-date';
import { formatDollar, validateDollar } from 'components/helpers/afp-bm-helpers';
import {
  CURRENT_FY,
  GET_AFV_SURCHARGES_BY_AGENCY,
  GET_AMOUNT_FUNDED_BY_AGENCY,
  CREATE_UPDATE_AMOUNT_FUNDED,
  GET_MOCK_DATA_FOR_BILLING_BY_AGENCY,
  GET_MOCK_DATA_FOR_STORE_BY_AGENCY,
  GET_MOCK_DATA_FOR_VMS_BY_AGENCY,
  MergeAgencyData,
} from './afv-funding-helper';
import AfvSurchargeCalculator from './components/afv-surcharge-calculator';
import AFVFundingSetSurcharge from './components/afv-funding-set-surcharge';
import AFVFundingSurchargeHistory from './components/afv-funding-surcharge-history';
import './style/afv-funding.scss';

const TITLE = 'AFV Surcharge and Funding';

export default function AfvSurchargeFunding() {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const [agencyList, setAgencyList] = useState(undefined);
  const [fiscalYear, setFiscalYear] = useState(undefined);
  const [fiscalYearOptions, setFiscalYearOptions] = useState([]);
  const [agencyCode, setAgencyCode] = useState(undefined);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [agencyIndicator, setAgencyIndicator] = useState(undefined);
  const [indicatorOptions, setIndicatorOptions] = useState([]);
  const [agencyData, setAgencyData] = useState(null);
  const [data, setData] = useState(null);
  const [surchargeBannerMsg, setSurchargeBannerMsg] = useState(null);
  const [displaySurcharge, setDisplaySurcharge] = useState('');
  const [newAmountFunded, setNewAmountFunded] = useState('');
  const [calcSurcharge, setCalcSurcharge] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const currentFY = getFYInt().toString();

  const bannerRef = useRef(null);
  const surchargeBannerRef = useRef(null);

  const setBannerMsg = (message) => {
    setMsg(message);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onSetSurchargeBannerMsg = (banner) => {
    setSurchargeBannerMsg(banner);
    surchargeBannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!location.state?.detailsOptions) {
      history.replace('/bm/afv-funding');
      return;
    }

    const endYear = getFYInt();
    const startYear = endYear - 2;
    const yearList = [];
    for (let i = endYear; i >= startYear; i -= 1) {
      const year = i.toString();
      yearList.push({ value: year, label: year });
    }
    setFiscalYearOptions(yearList);

    const { detailsOptions, fiscalYear: fy, agencyCode: ac } = location.state;
    setAgencyList(detailsOptions);
    setFiscalYear(fy);
    const agencyOptionsFromList = detailsOptions
      .map((a) => ({
        value: a.agencyCode,
        label: a.name,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    setAgencyOptions(agencyOptionsFromList);
    setAgencyCode(ac);
  }, [location]);

  useEffect(() => {
    if (!agencyCode || !agencyList) return;
    const agency = agencyList.find((a) => a.agencyCode === agencyCode);
    if (agency && agency.indicators?.length) {
      const indOpts = agency.indicators
        .sort((a, b) => {
          if (a === 'N/A') return -1;
          if (b === 'N/A') return 1;
          return a < b ? -1 : 1;
        })
        .map((i) => ({
          value: i,
          label: i === 'N/A' ? 'No indicator' : i,
        }));
      if (indOpts.length > 1) indOpts.unshift({ label: 'All', value: '' });
      setIndicatorOptions(indOpts);
      setAgencyIndicator(indOpts[0].value);
    }
  }, [agencyCode, agencyList]);

  const onGqlError = (err, message) => {
    setBannerMsg({
      type: 'error',
      message: (
        <>
          {message}
          <br />
          {err.message}
        </>
      ),
    });
  };
  const [
    fetchSurcharges,
    { data: dataSurcharges, loading: loadingSurcharges, refetch: refetchSurcharges },
  ] = useLazyQuery(GET_AFV_SURCHARGES_BY_AGENCY, {
    onError: (err) => onGqlError(err, 'Error occurred when retrieving surcharges'),
  });
  const [fetchAmountFunded, { data: dataAmountFunded, loading: loadingAmountFunded }] = useLazyQuery(
    GET_AMOUNT_FUNDED_BY_AGENCY,
    {
      onError: (err) => onGqlError(err, 'Error occurred when retrieving amount funded'),
    },
  );
  const [fetchVMSData, { data: dataVMS, loading: loadingVMSData }] = useLazyQuery(GET_MOCK_DATA_FOR_VMS_BY_AGENCY, {
    onError: (err) => onGqlError(err, 'Error occurred when retrieving number of vehicles from VMS'),
  });
  const [fetchStoreData, { data: dataStore, loading: loadingStoreData }] = useLazyQuery(
    GET_MOCK_DATA_FOR_STORE_BY_AGENCY,
    {
      onError: (err) => onGqlError(err, 'Error occurred when retrieving spendings from Store'),
    },
  );
  const [fetchBillingData, { data: dataBilling, loading: loadingBillingData }] = useLazyQuery(
    GET_MOCK_DATA_FOR_BILLING_BY_AGENCY,
    {
      onError: (err) => onGqlError(err, 'Error occurred when retrieving amount collected'),
    },
  );

  const fetchAllData = () => {
    const query = {
      variables: {
        agencyCode,
        // agencyIndicator,
      },
    };
    const queryFY = {
      variables: {
        fiscalYear,
        agencyCode,
        // agencyIndicator,
      },
    };
    fetchSurcharges(queryFY);
    fetchVMSData(query);
    fetchAmountFunded(queryFY);
    fetchBillingData(queryFY);
    fetchStoreData(queryFY);
  };

  useEffect(() => {
    if (fiscalYear && agencyCode) {
      setAgencyData(null);
      fetchAllData();
    }
  }, [fiscalYear, agencyCode]);

  useEffect(() => {
    setMsg(null);
    setSurchargeBannerMsg(null);
    setNewAmountFunded('');
    setCalcSurcharge(null);
  }, [fiscalYear, agencyCode, agencyIndicator]);

  useEffect(() => {
    if (!agencyList || !dataSurcharges || !dataAmountFunded || !dataVMS || !dataStore || !dataBilling) return;

    const resData = MergeAgencyData(
      false,
      fiscalYear,
      agencyList.find((a) => a.agencyCode === agencyCode),
      {
        dataSurcharges: dataSurcharges.getAFVSurchargesByAgency,
        dataAmountFunded: dataAmountFunded.getAFVFYFundByAgency,
        dataVMS: dataVMS.getMockDataNumVehiclesByAgency,
        dataStore: dataStore.getMockDataByFYAgency,
        dataBilling: dataBilling.getMockDataByFYAgency,
      },
      fiscalYear === CURRENT_FY,
    );
    setAgencyData(resData);
  }, [dataSurcharges, dataAmountFunded, dataVMS, dataStore, dataBilling]);

  const getSurchargeStatus = (isFinalized, startDate, endDate, todayStr) => {
    if (!isFinalized) return 'Recommended';
    if (todayStr < startDate) return 'Pending';
    if (todayStr > endDate) return 'Expired';
    return 'Active';
  };
  useEffect(() => {
    if (agencyData && agencyIndicator) {
      const funding = agencyData.fundings.find((f) => f.agencyIndicator === agencyIndicator);
      setData({
        ...funding,
        surcharges: funding?.surcharges.map((surcharge) => ({
          ...surcharge,
          status: getSurchargeStatus(surcharge.isFinalized, surcharge.startDate, surcharge.endDate, getTodayStr()),
        })),
      });
      setNewAmountFunded(formatDollar(funding?.amountFunded, '') || '');
    } else {
      setData(null);
      setNewAmountFunded('');
    }
  }, [agencyData, agencyIndicator]);

  useEffect(() => {
    if (!fiscalYear || !data?.surcharges?.length) {
      setDisplaySurcharge('');
      return;
    }
    let dateStr;
    if (fiscalYear === currentFY) dateStr = getTodayStr();
    else if (fiscalYear < currentFY) dateStr = getFYUTCDateRange(fiscalYear).endDate;
    else dateStr = getFYUTCDateRange(fiscalYear).startDate;
    const item = data.surcharges.find(
      (s) => s.isFinalized && dateStr >= s.startDate && (!s.endDate || dateStr <= s.endDate),
    );
    setDisplaySurcharge(formatDollar(item?.surcharge, ''));
  }, [data, fiscalYear]);

  const [updateAmountFunded, { loading: savingAmountFunded }] = useMutation(CREATE_UPDATE_AMOUNT_FUNDED, {
    onCompleted: () => {
      setBannerMsg({
        type: 'success',
        message: 'Amount funded has been successfully saved',
      });
      fetchAllData();
    },
    onError: (err) => onGqlError(err, 'Error occurred when saving amount funded'),
  });

  const onSaveAmountFunded = () => {
    if (validateDollar(newAmountFunded))
      updateAmountFunded({
        variables: {
          fiscalYear,
          agencyCode,
          agencyIndicator,
          amountFunded: newAmountFunded,
        },
      });
  };

  return (
    <div id="afv-surcharge-funding" className="margin-bottom-6">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          { location: '/bm/afv-funding', label: 'AFV Funding Summary' },
        ]}
      />
      <div ref={bannerRef}>
        {msg && (
          <BannerMessage focused className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
            {msg.message}
          </BannerMessage>
        )}
      </div>
      {(loadingSurcharges || loadingAmountFunded || loadingVMSData || loadingStoreData || loadingBillingData) && (
        <Spinner />
      )}
      <div className="grid-row grid-gap">
        <div className="grid-col-5">
          <PageTitle title={TITLE} />
          <h2>{data?.name}</h2>
          <p>
            Assigned Vehicles
            <span className="margin-left-1">
              <CounterTag count={data ? data.numVehicles : agencyData?.totalNumVehicles} />
            </span>
          </p>
        </div>
        <div className="grid-col-7">
          <div className="grid-row grid-gap flex-end-row">
            <div className="grid-col-3">
              <SelectDropdown
                required
                id="fiscal-year"
                data-testid="fiscal-year"
                name="fiscalYear"
                value={fiscalYear}
                label="Fiscal year"
                options={fiscalYearOptions}
                onChange={(e) => {
                  setFiscalYear(e.target.value);
                }}
              />
            </div>
            <div className="grid-col-6">
              <SelectDropdown
                required
                id="agency-code"
                data-testid="agency-code"
                name="agencyCode"
                value={agencyCode}
                label="Agency"
                options={agencyOptions}
                onChange={(e) => {
                  setAgencyCode(e.target.value);
                }}
              />
            </div>
            <div className="grid-col-3">
              <SelectDropdown
                required
                id="agency-indicator"
                data-testid="agency-indicator"
                name="agencyIndicator"
                value={agencyIndicator}
                label="Agency indicator"
                options={indicatorOptions}
                onChange={(e) => {
                  setAgencyIndicator(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap flex-end-row">
        <div className="grid-col-2">
          <Label>Amount funded</Label>
          <div className="dollar-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
            <Textbox
              disabled={!agencyIndicator || fiscalYear < currentFY}
              id="amount-funded"
              data-testid="amount-funded"
              name="amountFunded"
              value={formatDollar(agencyIndicator ? newAmountFunded : agencyData?.totalAmountFunded, '', -1)}
              className="text-right"
              aria-label="Amount funded input"
              onChange={(e) => {
                if (!e.target.value) setNewAmountFunded('');
                const val = e.target.value?.split(',').join('');
                if (validateDollar(val)) setNewAmountFunded(val);
              }}
            />
          </div>
        </div>
        <div className="grid-col-2">
          <Label>Funds remaining</Label>
          <div className="dollar-input">
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
            <Textbox
              id="funds-remaining"
              name="fundsRemaining"
              aria-label="funds remaining"
              value={formatDollar(data ? data.remainingFunds : agencyData?.totalRemainingFunds, '')}
              className="text-right"
              disabled
            />
          </div>
        </div>
        {agencyIndicator && fiscalYear >= currentFY && (
          <div className="grid-col-2">
            <Button
              size="sm"
              variant="primary"
              id="fund-save-btn"
              data-testid="fund-save-btn"
              className="fund-save-btn"
              disabled={savingAmountFunded}
              onClick={onSaveAmountFunded}
              label="Save"
            />
          </div>
        )}
      </div>
      <div className="grid-row grid-gap flex-end-row">
        <div className="grid-col-6">
          <div className="grid-row grid-gap flex-end-row">
            <div className="grid-col-3">
              <Label htmlFor="surcharge">Surcharge</Label>
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <Textbox
                  id="surcharge"
                  name="surcharge"
                  value={displaySurcharge || 'N/A'}
                  className="text-right"
                  disabled
                />
              </div>
            </div>
            <div className="grid-col-3">
              <Label htmlFor="amount-collected">Funds collected</Label>
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <Textbox
                  id="amount-collected"
                  name="amountCollected"
                  value={formatDollar(data ? data.amountCollected : agencyData?.totalAmountCollected, '')}
                  className="text-right"
                  disabled
                />
              </div>
            </div>
            <div className="grid-col-3">
              <Label htmlFor="amount-spent">{fiscalYear >= currentFY ? <>Current spending</> : <>Funds spent</>}</Label>
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <Textbox
                  id="amount-spent"
                  name="amountSpent"
                  value={formatDollar(data ? data.amountSpent : agencyData?.totalAmountSpent, '')}
                  className="text-right"
                  disabled
                />
              </div>
            </div>
            <div className="grid-col-3">
              <Label htmlFor="balance">{fiscalYear >= currentFY ? <>Current balance</> : 'Balance'}</Label>
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <Textbox
                  id="balance"
                  name="balance"
                  value={formatDollar(data ? data.balance : agencyData?.totalBalance, '')}
                  className="text-right"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        {fiscalYear >= currentFY && (
          <div className="grid-col-6">
            <div className="grid-row grid-gap flex-end-row">
              <div className="grid-col-3">
                <Label htmlFor="project-to-collect">Project to collect</Label>
                <div className="dollar-input">
                  <Icon iconName="attach_money" className="prefix-icon-dollar" />
                  <Textbox
                    id="project-to-collect"
                    name="projectToCollect"
                    value={formatDollar(data ? data.projectToCollect : agencyData?.totalProjectToCollect, '')}
                    className="text-right"
                    disabled
                  />
                </div>
              </div>
              <div className="grid-col-3">
                <Label htmlFor="project-to-spend">Project to spend</Label>
                <div className="dollar-input">
                  <Icon iconName="attach_money" className="prefix-icon-dollar" />
                  <Textbox
                    id="project-to-spend"
                    name="projectToSpend"
                    value={formatDollar(data ? data.projectToSpend : agencyData?.totalProjectToSpend, '')}
                    className="text-right"
                    disabled
                  />
                </div>
              </div>
              <div className="grid-col-3">
                <Label htmlFor="calc-projected-balance">Projected balance</Label>
                <div className="dollar-input">
                  <Icon iconName="attach_money" className="prefix-icon-dollar" />
                  <Textbox
                    id="calc-projected-balance"
                    name="calcProjectedBalance"
                    value={formatDollar(data ? data.projectedBalance : agencyData?.totalProjectedBalance, '')}
                    className="text-right"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {agencyIndicator && data && fiscalYear >= currentFY && (
        <AfvSurchargeCalculator data={data} onSetSurcharge={setCalcSurcharge} />
      )}

      <div ref={surchargeBannerRef}>
        {surchargeBannerMsg && (
          <BannerMessage
            focused
            className="margin-top-2"
            type={surchargeBannerMsg.type}
            onClose={() => setSurchargeBannerMsg(null)}
          >
            {surchargeBannerMsg.message}
          </BannerMessage>
        )}
      </div>
      {agencyIndicator && data && fiscalYear >= currentFY && (
        <AFVFundingSetSurcharge
          afvFundingData={data}
          onSetSurchargeBannerMsg={onSetSurchargeBannerMsg}
          refetchSurcharges={refetchSurcharges}
          calcSurcharge={calcSurcharge}
        />
      )}

      {agencyIndicator && data && (
        <div className="margin-top-6">
          <AFVFundingSurchargeHistory
            data={data}
            refetchSurcharges={refetchSurcharges}
            onSetSurchargeBannerMsg={onSetSurchargeBannerMsg}
          />
        </div>
      )}

      {!agencyIndicator && <div style={{ height: 100 }} />}
    </div>
  );
}
