import React from 'react';
import CurrencyCell from 'components/widgets/CurrencyCell';
import { tsToDatetTime } from 'components/helpers/afp-bm-date';

/* eslint-disable react/prop-types */
const columns = [
  {
    Header: 'Date created/updated',
    accessor: 'updatedAt',
    Cell: ({ value }) => tsToDatetTime(value),
  },
  {
    Header: 'Updated by',
    accessor: 'createdByUserInfo',
    sortable: false,
    disableSortBy: true,
    Cell: ({ value }) => <a href={`mailto:${value?.email}`}>{value?.fullName}</a>,
  },
  {
    Header: 'Replacement funding',
    accessor: 'replacementFunding',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Consolidation funding',
    accessor: 'consolidationFunding',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Additional funding',
    accessor: 'additionalFunding',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
  {
    Header: 'Total funding',
    accessor: 'totalAmountFunded',
    sortable: false,
    Cell: CurrencyCell,
    headerClassName: 'cell-right',
    cellClassName: 'cell-right cell-right-padding',
  },
];

export default columns;
