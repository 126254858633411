import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Label, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { ENTITY, ROLE_OP, hasAbilityTo } from 'components/role-permission/role-permission';
import { DELETE_UNIQUE_RATE, DEACTIVATE_ACTIVE_UNIQUE_RATE, REACTIVATE_ACTIVE_UNIQUE_RATE } from './unique-rate-helper';
import { DeleteModal } from '../../components/modals/delete-modal/delete-modal';
import { STATUS } from '../../components/helpers/constants';
import { UTCDateStrToUS } from '../../components/helpers/afp-bm-date';
import { classNameErrBox, classNameErrInput } from '../../components/helpers/afp-bm-helpers';

const UniqueRateDeleteModal = ({ onClose, data: { uniqueRateType, rateStatus, startDate }, handleDelete }) => {
  if (!hasAbilityTo(ROLE_OP.DELETE, ENTITY.UNIQUE_RATE)) onClose();

  const [comment, setComment] = useState('');
  const [commentsCount, setCommentsCount] = useState(200);
  const [errors, setErrors] = useState(null);

  const getOnCompletedFn = (verb, type) => {
    let message = null;

    if (verb === 'deleted') {
      message = (
        <>
          You have successfully {verb} the {type} unique rate for {UTCDateStrToUS(startDate)}.
        </>
      );
    } else {
      message = (
        <>
          You have successfully {verb} <b>{uniqueRateType}</b>.
        </>
      );
    }

    handleDelete('success', message);
    onClose(true);
  };

  const getOnErrorFn = (verb, type, error) => {
    let message = null;

    if (verb === 'delete') {
      message = (
        <>
          Unable to {verb} the {type === 'pending' ? 'unique pending rate' : 'new unique rate'} <b>{uniqueRateType}</b>{' '}
          for {UTCDateStrToUS(startDate)}.
          <br /> {error}
        </>
      );
    } else {
      message = (
        <>
          Unable to {verb} <b>{uniqueRateType}</b>.
          <br /> {error}
        </>
      );
    }

    handleDelete('error', message);
    onClose();
  };

  const [deleteUniqueRate, { loading: deleting }] = useMutation(DELETE_UNIQUE_RATE, {
    onCompleted: () => getOnCompletedFn('deleted', rateStatus === STATUS.PENDING ? 'pending' : 'new'),
    onError: (err) => getOnErrorFn('deleted', rateStatus === STATUS.PENDING ? 'pending' : 'new', err.message),
  });

  const [deactivateUniqueRate, { loading: deactivating }] = useMutation(DEACTIVATE_ACTIVE_UNIQUE_RATE, {
    onCompleted: () => getOnCompletedFn('deactivated', null),
    onError: (err) => getOnErrorFn('deactivate', null, err.message),
  });

  const [reactivateUniqueRate, { loading: activating }] = useMutation(REACTIVATE_ACTIVE_UNIQUE_RATE, {
    onCompleted: () => getOnCompletedFn('reactivated', null),
    onError: (err) => getOnErrorFn('reactivate', null, err.message),
  });

  const VARIATION = {
    [STATUS.PENDING]: {
      title: `Delete ${rateStatus?.toLowerCase()} unique lease rate`,
      instruction: (
        <>
          Are you sure you want to delete <b>{uniqueRateType}</b> for start date <b>{UTCDateStrToUS(startDate)}</b>?
          This cannot be undone.
        </>
      ),
      verb: 'delete',
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlApi: deleteUniqueRate,
      variables: {
        uniqueRateType,
        rateStatus,
        startDate,
      },
    },
    [STATUS.NEW]: {
      title: `Delete ${rateStatus?.toLowerCase()} unique lease rate`,
      instruction: (
        <>
          Are you sure you want to delete <b>{uniqueRateType}</b> for start date <b>{UTCDateStrToUS(startDate)}</b>?
          This cannot be undone.
        </>
      ),
      verb: 'delete',
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlApi: deleteUniqueRate,
      variables: {
        uniqueRateType,
        rateStatus,
        startDate,
      },
    },
    [STATUS.ACTIVE]: {
      title: 'Deactivate a Unique Rate',
      instruction: (
        <>
          You are about to deactivate <b>{uniqueRateType}</b>. Please provide a reason for deactivation in the box
          below.
        </>
      ),
      verb: 'deactivate',
      buttonText: 'Deactivate',
      isReasonRequired: true,
      gqlApi: deactivateUniqueRate,
      variables: {
        uniqueRateType,
        rateStatus,
        startDate,
        comment,
      },
    },
    [STATUS.INACTIVE]: {
      title: 'Reactivate a Unique Rate',
      instruction: (
        <>
          You are about to reactivate <b>{uniqueRateType}</b>. Please provide a reason for reactivate in the box
          below.
        </>
      ),
      verb: 'reactivate',
      buttonText: 'Reactivate',
      isReasonRequired: true,
      gqlApi: reactivateUniqueRate,
      variables: {
        uniqueRateType,
        rateStatus,
        startDate,
        comment,
      },
    },
  };

  const onCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
    setCommentsCount(200 - value.length);
    if (comment.length > 1) setErrors(false);
  };

  const onDelete = () => {
    if (VARIATION[rateStatus].isReasonRequired && !comment) setErrors(true);
    else {
      VARIATION[rateStatus].gqlApi({
        variables: { ...VARIATION[rateStatus].variables },
      });
    }
  };

  return (
    <DeleteModal
      id="unique-rate-delete-modal"
      title={VARIATION[rateStatus].title}
      onClose={onClose}
      onDelete={onDelete}
      deleting={deleting || deactivating || activating}
      deleteButtonText={VARIATION[rateStatus].buttonText}
    >
      <div className="margin-top-4 margin-bottom-4" data-testid="delete-modal-body">
        {VARIATION[rateStatus].instruction}
      </div>
      {VARIATION[rateStatus].isReasonRequired && (
        <div className="margin-top-4">
          <div className={classNameErrBox(errors)}>
            <Label htmlFor="activation-comment" required>
              Please provide reason
            </Label>
            {errors && <ErrorMessage>Please provide a reason</ErrorMessage>}
            <Textbox
              name="comment"
              className={classNameErrInput(errors)}
              id="activation-comment"
              value={comment}
              type="textarea"
              maxLength={200}
              rows={3}
              onChange={onCommentChange}
            />
            <div className="usa-hint usa-character-count__message">
              {commentsCount < 200 ? `${commentsCount} characters remaining` : '200 characters allowed'}
            </div>
          </div>
        </div>
      )}
    </DeleteModal>
  );
};

UniqueRateDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    uniqueRateType: PropTypes.string.isRequired,
    rateStatus: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default UniqueRateDeleteModal;
