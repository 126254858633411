import React from "react";
import { AFPTableRowAction } from "@gsa/afp-component-library";
import { ROLE_OP, hasAbilityTo } from "./role-permission";

export const getActions = (entity) => ({
  VIEW: {
    icon: "visibility",
    label: "View",
    operation: ROLE_OP.VIEW,
    entity,
  },
  REVIEW: {
    icon: "visibility",
    label: "Review",
    operation: ROLE_OP.REVIEW,
    entity,
  },
  CREATE: {
    icon: "add",
    label: "Create",
    operation: ROLE_OP.CREATE,
    entity,
  },
  UPDATE: {
    icon: "edit",
    label: "Update",
    operation: ROLE_OP.UPDATE,
    entity,
  },
  DELETE: {
    icon: "delete",
    label: "Delete",
    operation: ROLE_OP.DELETE,
    entity,
  },
  ACTIVATE: {
    icon: "check",
    label: "Reactivate",
    operation: ROLE_OP.ACTIVATE,
    entity,
  },
  DEACTIVATE: {
    icon: "close",
    label: "Deactivate",
    operation: ROLE_OP.DEACTIVATE,
    entity,
  },

  // for lease rates
  DUPLICATE: {
    icon: "content_copy",
    label: "Duplicate",
    operation: ROLE_OP.CREATE,
    entity,
  },
  MODIFY_COMBINATION: {
    icon: "arrow_forward",
    label: "Modify combination",
    operation: ROLE_OP.UPDATE,
    entity,
  },
  MOVE: {
    icon: "arrow_forward",
    label: "Move",
    operation: ROLE_OP.DELETE,
    entity,
  },
  REMOVE: {
    icon: "cancel",
    label: "Remove",
    operation: ROLE_OP.DELETE,
    entity,
  },

  // for unique rate applications
  COPY_REQUEST: {
    icon: "content_copy",
    label: "Copy Request",
    operation: ROLE_OP.CREATE,
    entity,
  },
  ADD_VEHICLES: {
    icon: "add",
    label: "Add Vehicles",
    operation: ROLE_OP.CREATE,
    entity,
  },

  // for afv funding
  MANAGE: {
    icon: "edit",
    label: "Manage",
    operation: ROLE_OP.MANAGE,
    entity,
  },
  COMMENT: {
    icon: "comment",
    label: "Comment",
    operation: ROLE_OP.UPDATE,
    entity,
  },
  MARK_RESOLVED: {
    icon: "check",
    label: "Mark as resolved",
    operation: ROLE_OP.UPDATE,
    entity,
  },
});
const ACTIONS = getActions("");

export const genRowActions = (actions, { row, ...otherProps }, onSelectAction, skipRoleCheck = null) => {
  const roleCheck = (act) => (skipRoleCheck ? true : hasAbilityTo(act.operation, act.entity));
  const rowActions = actions
    ?.map((act) => (roleCheck(act) ? { icon: act.icon, label: act.label } : null))
    .filter((act) => act);

  // remove VIEW if REVIEW is in the list
  if (rowActions.some((act) => act.label === ACTIONS.REVIEW.label)) {
    const ind = rowActions.findIndex((act) => act.label === ACTIONS.VIEW.label);
    if (ind >= 0) rowActions.splice(ind, 1);
  }

  if (rowActions?.length)
    return (
      <div className="bm-row-action">
        <AFPTableRowAction
          actions={rowActions}
          onSelectAction={(label) => {
            onSelectAction(label, row.original, row);
          }}
          row={row}
          {...otherProps}
        />
      </div>
    );
  return <div>–</div>;
};

export const getRowActionOps = (rowActions) => {
  const ops = new Set();
  Object.values(rowActions).forEach((actions) => {
    actions.forEach((act) => ops.add(act.operation));
  });
  return [...ops];
};
