import React from 'react';
import { TextInput, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { IPAC_VEHICLES_FIELDS } from './consts';

const WalletIpacFormFields = () => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  return (
    <div className="grid-row grid-gap">
      {Object.values(IPAC_VEHICLES_FIELDS).map((ipacVehicleField) => {
        return (
          <>
            <div className="grid-col-9">
              <Controller
                name={ipacVehicleField?.name}
                control={control}
                render={({ value, onChange, onBlur }) => {
                  return (
                    <div key={ipacVehicleField?.name}>
                      <TextInput
                        data-testid={`ipac-update-vehicle-${ipacVehicleField?.name}`}
                        inputClass="maxw-full"
                        type={ipacVehicleField?.type}
                        name={ipacVehicleField?.name}
                        hint={ipacVehicleField?.hint || ''}
                        id={ipacVehicleField?.id}
                        disabled={ipacVehicleField?.disabled}
                        value={value}
                        maxLength={ipacVehicleField?.maxLength}
                        onChange={(e) => {
                          onChange(e);
                          setValue(ipacVehicleField?.name, e.target.value);
                        }}
                        onBlur={onBlur}
                        label={
                          <span className="text-bold">
                            {ipacVehicleField?.label} {ipacVehicleField?.required && <RequiredFieldIndicator />}
                          </span>
                        }
                        errorMessage={
                          errors && errors[ipacVehicleField?.name] && !ipacVehicleField?.disabled
                            ? errors[ipacVehicleField?.name].message
                            : null
                        }
                        aria-invalid={
                          errors && errors[ipacVehicleField?.name] && !ipacVehicleField?.disabled ? 'true' : 'false'
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default WalletIpacFormFields;
