import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { usePegasysFilter } from './filters/pegasys-filter-provider';
import {usePegasysContext} from './pegasys-provider';
import { USDateStrToUTC } from '../../components/helpers/afp-bm-date';
import PegasysTypeaheadItem from './typeahead-field-component';

const PegasysFilterSidebar = () => {
  const { ffFilterStructure, setFlatFileFilters, setFlatFilesStructure } = usePegasysFilter();
  const { fetchBatchFileData, ffTypeaheadDataObj } = usePegasysContext();
  const fileFilterStructure = () => {
    return [
      {
        key: 'batch_type',
        title: 'Document type',
        type: 'multiselectdropdown',
        placeholder: '-Select-',
        permanent: false,
        operator: 'IN',
        options: [
          {
            label: 'Leasing DBRs',
            value: 'FLL',
          },
          {
            label: 'Purchasing DBRs',
            value: 'FLP',
          },
          {
            label: 'Rental DBRs',
            value: 'FLS',
          },
          {
            label: 'Leasing/Rental revenue accrual',
            value: 'FDA',
          },
          {
            label: 'Third Party Claim',
            value: 'DAV',
          },
        ],
        value: [],
      },
  
      {
        title: 'Document Number',
        key: 'record_id',
        operator: 'STARTWITH',
        component: PegasysTypeaheadItem,
        noResultsText: "No DocumentNumbers found",
        ariaLabel: "Default Typeahead",
        promptText: "Search requires 3 characters.",
        typeaheadData: ffTypeaheadDataObj,
        inputCharNum: 3,
        debounceDelay: 500,
        hideClear: true,
      },
  
      {
        id: "ffVin",
        title: "VIN",
        key: 'vin',
        operator: 'STARTWITH',
        component: PegasysTypeaheadItem,
        noResultsText: "No VIN's found",
        ariaLabel: "Default Typeahead",
        promptText: "Search requires 3 characters.",
        inputCharNum: 3,
        debounceDelay: 500,
        hideClear: true,
        typeaheadData: ffTypeaheadDataObj,
      },
    
      {
        title: 'Status',
        key: 'errorDataStatus',
        type: 'multiselect',
        value: ['Active'],
        options: [
          { value: 'Active', label: 'Active' },
          { value: 'Archived', label: 'Archived' },
        ],
        permanent: false,
        operator: 'IN',
        hideClear: true,
        expanded: true,
      },
    
      {
        title: 'Date',
        key: 'createdAt',
        type: 'daterange',
        operator: 'BETWEEN',
        options: {
          startDate: {
            key: 'beginning',
            label: 'Start Date',
            hint: 'mm/dd/yyy',
          },
          endDate: {
            key: 'end',
            label: 'End Date',
            hint: 'mm/dd/yyy',
          },
        },
        value: {
          beginning: '',
          end: '',
        },
        hideClear: true,
      }
    ];
  };

  useEffect(() => {
    setFlatFilesStructure(fileFilterStructure());
  }, [ffTypeaheadDataObj]);

  const handleFlatFilesFilters = (filters) => {
     const filterToApply = {
      filters: { operator: 'AND', value: [] },
      virtualFilter: { operator: 'AND', value: [] },
      order: [['id', 'DESC']],
      offset: 0,
      limit: 10,
    };
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.key;
      const virtualFilterTypes = ['errorDataStatus', 'record_id', 'batch_type'];
      if (valueObj) {
        if (virtualFilterTypes.includes(selectedKey)) {
          filterToApply.virtualFilter.value.push(valueObj);
        } else if (selectedKey === 'createdAt') {
          const startDate = USDateStrToUTC(valueObj?.value?.beginning);
          const endDate = USDateStrToUTC(valueObj?.value?.end, true);
          filterToApply.filters.value.push({
            operator: 'AND',
            value: [
              { key: 'createdAt', operator: 'GTE', value: startDate },
              { key: 'createdAt', operator: 'LTE', value: endDate },
            ],
          });
        } else {
          filterToApply.filters.value.push(valueObj);
        }
      }
    });

    setFlatFileFilters({ ...filterToApply });

    fetchBatchFileData({ variables: { ...filterToApply }});
  };

  if (ffFilterStructure?.length > 0 && ffTypeaheadDataObj) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={ffFilterStructure}
        setQueryFiltersState={(filters) => {
          handleFlatFilesFilters(filters)
        }}
        clearButtonLabel="Reset all"
        showClearIcon
        showSearchIcon
      />
    );
  }
  return null;
};

export default PegasysFilterSidebar;
