import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const WalletFilterContext = createContext({});
const useWalletFilter = () => useContext(WalletFilterContext);

const initialState = {
  error: {},
  filters: {
    operator: '$and',
    value: [],
  },
  filterStructure: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const walletFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setFilters: {
      return mergeState(
        {
          operator: 'AND',
          value: payload || [],
        },
        'filters',
      );
    }
    case actions.setStructure: {
      return mergeState(payload, 'filterStructure');
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const WalletFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(walletFilterReducer, initialState, () => initialState);

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchFilters = (conditions) => dispatch(actions.setFilters, conditions);

  const dispatchFilterStructure = (structure) => dispatch(actions.setStructure, structure);

  return (
    <WalletFilterContext.Provider
      value={{
        ...state,
        dispatch,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
      }}
    >
      {children}
    </WalletFilterContext.Provider>
  );
};

WalletFilterProvider.defaultProps = {
  children: undefined,
};

WalletFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { WalletFilterProvider as default, useWalletFilter };