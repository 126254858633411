/* eslint-disable react/prop-types */
import React from 'react';
import RateStatusBlock from 'components/widgets/rate-status-block';
import _ from 'lodash/fp';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { toCurrency, toPercentage } from 'utilities/formatUtils';
import { STATUS } from 'components/helpers/constants';
import { ENTITY, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import { genRowActions, getActions, getRowActionOps } from 'components/role-permission/row-action';
import { isTierRate } from '../../../helper';

const ROLE_ENTITY = ENTITY.OPT_IN_SERVICE;
const ACTIONS = getActions(ROLE_ENTITY);
const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.UPDATE, ACTIONS.DEACTIVATE],
  [STATUS.INACTIVE]: [ACTIONS.ACTIVATE],
  [STATUS.PENDING]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.NEW]: [ACTIONS.UPDATE, ACTIONS.DELETE],
  [STATUS.EXPIRED]: [],
};
const ACTION_OPS = getRowActionOps(ROW_ACTIONS);

export const handleSelectedAction = ({ setDispatch, DISPATCH_FNS, openModal }) => (label, original) => {
  const rowData = { ...original };
  const actionMap = {
    [`${ACTIONS.ACTIVATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'service_table.rowData',
        data: rowData,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'service_toggleActiveModal.isDeactivate',
        data: false,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.toggleActiveModal, { show: true, openModal, isCategory: false });
      }, 0);
    },
    [`${ACTIONS.DEACTIVATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'service_table.rowData',
        data: rowData,
      });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'service_toggleActiveModal.isDeactivate',
        data: true,
      });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.toggleActiveModal, { show: true, openModal, isCategory: false });
      }, 0);
    },
    [`${ACTIONS.CREATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateServiceRowData, { rowData: null });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.service_updateOptInServiceModal, { isCreate: true, show: true, openModal });
      }, 0);
    },
    [`${ACTIONS.UPDATE.label}`]: () => {
      setDispatch(DISPATCH_FNS.service_updateRateStructure, { rowData });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.service_createOriginalFormState, { rowData });
        setDispatch(DISPATCH_FNS.service_updateOptInServiceModal, { isCreate: false, show: true, openModal });
      }, 0);
    },
    [`${ACTIONS.DELETE.label}`]: () => {
      setDispatch(DISPATCH_FNS.updateServiceRowData, { rowData });
      setTimeout(() => {
        setDispatch(DISPATCH_FNS.service_updateDeleteModal, { show: true, openModal });
      }, 0);
    },
  };
  actionMap[label]();
};

export const columns = ({ setDispatch, DISPATCH_FNS, openModal }) => {
  const cols = [
    {
      Header: 'Category',
      accessor: 'optInSvcCategory',
      sortable: true,
      collapsedRowClassName: 'optServiceTypeStyle',
    },
    {
      Header: 'Service',
      accessor: 'optInSvcName',
      sortable: true,
      collapsedRowClassName: 'optServiceTypeStyle',
    },
    {
      Header: 'Status',
      accessor: 'rateStatus',
      // eslint-disable-next-line
      Cell: ({ value }) => <RateStatusBlock status={value} />,
      sortable: false,
    },
    {
      Header: 'Rate and frequency',
      accessor: 'optInServiceAssociationObj',
      sortable: false,
      collapsedRowClassName: 'optServiceTypeStyle',
      Cell: ({ value }) => {
        return isTierRate(value) ? (
          <span style={{ whiteSpace: 'nowrap', display: 'block' }}>Varied-rate</span>
        ) : (
          _.map(
            (associationObj) => (
              <span style={{ whiteSpace: 'nowrap', display: 'block' }}>
                {`${
                  associationObj.optInFlatDollar
                    ? toCurrency(associationObj.optInFlatDollar)
                    : toPercentage(associationObj.optInFixedRate)
                } ${associationObj.optInBillingFreq}`}
              </span>
            ),
            value,
          )
        );
      },
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      sortable: true,
      Cell: ({ value }) => UTCDateStrToUS(value),
      collapsedRowClassName: 'optServiceTypeStyle',
    },
  ];
  if (hasSomeAbilitiesTo(ACTION_OPS, ROLE_ENTITY))
    cols.push({
      Header: 'Actions',
      accessor: 'action',
      sortable: false,
      Cell: (rowData) =>
        genRowActions(
          ROW_ACTIONS[rowData.row.original?.rateStatus] || [],
          rowData,
          handleSelectedAction({ setDispatch, DISPATCH_FNS, openModal }),
          true,
        ),
    });

  return cols;
};
