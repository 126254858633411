import { gql } from '@apollo/client';

export const OptInServiceResponse = gql`
  fragment OptInServiceResponseFields on OptInService {
    optInSvcId
    optInSvcName
    optInSvcDesc
    optInSvcCategory
    rateStatus
    optInSvcSalesId
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
  }
`;

export const DEACTIVATE_OPT_IN_SERVICE = gql`
  mutation deactivateOptInService(
    $optInSvcId: String!
    $optInSvcName: String!
    $rateStatus: String!
    $comment: String!
  ) {
    deactivateOptInService(
      optInSvcId: $optInSvcId
      optInSvcName: $optInSvcName
      rateStatus: $rateStatus
      comment: $comment
    ) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;
