import exportFile from './client-side-file-export';

function convertCSVCell(cell) {
  if (!cell || typeof cell !== 'string') return '';
  if (/[",\n\r]/.test(cell)) return `"${(cell || '').toString().replace(/"/g, '""')}"`;
  return cell;
}

export function convertCSVRow(row) {
  return row.map(convertCSVCell).join(',');
}

function convertToCSV(rows) {
  return rows.map(convertCSVRow).join('\r\n');
}

export default function exportCSVFile(headers, items, fileName, subheaders) {
  const rows = [...items];
  if (subheaders) rows.unshift(subheaders);
  if (headers) rows.unshift(headers);

  const csv = convertToCSV(rows);

  const exportedFileName = fileName ? `${fileName}.csv` : 'export.csv';

  exportFile(csv, 'text/csv;charset=utf-8;', exportedFileName);
}
