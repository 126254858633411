import React, {useRef, useState} from "react";
import { PageTitle, TabSet } from "@gsa/afp-component-library";
import { useTitle } from "@gsa/afp-shared-ui-utils";
import Breadcrumbs from "widgets/breadcrumbs";
import WelcomeMessage from "widgets/welcome-message";
import BannerMessage from "widgets/banner-message";
import { ENTITY, ROLE_OP, hasSomeAbilitiesTo } from 'components/role-permission/role-permission';
import PegasysWsErrorHandlingTable from "./pegasys-ws-error-handling-table";
import PegasysFileErrorHandlingTable from "./pegasys-ff-error-handling-table";
import {WEB_SERVICES, FLAT_FILES } from "./pegasys-common-util";
import PegasysProvider from './pegasys-provider';
import PegasysFilterProvider from './filters/pegasys-filter-provider';

const TITLE = 'Pegasys Interface Management';

const WELCOME_MESSAGE =
  `Please use this page to view transactional errors that have occured while attemping to submit to Pegasys. 
  These errors require corrective action to ensure they process successfully. 
  Transactions will auto-retry for 10 business and any corrections to the data will be picked up and reprocessed during that window.`;
const PegasysErrorContent = () => {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const bannerRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(WEB_SERVICES);

  setTimeout(() => {
    setMsg(null);
  }, 5000);

    const onWsSetMsg = (message) => {
        setMsg(message);
        bannerRef.current.scrollIntoView({behavior: "smooth"});
    };

    const onFlatFileSetMsg = (message) => {
      setMsg(message);
      bannerRef.current.scrollIntoView({behavior: "smooth"});
    };

    const permissiableTabs = () => {      
      if(hasSomeAbilitiesTo([ROLE_OP.VIEW, ROLE_OP.UPDATE], ENTITY.PEGASYS_ERROR)){
        return [
          {
            heading: "Web Services",
            tabSelectedWhenOpen: WEB_SERVICES === selectedTab,
            content: <PegasysWsErrorHandlingTable setBannerMsg={onWsSetMsg} setSelectedTab={setSelectedTab} />,
          },
          {
            heading: "Flat Files",
            tabSelectedWhenOpen: FLAT_FILES === selectedTab,
            content: <PegasysFileErrorHandlingTable setBannerMsg={onFlatFileSetMsg} setSelectedTab={setSelectedTab} />,
          },
        ]        
      }
      return [];
    }

  return (
    <div className="grid-col margin-bottom-6">
      <PegasysProvider>
        <PegasysFilterProvider>
          <Breadcrumbs
            current={TITLE}
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
          />
          <div ref={bannerRef}>
            {msg && (
              <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
                {msg.message}
              </BannerMessage>
            )}
          </div>
          <PageTitle title={TITLE} />
          <WelcomeMessage className="textStyle" message={WELCOME_MESSAGE} />

          <TabSet tabs={permissiableTabs()} />
        </PegasysFilterProvider>
      </PegasysProvider>
    </div>
  );
};

export default PegasysErrorContent;
