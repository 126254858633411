import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_AGENCIES_BY_PERMISSION } from '../wallet.gql';
import { ENTITY, ROLE_OP } from '../../../components/role-permission/role-permission';

const placeholderOption = {
  value: '',
  label: '- Select agency -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const AgencyFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [fetchAgencyFilterOptions, { data: agencyOptions, loading: agencyOptionsLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    fetchAgencyFilterOptions({
      variables: {
        operation: ROLE_OP.VIEW,
        subject: ENTITY.WALLET,
      },
    });
  }, []);

  useEffect(() => {
    if (agencyOptions?.getAgenciesByPermission) {
      const formattedAgencyOptions = sortBy(agencyOptions.getAgenciesByPermission, 'id').map((agencyDetails) => ({
        value: agencyDetails.id,
        label: `${agencyDetails.id} - ${agencyDetails.name}`,
      }));
      setOptions(formattedAgencyOptions ? [placeholderOption, ...formattedAgencyOptions] : [placeholderOption]);
    }
  }, [agencyOptions]);

  if (agencyOptionsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

AgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default AgencyFilterItem;
