import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Label, RadioButton, SelectBox, Textbox, ErrorMessage, Button, FileUpload } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import BannerMessage from 'widgets/banner-message';
import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';
import {
  GET_AWS_SIGNED_READ_URL,
  MILEAGE_TYPE_OPTIONS,
  MONTHLY_RATE_OPTIONS,
  GET_REASON_OPTIONS,
  UNIQUE_RATE_OPTIONS,
  APP_TYPE,
  omit,
} from './unique-rate-app-helper';
import './style/unique-rate-app-form-step-one.scss';

// Form validation schema
const schema = yup.object().shape({
  mileageRate: yup.string().required('Mileage Rate is a required field'),
  mileageType: yup.string().required('Mileage Type is a required field'),
  reason: yup.string().required('Applicable Reason is a required field'),
  justification: yup
    .string()
    .min(1, 'Justification is a required field')
    .max(200, 'Max of 200 characters allowed')
    .required('Justification is a required field'),
  monthlyRateType: yup.string().nullable(),
  supportingDocument: yup.string().required('Signed MOA is required'),
});

const UniqueRateAppFormStepOne = forwardRef(
  ({ draft, onNext, setDidClickNext, onSaveDraft, onSelectFile, loading, isCopy }, ref) => {
    const isReadOnly = draft?.appType === APP_TYPE.ADD_VEHICLE;
    const [reasonOptions, setReasonOptions] = useState([]);
    const [isModifiedMileageRate, setIsModifiedMileageRate] = useState(true);
    const [justificationCount, setJustificationCount] = useState(200);
    const [supportingDocURL, setSupportingDocURL] = useState(null);
    const [showCopyWarning, setShowCopyWarning] = useState(isCopy);

    // useForm declaration
    const { errors, control, setValue, setError, clearErrors, getValues, trigger, register } = useForm({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
        mileageRate: draft?.mileageRate || 'Y',
        mileageType: draft?.mileageType || '',
        reason: draft?.reason || '',
        justification: draft?.justification || '',
        monthlyRateType: draft?.monthlyRateType || '',
        supportingDocument: draft?.supportingDocument?.metadata?.name,
      },
    });

    useEffect(() => {
      register({ name: 'isModifiedMileageRate' });
      setValue('isModifiedMileageRate', true);

      if (draft) {
        const { mileageRate, mileageType, reason, justification, monthlyRateType, supportingDocument } = draft;

        setValue('mileageRate', mileageRate);

        if (getValues('mileageRate') === 'Y') {
          setIsModifiedMileageRate(true);
          setValue('isModifiedMileageRate', true);
          setValue('mileageType', mileageType);
          setReasonOptions(GET_REASON_OPTIONS(mileageType));
        } else {
          setIsModifiedMileageRate(false);
          setValue('isModifiedMileageRate', false);
          setReasonOptions(GET_REASON_OPTIONS(monthlyRateType));
        }

        setValue('monthlyRateType', monthlyRateType);
        setValue('reason', reason);
        setValue('justification', justification);
        setValue('supportingDocument', supportingDocument?.metadata?.name);
      }
    }, [draft]);

    // gql call to retrieve Read URL for uploaded supporting document
    const [getReadURL] = useLazyQuery(GET_AWS_SIGNED_READ_URL, {
      onCompleted: async (requestData) => {
        setSupportingDocURL(requestData?.generateReadSignedURL);
      },
    });
    useEffect(() => {
      if (draft?.supportingDocument?.metadata) {
        getReadURL({
          variables: {
            fileKey: draft.supportingDocument.metadata.fileLocation,
            fileType: draft.supportingDocument.metadata.fileMimeType,
          },
        });
      }
    }, [draft?.supportingDocument?.metadataId]);

    const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

    // submit step-one form data
    const onSubmit = () => {
      const {
        mileageRate: mileageRateDraft,
        mileageType: mileageTypeDraft,
        reason: reasonDraft,
        justification: justificationDraft,
        monthlyRateType: monthlyRateTypeDraft,
      } = getValues();

      const uniqueRateAppData = {};

      if (draft) {
        // Updating existing draft
        const uniqueRateAppFcsInput = draft.uniqueRateAppFcs ? omit(['__typename'], draft.uniqueRateAppFcs) : {};
        const uniqueRateAppPocInput = draft.uniqueRateAppPoc
          ? omit(['__typename', 'agencyName'], draft.uniqueRateAppPoc)
          : {};
        const uniqueRateAppVehicleInput = draft.uniqueRateAppVehicle
          ? draft.uniqueRateAppVehicle.map((v) => omit(['__typename', 'customerId'], v))
          : [];
        uniqueRateAppData.variables = {
          id: draft.id,
          uniqueRateAppInput: {
            mileageRate: mileageRateDraft,
            mileageType: mileageTypeDraft,
            reason: reasonDraft,
            justification: justificationDraft,
            monthlyRateType: monthlyRateTypeDraft,
            appType: APP_TYPE.APPLY,
            status: draft.status,
            comment: draft.comment,
          },
          uniqueRateAppFcsInput,
          uniqueRateAppPocInput,
          uniqueRateAppVehicleInput,
          // supportingDocument: getInputs(draft).supportingDocument,
        };
      } else {
        // Creating brand new draft
        uniqueRateAppData.variables = {
          id: '',
          uniqueRateAppInput: {
            mileageRate: mileageRateDraft,
            mileageType: mileageTypeDraft || '',
            reason: reasonDraft,
            appType: APP_TYPE.APPLY,
            status: 'Draft',
            justification: justificationDraft,
            comment: '',
            monthlyRateType: monthlyRateTypeDraft,
          },
          uniqueRateAppPocInput: {},
          uniqueRateAppFcsInput: {},
          uniqueRateAppVehicleInput: [],
          // supportingDocument: {},
        };
      }

      onSaveDraft(uniqueRateAppData);
    };

    // handle "Save and continue" - submit after validate required fields
    const onNextClick = async () => {
      if (await trigger()) {
        setDidClickNext(true);
        onSubmit();
      }
    };

    // handle "Save as draft"
    useImperativeHandle(ref, () => ({
      onSaveDraftClick() {
        onSubmit();
      },
    }));

    const onMileageRateChange = (value) => {
      if (value === 'Y') {
        setIsModifiedMileageRate(true);
        setValue('isModifiedMileageRate', true);
      }

      if (value === 'N') {
        setIsModifiedMileageRate(false);
        setValue('isModifiedMileageRate', false);
      }

      clearErrors();
      setReasonOptions([]);
      setValue('mileageType', '');
      setValue('reason', '');
      setValue('justification', '');
      setValue('monthlyRateType', '');
    };

    const onSelectChange = (name, value) => {
      switch (name) {
        case 'mileageType':
        case 'reason':
          if (value === '') {
            setError(name, {
              message: 'Please select a option',
            });
          } else {
            clearErrors(name);
          }
          break;

        default:
          break;
      }

      setValue(name, value || null);
    };

    const onTypeChange = (e) => {
      const { value } = e.target;
      const reasons = GET_REASON_OPTIONS(value);

      if (value === 'Non-default assignment') {
        setValue('reason', 'Consolidation replacements');
      }

      if (value === 'Manual entry') {
        setValue('reason', 'User agency agreement');
      }

      setReasonOptions(reasons);
    };

    const onJustificationChange = (e) => {
      const { value } = e.target;

      setJustificationCount(200 - value.length);
    };

    return (
      <div>
        <form id="unique-rate-app-form">
          <div className="form-ra">
            <div className="grid-container">
              <div className="grid-row margin-bottom-5">
                <div className="grid-col-8">
                  <Label required>Do vehicles need to be on a modified mileage rate?</Label>
                  <div className={`usa-form-group ${errors.mileageRate && 'border-left-1 border-red padding-left-2'}`}>
                    <Controller
                      control={control}
                      name="mileageRate"
                      render={({ name, value, onChange }) => {
                        if (isReadOnly)
                          return (
                            <div>
                              <div className="margin-top-2">{value === 'Y' ? 'Yes' : 'No'}</div>
                            </div>
                          );
                        return (
                          <>
                            <div className="mileage-rate">
                              <RadioButton
                                id="mileage-rate-yes"
                                name={name}
                                value="Y"
                                label="Yes"
                                checked={value === 'Y'}
                                onChange={(e) => {
                                  onChange(e);
                                  onMileageRateChange('Y');
                                }}
                              />
                              <RadioButton
                                id="mileage-rate-no"
                                name={name}
                                value="N"
                                label="No"
                                checked={value === 'N'}
                                onChange={(e) => {
                                  onChange(e);
                                  onMileageRateChange('N');
                                }}
                              />
                            </div>
                            {createErrorMessage(errors.mileageRate)}
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {isModifiedMileageRate ? (
                <>
                  <div className="grid-row bm-form-row margin-bottom-2">
                    <div className="grid-col-6">
                      <Controller
                        control={control}
                        name="mileageType"
                        render={({ name, value }) => {
                          if (isReadOnly)
                            return (
                              <div>
                                <Label required>Modified mileage rate type</Label>
                                <div className="margin-top-2">{value}</div>
                              </div>
                            );
                          return (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="mileage-type" required>
                                Select applicable modified mileage rate type
                              </Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                required
                                id="mileage-type"
                                className={classNameErrInput(errors[name])}
                                name={name}
                                value={value}
                                options={MILEAGE_TYPE_OPTIONS}
                                onChangeFunc={(e) => {
                                  onSelectChange(name, e.target.value);
                                  onTypeChange(e);
                                }}
                                placeholder="-Select-"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="grid-col-6">
                      <Controller
                        control={control}
                        name="reason"
                        render={({ name, value }) => {
                          if (isReadOnly)
                            return (
                              <div>
                                <Label required>Reason</Label>
                                <div className="margin-top-2">{value}</div>
                              </div>
                            );
                          return (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="reason" required>
                                Select applicable reason
                              </Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                required
                                id="reason"
                                className={classNameErrInput(errors[name])}
                                name={name}
                                value={value}
                                options={reasonOptions}
                                onChangeFunc={
                                  (e) => onSelectChange(name, e.target.value) //eslint-disable-line
                                }
                                placeholder="-Select-"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="grid-row margin-bottom-4">
                    <div className="grid-col-12 monthly-rate-options">
                      <Controller
                        control={control}
                        name="monthlyRateType"
                        render={({ name, value }) => {
                          if (isReadOnly)
                            return (
                              <div>
                                <Label>Additional unique rate</Label>
                                <div className="margin-top-2">{value || 'N/A'}</div>
                              </div>
                            );
                          return (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="monthly-rate">
                                Select from menu below only if an additional unique rate is needed
                              </Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                id="monthly-rate"
                                className={classNameErrInput(errors[name])}
                                name={name}
                                options={MONTHLY_RATE_OPTIONS}
                                value={value}
                                onChangeFunc={
                                  (e) => onSelectChange(name, e.target.value) //eslint-disable-line
                                }
                                placeholder="-Select-"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="grid-row bm-form-row margin-bottom-2">
                    <div className="grid-col-12 monthly-rate-options">
                      <Controller
                        control={control}
                        name="monthlyRateType"
                        render={({ name, value, onChange }) => {
                          if (isReadOnly)
                            return (
                              <div>
                                <Label>Unique rate type</Label>
                                <div className="margin-top-2">{value || 'N/A'}</div>
                              </div>
                            );
                          return (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="monthly-rate">Select applicable unique rate type</Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                id="monthly-rate"
                                className={classNameErrInput(errors[name])}
                                name={name}
                                options={UNIQUE_RATE_OPTIONS}
                                value={value}
                                onChangeFunc={(e) => {
                                  onChange(e);
                                  onTypeChange(e);
                                }}
                                placeholder="-Select-"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid-row margin-bottom-4">
                    <div className="grid-col-6">
                      <Controller
                        control={control}
                        name="reason"
                        render={({ name, value, onChange }) => {
                          if (isReadOnly)
                            return (
                              <div>
                                <Label>Reason</Label>
                                <div className="margin-top-2">{value || 'N/A'}</div>
                              </div>
                            );
                          return (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="reason">Reason</Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                id="reason"
                                className={classNameErrInput(errors[name])}
                                name={name}
                                value={value}
                                options={reasonOptions}
                                placeholder="-Select-"
                                onChangeFunc={onChange}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="grid-row margin-bottom-4">
                <div className="grid-col-12 justification">
                  <Controller
                    control={control}
                    name="justification"
                    render={({ name, value, onChange, onBlur }) => {
                      if (isReadOnly)
                        return (
                          <div>
                            <Label required>Justification</Label>
                            <div className="margin-top-2">{value}</div>
                          </div>
                        );
                      return (
                        <div className={classNameErrBox(errors[name])}>
                          <Label htmlFor="justification" required>
                            Enter justification
                          </Label>
                          {createErrorMessage(errors[name])}
                          <Textbox
                            required
                            id="justification"
                            className={classNameErrInput(errors[name])}
                            name={name}
                            value={value}
                            maxLength={200}
                            placeholder="Type your justification here"
                            type="textarea"
                            onChange={(e) => {
                              onChange(e);
                              onJustificationChange(e);
                            }}
                            onBlur={onBlur}
                          />
                          <div className="usa-hint usa-character-count__message">
                            {justificationCount < 200
                              ? `${justificationCount} characters remaining`
                              : '200 characters allowed'}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="ura-upload-moa grid-row">
                <Controller
                  control={control}
                  name="supportingDocument"
                  render={({ name, value }) => {
                    if (isReadOnly)
                      return (
                        <div>
                          <Label>Signed MOA document</Label>
                          <div className="margin-top-2">
                            {draft?.supportingDocument && supportingDocURL ? (
                              <div className="margin-top-2">
                                <a href={supportingDocURL} target="_blank" rel="noreferrer">
                                  {draft.supportingDocument.metadata.name}
                                </a>{' '}
                                - {(draft.supportingDocument.metadata.size / 1024).toFixed(0)} KB
                              </div>
                            ) : (
                              '--'
                            )}
                          </div>
                        </div>
                      );
                    return (
                      <div className="width-full">
                        {showCopyWarning && (
                          <div className="margin-top-3" style={{ marginBottom: -8 }}>
                            <BannerMessage type="warning" onClose={() => setShowCopyWarning(false)}>
                              Is this attachment applicable for the current request? If not, please remove attachment
                              and upload a different one to proceed.
                            </BannerMessage>
                          </div>
                        )}
                        <FileUpload
                          required
                          label="Upload signed MOA document"
                          acceptableFiles={[
                            'application/pdf',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          ]}
                          acceptableFilesLabel="Accepts .pdf or .docx files"
                          fileSizeLimit={10} // MB
                          onChange={(file) => {
                            onSelectFile(file);
                            setValue(name, file?.name);
                          }}
                          defaultValue={value ? { name: value } : null}
                          errorMessage={errors.supportingDocument?.message}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="unique-rates-app-btns">
          {isReadOnly ? (
            <Button variant="primary" onClick={onNext} label="Next" rightIcon={{ name: 'arrow_forward' }} />
          ) : (
            <Button
              variant="primary"
              disabled={loading}
              onClick={onNextClick}
              label="Save and continue"
              rightIcon={{ name: 'arrow_forward' }}
            />
          )}
        </div>
      </div>
    );
  },
);

UniqueRateAppFormStepOne.defaultProps = {
  draft: null,
  onNext: undefined,
  isCopy: false,
};

UniqueRateAppFormStepOne.propTypes = {
  draft: PropTypes.shape({
    id: PropTypes.string,
    appType: PropTypes.string,
    mileageRate: PropTypes.string,
    mileageType: PropTypes.string,
    reason: PropTypes.string,
    justification: PropTypes.string,
    monthlyRateType: PropTypes.string,
    status: PropTypes.string,
    comment: PropTypes.string,
    uniqueRateAppFcs: PropTypes.shape(),
    uniqueRateAppPoc: PropTypes.shape(),
    uniqueRateAppVehicle: PropTypes.arrayOf(PropTypes.shape()),
    supportingDocument: PropTypes.shape(),
  }),
  onNext: PropTypes.func,
  setDidClickNext: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isCopy: PropTypes.bool,
};

export default UniqueRateAppFormStepOne;
