import React from 'react';
import PropTypes from 'prop-types';
import { EmptyState } from '@gsa/afp-component-library';

const NoDataComponent = ({ title, text }) => {
  return (
    <div data-testid="no-data-component">
      <EmptyState
        hasBackground
        containerStyles="padding-y-4 margin-top-1"
        topText={title}
        topTextStyles="text-bold"
        bottomText={text}
      />
    </div>
  );
};

NoDataComponent.defaultProps = {
  title: 'This table is Empty',
  text: null,
};

NoDataComponent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default NoDataComponent;
