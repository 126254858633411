import React from 'react';
import AFPTable from 'widgets/afp-table-wrapper';
import { Pagination } from '@gsa/afp-component-library';
import { updateSort } from 'utilities/bmAppUtilities';
import { handlePaginationChange, getCategoryQueryVariables } from '../../helper';
import { columns as categoryTableColumns } from '../../model/category/schemas/categoryTableSchema';
import CategoryRowSubComponent from './CategoryRowSubComponent';

/* eslint-disable react/prop-types */
const CategoryTable = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  loading,
  openModal,
  fetchRows,
  isPaginationComponentReset,
  resetPaginationComponent,
}) => {
  const onSort = (orderStr) => {
    if (orderStr) {
      const order = updateSort({ originalSort: pageState.categoryFilter.order, orderStr });
      setDispatch(DISPATCH_FNS.updateStateByPath, {
        path: 'categoryFilter.order',
        data: order,
      });
      fetchRows({
        variables: getCategoryQueryVariables({ pageState, order }),
      });
    }
  };
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return <CategoryRowSubComponent row={row} />;
  }, []);
  return (
    <>
      <div id="optin-category-table">
        <AFPTable
          isLoading={loading.categoryTableLoading}
          columns={categoryTableColumns({ pageState, setDispatch, DISPATCH_FNS, openModal })}
          data={pageState.category_table?.rows}
          NoDataMessages={{
            title: 'No category Available',
            text: 'There are no matches for the filtered values at left.',
          }}
          expandable
          onSort={onSort}
          defaultSort={pageState.categoryFilter.order}
          renderRowSubComponent={renderRowSubComponent}
          fullWidth
          className="category-table"
        />
        <div className="padding-y-4">
          <Pagination
            onPageChange={handlePaginationChange({
              setDispatch,
              DISPATCH_FNS,
              pageState,
              resetPaginationComponent,
              fetchRows,
              filterName: 'categoryFilter',
            })}
            variant="advanced"
            currentPage={pageState.categoryFilter.currentPage}
            itemsCount={pageState.category_itemsCount}
            itemsPerPage={pageState.categoryFilter.limit}
            isReset={isPaginationComponentReset}
          />
        </div>
      </div>
    </>
  );
};

export default CategoryTable;
