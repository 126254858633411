import React from 'react';

import { Spinner } from '@gsa/afp-component-library';

const LoadingComponent = () => (
  <div data-testid="loading-component" style={{ position: 'absolute', width: '100%' }}>
    <Spinner />
  </div>
);

export default LoadingComponent;
