import React from 'react';
import { labelText } from 'utilities/formatUtils';
import NumberFormat from 'react-number-format';
import _ from 'lodash/fp';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { Label, Icon, Textbox, ErrorMessage as AfpErrorMessage } from '@gsa/afp-component-library';

import { classNameErrBox, classNameErrInput } from 'components/helpers/afp-bm-helpers';

/* eslint-disable react/prop-types */

const RateInputComponent = ({
  path,
  // className = 'grid-col-2 ',
  thousandSeparator = true,
  allowNegative = true,
  decimalScale = 2,
  dispatchFn,
  errors,
  name,
  setDispatch = () => null,
  readOnly,
  value,
  afterOnchangeFn = () => null,
  required,
  labelNameFn,
  isPercentage,
  register,
}) => {
  const slugId = _.replace('.', '-', path);
  return (
    <div className={`usa-form-group bm-form-row-input ${classNameErrBox(_.get(name, errors))}`}>
      <Label required={required}>{labelText(labelNameFn ? labelNameFn(name) : slugId)}</Label>
      {errors && (
        <FormErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <AfpErrorMessage>{message}</AfpErrorMessage>}
        />
      )}
      {isPercentage ? null : (
        <Icon
          iconName="attach_money"
          className={`bm-prefix-icon-dollar ${_.get(name, errors) && 'bm-prefix-icon-dollar-error'}`}
        />
      )}

      <NumberFormat
        id={slugId}
        className={`text-right ${classNameErrInput(_.get(name, errors))}`}
        aria-label={slugId}
        name={name}
        value={value}
        customInput={Textbox}
        onValueChange={(val) => {
          setDispatch(dispatchFn, {
            path,
            data: val.value,
          });

          afterOnchangeFn(val.value);
        }}
        type="text"
        readOnly={readOnly ? 'readonly' : null}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        suffix={isPercentage ? '%' : ''}
        inputRef={register}
      />
    </div>
  );
};
export default RateInputComponent;
