import moment from 'moment-timezone';
import { getTodayDate, getUTCFYInt, getFYUTCDateRange } from 'components/helpers/afp-bm-date';

const EFFECTIVE_DATE_OFFSET = 0;

export const CalcProjectToCollect = ({
  amountCollected,
  numVehicles,
  surcharges,
  newSurcharge = null, // hypothetical surcharge from calculator
  numMon = 0, // hyperthecial months remained from calculator
}) => {
  if (!numVehicles || !surcharges) return null;
  const collection = parseFloat(amountCollected) || 0.0;

  const today = getTodayDate(); // for testing: const today = moment('2020-10-15');
  const { endDate: fyEndDate } = getFYUTCDateRange(getUTCFYInt());
  const rates = [];
  let mon = moment(today).utc().startOf('month');
  while (mon.toISOString() < fyEndDate) {
    const dateStr = moment(mon).add(EFFECTIVE_DATE_OFFSET, 'days').toISOString();
    const surcharge = surcharges.find((sur) => sur.startDate <= dateStr && (!sur.endDate || sur.endDate >= dateStr));
    rates.push(parseFloat(surcharge?.surcharge) || 0.0);
    mon = mon.add(1, 'months');
  }

  if (newSurcharge !== null) {
    const len = rates.length;
    for (let j = 1; j <= numMon && j <= len; j += 1) {
      rates[len - j] = parseFloat(newSurcharge);
    }
  }
  return collection + rates.reduce((a, b) => a + b, 0.0) * numVehicles;
};

export const CalcSurchargeToFYEnd = ({
  amountCollected,
  numVehicles,
  surcharges,
  projectToCollect, // hypothetical project-to-collect amount from calculator
  numMon, // hyperthecial months remained from calculator
}) => {
  if (Number.isNaN(numMon) || numMon <= 0) return undefined;

  const collected = CalcProjectToCollect({
    amountCollected,
    numVehicles,
    surcharges,
    newSurcharge: 0.0,
    numMon,
  });
  return (parseFloat(projectToCollect) - collected) / numMon / numVehicles;
};
