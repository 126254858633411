import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SelectBox,
  ErrorMessage,
  Textbox,
  Button,
  DetailsTable,
  Spinner,
  Label,
  PageTitle,
} from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import RateStatusBlock from 'components/widgets/rate-status-block';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import WelcomeMessage from 'widgets/welcome-message';
import AfpTable from 'widgets/afp-table-wrapper';
import {
  formatAddress,
  formatName,
  formatPhoneNumber,
  classNameErrBox,
  classNameErrInput,
} from 'components/helpers/afp-bm-helpers';
import {
  APP_STATUS,
  STATUS_BLOCK_TYPE,
  UNIQUE_RATE_REVIEW_OPTIONS,
  UPDATE_UNIQUE_RATE_APP_FOR_REVIEW,
  GET_CUSTOMER_VEHICLES_FROM_VMS,
  GET_AWS_SIGNED_READ_URL,
} from './unique-rate-app-helper';
import './style/unique-rate-app-review.scss';

// Form validation schema
const schema = yup.object().shape({
  decision: yup.string().required('Please select an action'),
  comment: yup.string().when('isCommentRequired', {
    is: true,
    then: yup.string().min(1, 'Please enter a comment').required('Please enter a comment'),
  }),
});

const UniqueRateAppReview = () => {
  const location = useLocation();
  const history = useHistory();
  if (!location.state) history.replace('/bm/unique-rates-app');

  const viewOnly = !location.pathname.match(/review/);
  const pageTitle = viewOnly ? 'Request Summary' : 'Review Request';
  useTitle(pageTitle);

  const [rateSummaryDetailsData, setRateSummaryDetailsData] = useState([]);
  const [customerInfoDetailsData, setCustomerInfoDetailsData] = useState([]);
  const [fleetCardDetailsData, setFleetCardDetailsData] = useState([]);
  const [reviewData, setReviewData] = useState({});
  const [isCommentRequired, setIsCommentRequired] = useState(false);
  const [bannerMsg, setBannerMsg] = useState(null);

  const bannerRef = useRef(null);

  // useForm declaration
  const { handleSubmit, getValues, setValue, errors, control, register, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      decision: '',
      comment: '',
    },
  });

  const onSetBannerMsg = (banner) => {
    setBannerMsg(banner);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // gql call to retrieve Read URL for uploaded supporting document
  const [getReadURL] = useLazyQuery(GET_AWS_SIGNED_READ_URL, {
    onCompleted: async (requestData) => {
      setRateSummaryDetailsData((prevVal) => {
        const newVal = [...prevVal];
        const [label, value] = newVal.pop();
        newVal.push([
          label,
          <a href={requestData?.generateReadSignedURL} target="_blank" rel="noreferrer">
            {value}
          </a>,
        ]);
        return newVal;
      });
    },
  });

  const [updateUniqueRateAppForReview, { loading }] = useMutation(UPDATE_UNIQUE_RATE_APP_FOR_REVIEW, {
    onCompleted: (resData) => {
      if (resData) {
        switch (resData.updateUniqueRateAppForReview.status) {
          case APP_STATUS.APPROVED:
            history.push({
              pathname: '/bm/unique-rates-app',
              state: {
                reviewStatus: {
                  status: APP_STATUS.APPROVED,
                  message: `You have successfully approved a unique rate for the selected vehicles for Customer Number ${reviewData?.uniqueRateAppPoc?.customerId}`,
                },
              },
            });
            break;

          case APP_STATUS.REJECTED:
            history.push({
              pathname: '/bm/unique-rates-app',
              state: {
                reviewStatus: {
                  status: APP_STATUS.REJECTED,
                  message: `You have successfully rejected a unique rate for the selected vehicles for Customer Number ${reviewData?.uniqueRateAppPoc?.customerId}`,
                },
              },
            });
            break;

          case APP_STATUS.REWORK:
            history.push({
              pathname: '/bm/unique-rates-app',
              state: {
                reviewStatus: {
                  status: APP_STATUS.REWORK,
                  message: `You have requested an update to the unique rate application request for the selected vehicles for Customer Number ${reviewData?.uniqueRateAppPoc?.customerId}`,
                },
              },
            });
            break;

          default:
            break;
        }
      }
    },
    onError: (err) =>
      onSetBannerMsg({
        type: 'error',
        message: (
          <div>
            Error occurred when updating this review request
            <br />
            {err.message}
          </div>
        ),
      }),
  });

  const [fetchCustomerVehicles, { data: customerVehicleData }] = useLazyQuery(GET_CUSTOMER_VEHICLES_FROM_VMS, {
    onError: () => {
      onSetBannerMsg({
        type: 'error',
        message: <div>Error occurred when retrieving information of the selected customer.</div>,
      });
    },
  });

  useEffect(() => {
    if (location.state) {
      register({ name: 'isCommentRequired' });

      const { data } = location.state;

      const rateSummaryDataPoints = [
        { label: 'Rate type', value: 'mileageType' },
        { label: 'Reason', value: 'reason' },
        { label: 'Billing method', value: 'billingMethod' },
        { label: 'Card type', value: 'cardType' },
        { label: 'Additional unique rate', value: 'monthlyRateType' },
        { label: 'MOA document', value: 'supportingDocument' },
      ];
      const rateSummaryData = rateSummaryDataPoints.map(({ label, value }) => {
        if (label === 'MOA document') return [label, data[value]?.metadata?.name || '--'];
        return [label, data[value] || ''];
      });

      const customerInfoDataPoints = [
        { label: 'Customer number', value: 'customerId' },
        { label: 'Agency', value: 'agencyCode' },
        { label: 'Bureau', value: 'bureauCode' },
        { label: 'Point of contact', value: 'poc' },
        { label: 'Phone', value: 'phonenumber' },
        { label: 'Email', value: 'email' },
      ];
      const customerInfoData = customerInfoDataPoints.map(({ label, value }) => {
        switch (label) {
          case 'Point of contact':
            return [label, formatName(data.uniqueRateAppPoc[value])];
          case 'Phone':
            return [label, formatPhoneNumber(data.uniqueRateAppPoc[value])];
          case 'Email':
            return [label, data.uniqueRateAppPoc[value]?.toLowerCase() || ''];
          default:
            return [label, data.uniqueRateAppPoc[value] || ''];
        }
      });

      const fleetCardData = [['Address', `${formatAddress(data.uniqueRateAppFcs)}`]];

      if (data.uniqueRateAppPoc.customerId) {
        fetchCustomerVehicles({
          variables: { customerId: data.uniqueRateAppPoc.customerId },
        });
      }

      if (data.supportingDocument)
        getReadURL({
          variables: {
            fileKey: data.supportingDocument.metadata.fileLocation,
            fileType: data.supportingDocument.metadata.fileMimeType,
          },
        });

      setValue('comment', data.comment);
      setValue('isCommentRequired', false);
      setRateSummaryDetailsData(rateSummaryData);
      setCustomerInfoDetailsData(customerInfoData);
      setFleetCardDetailsData(fleetCardData);
      setReviewData(data);
    }
  }, [location]);

  useEffect(() => {
    if (customerVehicleData) {
      if (customerVehicleData.getCustomer) {
        const { vehicles } = customerVehicleData.getCustomer;
        const vehicleList = reviewData?.uniqueRateAppVehicle.map((v1) => {
          const vehicle = vehicles.find((v2) => v2.id === v1.vin);
          if (vehicle) {
            return {
              ...v1,
              vehicleLeaseRateCode: vehicle.vehicleLeaseRateCode,
            };
          }

          return v1;
        });

        setReviewData({
          ...reviewData,
          uniqueRateAppVehicle: vehicleList,
        });
      }
    }
  }, [customerVehicleData]);

  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);

  const onSubmit = () => {
    const { decision, comment } = getValues();
    const { id } = reviewData;

    const uniqueRateAppReviewData = {
      variables: {
        uniqueRateAppReviewInput: {
          applicationId: id,
          status: decision,
          comment,
        },
      },
    };

    updateUniqueRateAppForReview(uniqueRateAppReviewData);
  };

  const onDecisionChange = (e) => {
    const {
      target: { value },
    } = e;

    if (!value || value === 'Approved') {
      setValue('isCommentRequired', false);
      setIsCommentRequired(false);
      clearErrors('comment');
    } else {
      setValue('isCommentRequired', true);
      setIsCommentRequired(true);
    }
  };

  const defaultVehicleColumns = [
    {
      Header: 'Plate number',
      accessor: 'plateNumber',
      Cell: ({ value }) => (value !== null ? value : '-'),
      sortable: true,
    },
    {
      Header: 'VIN',
      accessor: 'vin',
      Cell: ({ value }) => (value !== null ? value : '-'),
      sortable: true,
    },
    {
      Header: 'Current unique rate',
      accessor: 'vehicleLeaseRateCode',
      Cell: ({ value }) => (value !== null ? `Maintenance only ${value}` : '-'),
      sortable: true,
    },
  ];

  const getColumns = (monRateType) => {
    switch (monRateType) {
      case 'Non-default rate assignment':
      case 'Non-default assignment':
        return [
          ...defaultVehicleColumns,
          {
            Header: 'LRC',
            accessor: 'leaseRateCode',
            Cell: ({ value }) => (value !== null ? value : '-'),
            sortable: true,
          },
        ];
      case 'Manual entry':
        return [
          ...defaultVehicleColumns,
          {
            Header: 'Monthly rate',
            accessor: 'monthlyRate',
            Cell: ({ value }) => (value !== null ? value : '-'),
            sortable: true,
          },
          {
            Header: 'Mileage rate',
            accessor: 'mileageRate',
            Cell: ({ value }) => (value !== null ? value : '-'),
            sortable: true,
          },
        ];
      default:
        return defaultVehicleColumns;
    }
  };

  const { uniqueRateAppVehicle, justification, monthlyRateType, status } = reviewData;

  return (
    <div className="grid-col margin-bottom-8">
      {!reviewData ? (
        <div className="loading-spinner">
          <Spinner />
        </div>
      ) : (
        <>
          <Breadcrumbs
            current={pageTitle}
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
              {
                location: `${window.AFP_CONFIG.appURLs.bm}/bm/unique-rates-app`,
                label: 'Unique Rate Applications',
              },
            ]}
          />
          <div ref={bannerRef}>
            {bannerMsg && (
              <BannerMessage className="margin-top-2" type={bannerMsg.type} onClose={() => setBannerMsg(null)}>
                {bannerMsg.message}
              </BannerMessage>
            )}
          </div>
          <div className="title-status">
            <PageTitle title={pageTitle} />
            <div>
              <RateStatusBlock status={STATUS_BLOCK_TYPE[status]}>{status}</RateStatusBlock>
            </div>
          </div>
          {!viewOnly && (
            <WelcomeMessage
              className="review-request-info"
              message="Review the below request and select approve, reject, or request modification as necessary."
            />
          )}
          <form onSubmit={handleSubmit(onSubmit)} id="unique-rate-app-review">
            <div className="form-ra">
              <div className="grid-container padding-0">
                {!viewOnly && (
                  <>
                    <div className="grid-row margin-bottom-2">
                      <div className="grid-col-6">
                        <Controller
                          control={control}
                          name="decision"
                          render={({ name, value, onChange, onBlur }) => (
                            <div className={`usa-form-group bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="decision" required>
                                Select action
                              </Label>
                              {createErrorMessage(errors[name])}
                              <SelectBox
                                id="decision"
                                aria-label="Select an action, required"
                                name={name}
                                className={classNameErrInput(errors[name])}
                                value={value}
                                options={UNIQUE_RATE_REVIEW_OPTIONS}
                                onChangeFunc={(e) => {
                                  onChange(e);
                                  onDecisionChange(e);
                                }}
                                onBlur={onBlur}
                                placeholder="-Select-"
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid-row margin-bottom-4">
                      <div className="grid-col-8">
                        <Controller
                          control={control}
                          name="comment"
                          render={({ name, value, onChange, onBlur }) => (
                            <div className={`bm-form-row-input ${classNameErrBox(errors[name])}`}>
                              <Label htmlFor="comments" required={isCommentRequired}>
                                Review comments
                              </Label>
                              {createErrorMessage(errors[name])}
                              <Textbox
                                aria-label="Review comments, required for reject or request modification"
                                id="comments"
                                name={name}
                                className={classNameErrInput(errors[name])}
                                value={value}
                                type="textarea"
                                onChange={onChange}
                                onBlur={onBlur}
                                data-testid="comments"
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
                {viewOnly && reviewData?.comment && (
                  <div className="grid-row margin-bottom-5 grid-gap">
                    <div className="grid-col-12">
                      <h3 className="info-title">REVIEW COMMENTS</h3>
                      <div className="info-details">
                        <p className="review-comment">{reviewData.comment}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="grid-row margin-bottom-5 grid-gap">
                  <div className="grid-col-6 info-box">
                    <h3 className="info-title">RATE SUMMARY</h3>
                    <div className="info-details">
                      <DetailsTable bordered data={rateSummaryDetailsData} />
                    </div>
                  </div>
                  <div className="grid-col-6 info-box">
                    <h3 className="info-title">CUSTOMER INFORMATION</h3>
                    <div className="info-details customer-info">
                      <DetailsTable bordered data={customerInfoDetailsData} />
                    </div>
                  </div>
                </div>
                <div className="grid-row margin-bottom-5 grid-gap">
                  <div className="grid-col-6 info-box">
                    <h3 className="info-title">JUSTIFICATION</h3>
                    <div className="info-details">
                      <p className="justification-details">{justification}</p>
                    </div>
                  </div>
                  <div className="grid-col-6 info-box">
                    <h3 className="info-title">FLEET CARD SHIPPING ADDRESS</h3>
                    <div className="info-details fleet-card-details">
                      <DetailsTable bordered data={fleetCardDetailsData} />
                    </div>
                  </div>
                </div>

                <div className="grid-row grid-gap">
                  <div className="grid-col-12">
                    <h3 className="info-title">VEHICLE INFORMATION</h3>
                    <h4>Total vehicles: {uniqueRateAppVehicle?.length}</h4>
                    <div className="vehicle-details-table">
                      <AfpTable
                        isLoading={false}
                        columns={getColumns(monthlyRateType)}
                        data={uniqueRateAppVehicle || []}
                        NoDataMessages={{
                          title: 'No Vehicles Available',
                          text: ' There are no vehicles added to this request.',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!viewOnly && (
              <div className="review-action-btns margin-top-5 margin-bottom-5">
                <Button variant="unstyled" onClick={() => history.push('/bm/unique-rates-app')} label="Cancel" />
                <Button type="submit" form="unique-rate-app-review" disabled={loading} label="Save" />
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default UniqueRateAppReview;
