import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { STATUS } from 'components/helpers/constants';
import { UTCDateStrToUS } from 'components/helpers/afp-bm-date';
import { DELETE_AFV_FUNDING } from '../afv-funding-helper';

const DeleteSurchargeModal = ({ data: { id, status, startDate }, onClose, setBannerMsg, refetchSurcharges }) => {
  const startDateStr = UTCDateStrToUS(startDate);
  // gql APIs
  const getOnCompletedFn = (verb) => () => {
    const message = (
      <>
        You have successfully {verb} {status.toLowerCase()} surcharge for {startDateStr}.
      </>
    );
    setBannerMsg({ type: 'success', message });
    refetchSurcharges();
    onClose();
  };
  const getOnErrorFn = (verb) => (err) => {
    const message = (
      <>
        Error occurred when {verb} {status.toLowerCase()} surcharge for {startDateStr}.
        <br />
        {err.message}
      </>
    );
    setBannerMsg({ type: 'error', message });
    onClose();
  };
  const [deleteSurcharge, { loading: deleting }] = useMutation(DELETE_AFV_FUNDING, {
    onCompleted: getOnCompletedFn('deleted'),
    onError: getOnErrorFn('deleting'),
  });

  const VARIATION = {
    [STATUS.PENDING]: {
      title: 'Delete an AFV Surcharge',
      instruction: `By selecting the delete option below, you will be permanently deleting the pending surcharge for ${startDateStr}.`,
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deleteSurcharge,
    },
    [STATUS.RECOMMENDED]: {
      title: 'Delete an AFV Surcharge',
      instruction: `By selecting the delete option below, you will be permanently deleting the recommended surcharge for ${startDateStr}.`,
      buttonText: 'Delete',
      isReasonRequired: false,
      gqlAPI: deleteSurcharge,
    },
  };

  const onSubmit = () => {
    VARIATION[status].gqlAPI({ variables: { id } });
  };

  // unknown rate status
  if (!VARIATION[status]) {
    onClose();
    return null;
  }

  return (
    <DeleteModal
      id="afv-delete-surcharge-modal"
      title={VARIATION[status].title}
      onClose={onClose}
      onDelete={onSubmit}
      deleting={deleting}
      deleteButtonText={VARIATION[status].buttonText}
    >
      <div className="margin-top-4 margin-bottom-4">{VARIATION[status].instruction}</div>
    </DeleteModal>
  );
};

DeleteSurchargeModal.defaultProps = {
  refetchSurcharges: undefined,
};
DeleteSurchargeModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  setBannerMsg: PropTypes.func.isRequired,
  refetchSurcharges: PropTypes.func,
};

export default DeleteSurchargeModal;
