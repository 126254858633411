import React, { useState, useEffect, useMemo } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import moment from 'moment';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Menu,
  TextInput,
  useModal,
  Icon,
  Button,
} from '@gsa/afp-component-library';
import { get } from 'lodash';
import { utils, writeFile } from 'xlsx';
import { ENTITY, ROLE_OP } from '../../components/role-permission/role-permission';
import { useWalletDetails } from './wallet-details-provider';
import WalletIpacVehiclesModal from './wallet-ipac-vehicles-modal';
import IpacUploadModal from './components/ipac-upload-modal';
import ProcessImport from './components/process-import';
import useIpacDataProcessor from './hooks/use-ipac-data-processor';
import { useImport } from './state/hook';
import { alertMap } from './helpers/variable-maps';
import { ipacReportConfig } from './forms/consts';
import exportFile from '../../components/file-export/client-side-file-export';

const PRIMARY_ORDER = ['updatedAt', 'DESC'];
const SECONDARY_ORDER = ['tagNumber', 'DESC'];
const DEFAULT_ORDER = [PRIMARY_ORDER, SECONDARY_ORDER];

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    action: 'editWalletVehicle',
    operation: ROLE_OP.UPDATE,
  },
  {
    icon: 'delete',
    label: 'Delete',
    action: 'deleteWalletVehicle',
    operation: ROLE_OP.UPDATE,
    canShowIndicator: 'walletAssetId',
  },
];

const initialFilters = {
  operator: 'AND',
  value: [],
};

const WalletIpacVehiclesTable = () => {
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });
  const [filters, setFilters] = useState(initialFilters);
  const [modalMode, setModalMode] = useState();
  const [tag, setTag] = useState('');
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [fileState, setFileState] = useState();
  const [fileImportErrorState, setFileImportErrorState] = useState();
  const { importedData, setMappedData, setProcessedResults, processedResponse, reset } = useImport();
  const {
    exportIpacTemplate,
    exportIpacTemplateLoading,
    getIpacVehiclesList,
    ipacVehiclesLoading,
    ipacVehicles,
    updatedIpacVehicle,
    boacOfferingAssociation,
    wallet,
    ipacExport,
    dispatchAction,
  } = useWalletDetails();
  const ability = useAppAbility();

  const { isOpen, openModal, closeModal } = useModal();
  const { isOpen: isIpacOpen, openModal: openIpacModal, closeModal: closeIpacModal } = useModal();
  const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const getData = (isTemplateExport = false) => {
    const dataPayload = {
      variables: {
        offset: filters?.tag?.value ? 0 : paginationState.offset,
        order,
        salesGroup: wallet?.walletSalesGroupModel?.id || boacOfferingAssociation?.walletSalesGroupModel?.id,
        filters: {
          operator: 'AND',
          value: [
            {
              value: wallet?.boacModel?.boac || boacOfferingAssociation?.boacModel?.boac,
              operator: 'EQ',
              key: '$boac$',
            },
            {
              operator: 'OR',
              value: [
                {
                  value: wallet?.fiscalYear || boacOfferingAssociation?.fiscalYear,
                  operator: 'EQ',
                  key: '$fiscal_year$',
                },
                {
                  value: null,
                  operator: 'EQ',
                  key: '$fiscal_year$',
                },
              ],
            },
            {
              ...(filters?.tag?.value && {
                value: `G${filters?.tag?.value}`,
                operator: 'EQ',
                key: '$tag_number$',
              }),
            },
          ],
        },
      },
    };

    if (isTemplateExport) {
      dataPayload.variables.walletBoacSalesGroupId = wallet?.id;
      dataPayload.variables.limit = 25000;
      exportIpacTemplate(dataPayload);
    } else {
      dataPayload.variables.limit = paginationState.limit;
      getIpacVehiclesList(dataPayload);
    }
  };

  const createErrorFile = async () => {
    const fileName = `${ipacReportConfig.errorFileName}_${moment().utcOffset('-06:00').format('MMDDYYYY_HHMMSS')}.xlsx`;
    const worksheet = utils.json_to_sheet(processedResponse);
    worksheet['!cols'] = ipacReportConfig.errorSheetColumnWidths;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, ipacReportConfig.errorSheetName);
    utils.sheet_add_aoa(worksheet, [ipacReportConfig.errorSheetHeaders]);
    writeFile(workbook, fileName);
  };

  const fetchAndDownloadExport = async () => {
    const response = await fetch(ipacExport, {
      method: 'GET',
      headers: { 'Content-Type': excelMimeType },
    });
    // Grab blob from response
    const blob = await response.blob();
    exportFile(blob, excelMimeType, 'vehicle_ipac_template.xlsx');
  };

  useEffect(() => {
    if (ipacExport) {
      fetchAndDownloadExport();
    }
  }, [ipacExport]);
  useEffect(() => {
    if (!filters?.tag?.error) {
      getData();
    }
  }, [paginationState, order, filters, updatedIpacVehicle]);

  useEffect(() => {
    if (processedResponse?.length > 0) {
      setMappedData([]);
      setProcessedResults([]);
      if (processedResponse?.some((resp) => resp?.status === 'Error')) {
        const errorCount = processedResponse?.filter((resp) => resp?.status === 'Error')?.length;
        const totalCount = processedResponse?.length;
        const processedRecords = totalCount > 1 ? `${totalCount} records` : `${totalCount} record`;
        const errorRecords = errorCount > 1 ? `${errorCount} records` : `${errorCount} record`;

        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'error',
          message: alertMap.validationError.getContent(createErrorFile, processedRecords, errorRecords),
        });
      } else {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: alertMap.success.getContent(`IPAC Import`),
        });
      }
      getData();
    }
    return () => reset();
  }, [processedResponse]);

  const handleSelectedAction = (action, row) => {
    switch (action) {
      case 'editWalletVehicle':
        setSelectedVehicle(row);
        openModal();
        break;
      case 'deleteWalletVehicle':
        setSelectedVehicle(row);
        setModalMode('delete');
        openModal();
        break;
      default:
        break;
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const allowedActions = actionList.filter((action) => {
    return ability.can(action.operation, ENTITY.WALLET);
  });

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'License plate',
        accessor: 'tagNumber',
        sortable: true,
      },
      {
        Header: 'Standard Doc Number (SDN)',
        accessor: 'standardDocumentNumber',
        sortable: true,
      },
      {
        Header: 'BCN OBAN ASN',
        accessor: 'bcnObanAsn',
        sortable: true,
      },
      {
        Header: 'Limit',
        accessor: 'limit',
        sortable: true,
      },
      {
        Header: 'Supplemental Accounting Information',
        accessor: 'accountInformation',
        sortable: true,
      },
      {
        Header: 'Last updated date',
        accessor: 'updatedAt',
        sortable: true,
        Cell: ({ value }) => {
          return value ? moment(value).format('MM/DD/YYYY') : null;
        },
      },
      {
        Header: 'Last updated by',
        accessor: 'updatedByUser',
        sortable: true,
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          const actions = allowedActions.filter((a) => {
            const canShow = get(a, 'canShowIndicator');
            // eslint-disable-next-line react/prop-types
            return !canShow || get(original, canShow, false);
          });
          if (actions?.length === 0) {
            return <>{'\u2014'}</>;
          }
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={actions}
                onActionClick={(action) => {
                  // eslint-disable-next-line
                  handleSelectedAction(action, original);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];
    return columnList;
  }, []);

  const tableRef = React.createRef();

  const onSort = (val) => {
    const res = val?.split(' ');
    if (!res?.length) return;
    const primaryOrder = [...res[0].split('`')[1].split('.'), res[1]];
    if (primaryOrder[0] === PRIMARY_ORDER[0]) setOrder([primaryOrder, SECONDARY_ORDER]);
    else setOrder([primaryOrder, PRIMARY_ORDER]);
  };

  const filterByTag = () => {
    let tagValue = tag;
    const maxTagNumberLength = 7;
    if (tagValue?.[0] === 'G' || tagValue?.[0] === 'g') {
      tagValue = tagValue.substring(1);
      setTag(tagValue);
    }
    if (tagValue && tagValue.length !== maxTagNumberLength) {
      setFilters({
        tag: {
          error: true,
          message: `Must be ${maxTagNumberLength} characters.`,
        },
      });
    } else {
      setFilters({
        tag: {
          error: false,
          message: '',
          value: tagValue,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      filterByTag();
    }
  };

  const clearFilter = () => {
    setTag('');
    setFilters(initialFilters);
  };

  const downloadTemplate = () => {
    getData(true);
  };

  return (
    <>
      <div className="grid-row flex-align-end">
        <div className="grid-col-3">
          <TextInput
            type="text"
            prefix="G"
            maxLength="8"
            inputClass="text-uppercase width-full"
            label={<span className="text-bold">License plate</span>}
            help="No dash or space required"
            name="tagNumber"
            id="tagNumber"
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
            onChange={(e) => {
              setTag(e.target.value);
            }}
            value={tag}
            suffix={
              tag.length > 0 ? (
                <button
                  type="button"
                  onClick={clearFilter}
                  className="display-flex usa-button usa-button--unstyled filter-clear-button"
                  aria-label="clear-search"
                >
                  <Icon iconName="close" className="height-2" />
                </button>
              ) : (
                ''
              )
            }
            errorMessage={filters?.tag?.error ? filters?.tag?.message : null}
            aria-invalid={filters?.tag?.error ? 'true' : 'false'}
          />
        </div>
        <div className="grid-col-1">
          <Button
            variant="primary"
            className="margin-left-2"
            data-testid="filter-ipac-by-tag"
            onClick={() => {
              filterByTag();
            }}
            label="Search"
          />
        </div>
        <div className="grid-col-3 display-flex">
          {importedData?.length > 0 && <ProcessImport useDataProcessor={useIpacDataProcessor} />}
        </div>
        <div className="grid-col-5 display-flex">
          <div className="margin-left-auto display-flex">
            {exportIpacTemplateLoading ? <Spinner className="margin-right-1" /> : null}
            <Button
              variant="outline"
              label={exportIpacTemplateLoading ? 'Downloading Template' : 'Download Template'}
              disabled={exportIpacTemplateLoading}
              data-testid="bulk-update-download-temp"
              onClick={downloadTemplate}
            />
          </div>
          <Button
            variant="primary"
            data-testid="bulk-update-ipac"
            onClick={() => {
              openIpacModal();
            }}
            label="Bulk update vehicle data"
          />
        </div>
      </div>
      <div className="grid-row">
        <AFPTable
          fullWidth
          ref={tableRef}
          testId="wallet-listing-table"
          columns={columns}
          data={ipacVehiclesLoading ? [] : ipacVehicles?.rows || []}
          defaultSort={order}
          onSort={onSort}
        />
        {ipacVehiclesLoading && (
          <div className="width-full">
            <div data-testid="wallet-vehicles-no-data">
              <Spinner className="padding-y-9" />
            </div>
          </div>
        )}
        {ipacVehicles?.rows?.length > 0 && (
          <div className="width-full">
            <Pagination
              fullWidth
              variant="advanced"
              itemsPerPageOptions={[10, 25, 50]}
              itemsCount={ipacVehicles.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={handlePaginationChange}
              isReset={paginationState.isReset}
            />
          </div>
        )}
        {(!ipacVehicles || ipacVehicles.rows.length === 0) && !ipacVehiclesLoading && (
          <div className="width-full">
            <div className="bg-gray-3 padding-y-5">
              <div className="text-center padding-y-4">
                <EmptyState alt="Image not available" hasBackground />
              </div>
              <div className="text-center text-bold" data-testid="wallet-vehicles-no-data">
                No data available
              </div>
            </div>
          </div>
        )}
        <WalletIpacVehiclesModal isOpen={isOpen} closeModal={closeModal} asset={selectedVehicle} mode={modalMode} />
        <IpacUploadModal
          isOpen={isIpacOpen}
          closeModal={closeIpacModal}
          fileState={fileState}
          setFileState={setFileState}
          fileImportErrorState={fileImportErrorState}
          setFileImportErrorState={setFileImportErrorState}
        />
      </div>
    </>
  );
};

export default WalletIpacVehiclesTable;
