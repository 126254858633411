import React from 'react';
import PropTypes from 'prop-types';
import { DefaultLayout } from '@gsa/afp-component-library';
import { useCurrentUser, useNavigationMenu } from '@gsa/afp-shared-ui-utils';

const Layout = ({ children }) => {
  const { currentUser, isLoggedIn } = useCurrentUser();

  const menu = useNavigationMenu();

  return (
    <DefaultLayout currentUser={isLoggedIn && currentUser} menu={menu}>
      {children}
    </DefaultLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
