import React from 'react';
import { Button } from '@gsa/afp-component-library';
import _ from 'lodash';
import { getActions } from '../../components/role-permission/row-action';
import { STATUS } from '../../components/helpers/constants';
import { ENTITY } from '../../components/role-permission/role-permission';

const flatFileTypes = ['Leasing DBRs', 'Purchasing DBRs', 'Rental DBRs', 'Claims', 'Income Accruals', 'Leasing/Rental revenue accrual', 'Third Party Claim'];
export const WEB_SERVICES = 'Web Services';
export const FLAT_FILES = 'Flat Files';
export const DEFAULT_FILTER_STATE = { errorDataStatus: [STATUS.ACTIVE] };
export const DEFAULT_FILTERS = {
  filters: { operator: 'AND', value: []},
  virtualFilter: { operator: 'AND', value: [] },
  order: [['id', 'DESC']],
  offset: 0,
  limit: 10,
};
export const ACTIONS = getActions(ENTITY.PEGASYS_ERROR);
export const ROW_ACTIONS = {
  [STATUS.ACTIVE]: [ACTIONS.COMMENT, ACTIONS.MARK_RESOLVED],
  [STATUS.ARCHIVED]: [ACTIONS.COMMENT, ACTIONS.MARK_RESOLVED],
};

export const MODAL_TYPES = {
  COMMENT_MODAL : 'commentModal',
  RESOLVE_MODAL: 'resolveModal'
}

export const returnSelectedActionsData = (original) => {
  const rowData = { ...original };
  let rowComment = '';
  let rowUpdate = false;
  const checkDocType = !flatFileTypes.includes(original.documentType);
  const actionsData = checkDocType ? rowData.wsControlLogActionModel : rowData.batchCorrelationMapActionModel;
  const controlLogId = checkDocType ? rowData.pegasysSoapControlLogId : rowData.id;

  if (actionsData && actionsData.comment) {
    rowComment = actionsData.comment;
  }
  if (actionsData && actionsData.isResolved) {
    rowUpdate = actionsData.isResolved;
  }
  const rowCommentData = {
    controlLogId,
    documentNumber: rowData.documentNumber,
    comment: rowComment,
    isResolved: rowUpdate,
  };

  return rowCommentData;
};

export const returnValidatedData = (data) => {
  const allVins = [];
  const allDocNumbers = [];
  data.forEach((li) => {
    if (li.vin) allVins.push(li.vin);
    if (li.documentNumber) allDocNumbers.push(li.documentNumber);
  });

  return { allVins, allDocNumbers };
};

export const returnUpdatedFilters = (value, payload, filter) => {
  const filtersObj = _.cloneDeep(payload);
  const updatedList = filtersObj.filters?.value?.filter((item) => item.key !== filter.key) || [];
  updatedList.push({
    operator: filter.operator,
    key: filter.key,
    value,
  });
  return {...filtersObj, filters: {operator: "AND", value: updatedList}}
}

export const returnClearedFilters = (payload, filter) => {
  const filtersObj = _.cloneDeep(payload, filter);
  const updatedList = filtersObj.filters?.value?.filter((item) => item.key !== filter.key);
  return {...filtersObj, filters: {operator: "AND", value: updatedList}}
}

const downloadRequestXML = (requestPath) => {
  return window.open(requestPath);
};

export const rowSubComponent = ({ row: { original = {} } }) => {
  const checkDocType = !flatFileTypes.includes(original.documentType);
  return (
    <div className='grid-container'>
      <div className='grid-row grid-gap-6'>
        <div className='tablet:grid-col'>
          <div className='grid-row bm-subrow-grid-row'>
            <div className='tablet:grid-col bm-subrow-title'>Error Details</div>
          </div>
          <div className='grid-row'>
            {original.errorItems?.errors.map((error, i) => {
              return (
                <div>
                  <p>
                    {' '}
                    {i + 1}. Error Code: {error.errorCode}
                  </p>
                  <p>Error Description: {error.errorDescription}</p>
                </div>
              );
            })}
          </div>
          <div className='grid-row'>
            <div className='tablet:grid-col bm-subrow-title'>Comments</div>
          </div>
          <div className='grid-row'>
            <div className='tablet:grid-col'>
              <p>
                {checkDocType
                  ? original.wsControlLogActionModel?.comment
                  : original.batchCorrelationMapActionModel?.comment}
              </p>
            </div>
          </div>
        </div>
        <div className='tablet:grid-col'>
          {checkDocType && (
            <div className='grid-row bm-subrow-grid-row'>
              <div className='tablet:grid-col bm-subrow-title'>Request XML</div>
              <div className='tablet:grid-col text-right'>
                {original.requestPathS3BucketUrl ? (
                  <Button
                    type='button'
                    data-testId='download-req-button'
                    variant='unstyled'
                    onClick={() => downloadRequestXML(original.requestPathS3BucketUrl)}
                    label='Download Sent Request'
                  />
                ) : (
                  'NA'
                )}
              </div>
            </div>
          )}
          <div className='grid-row bm-subrow-grid-row'>
            <div className='tablet:grid-col bm-subrow-title'>Status</div>
            <div className='tablet:grid-col text-right'>
              {original.errorDataStatus ? original.errorDataStatus : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
