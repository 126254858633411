import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveFilterPanel } from '@gsa/afp-component-library';
import './style/afv-funding-filter.scss';
import { getFYInt } from '../../components/helpers/afp-bm-date';

const DEFAULT_FILTER_STATE = { fiscalYear: getFYInt().toString() };
const FilterItems = {
  fiscalYear: {
    id: 'fiscalYear',
    title: 'Fiscal year',
    type: 'dropdown',
    expanded: true,
    noChip: true,
  },
  agencyCode: {
    id: 'agencyCode',
    title: 'Agency',
    type: 'combobox',
    expanded: true,
    noChip: true,
  },
};

const getFiscalYear = () => {
  const endYear = getFYInt();
  const startYear = endYear - 2;
  const yearList = [];
  for (let i = endYear; i >= startYear; i -= 1) {
    const year = i.toString();
    yearList.push({ value: year, label: year });
  }
  return yearList;
};

const AfvFundingFilter = ({ onFilter, agencyList }) => {
  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    setFilterOptions((prev) => ({
      ...prev,
      agencyCode: agencyList?.map((d) => ({
        value: d.agencyCode,
        label: d.name,
      })),
      fiscalYear: getFiscalYear(),
    }));
  }, [agencyList]);

  const buildFilters = useCallback((state) => {
    return {
      filters: { fiscalYear: state.fiscalYear, agencyCode: state.agencyCode },
    };
  }, []);

  return (
    <div>
      <ResponsiveFilterPanel
        defaultState={DEFAULT_FILTER_STATE}
        items={FilterItems}
        filterOptions={filterOptions}
        onChange={(state) => onFilter(buildFilters(state))}
      />
    </div>
  );
};

AfvFundingFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  agencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AfvFundingFilter;
