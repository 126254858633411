import React, { useEffect } from 'react';
import _ from 'lodash/fp';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { classNameErrBox, classNameErrInput, classNameErrDatePicker } from 'components/helpers/afp-bm-helpers';
import { Label, Textbox, ErrorMessage, DatePicker, SelectDropdown } from '@gsa/afp-component-library';
import {
  autoLabelOptions,
  isRightFormatDate,
  ToUTCFormatDate,
  shortDateFormat,
  MMDDYYYDateFormat,
} from 'utilities/formatUtils';
import AFPTable from 'widgets/afp-table-wrapper';
import { STATUS } from 'components/helpers/constants';
import TrimmedInput from 'components/wrappers/TrimmedInput';
import { getTomorrowStr } from 'components/helpers/afp-bm-date';
import AlertMessage from 'components/header/AlertMessage';
import { maxLengthMap, pageStateToOptInSvcRatesAssoc, pageStateToStartDate } from '../../helper';
import { validationSchema } from '../../model/service/schemas/formSchema';
import RateStructureGroup from './RateStructureGroup';
import TierRateCalculator from './TierRateCalculator';
import { columns as tierRateColumns } from '../../model/service/schemas/tierRateTableSchema';
import { rateStructures } from '../../model/store';

/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */

const rateStructureOptions = autoLabelOptions(rateStructures);

const UpdateOptInServiceForm = ({
  pageState,
  setDispatch,
  DISPATCH_FNS,
  formId,
  isCreate,
  createNewOptInService,
  updateOptInService,
  getServiceNameAndStartDateThenUpdate,
}) => {
  const { handleSubmit, clearErrors, register, errors, control, setValue, setError } = useForm({
    resolver: yupResolver(validationSchema(pageState).service_updateOptInServiceForm),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });
  // use for check if startDate error exist from data controller
  useEffect(() => {
    if (pageState.service_table.rowData.error.startDate) {
      setError(`startDate`, {
        type: 'manual',
        message: `A ${pageState.service_table.rowData.optInSvcName} rate already exists for the start date`,
      });
    }
  }, [pageState.service_table.rowData.error.startDate]);

  const checkStartDateThenSubmit = (variables) => {
    setDispatch(DISPATCH_FNS.updateStateByPath, {
      path: 'service_table.rowData.startDate',
      data: variables.optInSvcInput.startDate,
    });
    getServiceNameAndStartDateThenUpdate({
      variables,
    });
  };

  const validatedSubmit = (data) => {
    const optInSvcCategory = pageState.service_table.rowData.optInSvcCategory
      ? pageState.service_table.rowData.optInSvcCategory
      : pageState.serviceFilter.options.optInSvcCategory[0];

    const optInSvcSalesId = pageState.service_table.rowData.optInSvcSalesId
      ? pageState.service_table.rowData.optInSvcSalesId
      : pageState.distinctOptInSalesCodeOptions[0]?.value;

    const { optInSvcDesc, optInSvcName } = pageState.service_table.rowData;
    const optInSvcInput = {
      optInSvcCategory,
      optInSvcSalesId,
      optInSvcDesc,
      optInSvcName,
      startDate: `${ToUTCFormatDate({ dateVal: data.startDate, format: shortDateFormat })}T00:00:00.000Z`,
    };

    const optInSvcRatesAssoc = pageStateToOptInSvcRatesAssoc(pageState);

    const variables = isCreate
      ? { optInSvcInput: { ...optInSvcInput, optInSvcSalesId: _.toString(optInSvcSalesId) }, optInSvcRatesAssoc }
      : {
          optInSvcInput: { ...optInSvcInput, optInSvcSalesId: _.toString(optInSvcSalesId) },
          optInSvcRatesAssoc,
          optInSvcId: pageState.service_table.rowData.optInSvcId,
        };

    setDispatch(DISPATCH_FNS.service_beforeSubmitOptInServiceForm, { formData: variables, isCreate });
    if (pageState.service_table.rowData.rateStatus === STATUS.ACTIVE) {
      // if the start data has been verified the submit will trigger on onComplete
      // startDate is init when create optInSvcInput
      setTimeout(() => {
        checkStartDateThenSubmit(variables);
      }, 0);
      return;
    }

    isCreate
      ? createNewOptInService({
          variables,
        })
      : updateOptInService({
          variables,
        });
  };

  const onSubmit = async (data) => {
    validatedSubmit(data);
  };
  const createErrorMessage = (err) => (err ? <ErrorMessage>{err.message}</ErrorMessage> : null);
  const optInSvcNameValue = pageState.service_table?.rowData.optInSvcName;
  const optInSvcDescValue = pageState.service_table?.rowData.optInSvcDesc;
  return (
    <div className="grid-row">
      <div className="grid-col-10">
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <div className="form-ra">
            <div className="modal-grid-container">
              <div className="grid-row">
                <div className="grid-col-5">
                  <SelectDropdown
                    required={isCreate}
                    id="optInSvcCategory"
                    data-testid="optInSvcCategory"
                    name="category"
                    value={pageState.service_table.rowData.optInSvcCategory}
                    label="Category"
                    disabled={!isCreate}
                    options={autoLabelOptions(pageState.categoryFilter.options.OptInCategoryName)}
                    onChange={(e) => {
                      setDispatch(DISPATCH_FNS.updateStateByPath, {
                        path: 'service_table.rowData.optInSvcCategory',
                        data: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="grid-col-5 grid-offset-1">
                  <div className={`usa-form-group ${classNameErrBox(errors?.optInSvcName)}`}>
                    <Label htmlFor="optInSvcName" required={isCreate}>
                      Service
                    </Label>
                    {createErrorMessage(errors?.optInSvcName)}
                    <TrimmedInput
                      readOnly={!isCreate}
                      id="optInSvcName"
                      data-testid="optInSvcName"
                      className={classNameErrInput(errors?.optInSvcName)}
                      name="optInSvcName"
                      maxLength={maxLengthMap.input}
                      aria-label="Service required."
                      value={optInSvcNameValue}
                      onChange={(e) => {
                        setDispatch(DISPATCH_FNS.updateStateByPath, {
                          path: 'service_table.rowData.optInSvcName',
                          data: e.target.value,
                        });
                      }}
                      inputRef={register}
                    />
                    {isCreate && (
                      <div className="usa-hint usa-character-count__message">
                        {optInSvcNameValue?.length <= maxLengthMap.input
                          ? `${maxLengthMap.input - optInSvcNameValue.length} characters remaining`
                          : `${maxLengthMap.input} characters allowed`}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid-row">
                <div className="grid-col-11">
                  <div className={`usa-form-group ${classNameErrBox(errors?.optInSvcDesc)}`}>
                    <Label htmlFor="optInSvcDesc" required>
                      Rate application
                    </Label>
                    {createErrorMessage(errors?.optInSvcDesc)}
                    <Textbox
                      id="optInSvcDesc"
                      data-testid="optInSvcDesc"
                      className={classNameErrInput(errors?.optInSvcDesc)}
                      name="optInSvcDesc"
                      type="textarea"
                      maxLength={maxLengthMap.textarea}
                      rows={3}
                      aria-label="  Rate application  required."
                      value={optInSvcDescValue}
                      onChange={(e) => {
                        setDispatch(DISPATCH_FNS.updateStateByPath, {
                          path: 'service_table.rowData.optInSvcDesc',
                          data: e.target.value,
                        });
                      }}
                      inputRef={register}
                    />
                    <div className="usa-hint usa-character-count__message">
                      {optInSvcDescValue?.length <= maxLengthMap.textarea
                        ? `${maxLengthMap.textarea - optInSvcDescValue?.length} characters remaining`
                        : `${maxLengthMap.textarea} characters allowed`}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-row">
                <div className="grid-col-5">
                  <SelectDropdown
                    required
                    id="optInSvcSalesId"
                    data-testid="optInSvcSalesId"
                    name="optInSalesCode"
                    value={pageState.service_table.rowData.optInSvcSalesId}
                    label="Sales code"
                    options={pageState.distinctOptInSalesCodeOptions}
                    onChange={(e) => {
                      setDispatch(DISPATCH_FNS.updateStateByPath, {
                        path: 'service_table.rowData.optInSvcSalesId',
                        data: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="grid-col-5 grid-offset-1">
                  <SelectDropdown
                    required
                    id="rateStructure"
                    data-testid="rateStructure"
                    name="rateStructure"
                    value={pageState.service_table.rowData.rateStructure}
                    label="Rate application structure"
                    options={rateStructureOptions}
                    onChange={(e) => {
                      setDispatch(DISPATCH_FNS.updateStateByPath, {
                        path: 'service_table.rowData.rateStructure',
                        data: e.target.value,
                      });
                    }}
                    inputRef={register}
                  />
                </div>
              </div>

              <div className="grid-row">
                <div className="grid-col-11">
                  {pageState.service_table.rowData.rateStructure === rateStructures[0] ? (
                    <RateStructureGroup
                      setDispatch={setDispatch}
                      pageState={pageState}
                      DISPATCH_FNS={DISPATCH_FNS}
                      register={register}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  ) : (
                    <>
                      <h4 className="color-blue margin-top-4">{_.toUpper('Add or update tier rate')}</h4>
                      <TierRateCalculator
                        setDispatch={setDispatch}
                        pageState={pageState}
                        DISPATCH_FNS={DISPATCH_FNS}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                      />{' '}
                      {pageState.service_table.rowData.tierRate.bannerMsgState.content && (
                        <AlertMessage
                          className="margin-top-2"
                          type="success"
                          onClose={() => {
                            setDispatch(DISPATCH_FNS.updateStateByPath, {
                              path: 'service_table.rowData.tierRate.bannerMsgState.content',
                              data: null,
                            });
                          }}
                          content={pageState.service_table.rowData.tierRate.bannerMsgState.content}
                        />
                      )}
                      <AFPTable
                        isLoading={false}
                        columns={tierRateColumns({ setDispatch, DISPATCH_FNS, clearErrors })}
                        data={pageState.service_table.rowData.tierRate.ratesRows}
                        defaultSort={[['lowerRange', 'ASC']]}
                        NoDataMessages={{
                          title: 'No Service Available',
                          text: 'There are no matches for the filtered values at left.',
                        }}
                        className="service-table"
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="grid-row margin-top-0">
                <div className="grid-col-4" style={{ minWidth: 300 }}>
                  <div className={`usa-form-group ${classNameErrBox(errors.startDate)}`}>
                    <Label htmlFor="effective-start-date" required>
                      Start date
                    </Label>
                    <div className="usa-hint" id="start-date">
                      mm/dd/yyyy
                    </div>
                    {createErrorMessage(errors.startDate)}
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={pageState.service_table.rowData.startDate}
                      render={({ name, value, onBlur }) => (
                        <DatePicker
                          id="start-date"
                          name={name}
                          className={classNameErrDatePicker(errors.startDate)}
                          defaultValue={pageStateToStartDate(pageState)}
                          placeholder={
                            value
                              ? ToUTCFormatDate({
                                  dateVal: value,
                                  format: MMDDYYYDateFormat,
                                })
                              : null
                          }
                          minDate={getTomorrowStr()}
                          aria-label="Start Date selection input required."
                          onBlur={onBlur}
                          disabled={pageState.service_table.rowData.rateStatus === 'Pending'}
                          onChange={(dateVal) => {
                            if (isRightFormatDate({ dateVal })) {
                              setValue('startDate', isRightFormatDate({ dateVal }) ? dateVal : null);
                            }
                            if (pageState.service_table.rowData.error.startDate) {
                              clearErrors('startDate');

                              setDispatch(DISPATCH_FNS.updateModalAlert, {
                                type: null,
                                content: null,
                                isCategory: false,
                              });
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOptInServiceForm;
