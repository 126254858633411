import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { RequiredFieldIndicator, SelectDropdown, Button, Spinner, TextInput } from '@gsa/afp-component-library';
import { scrollInto } from 'utilities/bmAppUtilities';
import { CREATE_WALLET_BASIC_FIELDS, CREATE_WALLET_ASSOCIATION_FIELDS, FLEET_OFFERING_OPTIONS } from './consts';
import { useWalletDetails } from '../wallet-details-provider';

const getFleetOfferingById = (id) => {
  if (!id) {
    return '-';
  }
  return FLEET_OFFERING_OPTIONS.find((offering) => offering.value === id.toString())?.label;
};

const getBoacById = (id, list) => {
  if (!id || !list || list.length === 0) {
    return '-';
  }
  return list.find((item) => item.boacId === id.toString())?.boac;
};

const BoacOfferingFormFields = () => {
  const [displayBoac, setDisplayBoac] = useState('');
  const [validBoac, setValidBoac] = useState(false);
  const history = useHistory();
  const {
    wallet,
    getScopedBoacs,
    scopedBoacs,
    dispatchAction,
    boacOfferingAssociation,
    validateBoacOffering,
    validatingBoacOffering,
  } = useWalletDetails();

  const {
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useFormContext();

  const watchAgency = watch(CREATE_WALLET_BASIC_FIELDS.agency.name);
  const watchBureau = watch(CREATE_WALLET_BASIC_FIELDS.bureau.name);

  const handleBoac = (boac) => {
    let selectedBoac = boac;
    if (!selectedBoac) {
      selectedBoac = displayBoac;
    }
    const matchedBoacId = scopedBoacs?.find(
      (scopedBoac) => scopedBoac?.boac?.toLowerCase() === selectedBoac.toLowerCase(),
    )?.boacId;
    const alphanumericRegExp = /^[a-zA-Z0-9]+$/i;
    if (!selectedBoac) {
      setError(
        CREATE_WALLET_ASSOCIATION_FIELDS.boac.name,
        {
          type: 'manual',
          message: 'BOAC is a required field',
        },
        { shouldFocus: true },
      );
    } else if (selectedBoac.length !== CREATE_WALLET_ASSOCIATION_FIELDS.boac.maxLength - 1) {
      setError(
        CREATE_WALLET_ASSOCIATION_FIELDS.boac.name,
        {
          type: 'manual',
          message: 'Must be 6 characters',
        },
        { shouldFocus: true },
      );
    } else if (!alphanumericRegExp.test(selectedBoac)) {
      setError(
        CREATE_WALLET_ASSOCIATION_FIELDS.boac.name,
        {
          type: 'manual',
          message: 'No special characters allowed',
        },
        { shouldFocus: true },
      );
    } else if (!matchedBoacId) {
      setError(
        CREATE_WALLET_ASSOCIATION_FIELDS.boac.name,
        {
          type: 'manual',
          message: 'Invalid BOAC',
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors(CREATE_WALLET_ASSOCIATION_FIELDS.boac.name);
      setValue(CREATE_WALLET_ASSOCIATION_FIELDS.boac.name, matchedBoacId);
      setValidBoac(true);
    }
  };

  useEffect(() => {
    if (watchAgency && watchBureau) {
      getScopedBoacs({
        variables: {
          getBoacsArgs: {
            agencyCode: watchAgency,
            bureauCode: watchBureau,
          },
        },
      });
    }
  }, [watchAgency, watchBureau]);

  useEffect(() => {
    if (scopedBoacs?.length > 0 && displayBoac.length > 0) {
      handleBoac();
    }
  }, [scopedBoacs]);

  useEffect(() => {
    dispatchAction('SET_BOAC_OFFERING_ASSOCIATION', undefined);
  }, []);

  const addAssociation = async () => {
    handleBoac();
    // validate basic and association fields before moving on
    await trigger([
      CREATE_WALLET_BASIC_FIELDS.fiscalYear.name,
      CREATE_WALLET_BASIC_FIELDS.agency.name,
      CREATE_WALLET_BASIC_FIELDS.bureau.name,
      CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name,
    ]);
    if (validBoac && isEmpty(errors)) {
      const response = await validateBoacOffering({
        variables: {
          fiscalYear: Number(getValues(CREATE_WALLET_BASIC_FIELDS.fiscalYear.name)),
          boacId: Number(getValues(CREATE_WALLET_ASSOCIATION_FIELDS.boac.name)),
          salesGroupId: Number(getValues(CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name)),
        },
      });
      if (response?.data?.validateBoacOffering?.length === 0) {
        // store selected values to be used in read-only and form payload
        dispatchAction('SET_BOAC_OFFERING_ASSOCIATION', {
          fiscalYear: getValues(CREATE_WALLET_BASIC_FIELDS.fiscalYear.name),
          agency: getValues(CREATE_WALLET_BASIC_FIELDS.agency.name),
          bureau: getValues(CREATE_WALLET_BASIC_FIELDS.bureau.name),
          boacId: getValues(CREATE_WALLET_ASSOCIATION_FIELDS.boac.name),
          salesGroupId: getValues(CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name),
        });
        scrollInto('tas-info');
      }
    }
  };

  if (!isEmpty(boacOfferingAssociation)) {
    return (
      <div className="grid-row grid-gap">
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_ASSOCIATION_FIELDS.boac.label}{' '}
            {CREATE_WALLET_ASSOCIATION_FIELDS.boac.required && <RequiredFieldIndicator />}
          </div>
          {getBoacById(boacOfferingAssociation?.boacId, scopedBoacs)}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.label}{' '}
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.required && <RequiredFieldIndicator />}
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip &&
              CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip}
          </div>
          {getFleetOfferingById(boacOfferingAssociation?.salesGroupId)}
        </div>
      </div>
    );
  }

  if (wallet) {
    return (
      <div className="grid-row grid-gap">
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_ASSOCIATION_FIELDS.boac.label}{' '}
            {CREATE_WALLET_ASSOCIATION_FIELDS.boac.required && <RequiredFieldIndicator />}
          </div>
          {getBoacById(wallet?.boacId, scopedBoacs)}
        </div>
        <div className="grid-col-3">
          <div className="text-bold margin-top-2">
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.label}{' '}
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.required && <RequiredFieldIndicator />}
            {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip &&
              CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip}
          </div>
          {getFleetOfferingById(wallet?.salesGroupId)}
        </div>
      </div>
    );
  }

  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-3">
        <Controller
          name={CREATE_WALLET_ASSOCIATION_FIELDS.boac.name}
          control={control}
          render={() => {
            return (
              <TextInput
                data-testid={`wallet-create-${CREATE_WALLET_ASSOCIATION_FIELDS.boac.name}`}
                type="text"
                name={CREATE_WALLET_ASSOCIATION_FIELDS.boac.name}
                id={CREATE_WALLET_ASSOCIATION_FIELDS.boac.id}
                disabled={CREATE_WALLET_ASSOCIATION_FIELDS.boac.disabled}
                value={displayBoac}
                maxLength={CREATE_WALLET_ASSOCIATION_FIELDS.boac.maxLength}
                hint={CREATE_WALLET_ASSOCIATION_FIELDS.boac.hint}
                onChange={(e) => {
                  setDisplayBoac(e.target.value);
                }}
                onBlur={(e) => {
                  handleBoac(e.target.value);
                }}
                label={
                  <span className="text-bold">
                    {CREATE_WALLET_ASSOCIATION_FIELDS.boac.label}{' '}
                    {CREATE_WALLET_ASSOCIATION_FIELDS.boac.required && <RequiredFieldIndicator />}
                    {CREATE_WALLET_ASSOCIATION_FIELDS.boac.toolTip && CREATE_WALLET_ASSOCIATION_FIELDS.boac.toolTip}
                  </span>
                }
                errorMessage={
                  errors &&
                  errors[CREATE_WALLET_ASSOCIATION_FIELDS.boac.name] &&
                  !CREATE_WALLET_ASSOCIATION_FIELDS.boac.disabled
                    ? errors[CREATE_WALLET_ASSOCIATION_FIELDS.boac.name].message
                    : null
                }
                aria-invalid={
                  errors &&
                  errors[CREATE_WALLET_ASSOCIATION_FIELDS.boac.name] &&
                  !CREATE_WALLET_ASSOCIATION_FIELDS.boac.disabled
                    ? 'true'
                    : 'false'
                }
              />
            );
          }}
        />
      </div>
      <div className="grid-col-3">
        <Controller
          name={CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name}
          control={control}
          render={({ value, onChange, onBlur }) => {
            return (
              <SelectDropdown
                data-testid={`wallet-create-${CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.id}`}
                label={
                  <span className="text-bold">
                    {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.label}{' '}
                    {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.required && <RequiredFieldIndicator />}
                    {CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip &&
                      CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.toolTip}
                  </span>
                }
                name={CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name}
                id={CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.id}
                options={CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.options}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name, e.target.value);
                }}
                onBlur={onBlur}
                errorMessage={
                  errors && errors[CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name]
                    ? errors[CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name].message
                    : null
                }
                aria-invalid={errors && errors[CREATE_WALLET_ASSOCIATION_FIELDS.fleetOffering.name] ? 'true' : 'false'}
              />
            );
          }}
        />
      </div>
      <div className="grid-col-2 display-flex flex-align-center">
        <div className="grid-row grid-gap">
          <div className="grid-col-12">
            <Button
              id="add-boac-offering"
              data-testid="add-boac-offering"
              disabled={validatingBoacOffering}
              onClick={addAssociation}
              label="Add"
            />
            <Button
              variant="unstyled"
              id="cancel-boac-offering"
              data-testid="cancel-boac-offering"
              onClick={() => {
                history.push({
                  pathname: `/bm/wallet`,
                  search: window.location.search,
                });
              }}
              label="Cancel"
            />
          </div>
          {validatingBoacOffering && <Spinner size="small" />}
        </div>
      </div>
    </div>
  );
};

export default BoacOfferingFormFields;
