import {gql} from "@apollo/client";

export const GET_PEGASYS_WS_ERRORS = gql`
query GetWsError(
  $filters: FilterType
  $virtualFilter: FilterType
  $order: OrderBy
  $offset: Int
  $limit: Int
){
  getWsError(
      filters: $filters
      virtualFilter: $virtualFilter
      order: $order
      offset: $offset
      limit: $limit
  ){
    rows{
    pegasysSoapControlLogId
    interfaceName
    tableName
    errorItems
    documentType
    documentNumber
    contextNumber
    vin
    requestPathS3BucketUrl
    createdAt
    errorDataStatus
    wsControlLogActionModel{
    comment
    isResolved
    }
    }
    count
    hasMore
  }
}`

export const ADD_OR_UPDATE_WS_ERR_COMMENT = gql`
mutation addOrEditWsErrorActionComment(
    $pegasysSoapControlLogId: Float!
    $comment: String!
  ){
  addOrEditWsErrorActionComment(
    pegasysSoapControlLogId: $pegasysSoapControlLogId
    comment:$comment
  ){
    id
    pegasysSoapControlLogId
    comment
    
  }
}`
export const ADD_OR_UPDATE_WS_ERR_RESOLVED = gql`
mutation addOrEditWsErrorActionResolved(
    $pegasysSoapControlLogId: Float!
    $isResolved: Boolean!
  ){
  addOrEditWsErrorActionResolved(
    pegasysSoapControlLogId: $pegasysSoapControlLogId
    isResolved:$isResolved
  ){
    id
    pegasysSoapControlLogId
    comment
    isResolved
  }
}`
;

export const GET_BATCH_ERRORS = gql`
query GetFlatFileError(
  $filters: FilterType
  $virtualFilter: FilterType
  $order: OrderBy
  $offset: Int
  $limit: Int
){
  getBatchError(
    filters: $filters
    virtualFilter: $virtualFilter
    order: $order
    offset: $offset,
    limit: $limit,
  ) {
    rows {
      id
      controlLogId
      recordId
      refTableId
      refTableName
      vin
      status
      createdAt
      createdBy
      documentType
      documentNumber
      errorDataStatus
      errorItems
      batchCorrelationMapActionModel {
        comment
        isResolved
        createdBy
        createdAt
      }
    }
    count
    hasMore
  }
}`
;

export const GET_ALL_BATCH_ERRORS = gql`
query GetAllFlatFileError{
  getBatchError{
    rows {
      id
      controlLogId
      recordId
      refTableId
      refTableName
      vin
      status
      createdAt
      createdBy
      documentType
      documentNumber
      errorDataStatus
      errorItems
      batchCorrelationMapActionModel {
        comment
        isResolved
        createdBy
        createdAt
      }
    }
    count
    hasMore
  }
}`
;

export const GET_ALL_PEGASYS_WS_ERRORS = gql`
query GetAllWsError{
  getWsError{
    rows{
    pegasysSoapControlLogId
    interfaceName
    tableName
    errorItems
    documentType
    documentNumber
    contextNumber
    vin
    requestPathS3BucketUrl
    createdAt
    errorDataStatus
    wsControlLogActionModel{
    comment
    isResolved
    }
    }
    count
    hasMore
  }
}`

export const ADD_OR_UPDATE_FF_ERR_COMMENT = gql`
mutation addOrEditBatchErrorActionComment(
    $batchCorrelationMapId: Float!
    $comment: String!
  ){
    addOrEditBatchErrorActionComment(
      batchCorrelationMapId: $batchCorrelationMapId
    comment:$comment
  ){
    batchCorrelationMapId
      comment
      createdAt
      createdBy
      isResolved
    
  }
}`

export const ADD_OR_UPDATE_FF_ERR_RESOLVED = gql`
mutation addOrEditBatchErrorActionResolved(
    $batchCorrelationMapId: Float!
    $isResolved: Boolean!
  ){
  addOrEditBatchErrorActionResolved(
    batchCorrelationMapId: $batchCorrelationMapId
    isResolved:$isResolved
  ){
    batchCorrelationMapId
    comment
    createdAt
    createdBy
    isResolved
  }
}`
;

export const GET_DISTINCT_WS_VINS_AND_DOCS = gql`
query getDistinctWSVinAndDocNbr {
  getDistinctWSVinAndDocNbr
}`;

export const GET_DISTINCT_BATCH_VINS_AND_DOCS = gql`
query getDistinctBatchVinAndDocNbr {
  getDistinctBatchVinAndDocNbr
}`;