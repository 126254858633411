import { gql } from '@apollo/client';
import { getTSDateStrToUS, UTCDateStrToUS, USDateStrToUTC } from 'components/helpers/afp-bm-date';

export const PaginatedLeaseRateResponse = gql`
  fragment PaginatedLeaseRateFields on PaginatedLeaseRateResponse {
    rows {
      leaseRateCode
      leaseRateDescription
      rateStatus
      monthlyRate
      mileageRate
      avgAgeAtSale
      optionalEquipMarkup
      usefulLifeInMonth
      highCostThresholdMarkup
      optionalEquipMarkup
      salvagePercent
      startDate
      endDate
      comment
      lastUpdateUserInfo {
        fullName
        email
      }
      leaseRateAssociation {
        fuelType
        standardItemNumber
      }
      createdByUser
      createdAt
      updatedByUser
      updatedAt
      deletedAt
    }
    count
    hasMore
  }
`;

export const LeaseRateResponse = gql`
  fragment UpdateLeaseRateResponseFields on LeaseRate {
    leaseRateCode
    leaseRateDescription
    rateStatus
    monthlyRate
    mileageRate
    avgAgeAtSale
    optionalEquipMarkup
    usefulLifeInMonth
    highCostThresholdMarkup
    salvagePercent
    startDate
    endDate
    updatedAt
  }
`;

export const GET_LEASE_RATES = gql`
  query getLeaseRates(
    $associationFilter: FilterType
    $virtualFilter: FilterType
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getLeaseRates(
      associationFilter: $associationFilter
      virtualFilter: $virtualFilter
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      ...PaginatedLeaseRateFields
    }
  }
  ${PaginatedLeaseRateResponse}
`;

export const GET_LEASE_RATE_BY_LRC_START_DATE = gql`
  query getLeaseRateByRateCodeStartDate($leaseRateCode: String!, $startDate: String!) {
    getLeaseRateByRateCodeStartDate(leaseRateCode: $leaseRateCode, startDate: $startDate) {
      ...UpdateLeaseRateResponseFields
    }
  }
  ${LeaseRateResponse}
`;

export const UPDATE_LEASE_RATE = gql`
  mutation updateLeaseRate($leaseRateCode: String!, $leaseRateInput: LeaseRateInput!) {
    updateLeaseRate(leaseRateCode: $leaseRateCode, leaseRateInput: $leaseRateInput) {
      ...UpdateLeaseRateResponseFields
    }
  }
  ${LeaseRateResponse}
`;

export const BULK_UPDATE_LEASE_RATE = gql`
  mutation bulkUpdateLeaseRate($bulkLeaseRateInputs: [BulkLeaseRateInput!]!) {
    bulkUpdateLeaseRate(bulkLeaseRateInputs: $bulkLeaseRateInputs)
  }
`;

export const CREATE_LEASE_RATE = gql`
  mutation createLeaseRate(
    $leaseRateCode: String!
    $leaseRateInput: LeaseRateInput!
    $sinFuelTypeAssoc: [SinFuelTypeAssoc!]!
  ) {
    createLeaseRate(
      leaseRateCode: $leaseRateCode
      leaseRateInput: $leaseRateInput
      sinFuelTypeAssoc: $sinFuelTypeAssoc
    ) {
      leaseRateCode
      leaseRateDescription
      rateStatus
      monthlyRate
      mileageRate
      avgAgeAtSale
      usefulLifeInMonth
      highCostThresholdMarkup
      salvagePercent
      optionalEquipMarkup
      startDate
      endDate
      updatedAt
    }
  }
`;

export const ADD_SIN_NUM_FUEL_TYPE_TO_LRC = gql`
  mutation createLeaseRateAssociationForExistingLRC($leaseRateCode: String!, $sinNumber: String!, $fuelType: String!) {
    createLeaseRateAssociationForExistingLRC(
      leaseRateCode: $leaseRateCode
      sinNumber: $sinNumber
      fuelType: $fuelType
    ) {
      leaseRateCode
      standardItemNumber
      fuelType
    }
  }
`;

export const MOVE_SIN_NUM_FUEL_TYPE_TO_NEW_LRC = gql`
  mutation moveSinNumberFuelTypeToNewLeaseRateCode(
    $oldLeaseRateCode: String!
    $newLeaseRateCode: String!
    $sinNumber: String!
    $fuelType: String!
  ) {
    moveSinNumberFuelTypeToNewLeaseRateCode(
      oldLeaseRateCode: $oldLeaseRateCode
      newLeaseRateCode: $newLeaseRateCode
      sinNumber: $sinNumber
      fuelType: $fuelType
    )
  }
`;

export const LEASE_RATE_FILTER_OPTIONS = gql`
  query getLeaseRateTypeAheadOptions($filters: [Filter!]) {
    getLeaseRateTypeAheadOptions(filters: $filters)
  }
`;

export const LEASE_RATE_TYPEAHEAD_OPTIONS_SEARCH = gql`
  query getLeaseRateTypeAheadOptions($key: String!, $search: String) {
    getLeaseRateTypeAheadOptions(key: $key, search: $search)
  }
`;

export const LEASE_RATE_ASSOCIATE_TYPEAHEAD_OPTIONS_SEARCH = gql`
  query getAssociationsTypeAheadOptions($key: String!, $search: String) {
    getAssociationsTypeAheadOptions(key: $key, search: $search)
  }
`;

export const GET_LRC_START_DATE_RANGE = gql`
  query getLrcStartDateRange {
    getLrcStartDateRange {
      startDate
    }
  }
`;

export const GET_DISTINCT_LEASE_RATE_CODE = gql`
  query {
    getDistinctLeaseRateCode {
      leaseRateCode
    }
  }
`;

export const GET_LEASE_RATE_WITH_ASSOC = gql`
  query getLeaseRateWithAssociation($leaseRateCode: String!, $startDate: String!) {
    getLeaseRateWithAssociation(leaseRateCode: $leaseRateCode, startDate: $startDate) {
      leaseRateCode
      leaseRateDescription
      monthlyRate
      mileageRate
      avgAgeAtSale
      usefulLifeInMonth
      highCostThresholdMarkup
      salvagePercent
      startDate
      endDate
      leaseRateAssociation {
        fuelType
        standardItemNumber
      }
    }
  }
`;

export const DELETE_LEASE_RATE_CODE_SIN_NUM_FUEL_TYPE = gql`
  mutation deleteLeaseRateCodeSinNumberFuelType($leaseRateCode: String!, $sinNumber: String!, $fuelType: String!) {
    deleteLeaseRateCodeSinNumberFuelType(leaseRateCode: $leaseRateCode, sinNumber: $sinNumber, fuelType: $fuelType)
  }
`;

export const DELETE_LEASE_RATE_CODE = gql`
  mutation deleteLeaseRateCode($leaseRateCode: String!, $rateStatus: String!, $startDate: String!) {
    deleteLeaseRateCode(leaseRateCode: $leaseRateCode, rateStatus: $rateStatus, startDate: $startDate)
  }
`;

export const VERIFY_LEASE_RATE_ASSOC_EXIST = gql`
  query verifyLeaseRateAssocExist($sinNumber: String!, $fuelType: String!) {
    verifyLeaseRateAssocExist(sinNumber: $sinNumber, fuelType: $fuelType) {
      leaseRateCode
      standardItemNumber
      fuelType
    }
  }
`;

export const DEACTIVATE_LEASE_RATE_CODE = gql`
  mutation deactivateLeaseRateCode(
    $leaseRateCode: String!
    $rateStatus: String!
    $comment: String!
    $startDate: String!
  ) {
    deactivateLeaseRateCode(
      leaseRateCode: $leaseRateCode
      rateStatus: $rateStatus
      comment: $comment
      startDate: $startDate
    )
  }
`;

export const REACTIVATE_LEASE_RATE_CODE = gql`
  mutation reactivateLeaseRate($leaseRateCode: String!, $rateStatus: String!, $comment: String!, $startDate: String!) {
    reactivateLeaseRate(
      leaseRateCode: $leaseRateCode
      rateStatus: $rateStatus
      comment: $comment
      startDate: $startDate
    )
  }
`;

const dateConverter = (dateStr) => UTCDateStrToUS(dateStr) || '';
export const CSVColumns = [
  { header: 'Lease Rate Code', accessor: 'leaseRateCode' },
  { header: 'Status', accessor: 'rateStatus' },
  {
    header: 'Standard Item Number - Fuel Type',
    accessor: 'leaseRateAssociation',
    converter: (data) => {
      if (!data?.length) return '';
      return data.map((lra) => `${lra.standardItemNumber} - ${lra.fuelType}`).join('\n');
    },
  },
  { header: 'Monthly rate', accessor: 'monthlyRate' },
  { header: 'Mileage rate', accessor: 'mileageRate' },
  {
    header: 'Average daily rate',
    accessor: 'monthlyRate',
    converter: (data) => data && parseFloat((data * 12) / 365).toFixed(2),
  },

  { header: 'Salvage percent', accessor: 'salvagePercent' },
  { header: 'Useful life', accessor: 'usefulLifeInMonth' },
  { header: 'High cost threshold markup', accessor: 'highCostThresholdMarkup' },
  { header: 'Average age at sale', accessor: 'avgAgeAtSale' },
  { header: 'Optional equipment markup', accessor: 'optionalEquipMarkup' },
  { header: 'Start date', accessor: 'startDate', converter: dateConverter },
  { header: 'End date', accessor: 'endDate', converter: dateConverter },

  { header: 'Last edited by', accessor: 'lastUpdateUserInfo', converter: (data) => data?.fullName || '' },
  {
    header: 'Last edited date',
    converter: (data, row) => getTSDateStrToUS(row.deletedAt || row.updatedAt || row.createdAt),
  },
];

const regex200char = /^.{1,200}$/;
const regexInt = /^\d+$/;
const regexDecimal2 = /^\d+([.]\d{1,2}[0]{0,2})?$/;
const regexDecimal3 = /^\d+([.]\d{1,3}[0]?)?$/;
const regexPercent = /^\d{1,2}([.][0]{1,4})?$/;
const regexDate = /^(0?[1-9]|1[0-2])\/((0?[1-9])|([12]\d))\/20[1-9]\d$/;
const genParseFloatFn = (regex) => (data) => {
  if (!regex.test(data)) return null;
  return parseFloat(data).toFixed(4);
};

export const LRTemplateColumns = [
  {
    header: 'Lease Rate Code',
    subheader: 'Read only',
    accessor: 'leaseRateCode',
    converter: (data) => `#${data}`,
    parser: (data, list) => {
      const [hashtag, ...rest] = data || '';
      const lrc = rest.join('');
      if (hashtag === '#' && list.some((item) => item === lrc)) return lrc;
      return null;
    },
  },
  { header: 'Description', subheader: '200 characters max', accessor: 'leaseRateDescription', regex: regex200char },
  {
    header: 'Monthly rate',
    subheader: 'Up to 2 decimal places',
    accessor: 'monthlyRate',
    parser: genParseFloatFn(regexDecimal2),
  },
  {
    header: 'Mileage rate',
    subheader: 'Up to 3 decimal places',
    accessor: 'mileageRate',
    parser: genParseFloatFn(regexDecimal3),
  },
  {
    header: 'Salvage percent',
    subheader: 'integer between 1 and 99',
    accessor: 'salvagePercent',
    parser: genParseFloatFn(regexPercent),
    isNumber: true,
  },
  {
    header: 'Useful life',
    subheader: 'positive number in month',
    accessor: 'usefulLifeInMonth',
    regex: regexInt,
    isNumber: true,
  },
  {
    header: 'Average age at sale',
    subheader: 'positive number in month',
    accessor: 'avgAgeAtSale',
    regex: regexInt,
    isNumber: true,
  },
  {
    header: 'Optional equipment markup',
    subheader: 'Up to 2 decimal places',
    accessor: 'optionalEquipMarkup',
    parser: genParseFloatFn(regexDecimal2),
  },
  {
    header: 'High cost threshold markup',
    subheader: 'Up to 2 decimal places',
    accessor: 'highCostThresholdMarkup',
    parser: genParseFloatFn(regexDecimal2),
  },
  {
    header: 'Start date',
    subheader: 'Mon/Day/YYYY',
    accessor: 'startDate',
    converter: dateConverter,
    parser: (data) => {
      if (!regexDate.test(data)) return null;
      return USDateStrToUTC(data);
    },
  },
];

export const parseData2CSV = (data, cols, hasSubheader = false) => {
  const headers = cols.map((col) => col.header || '');
  let subheaders = null;
  if (hasSubheader) subheaders = cols.map((col) => col.subheader || '');
  const items = data.map((row) =>
    cols.map((col) => {
      const val = col.accessor ? row?.[col.accessor] : null;
      return (col.converter ? col.converter(val, row) : val) || '';
    }),
  );
  return { headers, subheaders, items };
};
