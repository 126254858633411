export const fracToPercent = (d) => (d ? (Math.round(d * 10000) / 100).toString() : d);

export const parseDollar = (f) => (f ? parseFloat(f).toFixed(2) : f);
export const parsePercent = (p) => (p ? (parseFloat(p) / 100).toFixed(4) : p);

export const omit = (keys, obj) =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const formatName = (name) => {
  if (!name) return '';
  return name
    .split(' ')
    .map((c) => c.substr(0, 1).toUpperCase() + c.substr(1).toLowerCase())
    .join(' ');
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6)}`;
};

export const formatAddress = (uniqueRateAppFcs) => {
  if (uniqueRateAppFcs) {
    let { address1, address2, address3, city, state, zipcode, zipcode4 } = uniqueRateAppFcs;

    address1 = address1 ? `${address1}\n` : '';
    address2 = address2 ? `${address2}\n` : '';
    address3 = address3 ? `${address3}\n` : '';
    city = city ? `${city},` : '';
    if (!state) state = '';
    if (!zipcode) zipcode = '';
    zipcode4 = zipcode4 ? `-${zipcode4}` : '';

    if (!(address1 || address2 || address3 || city || state || zipcode)) {
      return '';
    }
    return `${address1}${address2}${address3}${city} ${state} ${zipcode}${zipcode4}`;
  }

  return '';
};

export const formatDollar = (val, prefix = '$', decimal = 2) => {
  let num;
  let sign = '';
  switch (typeof val) {
    case 'string':
      num = parseFloat(val);
      break;
    case 'number':
      num = val;
      break;
    default:
      return '';
  }
  if (num < 0) {
    sign = '-';
    num = -num;
  }
  let numStr;
  if (decimal < 0) numStr = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else numStr = num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return `${sign}${prefix}${numStr}`;
};

export const validateDollar = (val) => {
  if (!val) return false;
  return val.match(/^\d{1,10}(\.\d{0,2})?$/);
};

export const classNameErrBox = (err) => (err ? 'border-left-05 bm-error-border-color padding-left-2' : '');
export const classNameErrInput = (err) => (err ? 'border-05 bm-error-border-color' : '');
export const classNameErrDatePicker = (err) => (err ? 'bm-rate-date-picker-error' : '');
