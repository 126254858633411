/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useHistory, useLocation } from 'react-router-dom';
import { AFPTable, EmptyState, Menu, Pagination, Spinner, Tooltip, Icon } from '@gsa/afp-component-library';
import { getCurrentFiscalYear } from 'utilities/dateUtils';
import { ENTITY, ROLE_OP } from '../../components/role-permission/role-permission';
import { useWalletFilter } from './filters/filter-provider';
import { useWallet } from './wallet-provider';
import { WalletSubDetail } from './wallet-sub-details';
import { CREATE_WALLET_SUCCESS_MESSAGE, UPDATE_WALLET_SUCCESS_MESSAGE } from './messages';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    action: 'editWallet',
    operation: ROLE_OP.UPDATE,
    // canShowIndicator: 'managesWallet',
  },
];

const PRIMARY_ORDER = ['updatedAt', 'DESC'];
const SECONDARY_ORDER = ['fiscalYear', 'DESC'];
const DEFAULT_ORDER = [PRIMARY_ORDER, SECONDARY_ORDER];

const tableRef = React.createRef();

const WalletListingTable = () => {
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const currentFY = getCurrentFiscalYear();

  const [order, setOrder] = useState(DEFAULT_ORDER);
  const ability = useAppAbility();
  const history = useHistory();
  
  const { getWalletsList, walletsListLoading, walletsList, dispatchAction } = useWallet();
  const location = useLocation();
  const { filters } = useWalletFilter();

  const onSort = (val) => {
    const res = val?.split(' ');
    if (!res?.length) return;
    const primaryOrder = [...res[0].split('`')[1].split('.'), res[1]];
    if (primaryOrder[0] === PRIMARY_ORDER[0]) setOrder([primaryOrder, SECONDARY_ORDER]);
    else setOrder([primaryOrder, PRIMARY_ORDER]);
  };

  const getData = () => {
    getWalletsList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  useEffect(() => {
    if (location) {
      let successMessage;
      const { createdWallet, updatedWallet } = location?.state ?? '';
      if (createdWallet) {
        successMessage = CREATE_WALLET_SUCCESS_MESSAGE.body(
          createdWallet.boacModel.boac,
          createdWallet.fiscalYear,
          createdWallet.walletSalesGroupModel.name,
        );
      }
      if (updatedWallet) {
        successMessage = UPDATE_WALLET_SUCCESS_MESSAGE.body(
          updatedWallet.boacModel.boac,
          updatedWallet.fiscalYear,
          updatedWallet.walletSalesGroupModel.name,
        );
      }
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: successMessage,
      });
    } else {
      dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    }
  }, [location]);

  useEffect(() => {
    getData();
  }, [paginationState, order, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
    });
  }, [filters]);

  const handleSelectedAction = (action, rowData) => {
    switch (action) {
      case 'editWallet':
        history.push({
          pathname: `wallet/${rowData.id}`,
          search: window.location.search,
        });
        break;
      default:
        break;
    }
  };

  const allowedActions = actionList.filter((action) => {
    return ability.can(action.operation, ENTITY.WALLET);
  });

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: (
          <div className="display-flex flex-align-center">
            Fiscal year
            <Tooltip
              className="usa-button--unstyled"
              label={'This reflects the fiscal year that the line \n of accounting is valid'}
              position="bottom"
            >
              <Icon iconName="info" className="text-primary margin-left-1 text-ink" />
            </Tooltip>
          </div>
        ),
        accessor: 'fiscalYear',
        sortable: true,
      },
      {
        Header: 'BOAC',
        accessor: 'boacModel.boac',
        sortable: true,
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Fleet offering
            <Tooltip
              className="usa-button--unstyled"
              label={
                'The Fleet segments in which a unique line of accounting may be \n applied. \n\n Leasing Non-Accidents is comprised of all leasing charges related to \n monthly, mileage, telematics, AFV surcharge, non-accident AIEs, and \n other Fleet leasing costs. \n\n Leasing-Accidents is comprised of all leasing charges related to your \n accident AIEs. \n\n Rental is comprised of all charges associated with short term rentals. \n\n Purchasing is comprised of all costs associated with purchasing \n vehicles owned by your agency.'
              }
              position="bottom"
            >
              <Icon iconName="info" className="text-primary margin-left-1 text-ink" />
            </Tooltip>
          </div>
        ),
        accessor: 'walletSalesGroupModel.name',
        sortable: true,
        Cell: ({ value }) => {
          return value.toLowerCase() === 'rental' || value.toLowerCase() === 'short term rental'
            ? 'Short Term Rental'
            : value;
        },
      },
      {
        Header: 'Treasury Account Symbol (TAS)',
        accessor: 'agencyIdentifier',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return original?.tas || '\u2014';
        },
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Fiscal Station Number (FSN)
            <Tooltip className="usa-button--unstyled" label="Only applies to DOD lines of accounting" position="left">
              <Icon iconName="info" className="text-primary margin-left-1 text-ink" />
            </Tooltip>
          </div>
        ),
        accessor: 'fiscalStation',
        Cell: ({ row: { original } }) => {
          return original?.isDoD ? original.fiscalStation : 'NA';
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          if (allowedActions?.length === 0 || original?.fiscalYear < currentFY) {
            return <>{'\u2014'}</>;
          }
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={allowedActions}
                onActionClick={(action) => {
                  // eslint-disable-next-line
                  handleSelectedAction(action, original);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <WalletSubDetail {...original} />
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="wallet-listing-table"
        columns={columns}
        data={!walletsListLoading ? walletsList.rows : []}
        defaultSort={order}
        onSort={onSort}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {walletsListLoading && (
        <div data-testid="wallets-no-data">
          <Spinner className="padding-y-9" />
        </div>
      )}
      {walletsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={walletsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!walletsList || walletsList.rows.length === 0) && !walletsListLoading && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState alt="Image not available" hasBackground />
          </div>
          <div className="text-center text-bold" data-testid="wallets-no-data">
            No Wallets available
          </div>
        </div>
      )}
    </>
  );
};

export default WalletListingTable;
