import _ from 'lodash';

export const updateSort = ({ originalSort = [[]], orderStr = '' }) => {
  const orderStrs = orderStr.split(' ');
  if (orderStrs.length !== 2) {
    throw new Error('wrong orderStr');
  }
  const onSortColumnName = _.trim(orderStrs[0], '`');
  const onSortColumnOrder = orderStrs[1];
  return [[onSortColumnName, onSortColumnOrder], ..._.reject(originalSort, (x) => x[0] === onSortColumnName)];
};

export const scrollInto = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
}