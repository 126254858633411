import React from 'react';
import _ from 'lodash/fp';
import { Button } from '@gsa/afp-component-library';
import { stateValue } from 'utilities/stateUtils';
import { safeParseFloat } from 'utilities/formatUtils';
import { useForm } from 'react-hook-form';
import RateInputComponent from 'components/widgets/RateInput';

/* eslint-disable react/prop-types */

const FundingAdjustmentForm = ({ pageState, setDispatch, DISPATCH_FNS }) => {
  const { handleSubmit, setError, ...fieldProps } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      replacement: stateValue(pageState.fundingAdjustment.replacement),
      consolidation: stateValue(pageState.fundingAdjustment.consolidation),
      additional: stateValue(pageState.fundingAdjustment.additional),
    },
  });
  // use round and *100 because the float math problem
  const hasError = _.curry((data, fieldName) => {
    const adjustFund = _.get(`${fieldName}`, data);
    const fieldValue = stateValue(pageState[`initialFunding_${fieldName}`]);
    const newFund = safeParseFloat(fieldValue) + safeParseFloat(adjustFund);
    if (newFund < -0.0001) {
      setError(`${fieldName}`, {
        type: 'manual',
        message: 'Funding may not be negative.',
      });
      return true;
    }
    const amountSpentValue = stateValue(pageState.amountSpent[`${fieldName}`]);

    if (newFund - safeParseFloat(amountSpentValue) < -0.0001) {
      setError(`${fieldName}`, {
        type: 'manual',
        message: 'Amount spent exceeds funding.',
      });
      return true;
    }
    return false;
  });

  const onSubmit = async (data) => {
    const failValidation = _.flow(
      _.map(hasError(data)),
      _.some(_.eq(true)),
    )(['replacement', 'consolidation', 'additional']);
    if (failValidation) {
      setDispatch(DISPATCH_FNS.newFunding.reset);
      return;
    }
    setDispatch(DISPATCH_FNS.fundingAdjustment.setCalculate);
  };

  const responsiveGridClass = `tablet:grid-col-2`;
  const labelNameFn = (name) => `adjust ${name}`;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="acquisition-funding-adjust-form">
      <div className="grid-row grid-gap bm-form-row">
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.fundingAdjustment.replacement)}
            path="fundingAdjustment.replacement"
            name="replacement"
            labelNameFn={labelNameFn}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.fundingAdjustment.consolidation)}
            path="fundingAdjustment.consolidation"
            name="consolidation"
            labelNameFn={labelNameFn}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <RateInputComponent
            setDispatch={setDispatch}
            dispatchFn={DISPATCH_FNS.updateStateByPath}
            value={stateValue(pageState.fundingAdjustment.additional)}
            path="fundingAdjustment.additional"
            name="additional"
            labelNameFn={labelNameFn}
            {...fieldProps}
          />
        </div>
        <div className={responsiveGridClass}>
          <div className="bm-form-row-button">
            <Button
              size="sm"
              data-testid="calculate-button"
              aria-label="calculate-funding-adjustment"
              hidden="hidden"
              type="submit"
              variant="outline"
              label="Calculate"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default FundingAdjustmentForm;
