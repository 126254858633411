import React from 'react';
import LoadingComponent from 'components/widgets/LoadingComponent';
import { hasValue } from 'utilities/formatUtils';
/* eslint-disable react/prop-types */

const LoadingQueryResult = ({ children, onLoading, loadingObject = null }) => {
  if (loadingObject) {
    return hasValue(true)(loadingObject) ? <LoadingComponent /> : children;
  }
  return onLoading ? <LoadingComponent /> : children;
};

export default LoadingQueryResult;
