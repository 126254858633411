import React, { useState, useRef } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from 'widgets/breadcrumbs';
import BannerMessage from 'widgets/banner-message';
import WelcomeMessage from 'widgets/welcome-message';
import UniqueRateAppPage from '../unique-rate-app-listings';

const TITLE = 'Unique Rate Applications';

const UniqueRateAppContent = () => {
  useTitle(TITLE);
  const [msg, setMsg] = useState(null);
  const bannerRef = useRef(null);

  // callback function for child component to set banner message
  const onSetMsg = (message) => {
    setMsg(message);
    bannerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="grid-col margin-bottom-6">
      <Breadcrumbs
        current={TITLE}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <div ref={bannerRef}>
        {msg && (
          <BannerMessage className="margin-top-2" type={msg.type} onClose={() => setMsg(null)}>
            {msg.message}
          </BannerMessage>
        )}
      </div>
      <PageTitle title={TITLE} />
      <WelcomeMessage
        className="leaseintro"
        message="Use this page to view the status of unique rate requests or perform appropriate actions based on your need."
      />
      <UniqueRateAppPage setBannerMsg={onSetMsg} />
    </div>
  );
};

export default UniqueRateAppContent;
