import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { DeleteModal } from 'components/modals/delete-modal/delete-modal';
import { STATUS } from 'components/helpers/constants';
import { SinFuelTypeTable } from './sin-fuel-type-table/sin-fuel-type-table';
import { GET_DISTINCT_LEASE_RATE_CODE } from './lease-rate-helper';

const LeaseRateMoveModal = ({ onClose, data, isCreate }) => {
  const { leaseRateCode, rateStatus } = data;
  const leaseRateAssociation = data.leaseRateAssociation || [];
  const isNew = isCreate || rateStatus === STATUS.INACTIVE;

  const [distinctCodes, setDistinctCodes] = useState();
  const [refresh, setRefresh] = useState(false);

  const { loading: loadingDistinctCodes, data: distinctCodesData } = useQuery(GET_DISTINCT_LEASE_RATE_CODE);

  useEffect(() => {
    if (distinctCodesData) {
      const { getDistinctLeaseRateCode } = distinctCodesData;
      const codes = getDistinctLeaseRateCode
        .map(({ leaseRateCode: code }) => ({
          value: code,
          label: code,
        }))
        .filter(({ value }) => value !== leaseRateCode);

      setDistinctCodes(codes);
    }
  }, [distinctCodesData]);

  const getInstruction = () => {
    if (isNew)
      return (
        <>
          <p>
            Lease Rate Code {leaseRateCode} has been successfully {isCreate ? 'created' : 'reactivated'}.
          </p>
          <p>
            Please add associated Standard Item Number and fuel type combinations now, or add them later via
            &quot;Action -&gt; Modify combination&quot; in the Lease Rate table.
          </p>
        </>
      );
    return <p>Use this window to add, move, and delete associated Standard Item Number and fuel type combinations</p>;
  };

  return (
    <DeleteModal
      id="lease-rate-move"
      variant="extra-large"
      title={`Lease Rate Code ${leaseRateCode}`}
      onClose={() => onClose(refresh)}
      deleteButtonText=""
      cancelButtonText="Close"
    >
      <h2 id="lease-rate-update-subtitle">
        {isNew ? 'Add' : 'Modify'} Standard Item Number and fuel type combination(s)
      </h2>
      {getInstruction()}
      {loadingDistinctCodes && (
        <div style={{ top: '-50px', width: '100%' }}>
          <Spinner />
        </div>
      )}
      {!loadingDistinctCodes && distinctCodes && leaseRateAssociation && (
        <SinFuelTypeTable
          leaseRateCode={leaseRateCode}
          leaseRateAssociation={leaseRateAssociation}
          distinctCodes={distinctCodes}
          setRefresh={setRefresh}
        />
      )}
    </DeleteModal>
  );
};

LeaseRateMoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    leaseRateCode: PropTypes.string,
    rateStatus: PropTypes.string,
    leaseRateAssociation: PropTypes.instanceOf(Array),
  }).isRequired,
  isCreate: PropTypes.bool.isRequired,
};

export default LeaseRateMoveModal;
