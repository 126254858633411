import { gql } from '@apollo/client';

// AFP-111829 - Getting sales code from BM service api
export const DISTINCT_OPT_IN_SALES_CODES = gql`
query getDistinctSalesCodes {
  getDistinctSalesCodes {
    code
    description
  }
}
`;
