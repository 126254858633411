import React from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import WalletDetailsProvider from './wallet-details-provider';
import WalletDetailsHeader from './wallet-details-header';
import ManageWalletForm from './forms/manage-wallet-form';

const EditBoac = () => {
  useTitle('Edit Line of Accounting');

  return (
    <>
      <WalletDetailsProvider>
        <div className="margin-bottom-3">
          <WalletDetailsHeader />
        </div>
        <ManageWalletForm />
      </WalletDetailsProvider>
    </>
  );
};

export default EditBoac;
