import { getCurrentFiscalYear } from 'utilities/dateUtils';
import AgencyFilterItem from './agency-filter-item';
import BureauFilterItem from './bureau-filter-item';
import BoacFilterItem from './boac-filter-item';

const fiscalYear = getCurrentFiscalYear();
const fiscalYearOptions = [fiscalYear-1, fiscalYear, fiscalYear+1];
const fiscalYearOptionsFormatted = fiscalYearOptions.map((year) => ({
  value: year,
  label: year,
}));

export const generateWalletFilterStructure = () => {
  return [
    {
      title: 'Organization',
      key: 'agency-bureau-boac',
      filters: [
        {
          key: '$boacModel.agency_code$',
          title: 'Agency',
          component: AgencyFilterItem,
          permanent: false,
          operator: 'EQ',
          value: '',
        },
        {
          key: '$boacModel.bureau_code$',
          title: 'Bureau',
          component: BureauFilterItem,
          placeholder: '- Select bureau -',
          noResults: 'No bureaus found',
          permanent: false,
          operator: 'IN',
        },
        {
          key: '$boacModel.boac$',
          operator: 'EQ',
          component: BoacFilterItem,
          noResultsText: "No BOACs found for selected Agency and Bureau.",
          ariaLabel: "Default Typeahead",
          placeholder: "-Enter BOAC-",
          promptText: "Search requires 3 characters. Please make sure to select an Agency and Bureau first.",
          id: "boac-tp",
          inputCharNum: 3,
          debounceDelay: 500,
        },
      ],
    },
    {
      title: 'Fiscal year',
      key: '$fiscal_year$',
      type: 'select',
      operator: 'EQ',
      value: [],
      hideClear: true,
      options: [{ value: '', label: '-Select-' }, ...fiscalYearOptionsFormatted],
    },
    {
      title: 'Fleet offering',
      key: '$sales_group_id$',
      type: 'multiselect',
      operator: 'IN',
      value: [],
      hideClear: true,
      options: [
        { value: "1", label: 'Purchasing' },
        { value: '3', label: 'Leasing-Accidents' },
        { value: '4', label: 'Leasing-Non-Accidents' },
        { value: '2', label: 'Short Term Rental' },
      ],
    },
  ];
};