import { gql } from '@apollo/client';

export const OptInServiceResponse = gql`
  fragment OptInServiceResponseFields on OptInService {
    optInSvcId
    optInSvcName
    optInSvcDesc
    optInSvcCategory
    rateStatus
    optInSvcSalesId
    deletedAt
    updatedByUser
    updatedAt
    createdByUser
    createdAt
    dataSource
    dmlFlag
  }
`;

export const UPDATE_OPT_IN_SERVICE = gql`
  mutation updateOptInSvc(
    $optInSvcId: String!
    $optInSvcInput: OptInSvcInput!
    $optInSvcRatesAssoc: OptInSvcRatesAssoc!
  ) {
    updateOptInSvc(optInSvcId: $optInSvcId, optInSvcInput: $optInSvcInput, optInSvcRatesAssoc: $optInSvcRatesAssoc) {
      ...OptInServiceResponseFields
    }
  }
  ${OptInServiceResponse}
`;
