import React from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useWallet } from './wallet-provider';

const WalletHeader = () => {
  useTitle('Wallet');
  const { alertMessage } = useWallet();
  const { header, message, type } = alertMessage;

  return (
    <>
      {message && (
        <div className="margin-top-2" data-testid="wallet-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      )}
      <header>
        <PageTitle title="Wallet" />
        <div className="margin-bottom-4 tablet:grid-col-7" data-testid="wallets-list-page-description">
          <p>
            Please use this page to view your Agency/Bureau BOACs (Billing Office Address Code) and their Line of
            Accounting information. Line of Accounting data will include the Treasury Account Symbol (TAS) and
            Supplemental Accounting information. If your BOAC is not found, please take proper action to add your line
            of accounting. Please note, a TAS is required for all federal BOACs for all applicable Fleet Offerings.
          </p>
          <p>
            For more information, please see the{' '}
            <a href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Wallet-User-Guide-v2.pdf`} target="blank">
              user guide
            </a>{' '}
            for Wallet and{' '}
            <a
              href="https://vimeo.com/gsavisualcommunications/review/842952720/8eeb36cb17"
              target="_blank"
              rel="noreferrer"
            >
              How to video
            </a>
            .
          </p>
        </div>
      </header>
    </>
  );
};

export default WalletHeader;
